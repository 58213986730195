import { Spinner } from "@jelly/ui";
import config from "appConfig";
import Chart from "chart.js";
import React, { useEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";

import styles from "./Lesson0301.module.scss";
import { Lesson0301ChartData, Lesson0301ChartDataFdValue, Lesson0301ChartDataMode } from "./Lesson0301ChartData";

interface Lesson0301ChartProps {
  mode: Lesson0301ChartDataMode;
  fdVaue: Lesson0301ChartDataFdValue;
}

const renderLoader = (): React.ReactElement => {
  return (
    <div className={styles.loader}>
      <Spinner />
    </div>
  );
};

const Lesson0301Chart: React.FC<Lesson0301ChartProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();

  const data = useFetch<Lesson0301ChartData>(`${config.ASSETS_URL}/python-data/0301/data.json`);
  const dataIsReady = !!data;

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    if (!chartRef.current) {
      chartRef.current = new Chart(canvasRef.current, {
        type: "scatter",
        data: {
          datasets: [
            {
              label: "FD",
              data: data ? data[props.mode][props.fdVaue] : [],
              borderColor: "#131A4F",
              borderWidth: 1,
              pointBorderWidth: 1,
              pointBackgroundColor: "#131A4F",
              lineTension: 0.4,
              showLine: false,
              fill: false,
              // radius: 0
            },
            {
              label: "sin(x)",
              data: data?.sin || [],
              borderColor: "#FF6040",
              borderWidth: 2,
              lineTension: 0.4,
              showLine: true,
              fill: false,
              radius: 0,
            },
            {
              label: "cos(x)",
              data: data?.cos || [],
              borderColor: "#131A4F",
              borderWidth: 2,
              lineTension: 0.4,
              showLine: true,
              fill: false,
              radius: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          animation: {
            duration: 0,
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "x",
                },
                ticks: {
                  min: 0,
                  max: 6,
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "f[x]",
                },
                ticks: {
                  min: -1.0,
                  max: 1.0,
                },
              },
            ],
          },
          tooltips: {
            enabled: true,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIsReady]);

  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && data) {
      chartRef.current.data.datasets[0].data = data[props.mode][props.fdVaue];
      chartRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode, props.fdVaue]);

  if (!data) {
    return renderLoader();
  }

  return (
    <div>
      <canvas ref={canvasRef} width="700" height="380"></canvas>
    </div>
  );
};

export default Lesson0301Chart;
