import { COLORS } from "../../../../helpers/ant-novak/helpers";

export default class DragPoint {
  constructor(obj) {
    this.parent = obj;
    this.dragPoint = obj.chart.options.dragPoint;
    this.circle = null;
    this.ctx = obj.ctx;

    const canvas = obj.ctx.canvas;

    // ------ mouse down
    canvas.onmouseup = (e) => {
      canvas.onmousemove = null;
    };

    // ------ mouse down
    canvas.onmousedown = (e) => {
      // get the mouse click coordinates x, y [pixels]
      const mouse_coordinates = {
        x: e.pageX - canvas.offsetLeft,
        y: e.pageY - canvas.offsetTop,
      };
      this.mouseOverCircle(mouse_coordinates);
    };
  }

  draw = () => {
    const ctx = this.ctx;
    const { angle, value, radius } = this.dragPoint;
    this.circle = this.parent.getPointPosition(angle, value);

    const circ = new Path2D();
    circ.arc(this.circle.x, this.circle.y, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = COLORS[1];
    ctx.fill(circ);
  };

  isMouseOver = (mouse_coordinates) => {
    const xDist2 = Math.pow(mouse_coordinates.x - this.circle.x, 2);
    const yDist2 = Math.pow(mouse_coordinates.y - this.circle.y, 2);
    return Math.sqrt(xDist2 + yDist2) < this.dragPoint.radius;
  };

  mouseOverCircle = (mouse_coordinates) => {
    // if click on microphone
    if (this.isMouseOver(mouse_coordinates)) {
      // attach a function to mouse event
      this.ctx.canvas.onmousemove = (e) => {
        // new mouse coordinates inside canvas [in pixels]
        const x = e.pageX - this.ctx.canvas.offsetLeft;
        const y = e.pageY - this.ctx.canvas.offsetTop;
        const angle = this.xy2angle(x, y);
        this.dragPoint.fnc(angle);
      };
    }
  };

  xy2angle = (x, y) => {
    const angle = Math.atan2(x - this.parent.xCenter, this.parent.yCenter - y);
    return angle;
  };
}
