import Numpy from "./Numpy";

class LessonData {
  c = 1;
  alpha = 1000;
  x_s = 0;
  y_s = this.c / 2;
  x_r = 0;
  y_r = this.c / 4;

  n = 200;
  xdata = Numpy.linspace(-this.c / 2, this.c / 2, this.n);
  ydata = Numpy.linspace(0, this.c, this.n);

  t_max_plot = 2;
  t = Numpy.linspace(0, this.t_max_plot, 50);
  t_time = Numpy.linspace(0, 1, 400);

  x = Numpy.meshgrid(this.xdata, this.ydata)[0];
  y = Numpy.meshgrid(this.xdata, this.ydata)[1];

  impulse(arr: number[][]): number[][] {
    return arr.map((row) => row.map((val) => Math.exp(-1 * this.alpha * Math.pow(val, 2))));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get_r_s(x: number[][], y: number[][], x_s: number, y_s: number, x_r: number, y_r: number): number[][] {
    let xTemp = Numpy.npArrayAddScalar(x, -1 * x_s);
    let yTemp = Numpy.npArrayAddScalar(y, -1 * y_s);
    xTemp = Numpy.npArrayPow2(xTemp);
    yTemp = Numpy.npArrayPow2(yTemp);
    let sumTemp = Numpy.npArrayAdd(xTemp, yTemp);
    sumTemp = Numpy.npArraySqrt(sumTemp);
    return sumTemp;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  get_r_i(x: number[][], y: number[][], x_s: number, y_s: number, x_r: number, y_r: number): number[][] {
    let xTemp = Numpy.npArrayAddScalar(x, -1 * x_s);
    let yTemp = Numpy.npArrayAddScalar(y, y_s);
    xTemp = Numpy.npArrayPow2(xTemp);
    yTemp = Numpy.npArrayPow2(yTemp);
    let sumTemp = Numpy.npArrayAdd(xTemp, yTemp);
    sumTemp = Numpy.npArraySqrt(sumTemp);
    return sumTemp;
  }

  get_r_is(x_s: number, y_s: number, x_r: number, y_r: number): number[][] {
    return this.get_r_i(this.x, this.y, x_s, y_s, x_r, y_r);
  }

  get_r(
    x_s: number,
    y_s: number,
    x_r: number,
    y_r: number
  ): { r_s: { x: number; y: number }; r_i: { x: number; y: number } } {
    const r_s = Math.sqrt(Math.pow(x_r - x_s, 2) + Math.pow(y_r - y_s, 2));
    const r_i = Math.sqrt(Math.pow(x_r - x_s, 2) + Math.pow(y_r + y_s, 2));

    return {
      r_s: {
        x: r_s,
        y: 1 / r_s,
      },
      r_i: {
        x: r_i,
        y: 1 / r_i,
      },
    };
  }

  get_pressure_map(
    x: number[][],
    y: number[][],
    x_s: number,
    y_s: number,
    x_r: number,
    y_r: number,
    t: number
  ): number[][] {
    let r_s = this.get_r_s(x, y, x_s, y_s, x_r, y_r);
    let r_i = this.get_r_i(x, y, x_s, y_s, x_r, y_r);
    r_s = Numpy.ArrayDivide(r_s, this.c);
    r_i = Numpy.ArrayDivide(r_i, this.c);

    const r_s_t = Numpy.npScalarSubtractArray(t, r_s);
    const r_i_t = Numpy.npScalarSubtractArray(t, r_i);

    let r_s_imp = this.impulse(r_s_t);
    r_s_imp = Numpy.ArrayDivideArray(r_s_imp, r_s);

    let r_i_imp = this.impulse(r_i_t);
    r_i_imp = Numpy.ArrayDivideArray(r_i_imp, r_i);

    return Numpy.npArrayAdd(r_s_imp, r_i_imp);
  }

  draw(xs: number, ys: number, xr: number, yr: number, t: number): number[][] {
    return this.get_pressure_map(this.x, this.y, xs, ys, xr, yr, t);
  }
}

export default LessonData;
