import { fft, util } from "fft-js";

// prepare data:
// - outputSignal ... NLfnc(inputSignal) [in chartjs format]
// - fftBuffer ... first (Nfft) points of Power Spectrum of outputSignal [an array]
export function prepareData(A, NLfnc, Nfft) {
  const N = 128;

  let y = applyNLfnc(NLfnc, sinusOnePeriod(A, N));

  let Y = util.fftMag(fft(y));
  Y[0] = 0; // remove DC component

  let THD_numerator = Y.slice(2).reduce((acc, val) => acc + Math.pow(val, 2), 0);
  let THD_denominator = Y.slice(1).reduce((acc, val) => acc + Math.pow(val, 2), 0);
  let THD = Math.sqrt(THD_numerator / THD_denominator) * 100;

  let outputSignal = Array(2 * N);
  for (let i = 0; i < N; i++) {
    outputSignal[i] = { x: (2 * i) / N, y: y[i] };
    outputSignal[i + N] = { x: (2 * (i + N)) / N, y: y[i] };
  }

  let fftBuffer = Y.slice(0, Nfft).map((i) => 20 * Math.log10((i / N) * 2) + 94 - 3);
  return { outputSignal, fftBuffer, THD };
}

// sine wave signal (one period)
function sinusOnePeriod(A, N) {
  return [...Array(N).keys()].map((i) => A * Math.sin((2 * Math.PI * i) / N));
}

// apply a static nonlinear function NLfnc on a signal x (array)
export function applyNLfnc(NLfnc, x) {
  return x.map((num) => {
    return NLfnc(num);
  });
}
