export const chartLabels = Array.from({ length: 50 }, (_, i) => (i * 0.04).toFixed(2));

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,

  animation: {
    duration: 0,
  },
  legend: {
    display: false,
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
  scales: {
    xAxes: [
      {
        id: "x",
        scaleLabel: {
          display: true,
          labelString: "t (s)",
          fontStyle: "bold",
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "p (t)",
          fontStyle: "bold",
        },
      },
    ],
  },
};

export const chartAnnotations = {
  type: "line",
  display: true,
  scaleID: "x",
  value: 0,
  borderColor: "rgba(0, 0, 128, 1)",
  borderWidth: 3,
};
