/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import MicrophoneCardioGraph from "./MicrophoneCardio.graph";
import { sliders } from "./MicrophoneCardio.helper";

class MicrophoneCardio extends Component {
  // class state
  state = {
    Vab: 23, // back volume [cm^3]
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  renderBlock() {
    const { Vab } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ flex: "1" }}>
          <h5>CARDIOID MICROPHONE</h5>
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <MicrophoneCardioGraph
            Vab={Vab * 1e-6} // back volume, pass value from [cm^3] to [m^3]
          />
        </div>
        <div>
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default MicrophoneCardio;
