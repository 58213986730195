import React, { Component } from "react";
import Chart from "chart.js";
import { COLORS } from "../../../../helpers/ant-novak/helpers";

class BarGraph extends Component {
  myChart = {};
  chartRef = React.createRef();

  componentDidMount() {
    this.myChart = new Chart(
      this.chartRef.current.getContext("2d"), // context
      this.createChartContext() // what to render
    );
  }

  componentDidUpdate() {
    // update chart data for each line in the graph
    this.myChart.data.datasets[0].data = this.props.data.yAxes.data;
    this.myChart.options.title.text = this.props.data.title === undefined ? "" : this.props.data.title;
    this.myChart.update();
  }

  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} width="100%" height={this.props.graph_size_ratio * 100 + "%"} />
      </div>
    );
  }

  createChartContext = () => {
    const x_axis_scale = this.props.data.xAxes.scale === undefined ? 1 : this.props.data.xAxes.scale;

    return {
      type: "bar",
      data: {
        labels: this.props.data.xAxes.data,
        datasets: [
          {
            backgroundColor: COLORS[1],
            data: this.props.data.yAxes.data,
          },
        ],
      },
      options: {
        tooltips: { enabled: false },
        hover: { mode: null },
        title: {
          display: true,
          text: this.props.data.title,
        },
        legend: { display: false },
        animation: {
          duration: this.props.data.animation === undefined ? 100 : this.props.data.animation, // general animation time
        },
        scales: {
          xAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  return value * x_axis_scale;
                },
              },
              scaleLabel: {
                display: true,
                labelString: this.props.data.xAxes.xLabel,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: this.props.data.yAxes.yLabel,
              },
              ticks: {
                min: this.props.data.yAxes.min,
                max: this.props.data.yAxes.max,
              },
            },
          ],
        },
      },
    };
  };
}

export default BarGraph;
