/* eslint-disable */
// @ts-nocheck

// math functions Bessel and Strive

import { complex, isComplex, multiply, divide, add, subtract, abs, pow, sqrt, inv, exp, gamma, pi } from "mathjs";

let div = divide;
let mul = multiply;

export function round(x, decimalPlaces, roundFnc = Math.round, return_value_if_zero) {
  let return_value = 0;
  if (x !== 0) {
    const base10 = Math.log10(Math.abs(x));
    if (base10 > 2) {
      return roundFnc(x);
    }
    decimalPlaces = decimalPlaces === undefined ? 2 - Math.floor(base10) : decimalPlaces;
    return_value = Number(roundFnc(x + "e" + decimalPlaces) + "e-" + decimalPlaces);
  }
  if (isNaN(return_value)) return_value = 0;
  if (return_value_if_zero !== undefined && return_value === 0) return_value = return_value_if_zero;
  return return_value;
}

export function neg(x) {
  return mul(-1, x);
}

export function scientificRound(val) {
  // mantisa & exponent
  let exponent = Math.floor(Math.log10(val));
  let mantisa = val / Math.pow(10, exponent);

  let mantisa_ceil = [1, 1.5, 2, 3, 4, 5, 8, 10].reduce((acc, curr) => {
    return (curr - mantisa >= 0) & (acc > curr) ? curr : acc;
  }, Infinity);

  return mantisa_ceil * Math.pow(10, exponent);
}

// Struve function approximation
export function Struve(x) {
  return (
    2 / pi - besselJ(0, x) + ((16 / pi - 5) * Math.sin(x)) / x + ((12 - 36 / pi) * (1 - Math.cos(x))) / Math.pow(x, 2)
  );
}

// Bessel function J
export function besselJ(n, x) {
  if (isComplex(n) || isComplex(x)) {
    if (!isComplex(n)) n = complex(n);

    if (Number.isInteger(n.re) && n.re < 0 && n.im === 0) return mul(pow(-1, n), besselJ(mul(-1, n), x));

    var product = div(pow(div(x, 2), n), gamma(add(n, 1)));
    return mul(product, hypergeometric0F1(add(n, 1), mul(-0.25, pow(x, 2))));
  }

  if (Number.isInteger(n) && n < 0) return (-1) ** n * besselJ(-n, x);

  if (!Number.isInteger(n) && x < 0) return besselJ(n, complex(x));

  return ((x / 2) ** n * hypergeometric0F1(n + 1, -0.25 * x ** 2)) / gamma(n + 1);
}

// hyper-geometric function 1 for Bessel function J
export function hypergeometric0F1(a, x, tolerance = 1e-10) {
  var useAsymptotic = 100;
  let p, s, i;

  if (isComplex(a) || isComplex(x)) {
    if (!isComplex(a)) a = complex(a);

    if (Number.isInteger(a.re) && a.re <= 0 && a.im === 0) throw Error("Hypergeometric export function pole");

    // asymptotic form as per Johansson arxiv.org/abs/1606.06977
    if (abs(x) > useAsymptotic) {
      // transform variables for convenience
      var b = subtract(mul(2, a), 1);
      a = subtract(a, 1 / 2);
      x = mul(4, sqrt(x));

      // copied from hypergeometric1F1
      var t1 = mul(gamma(b), pow(neg(x), neg(a)), inv(gamma(subtract(b, a))));
      t1 = mul(t1, hypergeometric2F0(a, add(a, neg(b), 1), div(-1, x)));

      var t2 = mul(gamma(b), pow(x, subtract(a, b)), exp(x), inv(gamma(a)));
      t2 = mul(t2, hypergeometric2F0(subtract(b, a), subtract(1, a), div(1, x)));

      return mul(exp(div(x, -2)), add(t1, t2));
    }

    s = complex(1);
    p = complex(1);
    i = 1;

    while (Math.abs(p.re) > tolerance || Math.abs(p.im) > tolerance) {
      p = mul(p, x, inv(a), 1 / i);
      s = add(s, p);
      a = add(a, 1);
      i++;
    }

    return s;
  } else {
    if (Number.isInteger(a) && a <= 0) throw Error("Hypergeometric export function pole");

    // asymptotic form is complex
    if (Math.abs(x) > useAsymptotic) return hypergeometric0F1(a, complex(x)).re;

    s = 1;
    p = 1;
    i = 1;

    while (Math.abs(p) > tolerance) {
      p *= x / a / i;
      s += p;
      a++;
      i++;
    }

    return s;
  }
}

// hyper-geometric function 2 for Bessel function J
export function hypergeometric2F0(a, b, x, tolerance = 1e-10) {
  var terms = 50;

  let s, p, i, pLast;

  if (isComplex(a) || isComplex(b) || isComplex(x)) {
    s = complex(1);
    p = complex(1);
    pLast = p;
    var converging = false;
    i = 1;

    while (Math.abs(p.re) > tolerance || Math.abs(p.im) > tolerance) {
      p = mul(p, x, a, b, 1 / i);

      if (abs(p) > abs(pLast) && converging) break; // prevent runaway sum
      if (abs(p) < abs(pLast)) converging = true;
      if (i > terms) throw Error("Not converging after " + terms + " terms");

      s = add(s, p);
      a = add(a, 1);
      b = add(b, 1);
      i++;
      pLast = p;
    }

    return s;
  } else {
    s = 1;
    p = 1;
    pLast = p;
    converging = false;
    i = 1;

    while (Math.abs(p) > tolerance) {
      p *= (x * a * b) / i;

      if (Math.abs(p) > Math.abs(pLast) && converging) break; // prevent runaway sum
      if (Math.abs(p) < Math.abs(pLast)) converging = true;
      if (i > terms) throw Error("Not converging after " + terms + " terms");

      s += p;
      a++;
      b++;
      i++;
      pLast = p;
    }

    return s;
  }
}
