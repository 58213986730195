import { Chart, ChartData, ChartOptions } from "chart.js";

import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import React, { useEffect, useRef } from "react";

interface Lesson0302ChartProps {
  data: ChartData;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";

const Lesson0302Chart: React.FC<Lesson0302ChartProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    if (!chartRef.current) {
      chartRef.current = new Chart(canvasRef.current, {
        type: "bar",
        data: cloneDeep(props.data),
        options: cloneDeep({ ...props.options, ...{ annotation: { annotations: [props.annotations] } } }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && props.data.datasets) {
      chartRef.current.data.datasets[0].data = props.data.datasets[0].data;
      chartRef.current.update();
    }
  }, [props.data]);

  useEffect(() => {
    if (chartRef.current && props.annotations) {
      // ESLint cannot scan the options from the plugins.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current.options.annotation.annotations = [props.annotations];
      chartRef.current.update();
    }
  }, [props.annotations]);

  return (
    <div style={{ position: "relative", height: "20vh", width: "50vw" }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Lesson0302Chart;
