export const COLORS = [
  "#FF6040", //WaveGuideAnim: Orange
  "#131A4F", //Dark black/navy
  "#D0DAE9",
  "#FBAB00",
  "#2EB6EA",
  "#3AAA35",
  "#FF4141",
  "#FF9A9A",
  "#FFEBA3",
  "#BEEBFC",
  "#C6F1C3",
  "#898CA7",
];
