// calculate resonance frequency Fc
export function polarResponse(beta, N, order) {
  // polar axis (angle)
  // data will contain array of {angle, value}
  let graphDataPlus = Array(N + 2);
  let graphDataMinus = Array(N + 2);

  for (let i = 0; i < N + 1; i++) {
    let angle = (2 * Math.PI * i) / N;
    let value = polarValue(angle, beta, order);
    graphDataPlus[i] = {
      angle: angle,
      value: value >= 0 ? value : NaN,
    };
    graphDataMinus[i] = {
      angle: angle,
      value: value <= 0 ? -value : NaN,
    };
  }

  // set last point to NaN so that the radar graph is not closed (first and last point not connected)
  graphDataPlus[N + 1] = NaN;
  graphDataMinus[N + 1] = NaN;
  return { graphDataPlus: graphDataPlus, graphDataMinus };
}

export function polarValue(angle, beta, order) {
  let out = 1 - beta + beta * Math.cos(angle);
  if (order === 2) {
    //out *= Math.cos(angle); // different definition ()**2
    out *= out;
  }
  return out;
}

export function getPatternName(beta, order) {
  if (order === 1) {
    switch (beta) {
      case 0:
        return " (omnidirectional)";
      case 0.33:
        return " (hypocardioid)";
      case 0.5:
        return " (cardioid)";
      case 0.66:
        return " (supercardioid)";
      case 0.75:
        return " (hypercardioid)";
      case 1:
        return " (bidirectional)";

      default:
        return "";
    }
  }
  return "";
}
