import { Chart, ChartOptions } from "chart.js";
import npyjs from "npyjs";
import config from "appConfig";
import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import { Spinner } from "@jelly/ui";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../helpers/colors";
import { reshape } from "./Helpers";
import { stretchPointsForXY } from "../../helpers/charts";

interface LessonChartAProps {
  t: number;
  n: number;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";

const renderLoader = (): React.ReactElement => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const renderError = (): React.ReactElement => {
  return <div>Could not fetch graph data.</div>;
};

const LessonChartA: React.FC<LessonChartAProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [loadingAdditonal, setLoadingAdditional] = useState<boolean>(true);
  const [mainData, setMainData] = useState<Array<number | null>>([]);
  const [addtionalData, setAdditonalData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const npy = new npyjs();

  const loadMainData = async (t: number, n: number): Promise<void> => {
    const filePrefix = `N${n}_T${t.toFixed(1)}`;
    const url = `${config.ASSETS_URL}/python-data/0305c/dft_red_dots_${filePrefix}.npy`;

    try {
      const npData = await npy.load(url);
      const data = reshape(npData.data, [-1, 2]);

      const xLabels = Array.from({ length: 1000 }, (x, i) => i / 100);
      const ySinPoints = xLabels.map((val) => Math.sin(val * 6.28));

      const extendedData = stretchPointsForXY(data, xLabels);
      const labels = xLabels.map((val) => val.toFixed(2));

      setAdditonalData(ySinPoints);
      setMainData(extendedData);
      setLabels(labels);
      setLoadingAdditional(false);
      setLoadingMain(false);
    } catch (error) {
      setLoadingAdditional(false);
      setLoadingMain(false);
      setError(true);
    }
  };

  useEffect(() => {
    loadMainData(props.t, props.n);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingMain) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingMain, loadingAdditonal]);

  /**
   * Create graph once data is loaded.
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    chartRef.current = new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "s1",
            borderColor: COLORS[0],
            pointBackgroundColor: COLORS[0],
            data: mainData,
            pointRadius: 4,
            borderWidth: 0,
            barThickness: 0,
            showLine: false,
          },
          {
            label: "s2",
            borderColor: COLORS[1],
            fill: false,
            pointRadius: 0,
            data: addtionalData,
            barThickness: 4,
          },
        ],
      },
      options: cloneDeep({ ...props.options }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * Update graph on yData change
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && mainData.length > 0) {
      chartRef.current.data.datasets[0].data = mainData;
      chartRef.current.data.datasets[1].data = addtionalData;
      chartRef.current.update();
    }
  }, [mainData, addtionalData]);

  /**
   * Fetch new data on Tau change
   */
  useEffect(() => {
    loadMainData(props.t, props.n);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.t, props.n]);

  /**
   * Update graph on annotations change
   */
  useEffect(() => {
    if (chartRef.current && props.annotations) {
      // ESLint cannot scan the options from the plugins.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current.options.annotation.annotations = [props.annotations];
      chartRef.current.update();
    }
  }, [props.annotations]);

  if (loading) return renderLoader();
  if (error) return renderError();

  return (
    <div style={{ position: "relative", height: "30vh", width: "80vw" }}>
      <canvas ref={canvasRef} width="700" height="150"></canvas>
    </div>
  );
};

export default LessonChartA;
