import React, { Component } from "react";
import { COLORS } from "../../helpers/ant-novak/helpers";
// import { COLORS } from "../../helpers/helpers.js";

function convertPolynomPoints(arrayPts) {
  //return arrayPts[0][0] + "," + arrayPts[0][1] + " 250, 190, 156, 210";
  return arrayPts
    .map(function (point) {
      return point[0] + "," + point[1];
    })
    .join(" ");
}

class ClosedBoxSystemSVG extends Component {
  render() {
    // List of inputs ("props") :
    //        Vmult ... Volume = Vmult * Vas
    //        Rmult ... Rms_tot = Rmult * Rms
    const { Vmult, Rmult } = this.props;
    const boxWidth = 35 + (Vmult - 0.1) * 40;
    const HP = { x: 70 - boxWidth / 2, y: 30, w: 30, h: 35, l: 30 };
    const BOX = { x: 70 - boxWidth / 2, y: 10, w: boxWidth, h: 120 };
    const PTS = convertPolynomPoints([
      [HP.x + HP.w / 2, HP.y + HP.l / 2],
      [HP.x + HP.w / 2, HP.y + HP.l / 2 + HP.h],
      [HP.x, HP.y + HP.l + HP.h],
      [HP.x, HP.y],
      [HP.x + HP.w / 2, HP.y + HP.l / 2],
      [HP.x + HP.w, HP.y + HP.l / 2],
      [HP.x + HP.w, HP.y + HP.l / 2 + HP.h],
      [HP.x + HP.w / 2, HP.y + HP.l / 2 + HP.h],
    ]);

    return (
      <svg viewBox="0 0 130 140">
        {/*-- BOX --*/}
        <rect
          x={BOX.x}
          y={BOX.y}
          width={BOX.w}
          height={BOX.h}
          style={{ fill: COLORS[2], fillOpacity: Rmult / 5, strokeWidth: 2, stroke: "black" }}
        />
        {/*-- HP --*/}
        <polygon points={PTS} style={{ fill: "white", stroke: COLORS[0], strokeWidth: 2 }} />
      </svg>
    );
  }
}
export default ClosedBoxSystemSVG;
