import { complex, abs, arg, add, divide, pi } from "mathjs";

// calculate input impedance of a Loudspeaker
export function loudspeaker(addMms, f_axis) {
  let Bl = 5.1;
  let Re = 3.8;
  let Le = 0.17e-3;
  let Rms = 0.8;
  let Kms = 2010;
  let Mms = 14.2e-3 + addMms * 1e-3;

  // prepare arrays for Z_in and phase
  let Z_in = [];
  let phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    Z_in[i] = { x: f_axis[i], y: 0 };
    phase[i] = { x: f_axis[i], y: 0 };
  }

  let f, omega, Ze, Zm, Zin;

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    f = f_axis[i];
    omega = 2 * pi * f;

    Ze = complex(Re, omega * Le);
    Zm = complex(Rms, omega * Mms - Kms / omega);

    // loudspeaker impedance
    Zin = add(Ze, divide(Bl * Bl, Zm));

    // impedance
    Z_in[i].y = abs(Zin);
    phase[i].y = (180 / pi) * arg(Zin);
  }
  return { Z_in, phase };
}
