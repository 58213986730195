import React, { Component } from "react";
import LineGraph from "../../../ChartJS/LineGraph";
// import LineGraph from "../../../UI/ChartJS/LineGraph";
import { prepareDataTime } from "../../helpers/WaveGuide.helper.js";

class WaveGuideGraphTime extends Component {
  state = {};
  render() {
    const { pressureSignal, velocitySignal } = prepareDataTime(
      this.props.x,
      this.props.t,
      this.props.physics,
      this.props.signal
    );

    const { pmax, vmax } = this.props.signal.graph_limits;

    const signalData = {
      title:
        this.props.showTitle === true
          ? "Microphone position: x = " + Math.floor(this.props.x * 1000) / 1000 + " m"
          : undefined,
      xAxes: {
        xLabel: "Time (ms)",
        type: "linear",
      },
      yAxes: [
        {
          data: [pressureSignal],
          yLabel: "Acoustic pressure (Pa)",
          min: -pmax,
          max: pmax,
          type: "linear",
        },
        {
          data: [velocitySignal],
          yLabel: "Velocity (mm/s)",
          min: -1000 * vmax,
          max: 1000 * vmax,
          type: "linear",
        },
      ],
      animation: 0,
    };

    return (
      <div style={{ marginRight: "50px" }}>
        <LineGraph data={signalData} graph_size_ratio={0.25} />
      </div>
    );
  }
}

export default WaveGuideGraphTime;
