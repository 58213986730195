import { Chart, ChartOptions } from "chart.js";
import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import { Spinner } from "@jelly/ui";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../helpers/colors";
import { stretchPointsForXY } from "../../helpers/charts";

interface LessonChartAProps {
  fs: number;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";
const f_signal = 440;
const resolution = 4000;

const renderLoader = (): React.ReactElement => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const renderError = (): React.ReactElement => {
  return <div>Could not fetch graph data.</div>;
};

const LessonChartA: React.FC<LessonChartAProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [loadingAdditonal, setLoadingAdditional] = useState<boolean>(true);
  const [mainData, setMainData] = useState<Array<number | null>>([]);
  const [aliasingLineData, setAliasingLineData] = useState<Array<number | null>>([]);
  const [addtionalData, setAdditonalData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  /**
   * This is based on python script and inittialy was dowloading a data.
   * Now it's do in code nad works fine, but excuse me it's strange.
   * @param fs
   */
  const loadMainData = async (fs: number): Promise<void> => {
    setError(false);
    try {
      // Find sampling points
      const tSampled = [];
      for (let i = 0; i < 5 / f_signal; i = i + 1 / Math.round(fs)) {
        tSampled.push([i, 0]);
      }

      // Create X lables (time) for graph
      const xLabels = Array.from({ length: resolution }, (x, i) => (i * (5 / f_signal)) / resolution);

      // Create points for SIN blue graph
      const ySinPoints = xLabels.map((val) => 0.8 * Math.sin(val * 440 * 6.28));

      // Create points for semi-sin orange line
      const aliasingLine = xLabels.map((val) => getSampledValue(fs, val));

      // Stretch sampling points over a X domain
      let extendedData = stretchPointsForXY(tSampled, xLabels);

      // Calculate actuall values in points
      extendedData = extendedData.map((val, i) => {
        if (val != null) {
          val = getSampledValue(fs, xLabels[i]);
        }
        return val;
      });

      // Adjust display of labels
      const labels = xLabels.map((val) => val.toFixed(4));

      setAdditonalData(ySinPoints);
      setMainData(extendedData);
      setAliasingLineData(aliasingLine);
      setLabels(labels);
      setLoadingAdditional(false);
      setLoadingMain(false);
    } catch (error) {
      setLoadingAdditional(false);
      setLoadingMain(false);
      setError(true);
    }
  };

  /**
   * Gets Y value of a sampled point with given Freq (fs) and Time (t)
   * @param fs
   * @param t
   * @returns
   */
  const getSampledValue = (fs: number, t: number): number => {
    const Fs_ = Math.round(fs);
    let f_aliasing = f_signal;

    if (Fs_ < 2 * f_signal) {
      f_aliasing = -(Fs_ - f_signal);
    }

    return 0.8 * Math.sin(f_aliasing * t * 6.28);
  };

  useEffect(() => {
    loadMainData(props.fs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingMain) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingMain, loadingAdditonal]);

  /**
   * Create graph once data is loaded.
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    chartRef.current = new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "s1",
            data: mainData,
            borderColor: COLORS[0],
            backgroundColor: COLORS[0],
            showLine: false,
            fill: true,
            spanGaps: false,
            pointRadius: 4,
            borderWidth: 2,
            barThickness: 2,
          },
          {
            label: "s1line",
            data: aliasingLineData,
            borderColor: COLORS[0],
            showLine: true,
            fill: false,
            spanGaps: true,
            pointRadius: 0,
            borderWidth: 2,
            barThickness: 0,
          },
          {
            label: "s2",
            data: addtionalData,
            borderColor: COLORS[1],
            fill: false,
            pointRadius: 0,
            barThickness: 4,
          },
        ],
      },
      options: cloneDeep({ ...props.options }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * Update graph on yData change
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && mainData.length > 0) {
      chartRef.current.data.datasets[0].data = mainData;
      chartRef.current.data.datasets[1].data = aliasingLineData;
      chartRef.current.data.datasets[2].data = addtionalData;
      chartRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainData, addtionalData]);

  /**
   * Fetch new data on Tau change
   */
  useEffect(() => {
    loadMainData(props.fs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fs]);

  /**
   * Update graph on annotations change
   */
  useEffect(() => {
    if (chartRef.current && props.annotations) {
      // ESLint cannot scan the options from the plugins.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current.options.annotation.annotations = [props.annotations];
      chartRef.current.update();
    }
  }, [props.annotations]);

  if (loading) return renderLoader();
  if (error) return renderError();

  return (
    <div style={{ position: "relative", height: "30vh", width: "80vw" }}>
      <canvas ref={canvasRef} width="700" height="150"></canvas>
    </div>
  );
};

export default LessonChartA;
