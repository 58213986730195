export const speakers = {
  OVERGUNG: 0,
  OVERHUNG_OFF: 1,
  UNDERHUNG: 2,
};

// space graph options
export const speakers_options = [
  {
    title: "Overhung coil (centered)",
    value: speakers.OVERGUNG,
    x_dc: 0,
    file_name_start: "overhung_",
    Bl: [8, 0, 1e3, 0, -5e9],
  },
  {
    title: "Overhung coil (with an offset)",
    value: speakers.OVERHUNG_OFF,
    x_dc: 1,
    file_name_start: "overhung_",
    Bl: [8, 0, 1e3, 0, -5e9],
  },
  {
    title: "Underhung coil",
    value: speakers.UNDERHUNG,
    x_dc: 0,
    file_name_start: "underhung_",
    Bl: [5, 0, 1e4, 0, -2e9],
  },
];

// prepare data:
// - Bl_curve ... Bl(x) [in chartjs format]
export function prepareData(x0, x_max, speaker) {
  const x_dc = speaker.x_dc;

  // get the Bl curve
  const N = 100;
  let Bl_curve = Array(N);
  for (let i = 0; i < N; i++) {
    let x = 2 * (i / N - 0.5) * x_max - x_dc;
    let Bl = speaker.Bl.reduce((acc, Bln, idx) => acc + Bln * Math.pow(x / 1000, idx), 0);
    Bl_curve[i] = { x: x + x_dc, y: Bl };
  }

  // get x as a function of voltage u
  let Bl = speaker.Bl.reduce((acc, Bln, idx) => acc + Bln * Math.pow((x0 - x_dc) / 1000, idx), 0);
  let Bl_point = [{ x: x0, y: Bl }];

  return { Bl_curve, Bl_point };
}

export function getImageIndex(x, x_max, N) {
  let val = Math.round(((-x + x_max + 1) / (2 * x_max)) * 0.8 * N);
  val = val < 0 ? 0 : val;
  val = val > N - 1 ? N - 1 : val;

  return val;
}
