import { Chart, ChartOptions } from "chart.js";
import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import { Spinner } from "@jelly/ui";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../helpers/colors";
import Numpy from "../0302b/Numpy";

interface LessonChartAProps {
  fs: number;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";
const xLabels = Numpy.linspace(-600, 600, 500);

const renderLoader = (): React.ReactElement => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const renderError = (): React.ReactElement => {
  return <div>Could not fetch graph data.</div>;
};

const LessonChartA: React.FC<LessonChartAProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [mainData, setMainData] = useState<Array<number | null>>([]);

  /**
   * This is based on python script and inittialy was dowloading a data.
   * Now it's do in code nad works fine, but excuse me it's strange.
   * @param fs
   */
  const loadMainData = async (fs: number): Promise<void> => {
    setError(false);
    try {
      // Find sampling points

      // Create X lables (time) for graph

      // Create points for SIN blue graph
      const ySinPoints = periodisedSpectrum(fs, xLabels);

      setMainData(ySinPoints);
      setLoadingMain(false);
    } catch (error) {
      setLoadingMain(false);
      setError(true);
    }
  };

  const periodisedSpectrum = (Fs: number, xLabels: number[]): number[] => {
    const f = xLabels;
    let S = new Array(f.length).fill(0);

    for (let n = -10; n <= 10; n++) {
      const shiftedF = f.map((value) => value + n * Fs);
      S = S.map((value, index) => value + spectrum(shiftedF)[index]);
    }

    return S;
  };

  const spectrum = (f: number[]): number[] => {
    return f.map((value) => Math.exp(-1 * ((1.3 * value) / 100) ** 2));
  };

  useEffect(() => {
    loadMainData(props.fs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingMain) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingMain]);

  /**
   * Create graph once data is loaded.
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    chartRef.current = new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels: xLabels.map((val) => val.toFixed(0)),
        datasets: [
          {
            label: "s1line",
            data: spectrum(xLabels),
            borderColor: COLORS[0],
            showLine: true,
            fill: false,
            spanGaps: true,
            pointRadius: 0,
            borderWidth: 2,
            barThickness: 0,
          },
          {
            label: "s1",
            data: mainData,
            borderColor: COLORS[1],
            backgroundColor: COLORS[1],
            showLine: true,
            fill: false,
            spanGaps: false,
            pointRadius: 0,
            borderWidth: 2,
            barThickness: 2,
          },
        ],
      },
      options: cloneDeep({ ...props.options }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * Update graph on yData change
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && mainData.length > 0) {
      chartRef.current.data.datasets[1].data = mainData;
      chartRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainData]);

  /**
   * Fetch new data on Tau change
   */
  useEffect(() => {
    loadMainData(props.fs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fs]);

  /**
   * Update graph on annotations change
   */
  useEffect(() => {
    if (chartRef.current && props.annotations) {
      // ESLint cannot scan the options from the plugins.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current.options.annotation.annotations = [props.annotations];
      chartRef.current.update();
    }
  }, [props.annotations]);

  if (loading) return renderLoader();
  if (error) return renderError();

  return (
    <div style={{ position: "relative", height: "30vh", width: "80vw" }}>
      <canvas ref={canvasRef} width="700" height="150"></canvas>
    </div>
  );
};

export default LessonChartA;
