/* eslint-disable */
// @ts-nocheck

import React, { Component, Fragment } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import { COLORS } from "../../helpers/ant-novak/helpers";
import AdvancedLoudspeakerGraph from "./AdvancedLoudspeaker.graph";
import "./AdvancedLoudspeaker.css";
import { Button } from "@jelly/ui";

const INTRO_MESSAGE =
  "This example uses real data (voltage, current, and velocity) measured on an actual loudspeaker with wide-band signal as explained in the theory part. Try to estimate first the force factor Bl, and then the mass Mms.";

const PAGE_TITLE = ["part 1 of 2: Electrical part", "part 2 of 2: Mechanical part"];

const MAIN_TEXT_MESSAGE = [
  <Fragment>
    Set the value of Bℓ that gets rid of any resonance in apparent impedance curves and click on the button below. Once
    you find a correct value, the button becomes enabled.
  </Fragment>,
  <Fragment>
    Here, you can estimate the mass M<sub>ms</sub> to get the stiffness K<sub>ms</sub> as flat as possible.
  </Fragment>,
];

const BUTTON_MESSAGE = ["Go to estimation of Mechanical part", "Go back to Electromagnetic parameters."];

const FINAL_MESSAGE = [
  <Fragment>
    You have finished the estimation of electrical parameters Bℓ, R<sub>e</sub>(f) and L<sub>e</sub>(f).
  </Fragment>,
  <Fragment>
    You have finished the estimation of mechanical parameters M<sub>ms</sub>, R<sub>ms</sub>(f) and K<sub>ms</sub>(f).
  </Fragment>,
];

const INFO_MESSAGE_PART_1 = [
  <Fragment>
    Due to the eddy currents the parameters L<sub>e</sub>(f) and R<sub>e</sub>(f) are frequency dependent.
    <br />
    The estimated value of Bℓ ={" "}
  </Fragment>,
  <Fragment>
    Due to the surround's and spider's viscoelastic effects, such as creep, the stiffness is not independent of the
    frequency. The estimated value of <sub>Mm</sub>s ={" "}
  </Fragment>,
];
const INFO_MESSAGE_PART_2 = [
  <Fragment>
    {" "}
    T<sub>m</sub> is near to the real force factor value as there is almost no remaining resonance effect around the
    resonant frequency.
  </Fragment>,
  <Fragment>
    {" "}
    g leads to a stiffness that increases slowly with frequency, according to the viscoelastic theory. The losses R
    <sub>ms</sub>(f) decrease with frequency.
  </Fragment>,
];

const style_display = ["block", "none"];

class AdvancedLoudspeaker extends Component {
  // class state
  state = {
    page: 0, // step
    Bl: 0, // resistance [Ohm]
    Mms: 0, // mass [g]
  };
  // slider parameters
  sliders = [
    {
      name: "Bl",
      title: "Bℓ",
      unit: "Tm",
      min: 0,
      max: 10.0,
      step: 0.01,
    },
    {
      name: "Mms",
      title: "M<sub>ms</sub>",
      unit: "g",
      min: 0,
      max: 20.0,
      step: 0.01,
    },
  ];

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for button cliks
  buttonClick = (event) => {
    this.setState({ page: Number(!this.state.page) });
  };

  renderBlock() {
    const { Bl, Mms, page } = this.state;
    const estimated_parameter = page === 0 ? Bl : Mms;
    let show_messages = false;
    let disable_button = true;
    if (page === 0 && Bl > 5.36 && Bl < 5.42) show_messages = true;
    if (page === 1 && Mms < 10.8 && Mms > 10.4) show_messages = true;
    if (page === 1 || show_messages) disable_button = false;

    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
          <div style={{ flex: "1" }}>
            <AdvancedLoudspeakerGraph Bl={Bl} Mms={Mms / 1000} page={page} />
          </div>
          <div>
            <div style={{ display: style_display[page] }}>
              <SliderEdit
                onChange={this.handleChange}
                params={this.sliders[0]}
                val={this.state[this.sliders[0].name]}
              />
            </div>
            <div style={{ display: style_display[1 - page] }}>
              <SliderEdit
                onChange={this.handleChange}
                params={this.sliders[1]}
                val={this.state[this.sliders[1].name]}
              />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "row", height: "5em", paddingTop: "1em" }}>
            <div style={{ fontSize: "70%", textAlign: "left", width: "60%" }}>
              {!show_messages && MAIN_TEXT_MESSAGE[page]}
              {show_messages && (
                <>
                  <span style={{ color: COLORS[0], fontWeight: "700" }}>{FINAL_MESSAGE[page]}</span>
                  <br />
                  {INFO_MESSAGE_PART_1[page]}
                  {estimated_parameter.toString()}
                  {INFO_MESSAGE_PART_2[page]}
                </>
              )}
            </div>
            <div>
              <Button size="small" shape="round" disabled={disable_button} onClick={this.buttonClick}>
                {BUTTON_MESSAGE[page]}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default AdvancedLoudspeaker;
