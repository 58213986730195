import React, { useRef, useMemo, useEffect } from "react";
import * as d3Scale from "d3-scale";
import { axisLeft } from "d3-axis";
import { select } from "d3-selection";

export interface ColorLegendProps {
  minValue: number;
  maxValue: number;
  colorFunction: (val: number) => string;
  width: number;
  height: number;
  upsideDown?: boolean;
}

const AXIS_WIDTH = 30;
const AXIS_Y_MARGIN = 10;

export const ColorLegend: React.FC<ColorLegendProps> = ({
  width,
  height,
  minValue,
  maxValue,
  colorFunction,
  upsideDown,
}) => {
  const axisRef = useRef<SVGGElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const scale = useMemo(
    () =>
      d3Scale
        .scaleLinear()
        .domain(upsideDown ? [maxValue, minValue] : [minValue, maxValue])
        .range([0, height]),
    [height, minValue, maxValue, upsideDown]
  );

  useEffect(() => {
    if (axisRef.current) {
      const axis = select(axisRef.current);
      axis.attr("transform", `translate(${AXIS_WIDTH},${AXIS_Y_MARGIN})`).call(axisLeft(scale));
    }
  }, [scale]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (!canvasRef.current || !ctx) {
      return;
    }

    for (let y = 0; y < height; y++) {
      const val = scale.invert(y);
      const normalizedVal = (val - minValue) / (maxValue - minValue);
      const color = colorFunction(normalizedVal);
      ctx.fillStyle = color;
      ctx.fillRect(0, y, width, y + 1);
    }
  });

  return (
    <div
      style={{
        paddingTop: `${AXIS_Y_MARGIN}px`,
        display: "inline-flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <svg width={AXIS_WIDTH} height={height + AXIS_Y_MARGIN * 2} style={{ marginTop: `-${AXIS_Y_MARGIN}px` }}>
        <g ref={axisRef} />
      </svg>
      <canvas ref={canvasRef} width={width} height={height} />
    </div>
  );
};
