/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import ClosedBoxSystemGraph from "./ClosedBoxSystem.graph";
import ClosedBoxSystemSVG from "./ClosedBoxSystem.svg";

import { sliders } from "./ClosedBoxSystem.helper";

// -------------------------
// Closed-box loudspeaker system simulation
// - Loudspeaker with given TS parameters has a closed-box which can change
//        Vmult ... Volume = Vmult * Vas
//        Rmult ... Rms_tot = Rmult * Rms
// -------------------------

class ClosedBoxSystem extends Component {
  // class state
  state = {
    Vmult: 1, // multiplication factor of volume
    Rmult: 0, // multiplication factor of mechanical resistance
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  renderBlock() {
    const { Vmult, Rmult } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ display: "flex", flexDirection: "row", flex: "1", alignItems: "center" }}>
          <div style={{ width: "20%" }}>
            <ClosedBoxSystemSVG Vmult={Vmult} Rmult={Rmult} />
          </div>
          <div style={{ width: "80%" }}>
            <ClosedBoxSystemGraph Vmult={Vmult} Rmult={Rmult} />
          </div>
        </div>
        <div>
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default ClosedBoxSystem;
