/* eslint-disable */
// @ts-nocheck

import React from "react";
import {
  displacementFnc,
  pressureFnc,
  pressureMinMaxFnc,
  velocityFnc,
} from "../../components/common/ant-novak/waveguide/helpers/WaveGuide.mainEquations";
import { SIGNAL_CHOICE } from "../../components/common/ant-novak/waveguide/helpers/WaveGuide.signals";
import WaveGuide from "../../components/common/ant-novak/waveguide/WaveGuide";
import { COLORS } from "../../helpers/ant-novak/helpers";
import pulse_icon from "../../assets/images/icons/pulse.svg";
import square_icon from "../../assets/images/icons/square.svg";
import harmonic_icon from "../../assets/images/icons/harmonic.svg";
// import React from "react";
// import WaveGuide from "./WaveGuide.jsx";
// import { SIGNAL_CHOICE } from "./helpers/WaveGuide.signals.js";
// import { COLORS } from "../../helpers/helpers.js";
// import {
//   pressureFnc,
//   velocityFnc,
//   displacementFnc,
//   pressureMinMaxFnc,
// } from "./helpers/WaveGuide.mainEquations.js";

const props = {
  colormap: { name: "RdBu", unit: "Pa" },
  microphone: { show: true, x: 0.5 },
  signals: [
    {
      title: "Pulse",
      type: SIGNAL_CHOICE.PULSE,
      freq: { value: 1200 },
      A: { abs: 1.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      B: { abs: 0.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      edit_left_right: true,
      graph_limits: { pmax: 1.5, vmax: 0.005, auto: false },
      time_repeat: 6.5, // current time [ms]
      drawMinMax: pressureMinMaxFnc,
      icon: pulse_icon,
    },

    {
      title: "Square",
      type: SIGNAL_CHOICE.SQUARE,
      freq: { value: 1200 },
      A: { abs: 1.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      B: { abs: 0.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      edit_left_right: true,
      graph_limits: { pmax: 2, vmax: 0.006, auto: false },
      drawMinMax: pressureMinMaxFnc,
      icon: square_icon,
    },
    {
      title: "Harmonic",
      type: SIGNAL_CHOICE.HARMONIC,
      freq: { value: 1600 },
      A: { abs: 1.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      B: { abs: 0.0, arg: 0.0, edit: false, arg_edit: false, inv_edit: false },
      edit_left_right: true,
      graph_limits: { pmax: 2, vmax: 0.006, auto: false },
      drawMinMax: pressureMinMaxFnc,
      icon: harmonic_icon,
    },
  ],
  physics: {
    pressureFnc: pressureFnc,
    velocityFnc: velocityFnc,
    displacementFnc: displacementFnc,
    drawFnc: pressureFnc,
    vectorFnc: velocityFnc,
    open_left: true,
    open_right: true,
    radius: { values: [1.0 / 8, 1.0 / 8], can_be_modified: true },
    rho: [1.2, 1.2],
    c0: [340, 340],
    medium: { edit: true, title: undefined },
    reflection: { edit: false, x: 0.5, show_coefficients: true },
    showWaves: { edit: true, incident: 1, reflected: 1 },
  },
  graphs: { space: true },
  pressure_colors: { can_be_modified: true, show: true },
  velocity_arrows: { can_be_modified: true, show: false, size_ratio: 6000, color: COLORS[1] },
};

export function WaveGuideLesson06() {
  return (
    <div className={"app"}>
      <div className={"my-div"}>
        <WaveGuide {...props} />
      </div>
    </div>
  );
}
