import React, { useState, useEffect } from "react";
import { SwitchOption } from "@jelly/ui";
import "../../WaveGuide.css";

const RESONATOR_TYPES = [
  { idx: 0, name: "1/4 Wave Resonator", phi: 0, closed: true },
  { idx: 1, name: "1/2 Wave Resonator", phi: Math.PI, closed: false },
];

export default function WaveGuideOpenClosed({ properties }) {
  const [resonator_type, setResonatorType] = useState(RESONATOR_TYPES[0]);

  useEffect(() => {
    // update the properties (for other components)
    properties.signal.setReflection({ abs: 1, arg: Number(resonator_type.phi) }, properties.physics);
    properties.flag_recalculate = true;
    properties.physics.closed = resonator_type.closed;
  }, [resonator_type]);

  return (
    <SwitchOption
      items={RESONATOR_TYPES.map((type) => ({ name: type.name }))}
      onClick={(value) => setResonatorType(RESONATOR_TYPES[value])}
    />
  );
}
