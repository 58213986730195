import { F, FS, R } from "./ModulationPerceptionModels";

export function whiteNoise() {
  return Math.random() * 2 - 1;
}

export function sine(a, f, fs, n) {
  return a * Math.sin(2 * Math.PI * f * ((1 / fs) * n));
}

export function modulatedWhiteNoise(modIdx, fMod, fs, n) {
  var theta = Math.PI;
  var modulator = 1 + modIdx * Math.cos(2 * Math.PI * fMod * ((1 / fs) * n) + theta);
  var carrier = whiteNoise();
  return carrier * modulator;
}

export function modulatedSine(modIdx, fMod, a, f, fs, n) {
  var theta = Math.PI;
  var modulator = 1 + modIdx * Math.cos(2 * Math.PI * fMod * ((1 / fs) * n) + theta);
  var carrier = sine(a, f, fs, n);
  return carrier * modulator;
}

export function whiteNoiseFrame(audioFrame, N) {
  for (let n = 0; n < N; n++) {
    audioFrame[n] = whiteNoise();
  }
  return audioFrame;
}

export function sineFrame(audioFrame, a, f, fs, N) {
  for (let n = 0; n < N; n++) {
    audioFrame[n] = sine(a, f, fs, n);
  }
  return audioFrame;
}

export function modulatedWhiteNoiseFrame(audioFrame, modIdx, fMod, fs, N) {
  for (let n = 0; n < N; n++) {
    audioFrame[n] = modulatedWhiteNoise(modIdx, fMod, fs, n);
  }
  return audioFrame;
}

export function modulatedSineFrame(audioFrame, modIdx, fMod, a, f, fs, N) {
  for (let n = 0; n < N; n++) {
    audioFrame[n] = modulatedSine(modIdx, fMod, a, f, fs, n);
  }
  return audioFrame;
}

export function roughnessGraphData() {
  var N = F.length;
  let roughnessGraphData = new Array(N);
  for (let n = 0; n < N; n++) {
    roughnessGraphData[n] = {
      x: F[n],
      y: R[n],
    };
  }
  return roughnessGraphData;
}

export function fluctuationStrengthGraphData() {
  var N = F.length;
  let fluctuationStrengthGraphData = new Array(N);
  for (let n = 0; n < N; n++) {
    fluctuationStrengthGraphData[n] = {
      x: F[n],
      y: FS[n],
    };
  }
  return fluctuationStrengthGraphData;
}
