import React, { Component } from "react";
import { COLORS } from "../../../../../../helpers/ant-novak/helpers";
import LineGraph from "../../../ChartJS/LineGraph";
// import LineGraph from "../../../UI/ChartJS/LineGraphDrag";
import { prepareDataImpedance } from "../../helpers/WaveGuide.helper.js";
// import { COLORS } from "../../../../helpers/helpers";

class WaveGuideGraphImpedance extends Component {
  //state = {};
  render() {
    let { Z_abs, Z_phase, Z_abs_0, Z_phase_0 } = prepareDataImpedance(this.props.signal);
    let signalData = {
      //title: "Acoustic Impedance Z(x)",
      xAxes: {
        xLabel: "x (m)",
        type: "linear",
      },
      yAxes: [
        {
          data: [Z_abs, Z_abs_0],
          yLabel: "|Z| (kg/m^2/s)",
          min: -2,
          max: 2,
          type: "linear",
          scale: "log",
          borderColor: [COLORS[0], COLORS[0]],
          backgroundColor: [COLORS[0], COLORS[0]],
          lineWidth: [3, 1],
          borderDash: [undefined, [10, 5]],
        },
        {
          data: [Z_phase, Z_phase_0],
          yLabel: "Phase (rad)",
          min: -2,
          max: 2,
          type: "linear",
          borderColor: [COLORS[1], COLORS[1]],
          backgroundColor: [COLORS[1], COLORS[1]],
          lineWidth: [3, 1],
          borderDash: [undefined, [10, 5]],
        },
      ],
      animation: 0,
    };

    return <LineGraph data={signalData} func={this.props.callback_microphone_change} graph_size_ratio={0.25} />;
  }
}

export default WaveGuideGraphImpedance;
