import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { COLORS, LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { sensitivity } from "./VentedBoxSystem.helper";
// import { COLORS } from "../../helpers/helpers";

class VentedBoxSystemGraph extends Component {
  render() {
    const { Vmult, fmult } = this.props;

    // frequency axis
    const f_ax = LogSpace(Math.log10(10), Math.log10(150), 100);

    const { Z_ebr, Z_et, Pd, Pp, Pb } = sensitivity(Vmult, fmult, f_ax);

    // graph data
    const dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "f/fs (-)",
        type: "logarithmic",
        min: 0.2,
        max: 3,
      },
      yAxes: [
        {
          data: [Z_ebr, Z_et],
          label: ["bassreflex system", "speaker alone"],
          yLabel: "Input impedance (abs)",
          type: "linear",
          stepSize: 20,
        },
      ],
      animation: 0,
    };

    const dataSensitivity = {
      title: "SPL @2.83V @1m",
      xAxes: {
        xLabel: "f/fs (-)",
        type: "logarithmic",
        min: 0.2,
        max: 3,
      },
      yAxes: [
        {
          data: [Pd, Pp, Pb],
          borderColor: [COLORS[0], COLORS[1], COLORS[3]],
          backgroundColor: [COLORS[0], COLORS[1], COLORS[3]],
          yLabel: "Pressure Level (dB SPL)",
          label: ["diaphragm", "port", "total"],
          type: "linear",
          min: 60,
          max: 100,
          stepSize: 20,
        },
      ],
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
        <div>
          <LineGraph data={dataImpedance} graph_size_ratio={0.4} />
        </div>
        <div>
          <LineGraph data={dataSensitivity} graph_size_ratio={0.4} />
        </div>
      </div>
    );
  }
}
export default VentedBoxSystemGraph;
