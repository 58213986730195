import { Chart, ChartOptions } from "chart.js";
import npyjs from "npyjs";
import config from "appConfig";
import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import { Spinner } from "@jelly/ui";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../helpers/colors";

interface LessonChartProps {
  t: number;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

//const labels = Array.from({ length: 6002 }, (_, i) => (-2 + (i * 4) / 299).toFixed(2));

Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";

const renderLoader = (): React.ReactElement => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const renderError = (): React.ReactElement => {
  return <div>Could not fetch graph data.</div>;
};

const LessonChart: React.FC<LessonChartProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [mainData, setMainData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const npy = new npyjs();

  const loadMainData = async (index: number): Promise<void> => {
    setLoadingMain(true);
    const url = `${config.ASSETS_URL}/python-data/0303/reverb/chart_data_${index.toFixed(2)}.npy`;
    try {
      let npData = await npy.load(url);
      npData = npData.data as number[];
      setLoadingMain(false);
      const halfLength = npData.length / 2;
      const labels = Array.from(npData.slice(0, halfLength), (_, i) => (i / 1000).toFixed(1));
      const data = Array.from(npData.slice(halfLength));
      setMainData(data as number[]);
      setLabels(labels);
    } catch (error) {
      setLoadingMain(false);
      setError(true);
    }
  };

  useEffect(() => {
    loadMainData(props.t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingMain) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingMain]);

  /**
   * Create graph once data is loaded.
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    chartRef.current = new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "Impulse response of the room",
            borderColor: COLORS[0],
            borderWidth: 1,
            fill: false,
            pointRadius: 0,
            data: mainData,
            barThickness: 1,
          },
        ],
      },
      options: cloneDeep({ ...props.options }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * Update graph on yData change
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && mainData.length > 0) {
      chartRef.current.data.labels = labels;
      chartRef.current.data.datasets[0].data = mainData;
      chartRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainData]);

  /**
   * Fetch new data on Tau change
   */
  useEffect(() => {
    loadMainData(props.t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.t]);

  if (loading) return renderLoader();
  if (error) return renderError();

  return (
    <div style={{ position: "relative", height: "60vh", width: "80vw" }}>
      <canvas ref={canvasRef} width="700" height="150"></canvas>
    </div>
  );
};

export default LessonChart;
