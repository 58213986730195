import { hot } from "react-hot-loader/root";
import React from "react";
import { Route, Routes } from "react-router-dom";

import PressureBasics from "../pages/0101/PressureBasics";
import { WaveGuideLesson02 } from "../pages/0102/WaveGuideLesson02";
import { WaveGuideLesson03 } from "../pages/0103/WaveGuideLesson03";
import { WaveGuideLesson04 } from "../pages/0104/WaveGuideLesson04";
import { WaveGuideLesson05 } from "../pages/0105/WaveGuideLesson05";
import { WaveGuideLesson06 } from "../pages/0106/WaveGuideLesson06";
import { WaveGuideLesson07 } from "../pages/0107/WaveGuideLesson07";
import { WaveGuideLesson08 } from "../pages/0108/WaveGuideLesson08";
import { WaveGuideLesson09 } from "../pages/0109/WaveGuideLesson09";
import Modulation from "../pages/0208/Modulation";
import SoundSourceLocalization from "../pages/0212/SoundSourceLocalization";
import Lesson0301 from "../pages/0301/Lesson0301";
import Lesson0302a from "../pages/0302a/Lesson0302";
import Lesson0302b from "../pages/0302b/Lesson0302";
import Lesson0303a from "../pages/0303a/Lesson0303a";
import Lesson0303b from "../pages/0303b/Lesson0303b";
import Lesson0304 from "../pages/0304/Lesson0304";
import Lesson0305a from "../pages/0305a/Lesson0305a";
import Lesson0305b from "../pages/0305b/Lesson0305b";
import Lesson0305c from "../pages/0305c/Lesson0305c";
import Lesson0312 from "../pages/0312/Lesson0312";
import Lesson0324 from "../pages/0324/Lesson0324";
import Fourier from "../pages/0402/Fourier";
import MechanicalSystem from "../pages/0403/MechanicalSystem";
import PistonDirectivity from "../pages/0406/PistonDirectivity";
import BandPass from "../pages/0407/BandPass";
import Distortion from "../pages/0408/Distortion";
import ElectrostaticTransduction from "../pages/0410/ElectrostaticTransduction";
import Geophone from "../pages/0413/Geophone";
import MicrophoneDirectivity from "../pages/0414/MicrophoneDirectivity";
import MicrophoneEldyn from "../pages/0415/MicrophoneEldyn";
import MicrophoneElstat from "../pages/0416/MicrophoneElstat";
import MicrophoneCardio from "../pages/0417/MicrophoneCardio";
import TSLoudspeaker from "../pages/0418/TSLoudspeaker";
import ClosedBoxSystem from "../pages/0420/ClosedBoxSystem";
import VentedBoxSystem from "../pages/0421/VentedBoxSystem";
import PassiveRadiator from "../pages/0422/PassiveRadiator";
import AnalogFilters from "../pages/0423/AnalogFilters";
import EddyCurrents from "../pages/0424/EddyCurrents";
import AdvancedLoudspeaker from "../pages/0425/AdvancedLoudspeaker";
import NonlinearLoudspeaker from "../pages/0426/NonlinearLoudspeaker";
import MicrophoneArray from "../pages/0427/MicrophoneArray";
import AddedMass from "../pages/case-0402-01/AddedMass";
import BlockedImpedance from "../pages/case-0402-02/BlockedImpedance";

import "./common/ant-novak/OriginStyles.css";
import styles from "./App.module.scss";

const App: React.FC = () => {
  return (
    <div className={styles.root}>
      <Routes>
        <Route path="/" element={<div>home</div>} />
        <Route path="/0101" element={<PressureBasics />} />
        <Route path="/0102" element={<WaveGuideLesson02 />} />
        <Route path="/0103" element={<WaveGuideLesson03 />} />
        <Route path="/0104" element={<WaveGuideLesson04 />} />
        <Route path="/0105" element={<WaveGuideLesson05 />} />
        <Route path="/0106" element={<WaveGuideLesson06 />} />
        <Route path="/0107" element={<WaveGuideLesson07 />} />
        <Route path="/0108" element={<WaveGuideLesson08 />} />
        <Route path="/0109" element={<WaveGuideLesson09 />} />
        <Route path="/0208" element={<Modulation />} />
        <Route path="/0212" element={<SoundSourceLocalization />} />
        <Route path="/0301" element={<Lesson0301 />} />
        <Route path="/0302a" element={<Lesson0302a />} />
        <Route path="/0302b" element={<Lesson0302b />} />
        <Route path="/0303a" element={<Lesson0303a />} />
        <Route path="/0303b" element={<Lesson0303b />} />
        <Route path="/0304" element={<Lesson0304 />} />
        <Route path="/0305a" element={<Lesson0305a />} />
        <Route path="/0305b" element={<Lesson0305b />} />
        <Route path="/0305c" element={<Lesson0305c />} />
        <Route path="/0312" element={<Lesson0312 />} />
        <Route path="/0324" element={<Lesson0324 />} />
        <Route path="/0402" element={<Fourier />} />
        <Route path="/0403" element={<MechanicalSystem />} />
        <Route path="/0406" element={<PistonDirectivity />} />
        <Route path="/0407" element={<BandPass />} />
        <Route path="/0408" element={<Distortion />} />
        <Route path="/0410" element={<ElectrostaticTransduction />} />
        <Route path="/0413" element={<Geophone />} />
        <Route path="/0414" element={<MicrophoneDirectivity />} />
        <Route path="/0415" element={<MicrophoneEldyn />} />
        <Route path="/0416" element={<MicrophoneElstat />} />
        <Route path="/0417" element={<MicrophoneCardio />} />
        <Route path="/0418" element={<TSLoudspeaker />} />
        <Route path="/0420" element={<ClosedBoxSystem />} />
        <Route path="/0421" element={<VentedBoxSystem />} />
        <Route path="/0422" element={<PassiveRadiator />} />
        <Route path="/0423" element={<AnalogFilters />} />
        <Route path="/0424" element={<EddyCurrents />} />
        <Route path="/0425" element={<AdvancedLoudspeaker />} />
        <Route path="/0426" element={<NonlinearLoudspeaker />} />
        <Route path="/0427" element={<MicrophoneArray />} />
        <Route path="/case-0402-01" element={<AddedMass />} />
        <Route path="/case-0402-02" element={<BlockedImpedance />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </div>
  );
};

export default hot(App);
