/* eslint-disable */
// @ts-nocheck
import { Slider, Badge, Button } from "@jelly/ui";
import React, { Component, ReactNode } from "react";
import CircularSlider from "@fseehawer/react-circular-slider";
import Player from "../Player/BinauralAmbisonicsPlayer";
import HeadTracker from "../HeadTracking/HeadTracker";
import styles from "../Lesson0324.module.scss";
import { ReactComponent as LoudspeakerIcon } from "../assets/speaker.svg";
import HeadSVG from "../assets/head.svg";
import Figure1SVG from "../assets/figure-1.svg";

class BinauralEncoding extends Component {
  player: Player;
  audioPath: string;
  audioSource: HTMLElement | null;

  state: {
    isLoaded: boolean;
    buttonState: "Play" | "Stop";
    constraints: {
      audio: boolean;
      video: { facingMode: "user"; width: number; height: number };
    };
    modelConfiguration: { shouldLoadIrisModel: boolean; maxFaces: number };
    rotation: { yaw: number; pitch: number; roll: number };
    elevation: number;
    azimuth: number;
    loopAudio: boolean;
  };

  constructor() {
    super();
    this.audioPath = "/assets/viola.wav";
    this.player = new Player();

    this.state = {
      isLoaded: true,
      buttonState: "Play",
      constraints: {
        audio: false,
        video: { facingMode: "user", width: 640, height: 480 },
      },
      modelConfiguration: { shouldLoadIrisModel: false, maxFaces: 1 },
      rotation: { yaw: 0, pitch: 0, roll: 0 },
      elevation: 0,
      azimuth: 0,
      loopAudio: false,
    };
  }

  componentDidMount(): void {
    this.audioSource = document.getElementById("audio");
    this.audioSource!.onended = this.onAudioEnded.bind(this);
    this.player.setSource(this.audioSource);
  }

  componentWillUnmount(): void {
    if (this.player.isPlaying) {
      this.stop();
    }
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.isPlaying != this.props.isPlaying) {
      if (this.props.isPlaying) {
        this.play();
      } else {
        this.stop();
      }
    }
  }

  onClick(): void {
    this.playOrStopSound();
  }

  onSliderChange(type, value): void {
    this.player.loadAudio();
    switch (type) {
      case "azimuth":
        this.player.ambisonicsEncoder.azim = value;
        this.setState({ azimuth: value });
        break;
      case "elevation":
        this.player.ambisonicsEncoder.elev = value;
        break;
      default:
        this.player.ambisonicsEncoder.azim = 0;
        this.player.ambisonicsEncoder.elev = 0;
    }
    this.player.ambisonicsEncoder.updateGains();
  }

  onAudioEnded(): void {
    this.playOrStopSound();
  }

  playOrStopSound(): void {
    if (this.player.isPlaying) {
      this.stop();
    } else {
      this.play();
    }
  }

  play() {
    this.player.loadAudio(this.audioSource);
    this.player.play();
    this.setState({ buttonState: "Stop" });
    this.props.playChange(true);
  }

  stop() {
    this.player.stop();
    this.setState({ buttonState: "Play" });
    this.props.playChange(false);
  }

  updateRotation(currentAngles): void {
    this.setState({ rotation: currentAngles });
    this.player.rotateScene(this.state.rotation.yaw, this.state.rotation.pitch, this.state.rotation.roll);
  }

  render(): ReactNode {
    return (
      <>
        <audio id="audio" loop={this.state.loopAudio} src={this.audioPath}></audio>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
          <div style={{ display: "flex", flexDirection: "row", flex: "1 0 100%", gap: "1em" }}>
            <div style={{ flex: "1 1 40%" }}>
              <div
                style={{
                  flex: "1 0 80%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularSlider
                  min={-180}
                  max={180}
                  direction={-1}
                  label="Azimuth"
                  labelColor="#fff"
                  appendToValue="°"
                  knobColor="#131a4f"
                  knobSize={30}
                  knobPosition="bottom"
                  progressSize={0}
                  trackColor="#d0dae9"
                  trackSize={2}
                  hideLabelValue={true}
                  dataIndex={180}
                  onChange={(value) => {
                    this.onSliderChange("azimuth", value);
                  }}
                >
                  <LoudspeakerIcon height="30px" width="30px" x="1" y="1" />
                </CircularSlider>
                <img
                  src={HeadSVG}
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "30%",
                    margin: "auto",
                    width: "100px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "0 1 20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Azimuth</span>
                <div className={styles.label}>{this.state.azimuth}°</div>
              </div>
            </div>
            <div style={{ flex: "1 0", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ flex: "1 0 80%" }}>
                <Slider
                  min={-90}
                  max={90}
                  step={1}
                  defaultValue={0}
                  vertical
                  theme="primary"
                  onChange={(value) => {
                    this.setState({
                      elevation: value,
                    });
                    this.onSliderChange("elevation", value);
                  }}
                />
              </div>

              <div
                style={{
                  flex: "0 1 20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Elevation</span>

                <div className={styles.label}>{this.state.elevation}°</div>
              </div>
            </div>
            <div style={{ flex: "1 1" }}>
              <img src={Figure1SVG} alt="Figure" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BinauralEncoding;
