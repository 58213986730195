import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { loudspeaker } from "./TSLoudspeaker.helper";
import { SwitchOption } from "@jelly/ui";

class TSLoudspeakergraph extends Component {
  state = {
    displayedGraph: 0,
  };

  handleDisplayChange = (value) => {
    this.setState({ displayedGraph: value });
  };

  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    //   Re [Ohm] ... resistance
    //   Le [H] ... inductance
    //   Mms [kg] ... mass
    //   Rms [Ns/m] ... mechanical resistance
    //   Kms [N/m] ... stiffness
    //   Sd [m^2] ... surface
    const { Bl, Re, Le, Mms, Rms, Kms, Sd } = this.props;
    const { displayedGraph } = this.state;

    // frequency axis
    const f_ax = LogSpace(1, Math.log10(20e3), 200);

    // impedance and phase
    const { Z_in, phase, P_sens } = loudspeaker(Bl, Re, Le, Mms, Rms, Kms, Sd, f_ax);
    const dataImpedance = {
      //title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Z_in],
          label: ["Abs"],
          yLabel: "Absolute value (ohm)",
          type: "linear",
        },
        {
          data: [phase],
          label: ["Phase"],
          yLabel: "Phase (deg)",
          type: "linear",
          min: -100,
          max: 100,
        },
      ],
      animation: 0,
    };

    const dataSensitivity = {
      //title: "SPL @2.83V @1m",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [P_sens],
          yLabel: "Sound Pressure Level (dB SPL)",
          label: ["SPL"],
          type: "linear",
        },
      ],
    };

    return (
      <>
        <div style={{ width: "50%", margin: "auto" }}>
          <SwitchOption
            items={[{ name: "Loudspeaker impedance" }, { name: "SPL @2.83V @1m" }]}
            activeItem={displayedGraph}
            onClick={this.handleDisplayChange}
          />
        </div>
        <div>
          {displayedGraph == 0 && <LineGraph data={dataImpedance} graph_size_ratio={0.35} />}
          {displayedGraph == 1 && <LineGraph data={dataSensitivity} graph_size_ratio={0.35} />}
        </div>
      </>
    );
  }
}

export default TSLoudspeakergraph;
