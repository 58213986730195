import React, { Component } from "react";
import { COLORS } from "../../helpers/ant-novak/helpers";
// import { COLORS } from "../../helpers/helpers.js";

class MicrophoneElstatSVG extends Component {
  render() {
    // List of inputs ("props") :
    //        D  ... Diameter of the diaphragm [m]
    //        d0 ... Distance between electrodes at rest [m]
    //        U0 ... Bias voltage [V] (std 200 V)
    //        Ra ... Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
    const { D, d0, U0, Ra } = this.props;
    const svgU0 = { x: 270 + D * 5, y: 120, r: 15 };
    const svgMembrane = { x: 200, y: 10, d: D * 5 };
    const svgGrid = { x: 200, y: 10 + d0 / 2, d: D * 5 };
    const height = 170;
    const grid_dash = String(5 + Math.log10(Ra)) + ", " + String(5 - Math.log10(Ra));
    const wires_shift = 5;
    const mic_encl_radius = 5;
    const wires_corner = 30;
    const bottom_line = 170;
    const svgResistor = { x: 270 + D * 5, y: 60, w: 7, h: 30 };
    const svgCapacitor = { x: 320 + D * 5, y: 170, w: 5, h: 15 };
    const topWire = { y: 40 };

    const groud_wire_corner = String(svgGrid.x + svgGrid.d - wires_shift) + "," + String(bottom_line) + " ";
    const ground_wire =
      "M " +
      String(svgGrid.x + svgGrid.d - wires_shift) +
      "," +
      String(svgGrid.y) +
      " V " +
      String(150) +
      " C " +
      groud_wire_corner +
      groud_wire_corner +
      String(svgGrid.x + svgGrid.d - wires_shift + wires_corner) +
      "," +
      String(bottom_line) +
      " H" +
      String(svgCapacitor.x);

    const up_wire_corner = String(svgU0.x) + "," + String(svgMembrane.y) + " ";
    const up_wire =
      "M " +
      String(svgMembrane.x + svgMembrane.d - wires_shift + mic_encl_radius) +
      "," +
      String(svgMembrane.y) +
      " H " +
      String(svgU0.x - wires_corner) +
      " C " +
      up_wire_corner +
      up_wire_corner +
      String(svgU0.x) +
      "," +
      String(svgMembrane.y + wires_corner) +
      " " +
      " V " +
      String(svgU0.y - svgU0.r);

    const arrow_path_U = "M " + String(svgU0.x + 100) + "," + String(topWire.y + 20) + " V " + String(bottom_line - 20);

    const arrow_path_U0 = "M " + String(svgU0.x + 25) + "," + String(svgU0.y - 20) + " V " + String(bottom_line - 20);

    return (
      <svg viewBox="0 0 500 200">
        {/*-- arrow head --*/}
        <defs>
          <marker id="head" orient="auto" markerWidth="4" markerHeight="8" refX="4" refY="4">
            <path d="M 0,0 V8 L4,4 Z" fill={COLORS[2]} />
          </marker>
        </defs>

        {/*-- source U0 --*/}
        <circle cx={svgU0.x} cy={svgU0.y} r={svgU0.r} stroke={COLORS[1]} strokeWidth="2" fill="white" />
        <text
          x={svgU0.x}
          y={svgU0.y + 1}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize="0.8em"
          fill={COLORS[1]}
        >
          U
        </text>
        <text
          x={svgU0.x + 7}
          y={svgU0.y + 5}
          alignmentBaseline="middle"
          textAnchor="middle"
          fontSize="0.5em"
          fill={COLORS[1]}
        >
          0
        </text>
        <text x={svgU0.x + svgU0.r + 15} y={svgU0.y + 1} alignmentBaseline="middle" fontSize="0.8em">
          {U0} V
        </text>
        {/*-- membrane + electrode --*/}
        <rect
          x={svgMembrane.x - svgMembrane.d}
          y={svgMembrane.y}
          width={2 * svgMembrane.d}
          height={height}
          style={{ stroke: COLORS[2], fill: "white", strokeWidth: 5, strokeLinejoin: "round" }}
        />
        {/* -- Hide top of above rect so it appeears open */}
        <rect
          x={svgMembrane.x - svgMembrane.d + mic_encl_radius / 2}
          y={svgMembrane.y - 3}
          width={2 * svgMembrane.d - mic_encl_radius}
          height={6}
          style={{ stroke: COLORS[2], fill: "white", strokeWidth: 0, strokeLinejoin: "round" }}
        />
        {/* -- Red mic membrane */}
        <polyline
          points={`
        ${svgMembrane.x - svgMembrane.d + mic_encl_radius / 2} ${svgMembrane.y},
        ${svgMembrane.x - svgMembrane.d + mic_encl_radius / 2 + 3} ${svgMembrane.y - 3},
        ${svgMembrane.x - svgMembrane.d + mic_encl_radius / 2 + 6} ${svgMembrane.y},
        ${svgGrid.x + svgMembrane.d - mic_encl_radius / 2 - 6} ${svgMembrane.y}
        ${svgGrid.x + svgMembrane.d - mic_encl_radius / 2 - 3} ${svgMembrane.y - 3}
        ${svgGrid.x + svgMembrane.d - mic_encl_radius / 2} ${svgMembrane.y}

        `}
          fill="none"
          stroke={COLORS[0]}
        />

        {/*-- grid --*/}
        <line
          x1={svgGrid.x + svgGrid.d}
          y1={svgGrid.y}
          x2={svgGrid.x - svgGrid.d}
          y2={svgGrid.y}
          strokeWidth="2"
          stroke={COLORS[2]}
          strokeDasharray={grid_dash}
        />
        {/*-- ground --*/}
        <path d={ground_wire} strokeWidth="2" fill="none" stroke={COLORS[1]} />
        <line
          x1={svgCapacitor.x + svgCapacitor.w}
          y1={svgCapacitor.y}
          x2={svgU0.x + 100}
          y2={svgCapacitor.y}
          strokeWidth="2"
          stroke={COLORS[1]}
        />
        <circle cx={svgGrid.x + svgGrid.d - wires_shift} cy={svgGrid.y} r="2" fill={COLORS[1]} />
        <line x1={svgU0.x} y1={bottom_line} x2={svgU0.x} y2={svgU0.y + svgU0.r} strokeWidth="2" stroke={COLORS[1]} />

        <circle cx={svgU0.x} cy={bottom_line} r="2" fill={COLORS[1]} />
        {/*-- up wire --*/}
        <path d={up_wire} strokeWidth="2" fill="none" stroke={COLORS[1]} />
        <circle
          cx={svgMembrane.x + svgMembrane.d - wires_shift + mic_encl_radius}
          cy={svgMembrane.y}
          r="2"
          fill={COLORS[1]}
        />
        {/*-- resistor --*/}
        <rect
          x={svgResistor.x - svgResistor.w}
          y={svgResistor.y}
          width={2 * svgResistor.w}
          height={svgResistor.h}
          style={{ stroke: COLORS[1], fill: "white", strokeWidth: 2 }}
        />

        {/*-- top wire --*/}
        <circle cx={svgU0.x + 5} cy={svgCapacitor.y} r="2" fill={COLORS[1]} />
        <line x1={svgU0.x} y1={topWire.y} x2={svgU0.x + 100} y2={topWire.y} strokeWidth="2" stroke={COLORS[1]} />

        <line
          x1={svgCapacitor.x}
          y1={svgCapacitor.y - svgCapacitor.h / 2}
          x2={svgCapacitor.x}
          y2={svgCapacitor.y + svgCapacitor.h / 2}
          strokeWidth="2"
          stroke={COLORS[1]}
        />
        <line
          x1={svgCapacitor.x + svgCapacitor.w}
          y1={svgCapacitor.y - svgCapacitor.h / 2}
          x2={svgCapacitor.x + svgCapacitor.w}
          y2={svgCapacitor.y + svgCapacitor.h / 2}
          strokeWidth="2"
          stroke={COLORS[1]}
        />

        {/*-- out voltage --*/}
        <path id="arrow-line" markerEnd="url(#head)" strokeWidth="1" fill="none" stroke={COLORS[2]} d={arrow_path_U} />
        <text
          x={svgU0.x + 110}
          y={(topWire.y + bottom_line) / 2}
          alignmentBaseline="middle"
          textAnchor="middle"
          fill={COLORS[2]}
        >
          U
        </text>

        {/*-- U0 voltage --*/}
        <path id="arrow-line" markerEnd="url(#head)" strokeWidth="1" fill="none" stroke={COLORS[2]} d={arrow_path_U0} />
      </svg>
    );
  }
}
export default MicrophoneElstatSVG;
