/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import GeophoneGraph from "./Geophone.graph";
import { sliders, Fc, Q } from "./Geophone.helper";
import { Badge, SwitchOption } from "@jelly/ui";

//const MU = String.fromCharCode(956); // symbol for micro (mu)

// -------------------------
// Mechanical Mass Spring System:
// - Shows mechanical impedance or admittannce (amplitude and phase) of a mechanical system.
//   User can select between admittance and impedance,
//   and values of Rm ... mechanical resistance
//                 Mm ... moving mass
//                 Cm ... mechanical compliance
// -------------------------

class Geophone extends Component {
  // class state
  state = {
    Rl: 1200, // load resistance [ohm]
    Bl: 28, // force factor [Tm]
    Re: 395, // resistance [ohm]
    Le: 0.1, // inductance [mH]
    Rm: 0.4, // mechanical resistance [Ns/m]
    Mm: 11.0, // moving mass [g]
    Cm: 23, // mechanical compliance [mm/N]
    LinLog: "logarithmic", // "linear" or "logarithmic"
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  // handle for radio
  handleChangeLin = (value) => {
    if (value == 0) {
      this.setState({ LinLog: "linear" });
    } else {
      this.setState({ LinLog: "logarithmic" });
    }
  };

  renderBlock() {
    const { Rl, Re, Le, Bl, Rm, Mm, Cm, LinLog } = this.state;

    let fc_value = Fc(Mm * 1e-3, Cm * 1e-3) // pass values from [g] to [kg]
      .toFixed(2) //                     and from [mm/N] to [m/N]
      .toString();
    let fr_string = `f<sub>r</sub> = ${fc_value} (Hz)`;
    let q_value = Q(Rl, Re, Bl, Rm, Mm * 1e-3, Cm * 1e-3)
      .toFixed(2)
      .toString();
    let q_string = `Q = ${q_value}`;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div>
          <GeophoneGraph
            LinLog={LinLog} // selector between "linear" and "logarithmic"
            Rl={Rl} // load resistance
            Re={Re} // el. resistance
            Le={Le * 1e-3} // el. inductance
            Bl={Bl} // intrinsic sensitivity
            Rm={Rm} // mech. resistance
            Mm={Mm * 1e-3} // mass, pass value from [g] to [kg]
            Cm={Cm * 1e-3} // compliance, pass value from [mm/N] to [m/N]
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "auto" }}>
          <SwitchOption
            items={[{ name: "linear" }, { name: "logarithmic" }]}
            activeItem={LinLog == "linear" ? 0 : 1}
            onClick={this.handleChangeLin}
          />
          <p>
            <Badge theme="secondary" size="small">
              {fr_string}
            </Badge>
            <Badge theme="secondary" size="small">
              {q_string}
            </Badge>
          </p>
        </div>

        <div className="slider-block">
          <div style={{ marginTop: 20 }}>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default Geophone;
