import { Chart, ChartOptions } from "chart.js";
import npyjs from "npyjs";
import config from "appConfig";
import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import { Spinner } from "@jelly/ui";
import React, { useEffect, useRef, useState } from "react";
// eslint-disable-next-line
import { COLORS } from "../../helpers/colors";

interface LessonChartBProps {
  t: number;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

const labels = Array.from({ length: 300 }, (_, i) => (-2 + (i * 4) / 299).toFixed(2));
Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";

const renderLoader = (): React.ReactElement => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const renderError = (): React.ReactElement => {
  return <div>Could not fetch graph data.</div>;
};

const LessonChartB: React.FC<LessonChartBProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [loadingAdditonal, setLoadingAdditional] = useState<boolean>(true);
  const [mainData, setMainData] = useState<number[]>([]);
  const [addtionalData, setAdditonalData] = useState<number[]>([]);
  const npy = new npyjs();

  const loadMainData = async (): Promise<void> => {
    const url = `${config.ASSETS_URL}/python-data/0303/convolution/sc.npy`;
    try {
      const npData = await npy.load(url);
      setLoadingMain(false);
      setMainData(Array.from(npData.data).map(Number));
    } catch (error) {
      setLoadingMain(false);
      setError(true);
    }
  };
  const loadAdditionalData = async (t: number): Promise<void> => {
    setLoadingAdditional(true);
    const url = `${config.ASSETS_URL}/python-data/0303/convolution/s2_${t}.npy`;
    try {
      const npData = await npy.load(url);

      setAdditonalData(Array.from(npData.data).map(Number));
      setLoadingAdditional(false);
    } catch (error) {
      setLoadingAdditional(false);
      setError(true);
    }
  };

  const getParsedX = (): number[] => {
    const maxIndex = addtionalData.indexOf(Math.max(...addtionalData));
    const yMax = Math.max(...mainData);
    const data = Array.from({ length: 300 }, () => 0);
    data[maxIndex] = yMax;
    return data;
  };

  useEffect(() => {
    loadMainData();
    loadAdditionalData(props.t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingAdditonal && !loadingMain) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingMain, loadingAdditonal]);

  /**
   * Create graph once data is loaded.
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    chartRef.current = new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "s1",
            borderColor: COLORS[0],
            fill: false,
            pointRadius: 0,
            data: mainData,
            barThickness: 4,
          },
          {
            label: "s2",
            borderColor: COLORS[1],
            borderWidth: 4,
            data: getParsedX(),
            barThickness: 0,
            pointRadius: 0,
          },
        ],
      },
      options: cloneDeep({ ...props.options }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * Update graph on yData change
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && mainData.length > 0) {
      chartRef.current.data.datasets[0].data = mainData;
      chartRef.current.update();
    }
  }, [mainData]);

  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && addtionalData.length > 0) {
      chartRef.current.data.datasets[1].data = getParsedX();
      chartRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addtionalData]);

  /**
   * Fetch new data on Tau change
   */
  useEffect(() => {
    loadAdditionalData(props.t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.t]);

  /**
   * Update graph on annotations change
   */
  useEffect(() => {
    if (chartRef.current && props.annotations) {
      // ESLint cannot scan the options from the plugins.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current.options.annotation.annotations = [props.annotations];
      chartRef.current.update();
    }
  }, [props.annotations]);

  if (loading) return renderLoader();
  if (error) return renderError();

  return (
    <div style={{ position: "relative", height: "30vh", width: "80vw" }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default LessonChartB;
