/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import Figure from "./figure.svg";
import ElectrostaticTransductionAudio from "./ElectrostaticTransduction.audio";
import ElectrostaticTransductionAudio2 from "./ElectrostaticTransduction.audio2";

// -------------------------
// ElectrostaticTransduction
// -------------------------

class ElectrostaticTransduction extends Component {
  state = {
    U0: 100, // amplitude of the input signal
  };

  // slider to change the amplitude of the input signal
  sliderU = {
    name: "U0",
    title: "U<sub>0</sub>",
    unit: "V",
    min: 0.01,
    max: 100,
    step: 0.1,
    edit: false,
    log: true,
  };

  // handle for slider
  handleSliderChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  renderBlock() {
    const { U0 } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div className="one-graph" style={{ width: "100%", flexGrow: "1", flexShrink: "0" }}>
          <h5>ELECTROSTATIC TRANSDUCTION</h5>
          <img src={Figure} alt="Figure" />
        </div>
        <div style={{ marginTop: 20 }}>
          <SliderEdit onChange={this.handleSliderChange} params={this.sliderU} val={U0} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "auto" }}>
          <ElectrostaticTransductionAudio U0={U0} text="play music" />
          <ElectrostaticTransductionAudio2 U0={U0} text="play sine" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}

export default ElectrostaticTransduction;
