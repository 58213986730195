import React, { Component, ReactFragment, ReactNode } from "react";
import { Badge, Slider } from "@jelly/ui";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { chartOptions } from "./Lesson0303b.chart.config";
import config from "appConfig";
import LessonChart from "./Lesson0303b.chart";

class Lesson0303b extends Component {
  state = {
    tr: 0,
    tr_stable: 0,
  };
  audio = new Audio();

  /**
   * Adjust sliders config to fit Python
   */
  private sliders = {
    tr: {
      name: "T<sub>r</sub>",
      min: 0,
      max: 2,
      step: 0.1,
    },
  };

  updateParams = (key: string, value: number): void => {
    this.setState({ [key]: value });
  };
  updateStableParams = (key: string, value: number): void => {
    this.setState({ [`${key}_stable`]: value });
    this.loadAndPlayAudio(value);
  };

  loadAndPlayAudio = (index: number): void => {
    const audioFile = `${config.ASSETS_URL}/python-data/0303/reverb/asknow_reverb_${index.toFixed(2)}.wav`;
    if (this.audio) {
      try {
        this.audio.pause();
      } catch (error) {
        //no-op
      }
    }
    this.audio = new Audio(audioFile);
    this.audio.play();
  };

  /**
   * This needs to return data of Chart.
   * @param t
   * @returns
   */
  renderParamSlider = (key: "tr"): ReactFragment => {
    const property = this.state[key];
    const config = this.sliders[key];
    return (
      <div style={{ display: "flex", flex: "1 1 48%", alignItems: "center", gap: "0em", paddingRight: "1em" }}>
        <div style={{ flex: "0", minWidth: "6em" }}>
          <Badge theme="secondary" size="small">
            {`${config.name} = ${property.toFixed(2)}`}
          </Badge>
        </div>
        <div style={{ flex: "1" }}>
          <Slider
            min={config.min}
            max={config.max}
            step={config.step}
            theme="primary"
            value={property}
            onChange={(value) => {
              this.updateParams(key, value);
            }}
            onAfterChange={(value) => {
              this.updateStableParams(key, value);
            }}
          />
        </div>
      </div>
    );
  };

  render(): ReactNode {
    const { tr_stable } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%", flex: "1" }}>
        <div
          style={{
            flex: "1 0 80%",
            display: "flex",
            justifyContent: "center",
            gap: "1em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <LessonChart t={tr_stable} options={chartOptions} />
        </div>
        <div style={{ flex: "0 1", width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>{this.renderParamSlider("tr")}</div>
        </div>
      </div>
    );
  }
}
//
export default Lesson0303b;
