/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import ImageViewer from "../../components/common/ant-novak/ImageViewer";
import ProgressBar from "../../components/common/ant-novak/ProgressBar";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import { COLORS, pad } from "../../helpers/ant-novak/helpers";
import BlockedImpedanceGraph from "./BlockedImpedance.graph";
import "./BlockedImpedance.css";
import { Button } from "@jelly/ui";

let title_text =
  "Select a blocked position of the coil and start the measurement of the blocked impedance (resistance and inductance). You can add several measurements to the same graph.";
const TextButton = ["Start Measurement", "", "Do another measurement"];

class BlockedImpedance extends Component {
  constructor(props) {
    super(props);
    this.images = [new Array(11)];
    for (let i = 0; i < 11; i++) {
      this.images[0][i] = "/assets/img_loudspeaker/overhung_" + pad(24 - (i + 1) * 2, 2) + ".jpg";
    }

    // class state
    this.state = {
      page: 0, // step
      z: 0, // mass [g]
      progress: 0,
      z_values: [NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN, NaN],
      idx: 0,
    };
  }

  // slider parameters
  sliders = [
    {
      title: "position",
      name: "z",
      unit: "mm",
      min: -5,
      max: 5,
      step: 1,
    },
  ];

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for button cliks
  buttonClickMeasurement = (event) => {
    if (Number(this.state.page) === 2 && this.state.idx >= this.state.z_values.length) {
      alert("Number of measurements exceeds the limit. Please remove some measurement data before.");
    } else {
      // move to another page
      let new_page = (Number(this.state.page) + 1) % 3;
      this.setState({ page: new_page });

      // if new measurement (going from page 0 to page 1)
      if (new_page === 1) {
        // add the measurement value to array
        let new_z_values = [...this.state.z_values];
        new_z_values[this.state.idx] = Number(this.state.z);
        this.setState({ progress: 0, idx: this.state.idx + 1, z_values: new_z_values });

        // show the progress bar
        this.interval = setInterval(
          function () {
            if (this.state.progress >= 100) {
              // hide the progress bar
              this.setState({ page: 2 });
              clearInterval(this.interval);
            }
            this.setState({ progress: this.state.progress + 4 });
          }.bind(this),
          100
        );
      }
    }
  };

  buttonClickRemove = (event) => {
    // remove the last measurement
    let new_z_values = [...this.state.z_values];
    new_z_values[this.state.idx - 1] = NaN;
    this.setState({ idx: this.state.idx - 1, z_values: new_z_values });
  };

  render() {
    let { z, page, progress, z_values, idx } = this.state;

    // if deleted all the measured data show the first page (measurement)
    if (idx === 0) page = 0;

    let activeImageIndex = Number(z) + 5;

    let display_page_0 = page === 0 ? "block" : "none";
    let display_page_1 = page === 1 ? "block" : "none";
    let display_page_2 = page === 2 ? "block" : "none";

    return (
      <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
        <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", width: "100%", minHeight: "90vh" }}>
          <div style={{ flex: "1", width: "100%", alignSelf: "center" }}>
            {page == 0 && (
              <div style={{ width: "50%", margin: "auto" }}>
                <ImageViewer
                  images={this.images}
                  activeImageGroup={0}
                  activeImageIndex={activeImageIndex}
                  width="60%"
                  alt="loudspeaker"
                />
              </div>
            )}
            {page == 1 && (
              <div style={{ width: "50%", margin: "auto" }}>
                <ProgressBar bgcolor={COLORS[0]} completed={progress} />
              </div>
            )}
            {page == 2 && <BlockedImpedanceGraph z_values={z_values} page={page} />}
          </div>
          <div
            style={{
              display: "flex",
              minWidth: "50%",
              flex: "1",
              flexDirection: "row",
              alignSelf: "center",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {page == 0 && (
              <>
                <div style={{ flexGrow: "1" }}>
                  <SliderEdit
                    onChange={this.handleChange}
                    params={this.sliders[0]}
                    val={this.state[this.sliders[0].name]}
                  />
                </div>
                <div>
                  <Button onClick={this.buttonClickMeasurement}>{TextButton[page]}</Button>
                </div>
              </>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {page == 2 && (
              <>
                <Button icon="cil-replay" onClick={this.buttonClickMeasurement}>
                  {TextButton[page]}
                </Button>
                <Button icon="cil-delete" onClick={this.buttonClickRemove}>
                  Remove last measurement data
                </Button>
              </>
            )}
          </div>
        </div>
        <div>
          <div className="hint default">
            <i className="cil-comment-bubble-exclamation"></i>
            <p>{title_text}</p>
          </div>
        </div>
      </div>
    );
  }
}
export default BlockedImpedance;
