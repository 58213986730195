class Player {
  constructor() {
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.isPlaying = false;
    this.sampleRate = this.audioContext.sampleRate;
    this.duration = 1;
    this.numberOfChannels = 2;
    this.numberOfSamples = this.sampleRate * this.duration;
    this.arrayBuffer = this.audioContext.createBuffer(this.numberOfChannels, this.numberOfSamples, this.sampleRate);
  }

  fillBuffer(audioFrame) {
    for (var channel = 0; channel < this.arrayBuffer.numberOfChannels; channel++) {
      this.arrayBuffer.copyToChannel(audioFrame, channel, 0);
    }
    var source = this.audioContext.createBufferSource();
    source.buffer = this.arrayBuffer;
    source.loop = true;
    source.connect(this.audioContext.destination);
    return source;
  }

  stop() {
    this.source.stop(0);
    this.source.disconnect();
    this.isPlaying = false;
  }

  play(audioFrame) {
    this.source = this.fillBuffer(audioFrame);
    this.source.start(0);
    this.isPlaying = true;
  }
}

export default Player;
