import * as math from "mathjs";

// calculate resonance frequency Fc
export function Fc(L, C) {
  return 1 / (2 * Math.PI * Math.sqrt(L * C));
}

// calculate quality factor Q
export function Q(R, L, C, SerPar) {
  if (SerPar === "serial") {
    return (1 / R) * Math.sqrt(L / C);
  } else {
    return R * Math.sqrt(C / L);
  }
}

// calculate impedance of a RLC circuit
export function impedance_admittance(Rm, Mm, Cm, AdmImp, f_axis) {
  // prepare arrays for absVal and phase
  let absVal = [];
  let phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    absVal[i] = { x: f_axis[i], y: 0 };
    phase[i] = { x: f_axis[i], y: 0 };
  }

  let Z = {}; // impednace/admittance

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * math.pi * f;

    if (AdmImp === "admittance") {
      // mechanical admittance
      Z = math.divide(1, math.complex(Rm, omega * Mm - 1 / (Cm * omega)));
    } else {
      // mechanical impedance
      Z = math.complex(Rm, omega * Mm - 1 / (Cm * omega));
    }

    // attribute the impedance to the chart.js y-axis
    absVal[i].y = math.abs(Z);
    // attribute the phase to the chart.js y-axis
    phase[i].y = (180 / math.pi) * math.arg(Z);
  }

  return { absVal, phase };
}
