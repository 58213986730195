import React from "react";
import SliderEdit from "../../../SliderEdit";
// import SliderEdit from "../../../UI/SliderEdit";

const slider = {
  name: "radius",
  title: "Radius",
  unit: "m",
  min: 1e-4,
  max: 0.125,
  step: 0.01,
  log: true,
  edit: false,
};

export default function WaveGuideRadius({ value, properties, updateAmplitudes }) {
  const updateHeight = (name, value) => {
    properties.physics.radius.values = [Number(value), Number(value)];
    properties.signal.setAlpha(properties.physics.radius.values);
    properties.flag_updateReflection = !properties.flag_updateReflection;
    updateAmplitudes(properties);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <SliderEdit onChange={updateHeight} params={slider} val={value} />
    </div>
  );
}
