export const AzimuthAngles: number[] = [
  0, 2.5, 5, 7.5, 10, 12.5, 15, 17.5, 20, 22.5, 25, 27.5, 30, 32.5, 35, 37.5, 40, 42.5, 45, 47.5, 50, 52.5, 55, 57.5,
  60, 62.5, 65, 67.5, 70, 72.5, 75, 77.5, 80, 82.5, 85, 87.5, 90, 92.5, 95, 97.5, 100, 102.5, 105, 107.5, 110, 112.5,
  115, 117.5, 120, 122.5, 125, 127.5, 130, 132.5, 135, 137.5, 140, 142.5, 145, 147.5, 150, 152.5, 155, 157.5, 160,
  162.5, 165, 167.5, 170, 172.5, 175, 177.5, 180, 182.5, 185, 187.5, 190, 192.5, 195, 197.5, 200, 202.5, 205, 207.5,
  210, 212.5, 215, 217.5, 220, 222.5, 225, 227.5, 230, 232.5, 235, 237.5, 240, 242.5, 245, 247.5, 250, 252.5, 255,
  257.5, 260, 262.5, 265, 267.5, 270, 272.5, 275, 277.5, 280, 282.5, 285, 287.5, 290, 292.5, 295, 297.5, 300, 302.5,
  305, 307.5, 310, 312.5, 315, 317.5, 320, 322.5, 325, 327.5, 330, 332.5, 335, 337.5, 340, 342.5, 345, 347.5, 350,
  352.5, 355, 357.5,
];

export const ILD: number[] = [
  0.574395664288855, 1.62294100534727, 2.63810027325951, 3.61987346802558, 4.56826058964547, 5.48326163811918,
  6.36487661344672, 7.21310551562808, 8.02794834466327, 8.80940510055229, 9.55747578329513, 10.2811206595502,
  10.9770158526394, 11.6463601495995, 12.2825098746047, 12.8714260260363, 13.3901337700058, 13.8284140000708,
  14.178431012806, 14.4418168509397, 14.6807691759129, 14.9878213366982, 15.3795930625055, 15.7788580039035,
  16.0932666919897, 16.268517509102, 16.2689205245301, 16.0992691226885, 15.7911382939001, 15.3916226002509,
  14.9649981850799, 14.582855366563, 14.282993614221, 14.0575476464887, 13.8776197355524, 13.7149628755255,
  13.5632582181961, 13.4479586723023, 13.4360257975296, 13.6040343606214, 13.9502141042956, 14.3408578105588,
  14.6176105258304, 14.7147277952671, 14.618159423931, 14.3278136923645, 13.8893489169744, 13.3520168752176,
  12.7661846193177, 12.1924259798001, 11.6687134755398, 11.1755994482475, 10.6618368342972, 10.1151419027522,
  9.57132377215311, 9.0363252197498, 8.52855945727056, 8.06418235446936, 7.63002180008202, 7.21479914416276,
  6.79503452086778, 6.36672456719619, 5.9273685588275, 5.45300894421601, 4.89026976605635, 4.28941863646334,
  3.68256914224817, 3.09837031692216, 2.57054456032633, 2.11836380492015, 1.74687346282784, 1.48473754887561,
  1.376725435195, 1.43793246377319, 1.64274585398168, 1.98252275847989, 2.45228352562653, 3.02668533982737,
  3.66780162368318, 4.3556247064613, 5.06349181561913, 5.74632346834763, 6.36625290064613, 6.89285850864417,
  7.42073221648035, 7.94915924584922, 8.47477642580603, 9.01462583868845, 9.56084623293929, 10.105101841838,
  10.6396924274769, 11.1446424761103, 11.6408153197842, 12.1444405947326, 12.6531513230879, 13.1648587610258,
  13.6363448421664, 14.0269616610831, 14.3188313378427, 14.473122627857, 14.4805290392235, 14.3478528893704,
  14.0913417290868, 13.7885940614151, 13.5290909159189, 13.3975775543336, 13.4067679197252, 13.5013659736673,
  13.6375330726904, 13.792329372769, 13.9683528364931, 14.1934698540545, 14.4669656801087, 14.7793365603858,
  15.0988432947772, 15.3698097719705, 15.5408860336344, 15.570359670317, 15.4394017310343, 15.1624960481968,
  14.7671997084815, 14.3185782999357, 13.9065961183162, 13.5866980612101, 13.3362517932676, 13.0737846702591,
  12.7468704616481, 12.3368967916179, 11.8591819790072, 11.3279834646917, 10.7544457373143, 10.1557324407941,
  9.5436835344674, 8.90937514902727, 8.2474338947466, 7.52348185699414, 6.76206414567296, 5.96318076078307,
  5.12683170232447, 4.25301697029715, 3.34173656470112, 2.39299048553637, 1.40677873280291, 0.383101306500733,
];

export const ITD: number[] = [
  0.000787337662337727, 0.0238908350421508, 0.0469530359990886, 0.0699739405331511, 0.0929535486443381,
  0.11589186033265, 0.138788875598086, 0.161644594440647, 0.184459016860333, 0.207232142857143, 0.229963972431078,
  0.252933897243108, 0.275659461152882, 0.298338815789474, 0.321085526315789, 0.343378759398496, 0.365295269423559,
  0.386950971177945, 0.408459429824561, 0.429614661654135, 0.45156876566416, 0.474306077694235, 0.497808583959899,
  0.521831140350877, 0.54594219924812, 0.569615444862155, 0.592546209273183, 0.614431390977444, 0.634659304511278,
  0.653252662907268, 0.669522243107769, 0.683492324561403, 0.695066572681704, 0.704473684210526, 0.711802161654135,
  0.715575657894736, 0.714874686716792, 0.709642073934837, 0.700408834586466, 0.688097587719298, 0.672901002506265,
  0.65469768170426, 0.633876879699248, 0.610920269423559, 0.586054981203007, 0.560133145363408, 0.533371710526316,
  0.506660401002506, 0.48046992481203, 0.45500626566416, 0.431304824561403, 0.409418859649123, 0.388348214285714,
  0.36828399122807, 0.348405388471178, 0.32891212406015, 0.309786184210526, 0.290996240601504, 0.272720081453634,
  0.254812030075188, 0.2368710839599, 0.219129072681704, 0.201304824561403, 0.182639411027569, 0.161968201754386,
  0.14031093358396, 0.119458020050125, 0.0995715852130325, 0.0817073934837092, 0.0667520363408521, 0.0548026315789473,
  0.046312656641604, 0.0436795112781955, 0.0473840852130326, 0.056203790726817, 0.0695292919799498, 0.0873707706766917,
  0.108919172932331, 0.133225250626566, 0.159237938596491, 0.186002506265664, 0.212059837092732, 0.235920269423559,
  0.257744360902256, 0.279235588972431, 0.300729166666667, 0.321737155388471, 0.342648026315789, 0.363175908521303,
  0.382892387218045, 0.401768483709273, 0.421265664160401, 0.442236058897243, 0.464933427318296, 0.489390664160401,
  0.514987468671679, 0.541064379699248, 0.566985432330827, 0.592075501253133, 0.615896773182957, 0.638059993734335,
  0.658185307017544, 0.675805921052632, 0.690543546365914, 0.702315946115288, 0.710664943609022, 0.715563909774436,
  0.716786497493734, 0.714179197994987, 0.708067042606516, 0.700172305764411, 0.690458176691729, 0.678571428571428,
  0.664595864661654, 0.648383458646616, 0.630130795739348, 0.610191885964912, 0.588959899749373, 0.566804511278195,
  0.543926221804511, 0.520952380952381, 0.498146929824561, 0.475769893483709, 0.454278665413534, 0.433559680451128,
  0.413656015037594, 0.393975563909774, 0.373963815789473, 0.353263627819549, 0.332135808270676, 0.310549028822055,
  0.288836936090225, 0.266929824561403, 0.24515507518797, 0.223079573934837, 0.201123927242348, 0.17907666894509,
  0.156937799043062, 0.134707317536265, 0.112385224424698, 0.0899715197083618, 0.067466203387256, 0.0448692754613807,
  0.022180735930736,
];
