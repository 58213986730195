// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../../../../node_modules/normalize.css/normalize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.materialdesignicons.com/5.3.45/css/materialdesignicons.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://asknow-cdn.acoucou.org/engine/coreui-icons/css/linear.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://asknow-cdn.acoucou.org/engine/coreui-icons/css/solid.min.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*,*:before,*:after{box-sizing:border-box}body{font-family:\"Mulish\",sans-serif;font-weight:300}.stZ7r3hHadMrqpgAVD9o{height:100%;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-dark": "#e73b05",
	"primary": "#ff6040",
	"primary-light": "#ffe4df",
	"primary-extra-light": "#fff2f0",
	"secondary-dark": "#8292b1",
	"secondary": "#99a8c6",
	"secondary-light": "#d0dae9",
	"secondary-extra-light": "#eef4fc",
	"default-dark": "#898ca7",
	"default": "#b8baca",
	"default-light": "#ecedf5",
	"default-extra-light": "#f8f8f8",
	"warning-dark": "#d96800",
	"warning": "#fbab00",
	"warning-light": "#ffeba3",
	"warning-extra-light": "#fff5d1",
	"info-dark": "#0a82c6",
	"info": "#2eb6ea",
	"info-light": "#beebfc",
	"info-extra-light": "#dff5fe",
	"success-dark": "#069941",
	"success": "#3aaa35",
	"success-light": "#c6f1c3",
	"success-extra-light": "#e3f9e1",
	"danger-dark": "#cd0101",
	"danger": "#ff4141",
	"danger-light": "#ffcece",
	"danger-extra-light": "#ffe7e7",
	"text-primary": "#131a4f",
	"text-secondary": "#8292b1",
	"shadow-25": "rgba(184,186,202,.25)",
	"shadow-50": "rgba(184,186,202,.5)",
	"chartBlock": "stZ7r3hHadMrqpgAVD9o"
};
export default ___CSS_LOADER_EXPORT___;
