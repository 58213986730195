/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import NonlinearLoudspeakerGraph from "./NonlinearLoudspeaker.graph";
import { speakers_options } from "./NonlinearLoudspeaker.helper";
import { SwitchOption } from "@jelly/ui";

// -------------------------

// -------------------------

class NonlinearLoudspeaker extends Component {
  state = {
    x: 0, // displacement
    selected_speaker: speakers_options[0],
  };

  // sliders
  sliders = [
    {
      name: "x",
      unit: "mm",
      min: -5,
      max: 5,
      step: 0.5,
      edit: false,
    },
  ];

  // handle for slider (Amplitude)
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(value) });
    }
  };

  // handle for selection of ranges
  handleChangeSpeaker = (value) => {
    this.setState({
      selected_speaker: speakers_options.find((obj) => obj.value === Number(value)),
    });
  };

  renderBlock() {
    const { x, selected_speaker } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ flex: "1", display: "flex", alignItems: "center" }}>
          <NonlinearLoudspeakerGraph type="Bl" x={x} selected_speaker={selected_speaker} />
        </div>
        <div>
          {this.sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
        <div style={{ width: "70%", margin: "auto", paddingTop: "1em" }}>
          <SwitchOption
            items={speakers_options.map((option, index) => {
              return { name: option.title };
            })}
            onClick={this.handleChangeSpeaker}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}

export default NonlinearLoudspeaker;
