import { useEffect, useState } from "react";

export default function useFetch<T = Record<string, unknown>>(url: string): T | undefined {
  const [data, setData] = useState<T>();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const response = await fetch(url);
      const data = await response.json();

      setData(data);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}
