import { useEffect, useState } from "react";
import NumpyParser, { Numpy } from "../helpers/NumpyParser";

const npy = new NumpyParser();

export default function useNumpy(fileUrl: string): Numpy | undefined {
  const [data, setData] = useState<Numpy>();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      npy.load(fileUrl).then(setData);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}
