import Chart, { ChartConfiguration } from "chart.js";
import { cloneDeep } from "lodash-es";
import React, { CSSProperties, useEffect, useRef } from "react";

import styles from "./ChartBlock.module.scss";

export interface ChartBlockProps {
  data: ChartConfiguration;
  style?: CSSProperties;
  canvasStyle?: CSSProperties;
}

const ChartBlock: React.FC<ChartBlockProps> = (props) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  useEffect(() => {
    if (!canvas.current) {
      return;
    }

    if (!chartRef.current) {
      // We need deep copy {data} prop because Chart.js modifies this object by reference
      chartRef.current = new Chart(canvas.current, cloneDeep(props.data));
    } else {
      handleUpdateChart(props.data);
    }
  }, [props.data]);

  function handleUpdateChart(chartConfig: ChartConfiguration): void {
    if (!chartRef.current) {
      return;
    }

    if (chartConfig.data) {
      chartRef.current.data = chartConfig.data;
    }

    if (chartConfig.options) {
      chartRef.current.options = chartConfig.options;
    }

    chartRef.current.update();
  }

  return (
    <section className={styles.chartBlock} style={props.style}>
      <canvas ref={canvas} style={props.canvasStyle} />
    </section>
  );
};

export default ChartBlock;
