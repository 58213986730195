import { complex, abs, arg, add, multiply, divide, pi } from "mathjs";
import { AirParameters } from "../../helpers/ant-novak/helpers";
import { besselJ, Struve } from "../../helpers/ant-novak/math.helper";
// import { besselJ, Struve } from "../../helpers/math.helper";
// import { AirParameters } from "../../helpers/helpers";

const rhoC2 = 1.184 * Math.pow(346.1, 2);

export const mode_selector = {
  FUNDAMENTAL: 0,
  DATASHEET: 1,
};

// space graph options
export const mode_options = [
  {
    title: "Physical parameters",
    value: mode_selector.FUNDAMENTAL,
  },
  {
    title: "Datasheet parameters",
    value: mode_selector.DATASHEET,
  },
];

// slider for each TS parameter (Physics)
export const sliders_fundamental = [
  {
    name: "Re",
    title: "R<sub>e</sub>",
    unit: "Ohm",
    min: 0.01,
    max: 10.0,
    step: 0.01,
    edit: false,
  },
  {
    name: "Le",
    title: "L<sub>e</sub>",
    unit: "mH",
    min: 0.01,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    name: "Bl",
    title: "Bℓ",
    unit: "T∙m",
    min: 0.01,
    max: 20.0,
    step: 0.01,
    edit: false,
  },
  {
    name: "Mms",
    title: "M<sub>ms</sub>",
    unit: "g",
    min: 0.1,
    max: 50,
    step: 0.1,
    edit: false,
  },
  {
    name: "Rms",
    title: "R<sub>ms</sub>",
    unit: "N∙s/m",
    min: 0.01,
    max: 5,
    step: 0.01,
    edit: false,
  },
  {
    name: "Kms",
    title: "K<sub>ms</sub>",
    unit: "N/m",
    min: 1,
    max: 10e3,
    step: 1,
    edit: false,
  },
  {
    name: "Sd",
    title: "S<sub>d</sub>",
    unit: "cm<sup>2</sup>",
    min: 1,
    max: 1000,
    step: 1,
    edit: false,
  },
];

// slider for each TS parameter (Datasheet)
export const sliders_datasheet = [
  {
    name: "Re",
    title: "R<sub>e</sub>",
    unit: "Ohm",
    min: 0.01,
    max: 10.0,
    step: 0.01,
    edit: false,
  },
  {
    name: "Le",
    title: "L<sub>e</sub>",
    unit: "mH",
    min: 0.01,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    name: "Fs",
    title: "F<sub>s</sub>",
    unit: "Hz",
    min: 20,
    max: 500,
    step: 1,
    edit: false,
  },
  {
    name: "Qes",
    title: "Q<sub>es</sub>",
    unit: "",
    min: 0.01,
    max: 5,
    step: 0.01,
    edit: false,
  },
  {
    name: "Qms",
    title: "Q<sub>ms</sub>",
    unit: "",
    min: 0.01,
    max: 5,
    step: 0.01,
    edit: false,
  },
  {
    name: "Vas",
    title: "V<sub>as</sub>",
    unit: "l",
    min: 1,
    max: 100,
    step: 1,
    edit: false,
  },
  {
    name: "Sd",
    title: "S<sub>d</sub>",
    unit: "cm<sup>2</sup>",
    min: 1,
    max: 1000,
    step: 0.1,
    edit: false,
  },
];

// calculate input impedance of a Loudspeaker
export function loudspeaker(Bl, Re, Le, Mms, Rms, Kms, Sd, f_axis) {
  // prepare arrays for Z_in and phase
  const Z_in = [];
  const phase = [];
  const P_sens = [];
  for (let i = 0; i < f_axis.length; i++) {
    Z_in[i] = { x: f_axis[i], y: 0 };
    phase[i] = { x: f_axis[i], y: 0 };
    P_sens[i] = { x: f_axis[i], y: 0 };
  }

  let f, omega, Zav, Ze, Zm, Zma, Zin, Zback, W, P;
  let B21, B22;
  let A11, A12, A21, A22;

  const a = Math.sqrt(Sd / Math.PI);
  const r = 1; // at 1 meter

  // surfance
  const one_over_BlS = 1 / (Bl * Sd);

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    f = f_axis[i];
    omega = 2 * pi * f;

    // wavenumber
    const k = omega / AirParameters.c0;

    Zav = radiationImpedance(a, omega);
    Zback = Zav;

    Ze = complex(Re, omega * Le);
    Zm = complex(Rms, omega * Mms - Kms / omega);

    // back cavity
    Zma = add(Zm, multiply(Math.pow(Sd, 2), Zav), multiply(Math.pow(Sd, 2), Zback));

    // loudspeaker B matrix
    //B11 = multiply(Zma, -one_over_BlS);
    //B12 = add(Bl / Sd, multiply(Zma, Ze, one_over_BlS));
    B21 = Sd / Bl;
    B22 = multiply(Ze, -Sd / Bl);

    // loudspeaker A matrix
    A11 = multiply(Ze, Sd / Bl);
    A12 = add(multiply(Zma, Ze, one_over_BlS), Bl / Sd);
    A21 = Sd / Bl;
    A22 = multiply(Zma, one_over_BlS);

    // loudspeaker impedance
    Zin = divide(add(A11, divide(A12, Zav)), add(A21, divide(A22, Zav)));

    // loudspeaker volume velocity
    W = add(B21, divide(B22, Zin));

    // Pressure at 1 m @ 2.83 V
    P = multiply(
      (-2.83 * AirParameters.rho0 * AirParameters.c0) / Sd,
      W,
      add(-1, exp_complex(-k * (Math.sqrt(a * a + r * r) - r))),
      exp_complex(-k * r)
    );

    // FRF
    P_sens[i].y = 20 * Math.log10(abs(P) / 2e-5);

    // impedance
    Z_in[i].y = abs(Zin);
    phase[i].y = (180 / pi) * arg(Zin);
  }
  return { Z_in, phase, P_sens };
}

// Radiation Impedance of a flat piston diaphragm in a semi-infinite plate
//  a ... radius of the diaphragm
//  omega ... angular frequency
export function radiationImpedance(a, omega) {
  // surface
  const Sd = pi * Math.pow(a, 2);

  // wavenumber
  const k = omega / AirParameters.c0;

  // Rav (real part of the Radiation Impedance)
  const Rav = ((AirParameters.rho0 * AirParameters.c0) / Sd) * (1 - besselJ(1, 2 * k * a) / (k * a));

  // Xav (imaginary part of the Radiation Impedance)
  const Xav = ((AirParameters.rho0 * AirParameters.c0) / Sd) * (Struve(2 * k * a) / (k * a));

  return complex(Rav, Xav);
}

export function fundamental_to_datasheet(Re, Bl, Mms, Rms, Kms, Sd) {
  const Fs = (1 / (2 * Math.PI)) * Math.sqrt(Kms / Mms);
  const Qes = (2 * Math.PI * Fs * Mms * Re) / Math.pow(Bl, 2);
  const Qms = (2 * Math.PI * Fs * Mms) / Rms;
  const Vas = (rhoC2 * Math.pow(Sd, 2)) / Kms;
  return { Fs, Qes, Qms, Vas };
}

export function datasheet_to_fundamental(Re, Fs, Qes, Qms, Vas, Sd) {
  const Kms = (rhoC2 * Math.pow(Sd, 2)) / Vas;
  const Mms = Kms / Math.pow(2 * Math.PI * Fs, 2);
  const Rms = (2 * Math.PI * Fs * Mms) / Qms;
  const Bl = Math.sqrt((2 * Math.PI * Fs * Mms * Re) / Qes);
  return { Bl, Mms, Rms, Kms };
}

export function exp_complex(x) {
  return complex(Math.cos(x), Math.sin(x));
}
