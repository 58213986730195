import React, { Component } from "react";
import { fnc } from "./ElectrostaticTransduction.helper.js";
import { Button } from "@jelly/ui";

// Component used to play audio
// props:   freq ... frequency [Hz]
//          osc  ... array of oscillators, each oscillator has: { A, init_phase, last_phase}

const BUFFER_SIZE = 4096;

class ElectrostaticTransduction2 extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  componentDidUpdate() {
    // extract props from parrent
    const { U0 } = this.props;

    // on component mount create audioContext (Web Audio API)
    if (this.audioContext === null && this.state.is_playing === true) {
      // audio context
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();

      // oscillator
      this.osc = this.audioContext.createOscillator();
      this.osc.type = "sine";
      this.osc.frequency.value = 500;

      // script node
      this.scriptNode = this.audioContext.createScriptProcessor(BUFFER_SIZE, 1, 1);

      // connect chain
      this.osc.start();
      this.osc.connect(this.scriptNode);
      // but leave the scriptNode not connected to destination (no sound for the moment)
    }

    if (this.audioContext !== null) {
      // on change any of the properties check if audio is on (checkbox)
      if (this.state.is_playing === true) {
        this.scriptNode.onaudioprocess = function (audioProcessingEvent) {
          const inputBuffer = audioProcessingEvent.inputBuffer;
          const outputBuffer = audioProcessingEvent.outputBuffer;
          const N_channels = outputBuffer.numberOfChannels;

          // go through each output channel
          for (let channel = 0; channel < N_channels; channel++) {
            const inputData = inputBuffer.getChannelData(channel);
            const outputData = outputBuffer.getChannelData(channel);

            // sample-by-sample processing
            for (let sample = 0; sample < outputBuffer.length; sample++) {
              outputData[sample] = fnc(inputData[sample], U0);
            }
          }
        };

        // connect the scriptNode to the destination (it will start playing)
        this.scriptNode.connect(this.audioContext.destination);
      } else {
        // disconnect the scriptNode  (it will stop playing)
        this.scriptNode.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <Button
        size="standard"
        shape="round"
        onClick={this.handleChangePlay}
        icon={this.state.is_playing ? "cil-media-pause" : "cil-media-play"}
      >
        {this.state.is_playing ? "Pause" : "Play"}&nbsp;sine
      </Button>
    );
  }
}

export default ElectrostaticTransduction2;
