import { Slider, SwitchOption } from "@jelly/ui";
import React, { useState } from "react";
import Lesson0301Chart from "./Lesson0301Chart";
import { Lesson0301ChartDataFdValue, Lesson0301ChartDataMode } from "./Lesson0301ChartData";
import legend from "./assets/legend.svg";
import styles from "./Lesson0301.module.scss";

const modes: Array<[Lesson0301ChartDataMode, string]> = [
  ["linear", "Linear"],
  ["quadratic", "Quadratic"],
];

const Lesson0301: React.FC = () => {
  const [fdValue, setFdValue] = useState<number>(0.5);
  const [mode, setMode] = useState<Lesson0301ChartDataMode>("linear");

  return (
    <div className={styles.lesson0301}>
      <div className={styles.legend}>
        <img src={legend} alt="" />
      </div>
      <div className={styles.chart}>
        <Lesson0301Chart fdVaue={fdValue.toFixed(1) as Lesson0301ChartDataFdValue} mode={mode} />
      </div>
      <div className={styles.fd}>
        <div className={styles.badge}>h = {fdValue}</div>
        <div className={styles.slider}>
          <Slider theme={"primary"} onChange={setFdValue} min={0.1} max={1.0} step={0.1} value={fdValue} />
        </div>
      </div>
      <div className={styles.switch}>
        <SwitchOption
          items={modes.map((m) => {
            return { name: m[1] };
          })}
          onClick={(i) => setMode(modes[i][0])}
        />
      </div>
    </div>
  );
};

export default Lesson0301;
