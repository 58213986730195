import React, { useEffect, useRef } from "react";
import { interpolateHsl } from "d3-interpolate";

type HeatmapData = number[][];

export interface HeatmapProps {
  width: number;
  height: number;
  data: HeatmapData;
  minValue: number;
  maxValue: number;
  switchXY?: boolean;
  colorFunction?: (val: number) => string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  children?: any;
}

const val2color = interpolateHsl("blue", "yellow");

export const Heatmap: React.FC<HeatmapProps> = (props) => {
  const { width, height, data, minValue, maxValue, switchXY, colorFunction, children } = {
    referenceMaxValue: 255,
    colorFunction: val2color,
    ...props,
  } as Required<HeatmapProps>;

  const canvas = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const ctx = canvas.current?.getContext("2d");

    if (!canvas.current || !ctx) {
      return;
    }

    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const val = switchXY ? data[y][x] : data[x][y];
        const normalizedVal = (val - minValue) / (maxValue - minValue);
        const color = colorFunction(normalizedVal);
        ctx.fillStyle = color;
        ctx.fillRect(x, y, 1, 1);
      }
    }
  });

  return (
    <div style={{ display: "inline-block", position: "relative" }}>
      <canvas ref={canvas} width={width} height={height} />
      <div style={{ position: "absolute", top: 0, left: 0 }}>{children}</div>
    </div>
  );
};
