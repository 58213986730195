/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import MicrophoneElstatGraph from "./MicrophoneElstat.graph";
import MicrophoneElstatSVG from "./MicrophoneElstat.svg";

import { sliders } from "./MicrophoneElstat.helper";

// -------------------------
// Response of an electrostatic microphone
// - Simplified version, the back of the membrane is modeled by an acoustic impedance Za
//        D  ... Diameter of the diaphragm [m]
//        d0 ... Distance between electrodes at rest [m]
//        nu ... stretching force per unit length [N.m-1]
//        U0 ... Bias voltage [V] (std 200 V)
//        Ra ... Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
// -------------------------

class MicrophoneElstat extends Component {
  // class state
  state = {
    D: 6.35, // Diameter of the diaphragm [mm]
    d0: 13, // Distance between electrodes at rest [um]
    nu: 3162, // stretching force per unit length [N.m-1]
    U0: 200, // Bias voltage [V]
    Ra: 1, // Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  renderBlock() {
    let { D, d0, nu, U0, Ra } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ display: "flex", flexDirection: "row", flexGrow: "1", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <MicrophoneElstatSVG
              D={D} // Diameter of the diaphragm
              d0={d0} // Distance between electrodes at rest
              nu={nu} // stretching force per unit length
              U0={U0} // Bias voltage
              Ra={Ra} // Acoustic resistance of holes
            />
          </div>
          <div style={{ flex: "1" }}>
            <MicrophoneElstatGraph
              D={D * 1e-3} // Diameter of the diaphragm, pass value from [mm] to [m]
              d0={d0 * 1e-6} // Distance between electrodes at rest, pass value from [um] to [m]
              nu={nu} // stretching force per unit length [N.m-1]
              U0={U0} // Bias voltage [V]
              Ra={Ra * 1e6} // Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
            />
          </div>
        </div>

        <div className="slider-block">
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default MicrophoneElstat;
