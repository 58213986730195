import { complex, abs, add, subtract, multiply, divide, pi } from "mathjs";
import { AirParameters } from "../../helpers/ant-novak/helpers";
// import { AirParameters } from "../../helpers/helpers";

// slider for each parameter
export const sliders = [
  {
    name: "fmult",
    title: "f<sub>p</sub>",
    unit: "× f<sub>s</sub>",
    min: 0.5,
    max: 1.5,
    step: 0.01,
    edit: false,
  },
  {
    name: "Rmult",
    title: "R<sub>mp</sub>",
    unit: "× R<sub>m</sub>",
    min: 0.01,
    max: 2,
    step: 0.01,
    edit: false,
  },
];

const Re = 6;
const Le = 0.01;
const Bl = 4.58;
const Mms = 6.69e-3;
const Rms = 0.35;
const Kms = 660;
const Sd = 138e-4;
const U0 = 2.83;

const Vmult = 0.77;

const Cms = 1 / Kms;
const Cmp = Cms;

const rhoC2 = AirParameters.rho0 * Math.pow(346.1, 2);

const Vas = (rhoC2 * Math.pow(Sd, 2)) / Kms;

const fs = (1 / (2 * Math.PI)) * Math.sqrt(Kms / Mms);
const pg = (U0 * Bl) / Sd / Re;

// calculate microphone sensitivity
export function sensitivity(Rmult, fmult, f_axis) {
  // prepare arrays for absVal and phase
  const Z_pr = [];
  const Z_et = [];

  const Pd = [];
  const Pp = [];
  const Pb = [];

  for (let i = 0; i < f_axis.length; i++) {
    Z_pr[i] = { x: f_axis[i] / fs, y: 0 };
    Z_et[i] = { x: f_axis[i] / fs, y: 0 };
    Pd[i] = { x: f_axis[i] / fs, y: 0 };
    Pp[i] = { x: f_axis[i] / fs, y: 0 };
    Pb[i] = { x: f_axis[i] / fs, y: 0 };
  }

  const Rmp = Rmult * Rms;
  const Vab = Vmult * Vas;
  const fp = fmult * fs;
  const omp = 2 * Math.PI * fp;
  const Cap = Cms * Sd * Sd;
  const Rae = Math.pow(Bl, 2) / Re / Math.pow(Sd, 2);
  const Cab = Vab / AirParameters.rho0 / Math.pow(AirParameters.c0, 2);

  const Mmp = 1 / (4 * Math.pow(Math.PI * fp, 2) * Cmp);
  const Map = 1 / Cap / Math.pow(omp, 2);
  const Rap = Rmp / (Sd * Sd);

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    const f = f_axis[i];
    const omega = 2 * pi * f;

    const Zms = complex(Rms, omega * Mms - Kms / omega);
    const Zmp = complex(Rmp, omega * Mmp - 1 / (Cmp * omega));
    const Zas = divide(Zms, Math.pow(Sd, 2));
    const Zat = add(Rae, Zas);
    const Zab = complex(0, -1 / omega / Cab);
    const Zap = complex(Rap, omega * Map - 1 / (omega * Cap));

    const Zmb = multiply(Zab, Math.pow(Sd, 2));

    const Ze = complex(Re, omega * Le);

    const Zet = add(Ze, divide(Bl * Bl, Zms));

    const P1 = divide(multiply(Zmp, Zmb), add(Zmp, Zmb));
    const Zem = divide(Bl * Bl, add(Zms, P1));
    const Zpr = add(Ze, Zem);

    Z_pr[i].y = abs(Zpr);
    Z_et[i].y = abs(Zet);

    const A1 = divide(Zab, add(Zap, Zab));
    const qd = divide(pg, add(Zat, multiply(Zap, A1)));
    const qp = multiply(qd, A1);
    const qb = subtract(qd, qp);

    const B0 = complex(0, (omega * AirParameters.rho0) / 2 / Math.PI);
    const pd = multiply(B0, qd);
    const pp = multiply(-1, B0, qp);
    const pb = multiply(B0, qb);

    Pd[i].y = 20 * Math.log10(abs(divide(pd, 2e-5)));
    Pp[i].y = 20 * Math.log10(abs(divide(pp, 2e-5)));
    Pb[i].y = 20 * Math.log10(abs(divide(pb, 2e-5)));
  }

  return { Z_pr, Z_et, Pd, Pp, Pb };
}
