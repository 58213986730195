import { complex, abs, arg, divide, multiply, add, pi } from "mathjs";
import { AirParameters } from "../../helpers/ant-novak/helpers";
// import { AirParameters } from "../../helpers/helpers";

// slider for each parameter
export const sliders = [
  {
    name: "Vab",
    title: "V<sub>ab</sub>",
    unit: "cm<sup>3</sup>",
    min: 2,
    max: 200,
    step: 0.1,
    edit: false,
  },
];

// Microphone data
const a = 25e-3 / 2; // diaphragm radius
const Sd = Math.PI * Math.pow(a, 2); // diaphragm area
const d = 1.5e-2; // distance between diaphragm and opening
const Ram = 5e3; // Diaphragm acoustic resistance
const Cam = 4e-8; // Diaphragm acoustic compiance
const Mam = 95e-6 / Math.pow(Sd, 2); // Diaphragm acoustic mass
//const fr = 1 / 2 / Math.PI / Math.sqrt(Cam * Mam); // resoncance frequency
const B = 1.2; // Magnet flux
const L = 2; // coil length
//const Rab = 0; // loss term in rear cavity
const M0 = (2 * B * L * d) / Mam / Sd / AirParameters.c0;

// calculate sensitivity
export function sensitivity(Vab, f_axis) {
  // prepare arrays for absVal and phase
  const absVal = [];
  const phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    absVal[i] = { x: f_axis[i], y: 0 };
    phase[i] = { x: f_axis[i], y: 0 };
  }

  const Cab = Vab / AirParameters.rho0 / Math.pow(AirParameters.c0, 2); // acoustic compliance

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    const f = f_axis[i];
    const omega = 2 * pi * f;

    const Zab = complex(0, -1 / omega / Cab);
    const Zas = complex(Ram, omega * Mam - 1 / Cam / omega);

    const A = multiply(Zas, complex(1, -AirParameters.c0 / (omega * d)));
    const Mr = divide((2 * B * L) / Sd / M0, add(A, Zab)); // Exponantial approximation

    // attribute the abs value to the chart.js y-axis
    absVal[i].y = 20 * Math.log10(abs(Mr));
    // attribute the phase to the chart.js y-axis
    phase[i].y = (180 / pi) * arg(Mr);
  }

  return { absVal, phase };
}

const omd = 2 * Math.PI * 1000;
const k = omd / AirParameters.c0;

export function polarResponse(Vab) {
  // polar axis (angle)
  // data will contain array of {angle, value}
  const N = 72;
  const graphData = Array(N + 2);

  const Cab = Vab / AirParameters.rho0 / Math.pow(AirParameters.c0, 2); // acoustic compliance

  const Cab_optimal = 2.3e-6 / AirParameters.rho0 / Math.pow(AirParameters.c0, 2); // acoustic compliance
  const Rao = d / AirParameters.c0 / Cab_optimal;

  for (let i = 0; i < N + 1; i++) {
    const angle = (2 * Math.PI * i) / N;

    const A = (10 * d) / Cab / Rao / AirParameters.c0;
    const value = (1 + A * Math.cos(angle)) / (1 + A);

    graphData[i] = {
      angle: angle,
      value: Math.abs(value),
    };
  }

  // set last point to NaN so that the radar graph is not closed (first and last point not connected)
  graphData[N + 1] = NaN;
  return { graphData };
}
