/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import PassiveRadiatorGraph from "./PassiveRadiator.graph";
import PassiveRadiatorSVG from "./PassiveRadiator.svg";
import { getLength, getVolume } from "./PassiveRadiator.helper";
import { sliders } from "./PassiveRadiator.helper";
import { round } from "../../helpers/math.helper";

// -------------------------
// Loudspeaker system with a passive radiator
// - Loudspeaker with given TS parameters has a closed-box in which is a passive radiator with
//        fmult ... fb = fmult * fc
//        Rmult ... Rms_passive = Rmult * Rms
// -------------------------

class PassiveRadiator extends Component {
  // class state
  state = {
    Rmult: 1, // multiplication factor of volume
    fmult: 0.66, // multiplication factor of mechanical resistance
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  renderBlock() {
    const { Rmult, fmult } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <h5>CLOSED-BOX SYSTEM</h5>
        <div style={{ display: "flex", flexDirection: "row", flex: "1", alignItems: "center" }}>
          <div style={{ width: "20%" }}>
            <PassiveRadiatorSVG />
          </div>
          <div style={{ width: "80%" }}>
            <PassiveRadiatorGraph Rmult={Rmult} fmult={fmult} />
          </div>
        </div>
        <div>
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default PassiveRadiator;
