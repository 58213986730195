import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { graphData } from "./BlockedImpedance.helper";

class BlockedImpedanceGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    const { z_values } = this.props;

    // impedance data
    const { Re, Le, labels } = graphData(z_values);
    const dataRe = {
      title: "Apparent resistance",
      xAxes: {
        xLabel: "Frequency [Hz]",
        type: "logarithmic",
        min: 50,
        max: 20000,
      },
      yAxes: [
        {
          data: Re,
          yLabel: "Resistance [ohm]",
          type: "linear",
          label: labels,
        },
      ],
      animation: 0,
    };

    const dataLe = {
      title: "Apparent inductance",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
        min: 50,
        max: 20000,
      },
      yAxes: [
        {
          data: Le,
          yLabel: "Inductance (mH)",
          type: "linear",
        },
      ],
      animation: 0,
    };

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "50%" }}>
          <LineGraph data={dataRe} graph_size_ratio={0.7} />
        </div>
        <div style={{ width: "50%" }}>
          <LineGraph data={dataLe} graph_size_ratio={0.7} />
        </div>
      </div>
    );
  }
}
export default BlockedImpedanceGraph;
