import { complex, abs, multiply, divide, pi } from "mathjs";

const MU = String.fromCharCode(956); // symbol for micro (mu)

// slider for each parameter
export const sliders = [
  {
    name: "D",
    unit: "mm",
    min: 3,
    max: 25,
    step: 0.1,
    edit: false,
  },
  {
    name: "d0",
    title: "d<sub>0</sub>",
    unit: MU + "m",
    min: 5,
    max: 100,
    step: 0.01,
    edit: false,
    log: true,
  },
  {
    name: "nu",
    title: "n<sub>u</sub>",
    unit: "N/m",
    min: 30,
    max: 30000,
    step: 0.01,
    edit: false,
    log: true,
  },
  {
    name: "U0",
    title: "U<sub>0</sub>",
    unit: "V",
    min: 10,
    max: 1000,
    step: 1,
    edit: false,
  },
  {
    name: "Ra",
    title: "R<sub>a</sub>",
    unit: "*1e6 kg/s/m^2",
    min: 1,
    max: 1e4,
    step: 0.01,
    edit: false,
    log: true,
  },
];

const sigm = 44.5e-3; // diaphragm surface density [kg.m-2]

// calculate microphone sensitivity
export function sensitivity(D, d0, nu, U0, Ra, f_axis) {
  // prepare arrays for absVal and phase
  let absVal = [];
  for (let i = 0; i < f_axis.length; i++) {
    absVal[i] = { x: f_axis[i], y: 0 };
  }

  // prepare constant values
  const Sm = pi * D ** 2; // Membrane area
  //const C0 = (Sm * eps0) / d0; // Microphone capacitance%
  const Md = pi * D ** 2 * sigm; // Mechanical mass of diaphragm
  const Cd = 1 / (8 * Math.PI * nu); // Mechanical compliance of diaphragm
  //const f0 = Math.sqrt(1 / (Md * Cd)) / (2 * Math.PI); // resonance frequency in vacuo
  //const kb = (C0 * U0) / d0; // Transduction factor
  //const C0m = C0 / kb ** 2; // Negative mechanical compliance
  const Rm = Ra * Sm ** 2; // Mechanical resistance due to acoutcial resistance

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * pi * f;

    // Mechanical impedance
    let Zm = complex(Rm, omega * Md - 1 / (Cd * omega));
    let Sg = divide(complex(0, U0 * Sm), multiply(omega, d0, Zm));

    // attribute the abs value to the chart.js y-axis
    absVal[i].y = 20 * Math.log10(abs(Sg));
  }

  return absVal;
}
