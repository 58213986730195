import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { COLORS, LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { sensitivity, frequencyLimits } from "./MicrophoneEldyn.helper";
// import { COLORS } from "../../helpers/helpers";

class MicrophoneEldynGraph extends Component {
  render() {
    // List of inputs ("props") :
    // Rt ... mech. resistance  [Ns/m]
    // Mt ... mass [kg]
    // Ct ... compliance [m/N]
    const { Rt, Mt, Ct } = this.props;

    // frequency axis
    const f_ax = LogSpace(1, Math.log10(20000), 500);
    const { fl_line, fh_line, db_line } = frequencyLimits(Rt, Mt, Ct);

    // abs and phase
    const { absVal, phase } = sensitivity(Rt, Mt, Ct, f_ax);
    const data = {
      //title: "Microphone Sensitivity",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [absVal, fl_line, fh_line, db_line],
          yLabel: "Relative sensitivity (dB)",
          type: "linear",
          min: -30,
          max: 5,
          borderColor: [COLORS[0], COLORS[0], COLORS[0], COLORS[0]],
          lineWidth: [3, 1, 1, 1],
          borderDash: [undefined, [10, 5], [10, 5], [10, 5]],
        },
        {
          data: [phase],
          yLabel: "Phase (deg)",
          type: "linear",
          min: -180,
          max: 180,
          borderColor: [COLORS[1]],
        },
      ],
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={0.6} />;
  }
}
export default MicrophoneEldynGraph;
