/* eslint-disable */
// @ts-nocheck
import React, { Component } from "react";
import { Slider, Badge, Button } from "@jelly/ui";
import { monoEncoder, decoder, binDecoder } from "ambisonics";
import { SPEAKER_POSITIONS } from "./AmbisonicsLoudspeakerDecoder.helper";
import { ReactComponent as LoudspeakerIcon } from "../assets/speaker.svg";
import CircularSlider from "@fseehawer/react-circular-slider";
import TrackViewer from "../Chart/TrackViewer";
import HeadSVG from "../assets/head.svg";
import styles from "../Lesson0324.module.scss";

import Unity, { UnityContent } from "react-unity-webgl";

class AmbisonicsLoudspeakerDecoder extends Component {
  nSpeakers = SPEAKER_POSITIONS.length;
  ambisonicsOrder = 1;
  nChannels = (this.ambisonicsOrder + 1) * (this.ambisonicsOrder + 1);

  constructor() {
    super();
    this.isPlaying = false;
    this.state = {
      currentAudioBuffer: new Array(this.nSpeakers).fill(0),
      encoderGains: [],
      isLoaded: true,
      loopAudio: false,
      buttonState: "Play",
      sourceAzimuth: 0,
      sourceElevation: 0,
      selectedLoudspeaker: 0,
      lspAzimuth: 0,
      lspElevation: 0,
    };
    this.unityContent = new UnityContent(
      "/assets/lesson/0324/build/asknow-ambisonics.json",
      "/assets/lesson/0324/build/UnityLoader.js"
    );
  }

  initAudio() {
    if (this.audioContext != null) {
      return;
    }
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.audioPath = "/assets/viola.wav";
    this.speakerPositions = SPEAKER_POSITIONS;
    this.nSpeakers = this.speakerPositions.length;
    this.ambisonicsOrder = 1;
    this.nChannels = (this.ambisonicsOrder + 1) * (this.ambisonicsOrder + 1);
    this.sourceEncoder = new monoEncoder(this.audioContext, this.ambisonicsOrder);
    this.lspArrayDecoder = new decoder(this.audioContext, 2);
    this.channelSplitter = this.audioContext.createChannelSplitter(this.nSpeakers);
    this.analyser = new Array(this.nSpeakers);
    for (var i = 0; i < this.nSpeakers; i++) {
      this.analyser[i] = this.audioContext.createAnalyser();
      this.analyser[i].channelCountMode = "explicit";
      this.analyser[i].channelCount = 1;
      this.channelSplitter.connect(this.analyser[i], i);
    }
    this.currentAudioBuffer = new Array(this.nSpeakers);
    this.analyser.map((analyser, chNr) => (this.currentAudioBuffer[chNr] = new Float32Array(analyser.fftSize)));
    // this.ambisonicsDecoder.speakerPos = this.speakerPositions;
    this.lspChannelEncoder = new monoEncoder(this.audioContext, this.ambisonicsOrder);
    this.binauralDecoder = new binDecoder(this.audioContext, this.ambisonicsOrder);
    this.sourceEncoder.out.connect(this.lspArrayDecoder.in);
    this.lspArrayDecoder.out.connect(this.channelSplitter);
    // this.channelSplitter.connect(this.audioContext.destination, 4);
    this.lspChannelEncoder.out.connect(this.binauralDecoder.in);
    this.binauralDecoder.out.connect(this.audioContext.destination);
    // Unity loudspeaker visualization

    this.setState({
      currentAudioBuffer: this.currentAudioBuffer,
      encoderGains: new Array(this.nSpeakers).fill(0),
      isLoaded: true,
    });

    this.audioSource = document.getElementById("audio");
    this.audioSource.onended = this.onAudioEnded.bind(this);
    this.mediaElementSource = this.audioContext.createMediaElementSource(this.audioSource);
    // Connect audio to first (main) encoder
    this.mediaElementSource.connect(this.sourceEncoder.in);
    this.sourceEncoder.azim = this.state.sourceAzimuth;
    this.sourceEncoder.elev = this.state.sourceElevation;
    this.sourceEncoder.updateGains();
    // Determine what happens on SendLoudspeakerData
    this.unityContent.on("SendLoudspeakerData", this.onLoudspeakerSelection);
    // Connect splitter to auralization encoder
    this.channelSplitter.connect(this.lspChannelEncoder.in, this.state.selectedLoudspeaker);
    this.lspChannelEncoder.azim = this.state.lspAzimuth;
    this.lspChannelEncoder.elev = this.state.lspElevation;
    this.lspChannelEncoder.updateGains();
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.isPlaying != this.props.isPlaying) {
      if (this.props.isPlaying) {
        this.play();
      } else {
        this.stop();
      }
    }
  }

  componentWillUnmount() {
    if (this.isPlaying) {
      this.stop();
    }
  }

  render() {
    return (
      <>
        <audio id="audio" loop={this.state.loopAudio} src={this.audioPath}></audio>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
          <div style={{ display: "flex", flexDirection: "row", flex: "1 0 80%", gap: "1em" }}>
            <div style={{ flex: "1 1 40%" }}>
              <div
                style={{
                  flex: "1 0 80%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularSlider
                  min={-180}
                  max={180}
                  direction={-1}
                  label="Azimuth"
                  labelColor="#fff"
                  appendToValue="°"
                  knobColor="#131a4f"
                  knobSize={30}
                  knobPosition="bottom"
                  progressSize={0}
                  trackColor="#d0dae9"
                  trackSize={2}
                  hideLabelValue={true}
                  dataIndex={180}
                  onChange={(value) => {
                    this.onSliderChange("azimuth", value);
                  }}
                >
                  <LoudspeakerIcon height="30px" width="30px" x="1" y="1" />
                </CircularSlider>
                <img
                  src={HeadSVG}
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "30%",
                    margin: "auto",
                    width: "100px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "0 1 20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Azimuth</span>
                <div className={styles.label}>{this.state.sourceAzimuth}°</div>
              </div>
            </div>
            <div style={{ flex: "1 0", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ flex: "1 0 80%" }}>
                <Slider
                  min={-90}
                  max={90}
                  step={1}
                  defaultValue={0}
                  vertical
                  theme="primary"
                  onChange={(value) => {
                    this.setState({
                      elevation: value,
                    });
                    this.onSliderChange("elevation", value);
                  }}
                />
              </div>

              <div
                style={{
                  flex: "0 1 20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Elevation</span>

                <div className={styles.label}>{this.state.sourceElevation}°</div>
              </div>
            </div>
            <div style={{ flex: "1 1 50%" }}>
              <div style={{ width: "280px", height: "280px", margin: "auto" }}>
                <Unity unityContent={this.unityContent} />
              </div>
            </div>
          </div>
          <div style={{ flex: "1 0 20%", display: "flex", flexWrap: "wrap", gap: "5%", maxWidth: "600px" }}>
            {this.state.currentAudioBuffer.map((object, chNr) => (
              <div key={chNr} style={{ flex: "1 1 30%", width: "28%", height: "52px" }}>
                <TrackViewer
                  key={chNr}
                  chNr={chNr}
                  bufferSize={this.analyser != undefined ? this.analyser[chNr].fftSize : 2048}
                  encoderGains={this.state.encoderGains}
                  audioBuffer={this.state.currentAudioBuffer}
                  getCurrentAudioFrame={this.getCurrentAudioFrame.bind(this)}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  getCurrentAudioFrame(chNr) {
    var currentAudioBuffer = this.state.currentAudioBuffer;
    this.analyser[chNr].getFloatTimeDomainData(currentAudioBuffer[chNr]);
    this.setState({ currentAudioBuffer });
  }

  play() {
    this.initAudio();
    var playPromise = this.audioSource.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          this.isPlaying = true;
          this.setState({ buttonState: "Play" });
          this.props.playChange(true);
        })
        .catch((error) => {
          this.isPlaying = false;
          this.setState({ buttonState: "Stop" });
          this.props.playChange(false);

          console.error(error);
        });
    }
  }

  stop() {
    this.audioSource.pause();
    this.audioSource.currentTime = 0;
    this.isPlaying = false;
    this.props.playChange(false);

    this.setState({ buttonState: "Play" });
  }

  playOrStopSound() {
    if (this.isPlaying) {
      this.stop();
    } else {
      this.play();
    }
  }

  onSliderChange(type, value) {
    this.initAudio();

    switch (type) {
      case "azimuth":
        this.setState({ sourceAzimuth: value });
        this.sourceEncoder.azim = value;
        break;
      case "elevation":
        this.setState({ sourceElevation: value });
        this.sourceEncoder.elev = value;
        break;
      default:
        this.sourceEncoder.azim = 0;
        this.sourceEncoder.elev = 0;
    }
    this.sourceEncoder.updateGains();
  }

  onAudioEnded() {
    this.playOrStopSound();
  }

  onClick() {
    this.playOrStopSound();
  }

  onLoudspeakerSelection(speakerData) {
    this.setState({
      selectedLoudspeaker: speakerData, // TODO: Parse speaker data?
      lspAzimuth: this.speakerPositions[speakerData][0],
      lspElevation: this.speakerPositions[speakerData][1],
    });
  }
}

export default AmbisonicsLoudspeakerDecoder;
