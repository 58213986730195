export const R = [
  0, 0.000313699824336839, 0.000897915696667056, 0.0016611234877528, 0.00257016548728111, 0.00360575549629777,
  0.00475482426717775, 0.00600774631504709, 0.00735704496741587, 0.00879669284210235, 0.0103216955650944,
  0.0119278261777068, 0.0136114465063752, 0.0153693819352724, 0.0171988306255293, 0.0190972958593009, 0.021062534431341,
  0.0230925164926572, 0.0251853937647495, 0.0273394740001961, 0.029553200189652, 0.0318251334336101, 0.034153938684249,
  0.0365383727637543, 0.0389772742090481, 0.0414695545970806, 0.0440141910816693, 0.0466102199303039,
  0.0492567308928048, 0.0519528622670175, 0.0546977965524867, 0.0574907566031986, 0.0603310022063579,
  0.0632178270268062, 0.0661505558668132, 0.0691285421991573, 0.0721511659380585, 0.075217831417976, 0.0783279655547607,
  0.0814810161673689, 0.0846764504414292, 0.0879137535185437, 0.0911924271973787, 0.0945119887344324,
  0.0978719697339316, 0.101271915117629, 0.104711382166415, 0.108189939626621, 0.111707166874749, 0.115262653135063,
  0.118855996745125, 0.122486804464905, 0.126154690825556, 0.129859277514383, 0.133600192792892, 0.13737707094512,
  0.141189551753747, 0.145037280001738, 0.14891990499748, 0.152837080121576, 0.156788462393654, 0.160773712057686,
  0.16479249218446, 0.16884446828997, 0.172929307968632, 0.177046680540281, 0.181196256710048, 0.185377708240287,
  0.189590707633765, 0.193834927827453, 0.198110041896266, 0.202415722766197, 0.206751642936309, 0.211117474209126,
  0.215512887428988, 0.219937552227981, 0.224391136779092, 0.228873307556272, 0.233383729101119, 0.237922063795919,
  0.242487971642824, 0.247081110048955, 0.251701133617242, 0.256347693942849, 0.261020439415037, 0.265719015024345,
  0.270443062174972, 0.275192218502289, 0.279966117695388, 0.284764389324624, 0.289586658674086, 0.294432546578974,
  0.299301669267858, 0.304193638209799, 0.309108059966339, 0.31404453604836, 0.319002662777832, 0.323982031154481,
  0.328982226727391, 0.334002829471603, 0.339043413669745, 0.344103547798741, 0.349182794421676, 0.354280710084864,
  0.359396845220198, 0.364530744052854, 0.369681944514424, 0.374849978161572, 0.380034370100278, 0.385234638915778,
  0.390450296608288, 0.395680848534587, 0.400925793355584, 0.40618462298994, 0.411456822573857, 0.416741870427125,
  0.422039238025537, 0.427348389979752, 0.432668784020726, 0.437999870991788, 0.443341094847476, 0.448691892659215,
  0.454051694627933, 0.459419924103708, 0.464795997612533, 0.470179324890281, 0.475569308923957, 0.480965346000305,
  0.486366825761859, 0.491773131270485, 0.497183639078496, 0.502597719307398, 0.5080147357343, 0.513434045886061,
  0.518855001141197, 0.524276946839586, 0.529699222399997, 0.535121161445468, 0.540542091936538, 0.545961336312328,
  0.551378211639492, 0.556792029768993, 0.562202097500707, 0.567607716755802, 0.573008184756865, 0.578402794215726,
  0.583790833528903, 0.589171586980613, 0.594544334953261, 0.599908354145311, 0.605262917796442, 0.610607295919874,
  0.615940755541735, 0.62126256094734, 0.62657197393423, 0.631868254071812, 0.637150658967443, 0.642418444538755,
  0.647670865292058, 0.652907174606589, 0.658126625024424, 0.6633284685458, 0.668511956929633, 0.673676341998977,
  0.678820875951174, 0.68394481167242, 0.689047403056479, 0.694127905327265, 0.699185575364974, 0.704219672035499,
  0.709229456522781, 0.714214192663796, 0.719173147285848, 0.724105590545824, 0.729010796271079, 0.733888042301599,
  0.738736610833084, 0.743555788760598, 0.748344868022421, 0.753103145943727, 0.757829925579728, 0.762524516057898,
  0.767186232918915, 0.77181439845592, 0.776408342051736, 0.780967400513659, 0.785490918405427, 0.78997824837602,
  0.794428751484881, 0.798841797523208, 0.803216765330928, 0.807553043109007, 0.811850028726706, 0.816107130023452,
  0.820323765104952, 0.824499362633215, 0.828633362110148, 0.832725214154381, 0.836774380771017, 0.840780335613976,
  0.844742564240652, 0.84866056435857, 0.852533846063774, 0.856361932070673, 0.860144357933083, 0.863880672256223,
  0.867570436899436, 0.871213227169399, 0.874808632003641, 0.87835625414415, 0.881855710300917, 0.885306631305232,
  0.888708662252594, 0.892061462635113, 0.895364706463256, 0.898618082376871, 0.90182129374538, 0.904974058757071,
  0.908076110497447, 0.911127197016579, 0.914127081385452, 0.917075541741284, 0.919972371321839, 0.922817378488745,
  0.925610386739865, 0.92835123471077, 0.931039776165393, 0.933675879975923, 0.93625943009207, 0.93879032549979,
  0.941268480169597, 0.943693822994614, 0.946066297718503, 0.948385862853442, 0.95065249158833, 0.952866171687393,
  0.955026905379416, 0.95713470923778, 0.959189614051552, 0.961191664687834, 0.963140919945628, 0.965037452401448,
  0.966881348246943, 0.968672707118796, 0.970411641921149, 0.972098278640855, 0.973732756155814, 0.975315226036691,
  0.976845852342302, 0.978324811408958, 0.979752291634071, 0.981128493254308, 0.982453628118612, 0.983727919456373,
  0.984951601641069, 0.986124919949665, 0.987248130318094, 0.988321499093098, 0.989345302780753, 0.990319827791963,
  0.991245370185232, 0.992122235406997, 0.992950738029835, 0.993731201488801, 0.994463957816217, 0.995149347375171,
  0.995787718592006, 0.996379427688079, 0.996924838411043, 0.997424321765927, 0.997878255746255, 0.998287025065469,
  0.998651020888876, 0.998970640566381, 0.999246287366218, 0.999478370209899, 0.999667303408616, 0.999813506401277,
  0.999917403494403, 0.999979423604056, 1, 0.999964197082984, 0.999857312501371, 0.999680148242525, 0.999433526474914,
  0.999118288550507, 0.9987352940063, 0.998285419566668, 0.997769558148155, 0.997188617868309, 0.996543521060053,
  0.995835203293087, 0.995064612403691, 0.994232707534288, 0.993340458184031, 0.99238884327161, 0.99137885021144,
  0.990311474004277, 0.989187716343278, 0.988008584736444, 0.986775091646294, 0.985488253647595, 0.984149090603854,
  0.982758624863262, 0.981317880474669, 0.979827882424146, 0.978289655892596, 0.976704225534828, 0.975072614780448,
  0.973395845156869, 0.971674935634664, 0.96991090199546, 0.968104756222495, 0.966257505913927, 0.964370153718927,
  0.962443696796538, 0.960479126297252, 0.958477426867198, 0.956439576174817, 0.954366544459834, 0.952259294104332,
  0.950118779225673, 0.947945945291007, 0.945741728753057, 0.943507056706861, 0.941242846567116, 0.93895000576575,
  0.93662943146933, 0.934282010315906, 0.931908618170837, 0.929510119901187, 0.927087369168217, 0.924641208237517,
  0.922172467806297, 0.919681966847364, 0.917170512469283, 0.914638899792246, 0.912087911839134, 0.909518319441288,
  0.906930881158478, 0.904326343212584, 0.901705439434468, 0.899068891223573, 0.896417407519716, 0.893751684786633,
  0.891072407006742, 0.88838024568669, 0.885675859873179, 0.882959896178632, 0.880232988816212, 0.877495759643781,
  0.874748818216316, 0.871992761846393, 0.869228175672277, 0.866455632733232, 0.863675694051634, 0.860888908721496,
  0.858095814003026, 0.855296935422839, 0.852492786879465, 0.849683870753797, 0.846870678024148, 0.844053688385568,
  0.841233370373128, 0.838410181488836, 0.835584568331904, 0.83275696673207, 0.829927801885704, 0.827097488494428,
  0.82426643090599, 0.821435023257154, 0.818603649618364, 0.815772684139957, 0.812942491199709, 0.810113425551504,
  0.807285832474931, 0.804460047925618, 0.801636398686125, 0.798815202517226, 0.795996768309402, 0.793181396234416,
  0.790369377896802, 0.787560996485136, 0.784756526922961, 0.781956236019229, 0.779160382618163, 0.776369217748409,
  0.773582984771386, 0.770801919528729, 0.768026250488741, 0.765256198891765, 0.762491978894395, 0.759733797712453,
  0.756981855762665, 0.754236346802971, 0.751497458071412, 0.748765370423533, 0.746040258468264, 0.743322290702222,
  0.740611629642407, 0.737908431957231, 0.735212848595878, 0.732525024915943, 0.729845100809323, 0.727173210826357,
  0.724509484298167, 0.721854045457213, 0.719207013556018, 0.716568502984075, 0.713938623382915, 0.711317479759333,
  0.708705172596767, 0.706101797964824, 0.703507447626964, 0.700922209146328, 0.698346165989725, 0.695779397629783,
  0.69322197964526, 0.69067398381954, 0.688135478237302, 0.685606527379397, 0.683087192215929, 0.680577530297549,
  0.678077595845002, 0.675587439836903, 0.673107110095802, 0.670636651372511, 0.668176105428752, 0.665725511118108,
  0.663284904465323, 0.660854318743955, 0.65843378455241, 0.656023329888368, 0.653622980221638, 0.651232758565446,
  0.648852685546186, 0.646482779471654, 0.644123056397797, 0.641773530193973, 0.639434212606779, 0.637105113322441,
  0.6347862400278, 0.632477598469925, 0.630179192514357, 0.627891024202021, 0.625613093804828, 0.623345399879978,
  0.621087939323003, 0.618840707419557, 0.616603697895991, 0.61437690296872, 0.612160313392415, 0.609953918507034,
  0.607757706283725, 0.605571663369614, 0.603395775131491, 0.601230025698445, 0.59907439800343, 0.596928873823816,
  0.594793433820929, 0.592668057578598, 0.590552723640743, 0.588447409548008, 0.586352091873477, 0.584266746257465,
  0.58219134744144, 0.580125869301058, 0.578070284878355, 0.576024566413102, 0.57398868537335, 0.571962612485169,
  0.569946317761612, 0.567939770530918, 0.565942939463964, 0.563955792600992, 0.561978297377618, 0.56001042065015,
  0.55805212872022, 0.556103387358752, 0.554164161829281, 0.552234416910633, 0.55031411691899, 0.54840322572934,
  0.546501706796345, 0.544609523174618, 0.542726637538452, 0.54085301220098, 0.538988609132807, 0.537133389980117,
  0.535287316082259, 0.53345034848884, 0.531622447976328, 0.529803575064173, 0.527993690030471, 0.526192752927163,
  0.524400723594807, 0.522617561676899, 0.520843226633789, 0.519077677756178, 0.517320874178212, 0.515572774890194,
  0.513833338750905, 0.512102524499564, 0.51038029076741, 0.508666596088953, 0.506961398912857, 0.505264657612509,
  0.503576330496242, 0.501896375817256, 0.500224751783216, 0.498561416565553, 0.496906328308472, 0.495259445137667,
  0.493620725168764, 0.491990126515481, 0.490367607297533, 0.488753125648274, 0.487146639722084, 0.485548107701517,
  0.483957487804207, 0.482374738289542, 0.48079981746511, 0.479232683692928, 0.477673295395454, 0.476121611061392,
  0.474577589251292, 0.473041188602953, 0.471512367836637, 0.469991085760086, 0.468477301273371, 0.466970973373551,
  0.465472061159166, 0.463980523834564, 0.462496320714055, 0.461019411225921, 0.459549754916259, 0.45808731145268,
  0.456632040627861, 0.455183902362954, 0.453742856710861, 0.452308863859369, 0.450881884134156, 0.449461878001679,
  0.448048806071923, 0.446642629101046, 0.445243307993895, 0.443850803806423, 0.442465077747977, 0.441086091183501,
  0.439713805635619, 0.438348182786625, 0.436989184480373, 0.435636772724075, 0.434290909690001, 0.432951557717098,
  0.431618679312513, 0.430292237153039, 0.428972194086477, 0.427658513132915, 0.426351157485938, 0.425050090513755,
  0.423755275760257, 0.422466676946007, 0.421184257969158, 0.419907982906308, 0.418637816013288, 0.417373721725891,
  0.416115664660538, 0.414863609614888, 0.413617521568388, 0.412377365682773, 0.411143107302511, 0.409914711955193,
  0.408692145351881, 0.407475373387398, 0.406264362140579, 0.405059077874475, 0.403859487036514, 0.402665556258613,
  0.401477252357261, 0.400294542333552, 0.399117393373184, 0.397945772846424, 0.39677964830803, 0.395618987497145,
  0.394463758337155, 0.393313928935515, 0.392169467583542, 0.391030342756183, 0.389896523111749, 0.388767977491626,
  0.38764467491995, 0.386526584603268, 0.385413675930166, 0.384305918470873, 0.383203281976848, 0.382105736380335,
  0.381013251793908, 0.379925798509981, 0.378843347000314, 0.377765867915488, 0.376693332084364, 0.375625710513531,
  0.374562974386726, 0.373505095064248, 0.372452044082349, 0.371403793152615, 0.370360314161328, 0.369321579168821,
  0.368287560408813, 0.367258230287731, 0.366233561384033, 0.365213526447497, 0.364198098398523, 0.363187250327407,
  0.362180955493613, 0.361179187325034, 0.360181919417246, 0.359189125532749, 0.358200779600202, 0.357216855713657,
  0.35623732813177, 0.355262171277024, 0.354291359734932, 0.353324868253238, 0.352362671741116, 0.351404745268357,
  0.350451064064558, 0.349501603518299, 0.348556339176324, 0.34761524674271, 0.346678302078038, 0.345745481198561,
  0.344816760275359, 0.343892115633507, 0.342971523751228, 0.342054961259049, 0.341142404938954, 0.340233831723534,
  0.339329218695138, 0.338428543085023, 0.337531782272495, 0.336638913784064, 0.335749915292582, 0.334864764616394,
  0.333983439718478, 0.333105918705594, 0.332232179827428, 0.331362201475735, 0.330495962183489, 0.329633440624025,
  0.328774615610187, 0.327919466093481, 0.327067971163216, 0.326220110045662, 0.325375862103195, 0.324535206833457,
  0.323698123868504, 0.322864592973968, 0.322034594048213, 0.321208107121494, 0.320385112355124, 0.319565590040633,
  0.318749520598938, 0.317936884579512, 0.317127662659557, 0.316321835643176, 0.315519384460549, 0.314720290167118,
  0.313924533942764, 0.313132097090995, 0.312342961038134, 0.311557107332513, 0.31077451764366, 0.309995173761505,
  0.309219057595578, 0.308446151174212, 0.307676436643751, 0.306909896267762, 0.306146512426248, 0.305386267614868,
  0.304629144444157, 0.30387512563875, 0.303124194036614, 0.302376332588275, 0.301631524356061, 0.300889752513336,
  0.300151000343745, 0.299415251240463, 0.298682488705446, 0.297952696348684, 0.29722585788746, 0.296501957145616,
  0.295780978052817, 0.295062904643822, 0.294347721057761, 0.293635411537408, 0.292925960428471, 0.292219352178874,
  0.291515571338049, 0.290814602556231, 0.290116430583756, 0.289421040270366, 0.288728416564513, 0.288038544512672,
  0.287351409258655, 0.286666996042934, 0.285985290201958, 0.285306277167486, 0.284629942465915, 0.283956271717617,
  0.283285250636281, 0.282616865028253, 0.281951100791885, 0.281287943916889, 0.280627380483692, 0.279969396662795,
  0.27931397871414, 0.278661112986475, 0.27801078591673, 0.277362984029392, 0.276717693935885, 0.276074902333955,
  0.275434596007059, 0.274796761823759, 0.274161386737116, 0.273528457784094, 0.272897962084961, 0.272269886842701,
  0.271644219342427, 0.271020946950794, 0.270400057115423, 0.269781537364325, 0.26916537530533, 0.268551558625517,
  0.267940075090653, 0.267330912544632, 0.26672405890892, 0.266119502182002, 0.265517230438834, 0.264917231830299,
  0.264319494582667, 0.263724006997057, 0.263130757448903, 0.262539734387429, 0.261950926335119, 0.261364321887196,
  0.260779909711108, 0.260197678546007, 0.259617617202243, 0.259039714560856, 0.258463959573071, 0.257890341259801,
  0.257318848711147, 0.256749471085908, 0.256182197611091, 0.255617017581426, 0.255053920358883, 0.254492895372193,
  0.253933932116376, 0.253377020152263, 0.252822149106036, 0.252269308668756, 0.251718488595905, 0.251169678706929,
  0.250622868884781, 0.250078049075468, 0.249535209287609, 0.248994339591985, 0.248455430121098, 0.247918471068736,
  0.247383452689534, 0.246850365298547, 0.246319199270815, 0.245789945040943, 0.245262593102678, 0.244737134008488,
  0.244213558369146, 0.24369185685332, 0.243172020187158, 0.242654039153889, 0.24213790459341, 0.241623607401894,
  0.241111138531385, 0.240600488989408, 0.240091649838577, 0.239584612196203, 0.23907936723391, 0.238575906177252,
  0.238074220305334, 0.237574300950432, 0.237076139497619, 0.236579727384397, 0.236085056100323, 0.235592117186645,
  0.235100902235941, 0.234611402891753, 0.234123610848236, 0.233637517849796, 0.233153115690742, 0.232670396214934,
  0.232189351315438, 0.231709972934179, 0.231232253061599, 0.23075618373632, 0.230281757044804, 0.229808965121022,
  0.229337800146119, 0.228868254348088, 0.228400320001441, 0.227933989426884, 0.227469254990997, 0.227006109105915,
  0.226544544229009, 0.226084552862573, 0.22562612755351, 0.225169260893024, 0.224713945516312, 0.22426017410226,
  0.223807939373136, 0.223357234094293, 0.222908051073873, 0.222460383162504, 0.222014223253011, 0.221569564280127,
  0.221126399220196, 0.220684721090892, 0.220244522950932, 0.219805797899794, 0.219368539077434, 0.218932739664011,
  0.218498392879608, 0.218065491983958, 0.217634030276173, 0.217204001094471, 0.216775397815911, 0.216348213856127,
  0.21592244266906, 0.215498077746701, 0.215075112618825, 0.214653540852739, 0.214233356053022, 0.213814551861271,
  0.213397121955851, 0.21298106005164, 0.212566359899787, 0.212153015287459, 0.211741020037599, 0.211330368008685,
  0.210921053094484, 0.210513069223818, 0.210106410360322, 0.209701070502213, 0.20929704368205, 0.208894323966508,
  0.208492905456144, 0.208092782285168, 0.207693948621219, 0.207296398665138, 0.206900126650743, 0.206505126844609,
  0.206111393545849, 0.205718921085892, 0.205327703828269, 0.204937736168397, 0.204549012533363, 0.204161527381718,
  0.203775275203258, 0.203390250518824, 0.203006447880087, 0.202623861869348, 0.202242487099331, 0.20186231821298,
  0.201483349883259, 0.201105576812952, 0.200728993734466, 0.200353595409633, 0.199979376629512, 0.199606332214201,
  0.199234457012643, 0.198863745902431, 0.198494193789623, 0.198125795608553, 0.197758546321643, 0.19739244091922,
  0.19702747441933, 0.196663641867556, 0.196300938336838, 0.195939358927291, 0.195578898766031, 0.195219553006991,
  0.194861316830752, 0.194504185444364, 0.194148154081175, 0.193793218000658, 0.19343937248824, 0.193086612855135,
  0.192734934438173, 0.192384332599634, 0.192034802727084, 0.191686340233208, 0.191338940555647, 0.190992599156839,
  0.190647311523854, 0.190303073168239, 0.189959879625855, 0.189617726456721, 0.18927660924486, 0.188936523598142,
  0.188597465148131, 0.18825942954993, 0.187922412482033, 0.187586409646172, 0.18725141676717, 0.186917429592788,
  0.186584443893584, 0.18625245546276, 0.185921460116024, 0.185591453691441, 0.185262432049292, 0.184934391071931,
  0.184607326663644, 0.184281234750513, 0.183956111280271, 0.183631952222169, 0.183308753566835, 0.182986511326143,
  0.182665221533076, 0.182344880241588, 0.18202548352648, 0.181707027483259, 0.181389508228012, 0.181072921897276,
  0.180757264647907, 0.180442532656952, 0.180128722121523, 0.179815829258671, 0.179503850305257, 0.179192781517833,
  0.178882619172514, 0.178573359564856, 0.178264999009738, 0.177957533841234, 0.1776509604125, 0.177345275095649,
  0.177040474281638, 0.176736554380146, 0.176433511819458, 0.176131343046353, 0.175830044525983, 0.175529612741765,
  0.175230044195262, 0.174931335406073, 0.174633482911723, 0.174336483267548, 0.174040333046589, 0.173745028839478,
  0.173450567254334, 0.173156944916653, 0.172864158469199, 0.172572204571901, 0.172281079901745, 0.171990781152671,
  0.171701305035468, 0.171412648277671, 0.171124807623456, 0.170837779833542, 0.170551561685088, 0.17026614997159,
  0.169981541502787, 0.169697733104556, 0.169414721618818, 0.169132503903437, 0.168851076832125, 0.168570437294346,
  0.168290582195219, 0.168011508455422, 0.167733213011103, 0.167455692813779, 0.167178944830248, 0.166902966042495,
  0.166627753447602, 0.166353304057654, 0.16607961489965, 0.165806683015414, 0.165534505461504, 0.165263079309127,
  0.164992401644047, 0.164722469566498, 0.164453280191101, 0.164184830646773, 0.163917118076647, 0.163650139637981,
  0.163383892502078, 0.163118373854201, 0.162853580893489, 0.162589510832875, 0.162326160899006, 0.162063528332156,
  0.161801610386152, 0.161540404328288, 0.161279907439248, 0.161020117013027, 0.16076103035685, 0.160502644791096,
  0.16024495764922, 0.159987966277674, 0.159731668035834, 0.159476060295919, 0.159221140442922, 0.158966905874528,
  0.158713354001045, 0.158460482245324, 0.158208288042694, 0.15795676884088, 0.157705922099938, 0.157455745292177,
  0.157206235902091, 0.156957391426288, 0.156709209373417, 0.156461687264099, 0.15621482263086, 0.155968613018057,
  0.155723055981813, 0.155478149089948, 0.15523388992191, 0.154990276068709, 0.15474730513285, 0.154504974728266,
  0.154263282480252, 0.154022226025401, 0.153781803011535, 0.153542011097647, 0.153302847953829, 0.153064311261214,
  0.152826398711913, 0.152589108008944, 0.152352436866181, 0.152116383008282, 0.151880944170635, 0.151646118099291,
  0.151411902550905, 0.151178295292677, 0.15094529410229, 0.150712896767853, 0.150481101087836, 0.15024990487102,
  0.150019305936429, 0.149789302113279, 0.149559891240917, 0.149331071168764, 0.149102839756258, 0.148875194872799,
  0.148648134397691, 0.148421656220086, 0.148195758238929, 0.147970438362906, 0.147745694510382, 0.147521524609355,
  0.147297926597395, 0.147074898421594, 0.146852438038513, 0.146630543414126, 0.146409212523772, 0.146188443352096,
  0.145968233893005, 0.145748582149611, 0.145529486134179, 0.14531094386808, 0.14509295338174, 0.144875512714585,
  0.144658619914996, 0.144442273040258, 0.144226470156509, 0.144011209338694, 0.143796488670513, 0.143582306244374,
  0.143368660161346, 0.143155548531112, 0.142942969471916, 0.142730921110524, 0.142519401582169, 0.142308409030512,
  0.14209794160759, 0.141887997473773, 0.141678574797718, 0.141469671756321, 0.141261286534677, 0.141053417326032,
  0.140846062331736, 0.140639219761206, 0.140432887831875, 0.14022706476915, 0.140021748806374, 0.139816938184774,
  0.139612631153427, 0.139408825969209, 0.13920552089676, 0.139002714208439, 0.138800404184279, 0.138598589111954,
  0.138397267286727, 0.138196437011419, 0.137996096596361, 0.137796244359358, 0.137596878625646, 0.137397997727854,
  0.137199600005963, 0.137001683807269, 0.136804247486339, 0.136607289404978, 0.136410807932185, 0.136214801444117,
  0.136019268324052, 0.13582420696235, 0.135629615756412, 0.135435493110648, 0.135241837436437, 0.135048647152088,
  0.134855920682807, 0.134663656460658, 0.134471852924529, 0.134280508520092, 0.134089621699769, 0.133899190922699,
  0.133709214654698, 0.133519691368227, 0.133330619542355, 0.133141997662725, 0.132953824221521, 0.13276609771743,
  0.132578816655612, 0.132391979547661, 0.132205584911578, 0.132019631271729, 0.131834117158821, 0.131649041109861,
  0.131464401668128, 0.131280197383136, 0.131096426810607, 0.130913088512433, 0.130730181056649, 0.130547703017396,
  0.130365652974894, 0.130184029515406, 0.130002831231212, 0.129822056720572, 0.129641704587698, 0.129461773442724,
  0.129282261901675, 0.129103168586433, 0.128924492124713, 0.128746231150027, 0.128568384301659, 0.128390950224632,
  0.128213927569679, 0.128037314993216, 0.127861111157308, 0.127685314729647, 0.127509924383517, 0.127334938797767,
  0.127160356656786, 0.126986176650469, 0.126812397474192, 0.126639017828787, 0.126466036420508, 0.126293451961006,
  0.126121263167305, 0.12594946876177, 0.12577806747208, 0.125607058031206, 0.125436439177377, 0.125266209654061,
  0.125096368209931, 0.124926913598846, 0.124757844579817, 0.12458915991699, 0.12442085837961, 0.124252938742005,
  0.124085399783554, 0.123918240288663, 0.123751459046742, 0.123585054852176, 0.123419026504306, 0.123253372807396,
  0.123088092570615, 0.122923184608011, 0.122758647738485, 0.122594480785767, 0.122430682578393, 0.122267251949682,
  0.122104187737708, 0.121941488785282, 0.121779153939922, 0.121617182053838, 0.1214555719839, 0.12129432259162,
  0.121133432743129, 0.12097290130915, 0.120812727164981, 0.120652909190468, 0.120493446269985, 0.120334337292412,
  0.120175581151108, 0.120017176743896, 0.119859122973036, 0.119701418745206, 0.119544062971477, 0.119387054567295,
  0.119230392452459, 0.119074075551096, 0.118918102791645, 0.118762473106834, 0.118607185433655, 0.11845223871335,
  0.118297631891385, 0.118143363917434, 0.117989433745354, 0.117835840333165, 0.117682582643035, 0.117529659641254,
  0.117377070298216, 0.117224813588401, 0.11707288849035, 0.116921293986654, 0.116770029063924, 0.11661909271278,
  0.116468483927829, 0.116318201707643, 0.116168245054742, 0.116018612975578, 0.11586930448051, 0.115720318583792,
  0.115571654303546, 0.115423310661752, 0.115275286684225, 0.115127581400596, 0.114980193844296, 0.114833123052536,
  0.114686368066291, 0.114539927930281, 0.11439380169295, 0.114247988406456, 0.114102487126644, 0.113957296913035,
  0.113812416828807, 0.113667845940777, 0.113523583319382, 0.113379628038666, 0.11323597917626, 0.113092635813364,
  0.112949597034734, 0.112806861928661, 0.112664429586959, 0.112522299104942, 0.112380469581414, 0.112238940118648,
  0.112097709822372, 0.111956777801752, 0.111816143169377, 0.111675805041239, 0.111535762536724, 0.111396014778588,
  0.111256560892948, 0.111117400009262, 0.110978531260316, 0.110839953782206, 0.110701666714326, 0.110563669199348,
  0.110425960383211, 0.110288539415103, 0.110151405447448, 0.110014557635889, 0.109877995139274, 0.109741717119641,
  0.109605722742204, 0.109470011175337, 0.109334581590561, 0.109199433162525, 0.109064565068999, 0.108929976490854,
  0.108795666612048, 0.108661634619615, 0.108527879703647, 0.108394401057283, 0.108261197876693, 0.108128269361066,
  0.107995614712592, 0.107863233136455, 0.107731123840814, 0.107599286036789, 0.107467718938451, 0.107336421762807,
  0.107205393729787, 0.107074634062227, 0.106944141985863, 0.106813916729309, 0.106683957524053, 0.106554263604436,
  0.106424834207644, 0.106295668573694, 0.106166765945418, 0.106038125568457, 0.10590974669124, 0.105781628564979,
  0.10565377044365, 0.105526171583986, 0.105398831245461, 0.105271748690278, 0.105144923183359, 0.105018353992329,
  0.104892040387506, 0.104765981641892, 0.104640177031155, 0.104514625833619, 0.104389327330256, 0.104264280804668,
  0.104139485543079, 0.104014940834324, 0.103890645969835, 0.103766600243628, 0.103642802952297, 0.103519253394998,
  0.103395950873437, 0.103272894691863, 0.103150084157053, 0.103027518578301, 0.102905197267408, 0.102783119538672,
  0.102661284708873, 0.102539692097266, 0.102418341025569, 0.102297230817949, 0.102176360801017, 0.102055730303812,
  0.101935338657791, 0.101815185196823, 0.101695269257172, 0.101575590177491, 0.101456147298808, 0.101336939964519,
  0.101217967520375, 0.101099229314475, 0.100980724697249, 0.100862453021457, 0.10074441364217, 0.100626605916766,
  0.100509029204916, 0.100391682868579, 0.100274566271984, 0.100157678781629, 0.100041019766265, 0.0999245885968871,
  0.0998083846467279, 0.0996924072912443, 0.0995766559081095, 0.0994611298772029, 0.0993458285806005,
  0.0992307514025659, 0.0991158977295399, 0.0990012669501322, 0.0988868584551109, 0.0987726716373939,
  0.0986587058920395, 0.0985449606162369, 0.0984314352092972, 0.0983181290726445, 0.0982050416098061,
  0.0980921722264044, 0.0979795203301469, 0.097867085330818, 0.0977548666402699, 0.0976428636724134, 0.0975310758432095,
  0.0974195025706604, 0.0973081432748009, 0.0971969973776894, 0.0970860643033999, 0.0969753434780124,
  0.0968648343296055, 0.0967545362882469, 0.0966444487859856, 0.0965345712568429, 0.0964249031368047,
  0.0963154438638124, 0.0962061928777553, 0.0960971496204617, 0.0959883135356912, 0.0958796840691262, 0.095771260668364,
  0.0956630427829082, 0.0955550298641616, 0.095447221365417, 0.0953396167418502, 0.0952322154505115, 0.095125016950318,
  0.0950180207020459, 0.0949112261683222, 0.0948046328136174, 0.0946982401042375, 0.0945920475083165,
  0.0944860544958086, 0.0943802605384803, 0.0942746651099034, 0.0941692676854471, 0.0940640677422703,
  0.0939590647593147, 0.0938542582172965, 0.0937496475987001, 0.0936452323877696, 0.0935410120705025,
  0.0934369861346413, 0.0933331540696674, 0.093229515366793, 0.0931260695189543, 0.0930228160208044, 0.0929197543687059,
  0.0928168840607239, 0.0927142045966194, 0.0926117154778416, 0.0925094162075212, 0.0924073062904637,
  0.0923053852331421, 0.0922036525436903, 0.0921021077318962, 0.0920007503091946, 0.0918995797886609, 0.091798595685004,
  0.0916977975145598, 0.0915971847952843, 0.0914967570467472, 0.0913965137901251, 0.0912964545481949,
  0.0911965788453274, 0.0910968862074809, 0.0909973761621941, 0.0908980482385804, 0.090798901967321, 0.0906999368806587,
  0.0906011525123914, 0.090502548397866, 0.0904041240739717, 0.0903058790791341, 0.0902078129533089, 0.0901099252379756,
  0.0900122154761311, 0.089914683212284, 0.0898173279924483, 0.089720149364137, 0.0896231468763567, 0.0895263200796007,
  0.0894296685258437, 0.0893331917685357, 0.0892368893625956, 0.0891407608644058, 0.0890448058318062, 0.088949023824088,
  0.0888534144019883, 0.088757977127684, 0.0886627115647862, 0.0885676172783342, 0.0884726938347901, 0.0883779408020329,
  0.0882833577493528, 0.0881889442474456, 0.0880946998684073, 0.0880006241857278, 0.0879067167742866,
  0.0878129772103457, 0.0877194050715455, 0.0876259999368982, 0.0875327613867832, 0.0874396890029412,
  0.0873467823684688, 0.0872540410678131, 0.0871614646867667, 0.087069052812462, 0.0869768050333658, 0.0868847209392745,
  0.0867928001213082, 0.0867010421719062, 0.0866094466848211, 0.086518013255114, 0.0864267414791493, 0.0863356309545894,
  0.0862446812803897, 0.0861538920567937, 0.0860632628853274, 0.0859727933687949, 0.0858824831112729,
  0.0857923317181059, 0.0857023387959011, 0.0856125039525238, 0.0855228267970919, 0.0854333069399717,
  0.0853439439927721, 0.0852547375683409, 0.0851656872807588, 0.0850767927453354, 0.084988053578604, 0.0848994693983171,
  0.0848110398234413, 0.084722764474153, 0.0846346429718334, 0.0845466749390638, 0.084458859999621, 0.0843711977784729,
  0.0842836879017736, 0.0841963299968587, 0.0841091236922412, 0.0840220686176062, 0.0839351644038072,
  0.0838484106828612, 0.0837618070879438, 0.0836753532533855, 0.0835890488146668, 0.0835028934084136,
  0.0834168866723934, 0.0833310282455103, 0.0832453177678008, 0.0831597548804298, 0.0830743392256856,
  0.0829890704469763, 0.0829039481888249, 0.0828189720968655, 0.0827341418178389, 0.0826494569995879, 0.082564917291054,
  0.0824805223422723, 0.082396271804368, 0.0823121653295518, 0.0822282025711159, 0.0821443831834301, 0.0820607068219372,
  0.0819771731431495, 0.0818937818046442, 0.0818105324650597, 0.0817274247840915, 0.081644458422488, 0.081561633042047,
  0.081478948305611, 0.0813964038770639, 0.0813139994213265, 0.0812317346043532, 0.0811496090931276, 0.0810676225556586,
  0.0809857746609771, 0.0809040650791315, 0.0808224934811839, 0.080741059539207, 0.0806597629262795, 0.0805786033164825,
  0.0804975803848961, 0.0804166938075952, 0.0803359432616461, 0.0802553284251025, 0.0801748489770021,
  0.0800945045973626, 0.0800142949671782, 0.0799342197684161, 0.0798542786840125, 0.0797744713978692,
  0.0796947975948502, 0.0796152569607775, 0.0795358491824282, 0.0794565739475307, 0.0793774309447608,
  0.0792984198637387, 0.0792195403950254, 0.0791407922301189, 0.0790621750614508, 0.0789836885823834,
  0.0789053324872051, 0.0788271064711284, 0.0787490102302852, 0.0786710434617242, 0.0785932058634071,
  0.0785154971342056, 0.0784379169738976, 0.0783604650831643, 0.0782831411635863, 0.0782059449176409,
  0.0781288760486985, 0.0780519342610192, 0.0779751192597499, 0.0778984307509208, 0.0778218684414418, 0.0777454320391,
  0.0776691212525561, 0.0775929357913412, 0.0775168753658534, 0.0774409396873552, 0.0773651284679698,
  0.0772894414206782, 0.0772138782593162, 0.0771384386985707, 0.0770631224539774, 0.0769879292419171,
  0.0769128587796129, 0.076837910785127, 0.0767630849773578, 0.0766883810760368, 0.0766137988017252, 0.0765393378758118,
  0.0764649980205089, 0.0763907789588502, 0.0763166804146873, 0.076242702112687, 0.0761688437783281, 0.0760951051378988,
  0.0760214859184935, 0.07594798584801, 0.0758746046551467, 0.0758013420693993, 0.0757281978210586, 0.075655171641207,
  0.075582263261716, 0.0755094724152433, 0.0754367988352299, 0.0753642422558973, 0.075291802412245, 0.0752194790400471,
  0.0751472718758501, 0.0750751806569696, 0.0750032051214883, 0.0749313450082524, 0.0748596000568696,
  0.0747879700077057, 0.0747164546018827, 0.0746450535812751, 0.0745737666885082, 0.0745025936669548,
  0.0744315342607328, 0.0743605882147024, 0.0742897552744637, 0.0742190351863536, 0.0741484276974437,
  0.0740779325555376, 0.074007549509168, 0.0739372783075942, 0.0738671187007999, 0.0737970704394902, 0.0737271332750892,
  0.0736573069597375, 0.0735875912462897, 0.0735179858883117, 0.0734484906400784, 0.0733791052565711,
  0.0733098294934749, 0.0732406631071764, 0.0731716058547612, 0.0731026574940114, 0.0730338177834032,
  0.0729650864821044, 0.0728964633499718, 0.0728279481475494, 0.072759540636065, 0.072691240577429, 0.0726230477342308,
  0.0725549618697375, 0.0724869827478907, 0.0724191101333046, 0.0723513437912636, 0.0722836834877197,
  0.0722161289892906, 0.0721486800632569, 0.0720813364775603, 0.0720140980008007, 0.0719469644022346,
  0.0718799354517723, 0.0718130109199756, 0.0717461905780561, 0.0716794741978723, 0.0716128615519277,
  0.0715463524133686, 0.0714799465559815, 0.0714136437541914, 0.0713474437830593, 0.0712813464182801, 0.07121535143618,
  0.0711494586137151, 0.0710836677284686, 0.0710179785586489, 0.0709523908830874, 0.0708869044812363,
  0.0708215191331663, 0.0707562346195652, 0.0706910507217347, 0.0706259672215893, 0.0705609839016533,
  0.0704961005450595, 0.0704313169355466, 0.0703666328574575, 0.0703020480957366, 0.0702375624359287,
  0.0701731756641759, 0.0701088875672164, 0.0700446979323821, 0.0699806065475964, 0.0699166132013726,
  0.0698527176828116, 0.0697889197816, 0.0697252192880081, 0.0696616159928878, 0.0695981096876708, 0.0695347001643665,
  0.0694713872155602, 0.0694081706344109, 0.0693450502146495, 0.069282025750577, 0.0692190970370622, 0.0691562638695401,
  0.0690935260440099, 0.0690308833570331, 0.0689683356057314, 0.0689058825877851, 0.0688435241014312,
  0.0687812599454613, 0.0687190899192197, 0.0686570138226021, 0.0685950314560528, 0.0685331426205638,
  0.0684713471176724, 0.0684096447494595, 0.0683480353185478, 0.0682865186280998, 0.0682250944818166,
  0.0681637626839351, 0.0681025230392272, 0.0680413753529972, 0.0679803194310808, 0.0679193550798425,
  0.0678584821061744, 0.0677977003174945, 0.0677370095217443, 0.0676764095273878, 0.0676159001434093,
  0.0675554811793119, 0.0674951524451155, 0.0674349137513554, 0.0673747649090804, 0.0673147057298511,
  0.0672547360257384, 0.0671948556093214, 0.0671350642936861, 0.0670753618924236, 0.0670157482196283,
  0.0669562230898965, 0.0668967863183242, 0.0668374377205064, 0.0667781771125344, 0.0667190043109947,
  0.0666599191329677, 0.0666009213960251, 0.0665420109182292, 0.0664831875181309, 0.0664244510147681, 0.066365801227664,
  0.0663072379768259, 0.066248761082743, 0.0661903703663855, 0.0661320656492023, 0.0660738467531203, 0.0660157135005418,
  0.0659576657143438, 0.0658997032178761, 0.0658418258349596, 0.0657840333898853, 0.065726325707412, 0.0656687026127653,
  0.0656111639316362, 0.0655537094901789, 0.0654963391150101, 0.0654390526332069, 0.0653818498723055,
  0.0653247306602998, 0.0652676948256399, 0.0652107421972304, 0.0651538726044292, 0.0650970858770457,
  0.0650403818453398, 0.0649837603400199, 0.0649272211922419, 0.0648707642336076, 0.064814389296163, 0.0647580962123973,
  0.0647018848152411, 0.0646457549380654, 0.0645897064146795, 0.0645337390793304, 0.0644778527667006,
  0.0644220473119073, 0.0643663225505009, 0.0643106783184631, 0.0642551144522062, 0.0641996307885713, 0.064144227164827,
  0.0640889034186681, 0.0640336593882143, 0.0639784949120084, 0.0639234098290155, 0.0638684039786215,
  0.0638134772006315, 0.0637586293352685, 0.0637038602231726, 0.0636491697053988, 0.0635945576234164,
  0.0635400238191074, 0.063485568134765, 0.0634311904130927, 0.0633768904972025, 0.0633226682306143, 0.0632685234572536,
  0.0632144560214511, 0.0631604657679413, 0.0631065525418605, 0.0630527161887464, 0.0629989565545363, 0.062945273485566,
  0.0628916668285686, 0.0628381364306731, 0.0627846821394032, 0.0627313038026761, 0.0626780012688011,
  0.0626247743864787, 0.0625716230047988, 0.0625185469732402, 0.0624655461416687, 0.0624126203603363,
  0.0623597694798797, 0.0623069933513194, 0.0622542918260582, 0.0622016647558802, 0.0621491119929495, 0.062096633389809,
  0.0620442287993792, 0.0619918980749571, 0.061939641070215, 0.0618874576391993, 0.0618353476363292, 0.0617833109163957,
  0.0617313473345605, 0.0616794567463546, 0.0616276390076773, 0.061575893974795, 0.0615242215043402, 0.0614726214533101,
  0.0614210936790657, 0.0613696380393304, 0.0613182543921891, 0.0612669425960872, 0.0612157025098289,
  0.0611645339925769, 0.0611134369038504, 0.061062411103525, 0.0610114564518304, 0.0609605728093504, 0.0609097600370212,
  0.0608590179961304, 0.0608083465483161, 0.0607577455555655, 0.0607072148802141, 0.0606567543849444,
  0.0606063639327852, 0.06055604338711, 0.0605057926116363, 0.0604556114704245, 0.0604054998278768, 0.060355457548736,
  0.0603054844980847, 0.0602555805413442, 0.0602057455442732, 0.0601559793729672, 0.060106281893857, 0.0600566529737081,
  0.0600070924796193, 0.0599576002790219, 0.0599081762396788, 0.059858820229683, 0.0598095321174571, 0.059760311771752,
  0.0597111590616461, 0.0596620738565441, 0.0596130560261761, 0.0595641054405965, 0.0595152219701834,
  0.0594664054856369, 0.0594176558579789, 0.0593689729585515, 0.0593203566590164, 0.0592718068313538,
  0.0592233233478614, 0.0591749060811535, 0.0591265549041599, 0.0590782696901253, 0.0590300503126079,
  0.0589818966454785, 0.0589338085629201, 0.0588857859394262, 0.0588378286498004, 0.0587899365691551,
  0.0587421095729109, 0.0586943475367954, 0.0586466503368425, 0.0585990178493911, 0.0585514499510848,
  0.0585039465188702, 0.0584565074299968, 0.0584091325620153, 0.0583618217927773, 0.0583145750004342,
  0.0582673920634362, 0.0582202728605314, 0.0581732172707651, 0.0581262251734787, 0.0580792964483089,
  0.0580324309751868, 0.0579856286343372, 0.0579388893062773, 0.0578922128718162, 0.0578455992120538,
  0.0577990482083801, 0.0577525597424744, 0.0577061336963039, 0.0576597699521236, 0.0576134683924749, 0.057567228900185,
  0.057521051358366, 0.0574749356504139, 0.0574288816600078, 0.0573828892711094, 0.0573369583679617, 0.0572910888350884,
  0.057245280557293, 0.057199533419658, 0.0571538473075441, 0.0571082221065892, 0.0570626577027079, 0.0570171539820903,
  0.0569717108312015, 0.0569263281367807, 0.0568810057858402, 0.0568357436656648, 0.056790541663811, 0.0567453996681061,
  0.0567003175666474, 0.0566552952478015, 0.0566103326002033, 0.0565654295127555, 0.0565205858746277,
  0.0564758015752554, 0.0564310765043395, 0.0563864105518454, 0.0563418036080023, 0.0562972555633023,
  0.0562527663084996, 0.05620833573461, 0.0561639637329098, 0.0561196501949353, 0.0560753950124818, 0.0560311980776031,
  0.0559870592826106, 0.0559429785200725, 0.055898955682813, 0.055854990663912, 0.0558110833567036, 0.0557672336547761,
  0.0557234414519708, 0.0556797066423812, 0.0556360291203529, 0.0555924087804819, 0.0555488455176149,
  0.0555053392268477, 0.0554618898035249, 0.0554184971432393, 0.0553751611418308, 0.055331881695386, 0.0552886587002372,
  0.0552454920529621, 0.0552023816503825, 0.0551593273895643, 0.0551163291678162, 0.0550733868826893,
  0.0550305004319762, 0.0549876697137106, 0.0549448946261662, 0.0549021750678565, 0.0548595109375336, 0.054816902134188,
  0.0547743485570473, 0.0547318501055763, 0.0546894066794756, 0.0546470181786814, 0.0546046845033644,
  0.0545624055539297, 0.0545201812310155, 0.0544780114354928, 0.0544358960684648, 0.0543938350312659,
  0.0543518282254613, 0.0543098755528464,
];

export const FS = [
  0, 0.124513618677043, 0.246153846153846, 0.362264150943396, 0.470588235294118, 0.569395017793594, 0.657534246575343,
  0.734426229508197, 0.8, 0.85459940652819, 0.898876404494382, 0.93368700265252, 0.96, 0.978823529411765,
  0.991150442477876, 0.997920997920998, 1, 0.998165137614679, 0.993103448275862, 0.985413290113452, 0.975609756097561,
  0.964131994261119, 0.951351351351351, 0.937579617834395, 0.923076923076923, 0.908059023836549, 0.892703862660944,
  0.877157360406091, 0.861538461538462, 0.845943482224248, 0.830449826989619, 0.815119145439606, 0.8, 0.785130111524164,
  0.770538243626062, 0.75624577987846, 0.742268041237113, 0.728615384615385, 0.715294117647059, 0.702307259425999,
  0.689655172413793, 0.67733608673206, 0.665346534653465, 0.653681710213777, 0.642335766423358, 0.631302060499781,
  0.620573355817875, 0.610141987829615, 0.6, 0.590139254798645, 0.58055152394775, 0.571228561428071, 0.562162162162162,
  0.553344208809135, 0.544766708701135, 0.536421822615056, 0.528301886792453, 0.520399429386591, 0.512707182320442,
  0.505218089376505, 0.49792531120332, 0.490822227809907, 0.48390243902439, 0.47715976331361, 0.470588235294118,
  0.464182102209328, 0.457935819601041, 0.451844046364594, 0.445901639344262, 0.440103647598166, 0.4344453064391,
  0.428922031338494, 0.423529411764706, 0.418263205013429, 0.413119330076762, 0.408093861588165, 0.403183023872679,
  0.398383185125303, 0.393690851735016, 0.389102662767431, 0.384615384615385, 0.380225905823676, 0.375931232091691,
  0.371728481455563, 0.367614879649891, 0.363587755647641, 0.359644537375849, 0.355782747603834, 0.352,
  0.348293995352819, 0.344662517951173, 0.341103432119011, 0.337614678899083, 0.334194272880404, 0.3308402991641,
  0.327550910462235, 0.324324324324324, 0.321158820486291, 0.318052738336714, 0.315004474495376, 0.31201248049922,
  0.309075260590992, 0.306191369606004, 0.3033594109526, 0.300578034682081, 0.297845935644003, 0.295161851722938,
  0.292524562152926, 0.28993288590604, 0.287385680151603, 0.284881838782778, 0.282420291007394, 0.28, 0.277619961612284,
  0.275279203139149, 0.272976782137824, 0.27071178529755, 0.268483327357476, 0.266290550070522, 0.26413262121107,
  0.262008733624454, 0.259918104316305, 0.257859973579921, 0.255833604159896, 0.253838280450358, 0.251873307726214,
  0.249938011405901, 0.248031736344217, 0.246153846153846, 0.2443037225543, 0.242480764747027, 0.240684388815525,
  0.238914027149321, 0.237169127890777, 0.23544915440369, 0.233753584762729, 0.232081911262799, 0.230433639947438,
  0.22880829015544, 0.227205394084896, 0.225624496373892, 0.224065153697174, 0.222526934378061, 0.221009418014972,
  0.219512195121951, 0.218034866782576, 0.216577044316707, 0.215138348959524, 0.213718411552347, 0.212316872244734,
  0.210933380207418, 0.209567593355597, 0.208219178082192, 0.206887809000634, 0.205573168696813, 0.204274947489807,
  0.202992843201041, 0.20172656093154, 0.200475812846947, 0.199240317970004, 0.198019801980198, 0.196813997020285,
  0.195622641509434, 0.194445479962721, 0.193282262816735, 0.192132746261053, 0.190996692075363, 0.18987386747202,
  0.18876404494382, 0.187667002116806, 0.186582521607902, 0.185510390887209, 0.184450402144772, 0.18340235216167,
  0.182366042185248, 0.181341277808361, 0.180327868852459, 0.179325629254393, 0.178334376956794, 0.177353933801901,
  0.176384125428711, 0.175424781173335, 0.174475733972439, 0.17353682026967, 0.172607879924953, 0.171688756126562,
  0.170779295305865, 0.169879347054649, 0.168988764044944, 0.168107401951247, 0.167235119375069, 0.166371777771729,
  0.16551724137931, 0.164671377149713, 0.163834054681727, 0.163005146156057, 0.162184526272238, 0.16137207218738,
  0.160567663456665, 0.159771181975563, 0.158982511923688, 0.158201539710259, 0.157428153921091, 0.156662245267093,
  0.155903706534199, 0.155152432534708, 0.154408320059964, 0.153671267834358, 0.152941176470588, 0.152217948426156,
  0.151501487961042, 0.150791701096545, 0.150088495575221, 0.149391780821918, 0.148701467905846, 0.148017469503668,
  0.147339699863574, 0.146668074770303, 0.146002511511093, 0.145342928842524, 0.144689246958237, 0.144041387457482,
  0.143399273314493, 0.142762828848655, 0.142131979695431, 0.141506652778051, 0.140886776279903, 0.14027227961765,
  0.139663093415008, 0.1390591494772, 0.138460380766047, 0.137866721375683, 0.137278106508876, 0.136694472453937,
  0.136115756562205, 0.135541897226077, 0.134972833857592, 0.134408506867523, 0.133848857644991, 0.133293828537567,
  0.132743362831858, 0.13219740473456, 0.131655899353961, 0.131118792681899, 0.130586031576131, 0.130057563743136,
  0.129533337721319, 0.129013302864605, 0.128497409326425, 0.127985608044075, 0.127477850723437, 0.126974089824051,
  0.126474278544542, 0.125978370808372, 0.125486321249923, 0.124998085200901, 0.124513618677043, 0.124032878365131,
  0.123555821610296, 0.123082406403612, 0.12261259136996, 0.122146335756175, 0.121683599419448, 0.121224342815988,
  0.120768526989936, 0.12031611356252, 0.119867064721456, 0.119421343210567, 0.118978912319645, 0.11853973587452,
  0.11810377822735, 0.11767100424712, 0.117241379310345, 0.11681486929197, 0.11639144055647, 0.115971059949131,
  0.115553694787524, 0.115139312853153, 0.114727882383286, 0.114319372062947, 0.113913751017087, 0.113510988802908,
  0.113111055402356, 0.112713921214761, 0.112319557049634, 0.111927934119611, 0.11153902403354, 0.111152798789713,
  0.110769230769231, 0.110388292729508, 0.110009957797904, 0.109634199465486, 0.109260991580917, 0.108890308344463,
  0.108522124302127, 0.108156414339891, 0.107793153678077, 0.107432317865823, 0.107073882775657, 0.106717824598191,
  0.106364119836908, 0.106012745303059, 0.105663678110649, 0.105316895671536, 0.104972375690608, 0.104630096161062,
  0.104290035359775, 0.10395217184276, 0.103616484440706, 0.103282952254614, 0.102951554651501, 0.102622271260196,
  0.102295081967213, 0.1019699669127, 0.101646906486465, 0.101325881324072, 0.101006872303021, 0.100689860538985,
  0.100374827382127, 0.10006175441348, 0.0997506234413965, 0.099441416498059, 0.0991341158360593, 0.0988287039250371,
  0.0985251634483807, 0.0982234772999877, 0.0979236285810836, 0.0976256005970985, 0.0973293768545994,
  0.0970349410582781, 0.0967422771079922, 0.0964513690958595, 0.0961622013034033, 0.0958747581987494,
  0.0955890244338711, 0.0953049848418844, 0.0950226244343891, 0.0947419283988579, 0.0944628820960699,
  0.0941854710575896, 0.0939096809832896, 0.0936354977389155, 0.0933629073536939, 0.0930918960179806,
  0.0928224500809498, 0.0925545560483229, 0.0922882005801354, 0.0920233704885427, 0.0917600527356625,
  0.0914982344314542, 0.0912379028316335, 0.0909790453356229, 0.0907216494845361, 0.0904657029591959,
  0.0902111935781862, 0.0899581092959353, 0.0897064382008316, 0.0894561685133707, 0.0892072885843327,
  0.0889597868929896, 0.0887136520453425, 0.0884688727723872, 0.0882254379284082, 0.0879833364893013, 0.087742557550922,
  0.0875030903274623, 0.0872649241498525, 0.0870280484641891, 0.0867924528301887, 0.0865581269196654, 0.086325060515034,
  0.0860932435078356, 0.0858626658972879, 0.0856333177888582, 0.0854051893928582, 0.085178271023062, 0.0849525530953457,
  0.0847280261263476, 0.0845046807321504, 0.0842825076269832, 0.0840614976219445, 0.0838416416237441,
  0.0836229306334656, 0.0834053557453469, 0.0831889081455806, 0.0829735791111321, 0.0827593600085761,
  0.0825462422929512, 0.0823342175066313, 0.082123277278215, 0.0819134133214314, 0.0817046174340623, 0.0814968814968815,
  0.0812901974726092, 0.0810845574048826, 0.080879953417242, 0.080676377712132, 0.0804738225699173, 0.0802722803479138,
  0.0800717434794333, 0.0798722044728435, 0.0796736559106403, 0.0794760904485358, 0.0792795008145575,
  0.0790838798081629, 0.0788892202993651, 0.0786955152278729, 0.0785027576022423, 0.0783109404990403,
  0.0781200570620221, 0.0779301005013186, 0.0777410640926367, 0.0775529411764706, 0.0773657251573248,
  0.0771794095029478, 0.0769939877435776, 0.0768094534711965, 0.0766258003387981, 0.076443022059664, 0.0762611124066501,
  0.0760800652114845, 0.0758998743640738, 0.0757205338118201, 0.0755420375589475, 0.0753643796658372,
  0.0751875542483732, 0.0750115554772962, 0.0748363775775666, 0.0746620148277366, 0.0744884615593309,
  0.0743157121562358, 0.0741437610540972, 0.073972602739726, 0.0738022317505127, 0.073632642673849, 0.0734638301465582,
  0.073295788854333, 0.0731285135311805, 0.0729619989588756, 0.0727962399664209, 0.0726312314295147, 0.0724669682700257,
  0.0723034454554749, 0.0721406579985242, 0.0719786009564724, 0.0718172694307574, 0.0716566585664652,
  0.0714967635518456, 0.0713375796178344, 0.0711791020375811, 0.0710213261259839, 0.0708642472392307,
  0.0707078607743449, 0.0705521621687392, 0.0703971468997732, 0.0702428104843184, 0.0700891484783277,
  0.0699361564764114, 0.069783830111418, 0.0696321650540209, 0.0694811570123102, 0.0693308017313901, 0.0691810949929808,
  0.0690320326150262, 0.0688836104513064, 0.0687358243910551, 0.0685886703585819, 0.0684421443128993, 0.068296242247355,
  0.0681509601892679, 0.0680062941995695, 0.0678622403724499, 0.0677187948350072, 0.0675759537469027,
  0.0674337133000196, 0.0672920697181259, 0.0671510192565423, 0.0670105582018131, 0.0668706828713824,
  0.0667313896132731, 0.0665926748057714, 0.0664545348571133, 0.0663169662051767, 0.0661799653171766,
  0.0660435286893635, 0.0659076528467265, 0.0657723343426996, 0.0656375697588712, 0.065503355704698, 0.0653696888172214,
  0.0652365657607882, 0.0651039832267742, 0.0649719379333113, 0.064840426625018, 0.0647094460727326, 0.0645789930732507,
  0.0644490644490645, 0.0643196570481063, 0.0641907677434947, 0.0640623934332837, 0.0639345310402148,
  0.0638071775114723, 0.0636803298184413, 0.0635539849564685, 0.0634281399446262, 0.0633027918254786, 0.063177937664851,
  0.0630535745516022, 0.0629296995973986, 0.0628063099364919, 0.0626834027254989, 0.062560975143184, 0.0624390243902439,
  0.0623175476890956, 0.0621965422836659, 0.062076005439184, 0.0619559344419764, 0.0618363265992637, 0.0617171792389605,
  0.0615984897094768, 0.0614802553795224, 0.0613624736379128, 0.0612451418933783, 0.0611282575743741,
  0.0610118181288933, 0.0608958210242822, 0.060780263747057, 0.0606651438027232, 0.0605504587155963, 0.0604362060286258,
  0.0603223833032196, 0.0602089881190715, 0.0600960180739904, 0.059983470783731, 0.059871343881827, 0.0597596350194254,
  0.0596483418651235, 0.0595374621048073, 0.0594269934414912, 0.0593169335951606, 0.059207280302615, 0.059098031317314,
  0.0589891844092239, 0.0588807373646668, 0.0587726879861711, 0.0586650340923234, 0.0585577735176223,
  0.0584509041123337, 0.0583444237423476, 0.0582383302890369, 0.0581326216491168, 0.0580272957345067,
  0.0579223504721931, 0.0578177838040939, 0.0577135936869246, 0.0576097780920654, 0.05750633500543, 0.0574032624273361,
  0.0573005583723766, 0.0571982208692927, 0.0570962479608483, 0.0569946377037053, 0.0568933881683012,
  0.0567924974387265, 0.0566919636126049, 0.0565917848009741, 0.0564919591281674, 0.0563924847316975,
  0.0562933597621407, 0.0561945823830231, 0.056096150770707, 0.0559980631142793, 0.055900317615441, 0.0558029124883972,
  0.0557058459597491, 0.0556091162683865, 0.0555127216653817, 0.0554166604138842, 0.0553209307890172,
  0.0552255310777742, 0.0551304595789174, 0.0550357146028767, 0.0549412944716503, 0.0548471975187053,
  0.0547534220888806, 0.0546599665382897, 0.0545668292342253, 0.0544740085550642, 0.0543815028901734,
  0.0542893106398177, 0.0541974302150673, 0.054105860037707, 0.054014598540146, 0.0539236441653287, 0.0538329953666467,
  0.0537426506078508, 0.0536526083629653, 0.0535628671162015, 0.0534734253618734, 0.0533842816043135, 0.05329543435779,
  0.0532068821464241, 0.0531186235041085, 0.0530306569744273, 0.0529429811105755, 0.0528555944752799,
  0.0527684956407214, 0.0526816831884569, 0.0525951557093426, 0.052508911803458, 0.0524229500800309, 0.0523372691573625,
  0.0522518676627535, 0.0521667442324314, 0.0520818975114777, 0.0519973261537567, 0.0519130288218439,
  0.0518290041869561, 0.0517452509288817, 0.0516617677359116, 0.051578553304771, 0.051495606340552, 0.0514129255566462,
  0.0513305096746785, 0.0512483574244415, 0.0511664675438305, 0.0510848387787786, 0.0510034698831933,
  0.0509223596188932, 0.050841506755545, 0.050760910070602, 0.0506805683492421, 0.0506004803843075, 0.0505206449762436,
  0.0504410609330403, 0.0503617270701718, 0.0502826422105388, 0.0502038051844098, 0.0501252148293641,
  0.0500468699902344, 0.0499687695190506, 0.0498909122749838, 0.0498132971242908, 0.0497359229402594,
  0.0496587886031538, 0.0495818930001609, 0.049505235025337, 0.0494288135795543, 0.0493526275704494, 0.0492766759123707,
  0.0492009575263272, 0.0491254713399378, 0.0490502162873801, 0.0489751913093411, 0.048900395352967, 0.0488258273718147,
  0.0487514863258026, 0.0486773711811625, 0.048603480910392, 0.0485298144922066, 0.0484563709114937, 0.048383149159265,
  0.0483101482326112, 0.0482373671346558, 0.0481648048745104, 0.0480924604672291, 0.0480203329337648,
  0.0479484213009244, 0.0478767246013259, 0.0478052418733545, 0.04773397216112, 0.0476629145144141, 0.0475920679886686,
  0.0475214316449132, 0.0474510045497347, 0.0473807857752352, 0.0473107743989922, 0.0472409695040178, 0.047171370178719,
  0.0471019755168579, 0.0470327846175128, 0.0469637965850389, 0.04689501052903, 0.0468264255642803, 0.0467580408107461,
  0.046689855393509, 0.0466218684427378, 0.0465540790936524, 0.0464864864864865, 0.0464190897664518, 0.0463518880837021,
  0.0462848805932972, 0.0462180664551678, 0.0461514448340808, 0.0460850148996041, 0.0460187758260722,
  0.0459527267925525, 0.0458868669828109, 0.0458211955852788, 0.0457557117930192, 0.0456904148036944,
  0.0456253038195327, 0.0455603780472965, 0.0454956366982496, 0.045431078988126, 0.0453667041370975, 0.0453025113697433,
  0.0452384999150179, 0.0451746690062211, 0.0451110178809672, 0.0450475457811546, 0.0449842519529358,
  0.0449211356466877, 0.0448581961169821, 0.0447954326225561, 0.0447328444262837, 0.0446704307951462,
  0.0446081910002042, 0.0445461243165691, 0.0444842300233751, 0.0444225074037512, 0.0443609557447938,
  0.0442995743375388, 0.0442383624769353, 0.0441773194618177, 0.0441164445948798, 0.0440557371826477,
  0.0439951965354539, 0.043934821967411, 0.0438746127963859, 0.0438145683439744, 0.0437546879354753, 0.0436949708998657,
  0.0436354165697756, 0.0435760242814631, 0.0435167933747902, 0.0434577231931979, 0.0433988130836822,
  0.0433400623967701, 0.0432814704864956, 0.0432230367103765, 0.0431647604293903, 0.0431066410079518,
  0.0430486778138891, 0.0429908702184214, 0.042933217596136, 0.0428757193249661, 0.0428183747861679, 0.042761183364299,
  0.0427041444471962, 0.0426472574259537, 0.0425905216949014, 0.0425339366515837, 0.0424775016967379,
  0.0424212162342731, 0.0423650796712498, 0.0423090914178582, 0.0422532508873985, 0.0421975574962597,
  0.0421420106638998, 0.0420866098128253, 0.0420313543685718, 0.0419762437596833, 0.0419212774176933, 0.041866454777105,
  0.0418117752753719, 0.0417572383528787, 0.041702843452922, 0.041648590021692, 0.0415944775082528, 0.0415405053645247,
  0.0414866730452651, 0.0414329800080505, 0.0413794257132581, 0.0413260096240481, 0.0412727312063454,
  0.0412195899288218, 0.041166585262879, 0.0411137166826304, 0.0410609836648839, 0.0410083856891249, 0.0409559222374991,
  0.0409035927947955, 0.0408513968484295, 0.0407993338884263, 0.0407474034074043, 0.0406956049005585,
  0.0406439378656446, 0.040592401802962, 0.0405409962153387, 0.0404897206081146, 0.0404385744891259, 0.0403875573686894,
  0.040336668759587, 0.04028590817705, 0.0402352751387437, 0.0401847691647525, 0.0401343897775645, 0.0400841365020563,
  0.0400340088654787, 0.039984006397441, 0.0399341286298973, 0.0398843750971311, 0.0398347453357414, 0.0397852388846278,
  0.0397358552849767, 0.0396865940802472, 0.0396374548161564, 0.0395884370406663, 0.0395395403039694, 0.039490764158475,
  0.0394421081587958, 0.0393935718617344, 0.0393451548262694, 0.0392968566135427, 0.0392486767868457,
  0.0392006149116065, 0.0391526705553767, 0.0391048432878186, 0.0390571326806921, 0.0390095383078423,
  0.0389620597451865, 0.0389146965707015, 0.0388674483644119, 0.0388203147083765, 0.0387732951866773,
  0.0387263893854061, 0.0386795968926533, 0.0386329172984953, 0.0385863501949827, 0.0385398951761285,
  0.0384935518378963, 0.0384473197781885, 0.038401198596835, 0.038355187895581, 0.0383092872780764, 0.0382634963498638,
  0.0382178147183676, 0.0381722419928826, 0.0381267777845627, 0.0380814217064104, 0.0380361733732653,
  0.0379910324017935, 0.0379459984104768, 0.0379010710196017, 0.037856249851249, 0.0378115345292833, 0.037766924679342,
  0.0377224199288256, 0.0376780199068867, 0.03763372424442, 0.037589532574052, 0.0375454445301311, 0.0375014597487171,
  0.0374575778675717, 0.037413798526148, 0.0373701213655811, 0.0373265460286782, 0.0372830721599088, 0.0372396994053949,
  0.037196427412902, 0.0371532558318287, 0.0371101843131979, 0.0370672125096473, 0.03702434007542, 0.0369815666663549,
  0.0369388919398783, 0.0368963155549941, 0.0368538371722747, 0.0368114564538526, 0.036769173063411, 0.0367269866661747,
  0.036684896928902, 0.0366429035198751, 0.0366010061088922, 0.0365592043672581, 0.0365174979677762, 0.0364758865847398,
  0.0364343698939233, 0.0363929475725744, 0.0363516192994052, 0.0363103847545842, 0.0362692436197281,
  0.0362281955778933, 0.0361872403135682, 0.0361463775126646, 0.0361056068625104, 0.0360649280518408,
  0.0360243407707911, 0.0359838447108884, 0.035943439565044, 0.0359031250275456, 0.0358629007940497, 0.0358227665615735,
  0.0357827220284881, 0.0357427668945102, 0.0357029008606949, 0.0356631236294285, 0.0356234349044207,
  0.0355838343906973, 0.0355443217945933, 0.0355048968237451, 0.0354655591870838, 0.0354263085948275,
  0.0353871447584749, 0.0353480673907975, 0.0353090762058332, 0.0352701709188789, 0.0352313512464837,
  0.0351926169064422, 0.0351539676177875, 0.0351154031007845, 0.0350769230769231, 0.0350385272689113,
  0.0350002154006692, 0.0349619871973215, 0.0349238423851917, 0.0348857806917951, 0.0348478018458326,
  0.0348099055771842, 0.0347720916169023, 0.0347343596972058, 0.0346967095514735, 0.0346591409142381,
  0.0346216535211795, 0.0345842471091191, 0.0345469214160134, 0.0345096761809478, 0.0344725111441308,
  0.0344354260468877, 0.0343984206316549, 0.0343614946419736, 0.0343246478224841, 0.03428787991892, 0.0342511906781021,
  0.0342145798479327, 0.0341780471773899, 0.034141592416522, 0.0341052153164413, 0.0340689156293191, 0.0340326931083797,
  0.0339965475078946, 0.0339604785831775, 0.0339244860905785, 0.0338885697874785, 0.0338527294322839,
  0.0338169647844213, 0.0337812756043319, 0.0337456616534662, 0.0337101226942789, 0.0336746584902233,
  0.0336392688057463, 0.0336039534062831, 0.0335687120582521, 0.0335335445290497, 0.033498450587045, 0.0334634300015752,
  0.0334284825429399, 0.0333936079823969, 0.0333588060921563, 0.0333240766453763, 0.0332894194161578,
  0.0332548341795398, 0.0332203207114944, 0.033185878788922, 0.0331515081896464, 0.0331172086924102, 0.03308298007687,
  0.0330488221235917, 0.0330147346140455, 0.0329807173306019, 0.0329467700565265, 0.0329128925759756,
  0.0328790846739917, 0.0328453461364987, 0.0328116767502979, 0.0327780763030629, 0.0327445445833355,
  0.0327110813805214, 0.0326776864848852, 0.0326443596875468, 0.0326111007804766, 0.0325779095564909,
  0.0325447858092484, 0.0325117293332452, 0.032478739923811, 0.0324458173771045, 0.0324129614901096, 0.0323801720606309,
  0.0323474488872899, 0.0323147917695205, 0.0322822005075651, 0.0322496749024707, 0.0322172147560846,
  0.0321848198710503, 0.0321524900508038, 0.0321202250995695, 0.0320880248223562, 0.0320558890249533,
  0.0320238175139268, 0.0319918100966153, 0.0319598665811264, 0.0319279867763328, 0.0318961704918684,
  0.0318644175381246, 0.0318327277262465, 0.031801100868129, 0.0317695367764134, 0.0317380352644836, 0.0317065961464623,
  0.0316752192372074, 0.0316439043523084, 0.0316126513080828, 0.0315814599215725, 0.0315503300105401,
  0.0315192613934658, 0.0314882538895432, 0.0314573073186763, 0.031426421501476, 0.0313955962592563, 0.0313648314140312,
  0.031334126788511, 0.0313034822060991, 0.0312728974908888, 0.0312423724676593, 0.0312119069618729, 0.0311815007996718,
  0.031151153807874, 0.0311208658139711, 0.031090636646124, 0.0310604661331604, 0.0310303541045713, 0.0310003003905077,
  0.0309703048217774, 0.0309403672298422, 0.0309104874468143, 0.0308806653054533, 0.0308509006391633,
  0.0308211932819894, 0.0307915430686151, 0.0307619498343587, 0.0307324134151708, 0.0307029336476308,
  0.0306735103689442, 0.0306441434169394, 0.030614832630065, 0.0305855778473864, 0.0305563789085835, 0.030527235653947,
  0.0304981479243761, 0.0304691155613753, 0.0304401384070518, 0.0304112163041122, 0.03038234909586, 0.0303535366261929,
  0.0303247787395994, 0.0302960752811568, 0.0302674260965275, 0.0302388310319573, 0.0302102899342716,
  0.0301818026508734, 0.0301533690297404, 0.030124988919422, 0.0300966621690371, 0.0300683886282711, 0.0300401681473731,
  0.0300120005771539, 0.0299838857689825, 0.0299558235747844, 0.0299278138470382, 0.0298998564387734, 0.029871951203568,
  0.0298440979955457, 0.0298162966693731, 0.0297885470802578, 0.0297608490839455, 0.0297332025367175,
  0.0297056072953882, 0.029678063217303, 0.0296505701603353, 0.0296231279828844, 0.029595736543873, 0.0295683957027447,
  0.0295411053194618, 0.0295138652545026, 0.0294866753688595, 0.0294595355240359, 0.0294324455820447,
  0.0294054054054054, 0.0293784148571419, 0.0293514738007802, 0.0293245821003463, 0.0292977396203635,
  0.0292709462258506, 0.0292442017823192, 0.0292175061557717, 0.0291908592126991, 0.0291642608200787,
  0.0291377108453718, 0.0291112091565216, 0.0290847556219511, 0.0290583501105606, 0.0290319924917261,
  0.0290056826352964, 0.0289794204115918, 0.0289532056914011, 0.0289270383459802, 0.0289009182470497,
  0.0288748452667926, 0.0288488192778528, 0.0288228401533323, 0.0287969077667898, 0.028771021992238, 0.0287451827041424,
  0.0287193897774183, 0.0286936430874296, 0.0286679425099863, 0.0286422879213427, 0.0286166791981955,
  0.0285911162176815, 0.028565598857376, 0.0285401269952908, 0.0285147005098718, 0.0284893192799978, 0.0284639831849779,
  0.0284386921045501, 0.0284134459188791, 0.0283882445085545, 0.0283630877545889, 0.028337975538416, 0.028312907741889,
  0.0282878842472783, 0.0282629049372698, 0.0282379696949636, 0.0282130784038712, 0.0281882309479146, 0.028163427211424,
  0.0281386670791361, 0.0281139504361924, 0.0280892771681372, 0.0280646471609163, 0.0280400603008746,
  0.0280155164747551, 0.0279910155696963, 0.0279665574732313, 0.0279421420732856, 0.0279177692581754,
  0.0278934389166062, 0.0278691509376707, 0.0278449052108475, 0.0278207016259991, 0.0277965400733704,
  0.0277724204435873, 0.0277483426276543, 0.0277243065169538, 0.0277003120032437, 0.0276763589786562, 0.027652447335696,
  0.0276285769672388, 0.0276047477665297, 0.0275809596271815, 0.0275572124431733, 0.0275335061088486,
  0.0275098405189141, 0.0274862155684381, 0.0274626311528485, 0.027439087167932, 0.0274155835098319, 0.0273921200750469,
  0.0273686967604295, 0.0273453134631846, 0.0273219700808678, 0.0272986665113842, 0.0272754026529863,
  0.0272521784042735, 0.0272289936641897, 0.0272058483320224, 0.0271827423074008, 0.027159675490295, 0.0271366477810137,
  0.0271136590802036, 0.0270907092888474, 0.0270677983082626, 0.0270449260401002, 0.0270220923863429,
  0.0269992972493042, 0.0269765405316266, 0.0269538221362806, 0.0269311419665628, 0.026908499926095, 0.0268858959188227,
  0.0268633298490136, 0.0268408016212565, 0.0268183111404596, 0.0267958583118497, 0.0267734430409702,
  0.0267510652336804, 0.0267287247961537, 0.0267064216348766, 0.0266841556566474, 0.0266619267685745,
  0.0266397348780757, 0.0266175798928764, 0.0265954617210088, 0.0265733802708101, 0.0265513354509217,
  0.0265293271702876, 0.0265073553381534, 0.0264854198640649, 0.0264635206578669, 0.0264416576297021,
  0.0264198306900095, 0.0263980397495236, 0.0263762847192729, 0.026354565510579, 0.026332882035055, 0.0263112342046045,
  0.0262896219314204, 0.0262680451279839, 0.026246503707063, 0.0262249975817113, 0.0262035266652673, 0.0261820908713526,
  0.0261606901138715, 0.0261393243070089, 0.0261179933652302, 0.0260966972032791, 0.0260754357361775,
  0.0260542088792235, 0.0260330165479908, 0.0260118586583274, 0.0259907351263545, 0.0259696458684654,
  0.0259485908013245, 0.025927569841866, 0.0259065829072929, 0.0258856299150759, 0.0258647107829525, 0.0258438254289256,
  0.0258229737712627, 0.0258021557284947, 0.025781371219415, 0.025760620163078, 0.0257399024787987, 0.0257192180861511,
  0.0256985669049676, 0.0256779488553374, 0.0256573638576063, 0.0256368118323747, 0.0256162927004974,
  0.0255958063830822, 0.0255753528014888, 0.0255549318773282, 0.0255345435324611, 0.0255141876889975,
  0.0254938642692954, 0.0254735731959599, 0.0254533143918421, 0.0254330877800382, 0.0254128932838885,
  0.0253927308269768, 0.0253726003331286, 0.0253525017264109, 0.0253324349311312, 0.025312399871836, 0.0252923964733102,
  0.0252724246605765, 0.0252524843588937, 0.0252325754937565, 0.0252126979908942, 0.0251928517762696,
  0.0251730367760787, 0.0251532529167491, 0.0251335001249395, 0.0251137783275387, 0.0250940874516647,
  0.0250744274246637, 0.0250547981741095, 0.0250351996278022, 0.0250156317137676, 0.0249960943602562,
  0.0249765874957425, 0.0249571110489238, 0.0249376649487197, 0.024918249124271, 0.0248988635049389, 0.0248795080203041,
  0.024860182600166, 0.0248408871745419, 0.0248216216736662, 0.0248023860279892, 0.0247831801681767, 0.024764004025109,
  0.02474485752988, 0.0247257406137965, 0.0247066532083771, 0.024687595245352, 0.0246685666566615, 0.0246495673744555,
  0.0246305973310928, 0.0246116564591399, 0.0245927446913709, 0.0245738619607658, 0.0245550082005106,
  0.0245361833439958, 0.024517387324816, 0.0244986200767692, 0.0244798815338556, 0.0244611716302772, 0.024442490300437,
  0.0244238374789379, 0.0244052131005825, 0.0243866171003717, 0.0243680494135046, 0.0243495099753772,
  0.0243309987215818, 0.0243125155879066, 0.0242940605103344, 0.0242756334250423, 0.0242572342684008,
  0.0242388629769731, 0.0242205194875142, 0.0242022037369704, 0.0241839156624785, 0.0241656552013653,
  0.0241474222911462, 0.0241292168695253, 0.0241110388743942, 0.0240928882438316, 0.0240747649161023,
  0.0240566688296566, 0.0240385999231298, 0.0240205581353411, 0.0240025434052933, 0.0239845556721721,
  0.0239665948753451, 0.0239486609543613, 0.0239307538489505, 0.0239128734990226, 0.0238950198446668,
  0.0238771928261509, 0.0238593923839209, 0.0238416184586003, 0.023823870990989, 0.0238061499220632, 0.0237884551929745,
  0.023770786745049, 0.0237531445197874, 0.0237355284588634, 0.0237179385041238, 0.0237003745975874, 0.0236828366814447,
  0.0236653246980571, 0.0236478385899561, 0.0236303782998431, 0.0236129437705885, 0.0235955349452309,
  0.0235781517669768, 0.0235607941792, 0.0235434621254406, 0.0235261555494049, 0.0235088743949644, 0.0234916186061553,
  0.0234743881271781, 0.0234571829023965, 0.0234400028763373, 0.0234228479936899, 0.023405718199305, 0.0233886134381946,
  0.0233715336555313, 0.0233544787966477, 0.0233374488070357, 0.0233204436323461, 0.0233034632183878,
  0.0232865075111274, 0.0232695764566887, 0.0232526700013519, 0.0232357880915531, 0.023218930673884, 0.0232020976950907,
  0.0231852891020741, 0.0231685048418883, 0.0231517448617408, 0.0231350091089916, 0.0231182975311529,
  0.0231016100758882, 0.0230849466910119, 0.023068307324489, 0.0230516919244343, 0.0230351004391118, 0.0230185328169344,
  0.0230019890064631, 0.0229854689564069, 0.0229689726156216, 0.0229524999331101, 0.022936050858021, 0.0229196253396488,
  0.022903223327433, 0.0228868447709576, 0.0228704896199508, 0.0228541578242842, 0.0228378493339725, 0.0228215640991729,
  0.0228053020701846, 0.0227890631974484, 0.022772847431546, 0.0227566547231995, 0.0227404850232713, 0.0227243382827631,
  0.0227082144528155, 0.022692113484708, 0.0226760353298577, 0.0226599799398195, 0.0226439472662852, 0.0226279372610834,
  0.0226119498761786, 0.0225959850636709, 0.0225800427757956, 0.0225641229649226, 0.022548225583556, 0.0225323505843337,
  0.0225164979200266, 0.0225006675435387, 0.0224848594079059, 0.0224690734662964, 0.0224533096720093, 0.022437567978475,
  0.0224218483392542, 0.0224061507080375, 0.0223904750386452, 0.0223748212850267, 0.0223591894012599,
  0.0223435793415509, 0.0223279910602339, 0.0223124245117698, 0.022296879650747, 0.0222813564318797, 0.0222658548100086,
  0.0222503747400996, 0.0222349161772439, 0.0222194790766572, 0.0222040633936796, 0.0221886690837749,
  0.0221732961025304, 0.0221579444056561, 0.0221426139489848, 0.0221273046884713, 0.0221120165801921,
  0.0220967495803449, 0.0220815036452482, 0.0220662787313412, 0.0220510747951827, 0.0220358917934515,
  0.0220207296829453, 0.0220055884205807, 0.0219904679633927, 0.0219753682685342, 0.0219602892932757,
  0.0219452309950048, 0.0219301933312259, 0.0219151762595597, 0.0219001797377431, 0.0218852037236282,
  0.0218702481751825, 0.0218553130504882, 0.0218403983077418, 0.0218255039052541, 0.0218106298014491,
  0.0217957759548643, 0.02178094232415, 0.0217661288680688, 0.0217513355454954, 0.0217365623154164, 0.0217218091369295,
  0.0217070759692434, 0.0216923627716773, 0.0216776695036607, 0.0216629961247327, 0.0216483425945421,
  0.0216337088728467, 0.0216190949195127, 0.021604500694515, 0.0215899261579364, 0.0215753712699672, 0.0215608359909049,
  0.0215463202811541, 0.0215318241012257, 0.0215173474117368, 0.0215028901734104, 0.0214884523470749,
  0.0214740338936639, 0.0214596347742155, 0.0214452549498724, 0.0214308943818814, 0.0214165530315926,
  0.0214022308604601, 0.0213879278300403, 0.0213736439019929, 0.0213593790380794, 0.0213451332001636,
  0.0213309063502108, 0.0213166984502877, 0.0213025094625619, 0.0212883393493017, 0.0212741880728754,
  0.0212600555957517, 0.0212459418804986, 0.0212318468897836, 0.0212177705863731, 0.021203712933132, 0.0211896738930237,
  0.0211756534291096, 0.0211616515045486, 0.0211476680825972, 0.0211337031266085, 0.0211197566000328,
  0.0211058284664165, 0.0210919186894021, 0.0210780272327279, 0.0210641540602275, 0.0210502991358298,
  0.0210364624235585, 0.0210226438875316, 0.0210088434919615, 0.0209950612011545, 0.0209812969795101,
  0.0209675507915216, 0.0209538226017749, 0.0209401123749486, 0.0209264200758138, 0.0209127456692335,
  0.0208990891201625, 0.0208854503936471, 0.0208718294548247, 0.0208582262689236, 0.0208446408012626,
  0.0208310730172507, 0.0208175228823872, 0.0208039903622606, 0.0207904754225493, 0.0207769780290203,
  0.0207634981475298, 0.0207500357440223, 0.0207365907845307, 0.0207231632351757, 0.0207097530621657,
  0.0206963602317966, 0.0206829847104512, 0.0206696264645993, 0.0206562854607971, 0.0206429616656872,
  0.0206296550459982, 0.0206163655685441, 0.0206030932002247, 0.0205898379080248, 0.020576599659014, 0.0205633784203466,
  0.0205501741592614, 0.0205369868430809, 0.0205238164392116, 0.0205106629151437, 0.0204975262384503,
  0.0204844063767878, 0.0204713032978952, 0.0204582169695939, 0.0204451473597876, 0.020432094436462, 0.0204190581676844,
  0.0204060385216033, 0.0203930354664489, 0.0203800489705317, 0.0203670790022432, 0.0203541255300554,
  0.0203411885225199, 0.0203282679482688, 0.0203153637760134, 0.0203024759745446, 0.0202896045127323,
  0.0202767493595252, 0.0202639104839509, 0.020251087855115, 0.0202382814422016, 0.0202254912144724, 0.0202127171412668,
  0.0201999591920016, 0.0201872173361709, 0.0201744915433454, 0.0201617817831727, 0.0201490880253767,
  0.0201364102397575, 0.020123748396191, 0.0201111024646292, 0.0200984724150991, 0.0200858582177032, 0.0200732598426188,
  0.0200606772600982, 0.020048110440468, 0.0200355593541292, 0.0200230239715568, 0.0200105042632996, 0.01999800019998,
  0.0199855117522939, 0.0199730388910101, 0.0199605815869705, 0.0199481398110896, 0.0199357135343544,
  0.0199233027278239, 0.0199109073626295, 0.019898527409974, 0.019886162841132, 0.0198738136274493, 0.0198614797403429,
  0.0198491611513006, 0.0198368578318809, 0.0198245697537129, 0.0198122968884956, 0.0198000392079984,
  0.0197877966840603, 0.0197755692885898, 0.0197633569935649, 0.0197511597710329, 0.0197389775931096,
  0.0197268104319801, 0.0197146582598975, 0.0197025210491835, 0.0196903987722279, 0.0196782914014883,
  0.0196661989094899, 0.0196541212688256, 0.0196420584521555, 0.0196300104322065, 0.0196179771817726,
  0.0196059586737146, 0.0195939548809593, 0.0195819657765001, 0.0195699913333964, 0.0195580315247733,
  0.0195460863238216, 0.0195341557037976, 0.0195222396380227, 0.0195103380998834, 0.0194984510628312, 0.019486578500382,
  0.0194747203861163, 0.0194628766936787, 0.0194510473967781, 0.019439232469187, 0.0194274318847418, 0.0194156456173421,
  0.0194038736409511, 0.0193921159295949, 0.0193803724573624, 0.0193686431984055, 0.0193569281269384,
  0.0193452272172376, 0.019333540443642, 0.0193218677805521, 0.0193102092024305, 0.0192985646838012, 0.0192869341992496,
  0.0192753177234224, 0.0192637152310272, 0.0192521266968326, 0.0192405520956677, 0.0192289914024221,
  0.0192174445920459, 0.0192059116395491, 0.0191943925200017, 0.0191828872085334, 0.0191713956803336,
  0.0191599179106511, 0.0191484538747938, 0.0191370035481287, 0.0191255669060816, 0.0191141439241372,
  0.0191027345778385, 0.0190913388427869, 0.0190799566946421, 0.0190685881091216, 0.0190572330620009, 0.019045891529113,
  0.0190345634863485, 0.0190232489096551, 0.019011947775038, 0.019000660058559, 0.018989385736337, 0.0189781247845473,
  0.0189668771794219, 0.0189556428972487, 0.0189444219143723, 0.0189332142071927, 0.018922019752166, 0.018910838525804,
  0.0188996705046738, 0.0188885156653979, 0.0188773739846538, 0.0188662454391742, 0.0188551300057464,
  0.0188440276612126, 0.0188329383824692, 0.0188218621464673, 0.0188107989302118, 0.0187997487107619,
  0.0187887114652306, 0.0187776871707844, 0.0187666758046436, 0.0187556773440818, 0.0187446917664257,
  0.0187337190490554, 0.0187227591694035, 0.0187118121049558, 0.0187008778332503, 0.0186899563318777,
  0.0186790475784811, 0.0186681515507554, 0.0186572682264478, 0.0186463975833573, 0.0186355395993345,
  0.0186246942522817, 0.0186138615201523, 0.0186030413809514, 0.0185922338127347, 0.0185814387936093,
  0.0185706563017327, 0.0185598863153135, 0.0185491288126105, 0.0185383837719328, 0.0185276511716399,
  0.0185169309901415, 0.0185062232058968, 0.0184955277974152, 0.0184848447432555, 0.0184741740220261,
  0.0184635156123847, 0.0184528694930383, 0.0184422356427428, 0.0184316140403032, 0.0184210046645733,
  0.0184104074944553, 0.0183998225089002, 0.0183892496869072, 0.0183786890075237, 0.0183681404498455,
  0.0183576039930158, 0.0183470796162262, 0.0183365672987156, 0.0183260670197706, 0.0183155787587251,
  0.0183051024949603, 0.0182946382079047, 0.0182841858770336, 0.0182737454818693, 0.0182633170019806,
  0.0182529004169832, 0.0182424957065392, 0.0182321028503568, 0.0182217218281908, 0.0182113526198416,
  0.0182009952051559, 0.0181906495640262, 0.0181803156763904, 0.0181699935222322, 0.0181596830815807,
  0.0181493843345102, 0.0181390972611403, 0.0181288218416354, 0.018118558056205, 0.0181083058851033, 0.0180980653086293,
  0.0180878363071264, 0.0180776188609823, 0.0180674129506293, 0.0180572185565435, 0.0180470356592451,
  0.0180368642392986, 0.0180267042773116, 0.0180165557539361, 0.0180064186498669, 0.0179962929458429,
  0.0179861786226457, 0.0179760756611005, 0.0179659840420753, 0.0179559037464812, 0.0179458347552719, 0.017935777049444,
  0.0179257306100366, 0.0179156954181311, 0.0179056714548515, 0.0178956587013637, 0.017885657138876, 0.0178756667486384,
  0.017865687511943, 0.0178557194101236, 0.0178457624245553, 0.0178358165366553, 0.0178258817278816, 0.0178159579797339,
  0.0178060452737528, 0.0177961435915201, 0.0177862529146586, 0.0177763732248316, 0.0177665045037435,
  0.0177566467331391, 0.0177467998948037, 0.0177369639705629, 0.0177271389422828, 0.0177173247918693,
  0.0177075215012686, 0.0176977290524667, 0.0176879474274896, 0.0176781766084028, 0.0176684165773114,
  0.0176586673163601, 0.017648928807733, 0.0176392010336533, 0.0176294839763835, 0.0176197776182252, 0.0176100819415188,
  0.0176003969286436, 0.0175907225620177, 0.0175810588240976, 0.0175714056973787, 0.0175617631643945, 0.017552131207717,
  0.0175425098099561, 0.0175328989537603, 0.0175232986218156, 0.0175137087968462, 0.017504129461614, 0.0174945605989185,
  0.0174850021915969, 0.0174754542225239, 0.0174659166746115, 0.0174563895308089, 0.0174468727741027,
  0.0174373663875164, 0.0174278703541104, 0.0174183846569822, 0.0174089092792659, 0.0173994442041324,
  0.0173899894147891, 0.0173805448944797, 0.0173711106264846, 0.0173616865941202, 0.0173522727807394,
  0.0173428691697307, 0.017333475744519, 0.017324092488565, 0.0173147193853649, 0.0173053564184511, 0.017296003571391,
  0.017286660827788, 0.0172773281712806, 0.0172680055855428, 0.0172586930542837, 0.0172493905612474, 0.0172400980902133,
  0.0172308156249955, 0.017221543149443, 0.0172122806474396, 0.0172030281029037, 0.0171937854997882, 0.0171845528220806,
  0.0171753300538026, 0.0171661171790102, 0.0171569141817937, 0.0171477210462775, 0.0171385377566198,
  0.0171293642970129, 0.0171202006516829, 0.0171110468048895, 0.0171019027409261, 0.0170927684441198,
  0.0170836438988309, 0.0170745290894534, 0.0170654240004141, 0.0170563286161735, 0.0170472429212249,
  0.0170381669000947, 0.0170291005373422, 0.0170200438175596, 0.0170109967253718, 0.0170019592454364,
  0.0169929313624436, 0.0169839130611159, 0.0169749043262086, 0.0169659051425089, 0.0169569154948365,
  0.0169479353680431, 0.0169389647470126, 0.0169300036166608, 0.0169210519619355, 0.016912109767816, 0.0169031770193138,
  0.0168942537014717, 0.0168853397993641, 0.016876435298097, 0.0168675401828076, 0.0168586544386648, 0.0168497780508682,
  0.016840911004649, 0.0168320532852692, 0.0168232048780218, 0.0168143657682309, 0.0168055359412512, 0.0167967153824683,
  0.0167879040772983, 0.0167791020111881, 0.0167703091696148, 0.0167615255380862, 0.0167527511021403,
  0.0167439858473453, 0.0167352297592998, 0.0167264828236323, 0.0167177450260014, 0.0167090163520956,
  0.0167002967876334, 0.016691586318363, 0.0166828849300621, 0.0166741926085385, 0.0166655093396292, 0.0166568351092007,
  0.0166481699031491, 0.0166395137073997, 0.016630866507907, 0.0166222282906548, 0.0166135990416558, 0.0166049787469521,
  0.0165963673926143, 0.0165877649647421, 0.0165791714494641, 0.0165705868329375, 0.016562011101348, 0.0165534442409102,
  0.016544886237867, 0.0165363370784897, 0.016527796749078, 0.01651926523596, 0.0165107425254919, 0.016502228604058,
  0.0164937234580707, 0.0164852270739704, 0.0164767394382255, 0.0164682605373321, 0.0164597903578142,
  0.0164513288862234, 0.016442876109139, 0.0164344320131678, 0.0164259965849442, 0.01641756981113, 0.0164091516784143,
  0.0164007421735134, 0.016392341283171, 0.0163839489941578, 0.0163755652932716, 0.0163671901673373, 0.0163588236032065,
  0.016350465587758, 0.0163421161078972, 0.0163337751505561, 0.0163254427026937, 0.0163171187512953, 0.0163088032833727,
  0.0163004962859645, 0.0162921977461354, 0.0162839076509764, 0.0162756259876049, 0.0162673527431645,
  0.0162590879048249, 0.0162508314597816, 0.0162425833952565, 0.0162343436984971, 0.016226112356777, 0.0162178893573954,
  0.0162096746876774, 0.0162014683349736, 0.0161932702866602, 0.0161850805301392, 0.0161768990528377,
  0.0161687258422084, 0.0161605608857293, 0.0161524041709038, 0.0161442556852603, 0.0161361154163525,
  0.0161279833517591, 0.0161198594790839, 0.0161117437859556, 0.0161036362600277, 0.0160955368889789,
  0.0160874456605121, 0.0160793625623555, 0.0160712875822615, 0.0160632207080074, 0.0160551619273948,
  0.0160471112282498, 0.0160390685984231, 0.0160310340257894, 0.016023007498248, 0.0160149890037222, 0.0160069785301596,
  0.0159989760655318,
];

export const F = [
  0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5,
  5.75, 6, 6.25, 6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 8.25, 8.5, 8.75, 9, 9.25, 9.5, 9.75, 10, 10.25, 10.5, 10.75, 11,
  11.25, 11.5, 11.75, 12, 12.25, 12.5, 12.75, 13, 13.25, 13.5, 13.75, 14, 14.25, 14.5, 14.75, 15, 15.25, 15.5, 15.75,
  16, 16.25, 16.5, 16.75, 17, 17.25, 17.5, 17.75, 18, 18.25, 18.5, 18.75, 19, 19.25, 19.5, 19.75, 20, 20.25, 20.5,
  20.75, 21, 21.25, 21.5, 21.75, 22, 22.25, 22.5, 22.75, 23, 23.25, 23.5, 23.75, 24, 24.25, 24.5, 24.75, 25, 25.25,
  25.5, 25.75, 26, 26.25, 26.5, 26.75, 27, 27.25, 27.5, 27.75, 28, 28.25, 28.5, 28.75, 29, 29.25, 29.5, 29.75, 30,
  30.25, 30.5, 30.75, 31, 31.25, 31.5, 31.75, 32, 32.25, 32.5, 32.75, 33, 33.25, 33.5, 33.75, 34, 34.25, 34.5, 34.75,
  35, 35.25, 35.5, 35.75, 36, 36.25, 36.5, 36.75, 37, 37.25, 37.5, 37.75, 38, 38.25, 38.5, 38.75, 39, 39.25, 39.5,
  39.75, 40, 40.25, 40.5, 40.75, 41, 41.25, 41.5, 41.75, 42, 42.25, 42.5, 42.75, 43, 43.25, 43.5, 43.75, 44, 44.25,
  44.5, 44.75, 45, 45.25, 45.5, 45.75, 46, 46.25, 46.5, 46.75, 47, 47.25, 47.5, 47.75, 48, 48.25, 48.5, 48.75, 49,
  49.25, 49.5, 49.75, 50, 50.25, 50.5, 50.75, 51, 51.25, 51.5, 51.75, 52, 52.25, 52.5, 52.75, 53, 53.25, 53.5, 53.75,
  54, 54.25, 54.5, 54.75, 55, 55.25, 55.5, 55.75, 56, 56.25, 56.5, 56.75, 57, 57.25, 57.5, 57.75, 58, 58.25, 58.5,
  58.75, 59, 59.25, 59.5, 59.75, 60, 60.25, 60.5, 60.75, 61, 61.25, 61.5, 61.75, 62, 62.25, 62.5, 62.75, 63, 63.25,
  63.5, 63.75, 64, 64.25, 64.5, 64.75, 65, 65.25, 65.5, 65.75, 66, 66.25, 66.5, 66.75, 67, 67.25, 67.5, 67.75, 68,
  68.25, 68.5, 68.75, 69, 69.25, 69.5, 69.75, 70, 70.25, 70.5, 70.75, 71, 71.25, 71.5, 71.75, 72, 72.25, 72.5, 72.75,
  73, 73.25, 73.5, 73.75, 74, 74.25, 74.5, 74.75, 75, 75.25, 75.5, 75.75, 76, 76.25, 76.5, 76.75, 77, 77.25, 77.5,
  77.75, 78, 78.25, 78.5, 78.75, 79, 79.25, 79.5, 79.75, 80, 80.25, 80.5, 80.75, 81, 81.25, 81.5, 81.75, 82, 82.25,
  82.5, 82.75, 83, 83.25, 83.5, 83.75, 84, 84.25, 84.5, 84.75, 85, 85.25, 85.5, 85.75, 86, 86.25, 86.5, 86.75, 87,
  87.25, 87.5, 87.75, 88, 88.25, 88.5, 88.75, 89, 89.25, 89.5, 89.75, 90, 90.25, 90.5, 90.75, 91, 91.25, 91.5, 91.75,
  92, 92.25, 92.5, 92.75, 93, 93.25, 93.5, 93.75, 94, 94.25, 94.5, 94.75, 95, 95.25, 95.5, 95.75, 96, 96.25, 96.5,
  96.75, 97, 97.25, 97.5, 97.75, 98, 98.25, 98.5, 98.75, 99, 99.25, 99.5, 99.75, 100, 100.25, 100.5, 100.75, 101,
  101.25, 101.5, 101.75, 102, 102.25, 102.5, 102.75, 103, 103.25, 103.5, 103.75, 104, 104.25, 104.5, 104.75, 105,
  105.25, 105.5, 105.75, 106, 106.25, 106.5, 106.75, 107, 107.25, 107.5, 107.75, 108, 108.25, 108.5, 108.75, 109,
  109.25, 109.5, 109.75, 110, 110.25, 110.5, 110.75, 111, 111.25, 111.5, 111.75, 112, 112.25, 112.5, 112.75, 113,
  113.25, 113.5, 113.75, 114, 114.25, 114.5, 114.75, 115, 115.25, 115.5, 115.75, 116, 116.25, 116.5, 116.75, 117,
  117.25, 117.5, 117.75, 118, 118.25, 118.5, 118.75, 119, 119.25, 119.5, 119.75, 120, 120.25, 120.5, 120.75, 121,
  121.25, 121.5, 121.75, 122, 122.25, 122.5, 122.75, 123, 123.25, 123.5, 123.75, 124, 124.25, 124.5, 124.75, 125,
  125.25, 125.5, 125.75, 126, 126.25, 126.5, 126.75, 127, 127.25, 127.5, 127.75, 128, 128.25, 128.5, 128.75, 129,
  129.25, 129.5, 129.75, 130, 130.25, 130.5, 130.75, 131, 131.25, 131.5, 131.75, 132, 132.25, 132.5, 132.75, 133,
  133.25, 133.5, 133.75, 134, 134.25, 134.5, 134.75, 135, 135.25, 135.5, 135.75, 136, 136.25, 136.5, 136.75, 137,
  137.25, 137.5, 137.75, 138, 138.25, 138.5, 138.75, 139, 139.25, 139.5, 139.75, 140, 140.25, 140.5, 140.75, 141,
  141.25, 141.5, 141.75, 142, 142.25, 142.5, 142.75, 143, 143.25, 143.5, 143.75, 144, 144.25, 144.5, 144.75, 145,
  145.25, 145.5, 145.75, 146, 146.25, 146.5, 146.75, 147, 147.25, 147.5, 147.75, 148, 148.25, 148.5, 148.75, 149,
  149.25, 149.5, 149.75, 150, 150.25, 150.5, 150.75, 151, 151.25, 151.5, 151.75, 152, 152.25, 152.5, 152.75, 153,
  153.25, 153.5, 153.75, 154, 154.25, 154.5, 154.75, 155, 155.25, 155.5, 155.75, 156, 156.25, 156.5, 156.75, 157,
  157.25, 157.5, 157.75, 158, 158.25, 158.5, 158.75, 159, 159.25, 159.5, 159.75, 160, 160.25, 160.5, 160.75, 161,
  161.25, 161.5, 161.75, 162, 162.25, 162.5, 162.75, 163, 163.25, 163.5, 163.75, 164, 164.25, 164.5, 164.75, 165,
  165.25, 165.5, 165.75, 166, 166.25, 166.5, 166.75, 167, 167.25, 167.5, 167.75, 168, 168.25, 168.5, 168.75, 169,
  169.25, 169.5, 169.75, 170, 170.25, 170.5, 170.75, 171, 171.25, 171.5, 171.75, 172, 172.25, 172.5, 172.75, 173,
  173.25, 173.5, 173.75, 174, 174.25, 174.5, 174.75, 175, 175.25, 175.5, 175.75, 176, 176.25, 176.5, 176.75, 177,
  177.25, 177.5, 177.75, 178, 178.25, 178.5, 178.75, 179, 179.25, 179.5, 179.75, 180, 180.25, 180.5, 180.75, 181,
  181.25, 181.5, 181.75, 182, 182.25, 182.5, 182.75, 183, 183.25, 183.5, 183.75, 184, 184.25, 184.5, 184.75, 185,
  185.25, 185.5, 185.75, 186, 186.25, 186.5, 186.75, 187, 187.25, 187.5, 187.75, 188, 188.25, 188.5, 188.75, 189,
  189.25, 189.5, 189.75, 190, 190.25, 190.5, 190.75, 191, 191.25, 191.5, 191.75, 192, 192.25, 192.5, 192.75, 193,
  193.25, 193.5, 193.75, 194, 194.25, 194.5, 194.75, 195, 195.25, 195.5, 195.75, 196, 196.25, 196.5, 196.75, 197,
  197.25, 197.5, 197.75, 198, 198.25, 198.5, 198.75, 199, 199.25, 199.5, 199.75, 200, 200.25, 200.5, 200.75, 201,
  201.25, 201.5, 201.75, 202, 202.25, 202.5, 202.75, 203, 203.25, 203.5, 203.75, 204, 204.25, 204.5, 204.75, 205,
  205.25, 205.5, 205.75, 206, 206.25, 206.5, 206.75, 207, 207.25, 207.5, 207.75, 208, 208.25, 208.5, 208.75, 209,
  209.25, 209.5, 209.75, 210, 210.25, 210.5, 210.75, 211, 211.25, 211.5, 211.75, 212, 212.25, 212.5, 212.75, 213,
  213.25, 213.5, 213.75, 214, 214.25, 214.5, 214.75, 215, 215.25, 215.5, 215.75, 216, 216.25, 216.5, 216.75, 217,
  217.25, 217.5, 217.75, 218, 218.25, 218.5, 218.75, 219, 219.25, 219.5, 219.75, 220, 220.25, 220.5, 220.75, 221,
  221.25, 221.5, 221.75, 222, 222.25, 222.5, 222.75, 223, 223.25, 223.5, 223.75, 224, 224.25, 224.5, 224.75, 225,
  225.25, 225.5, 225.75, 226, 226.25, 226.5, 226.75, 227, 227.25, 227.5, 227.75, 228, 228.25, 228.5, 228.75, 229,
  229.25, 229.5, 229.75, 230, 230.25, 230.5, 230.75, 231, 231.25, 231.5, 231.75, 232, 232.25, 232.5, 232.75, 233,
  233.25, 233.5, 233.75, 234, 234.25, 234.5, 234.75, 235, 235.25, 235.5, 235.75, 236, 236.25, 236.5, 236.75, 237,
  237.25, 237.5, 237.75, 238, 238.25, 238.5, 238.75, 239, 239.25, 239.5, 239.75, 240, 240.25, 240.5, 240.75, 241,
  241.25, 241.5, 241.75, 242, 242.25, 242.5, 242.75, 243, 243.25, 243.5, 243.75, 244, 244.25, 244.5, 244.75, 245,
  245.25, 245.5, 245.75, 246, 246.25, 246.5, 246.75, 247, 247.25, 247.5, 247.75, 248, 248.25, 248.5, 248.75, 249,
  249.25, 249.5, 249.75, 250, 250.25, 250.5, 250.75, 251, 251.25, 251.5, 251.75, 252, 252.25, 252.5, 252.75, 253,
  253.25, 253.5, 253.75, 254, 254.25, 254.5, 254.75, 255, 255.25, 255.5, 255.75, 256, 256.25, 256.5, 256.75, 257,
  257.25, 257.5, 257.75, 258, 258.25, 258.5, 258.75, 259, 259.25, 259.5, 259.75, 260, 260.25, 260.5, 260.75, 261,
  261.25, 261.5, 261.75, 262, 262.25, 262.5, 262.75, 263, 263.25, 263.5, 263.75, 264, 264.25, 264.5, 264.75, 265,
  265.25, 265.5, 265.75, 266, 266.25, 266.5, 266.75, 267, 267.25, 267.5, 267.75, 268, 268.25, 268.5, 268.75, 269,
  269.25, 269.5, 269.75, 270, 270.25, 270.5, 270.75, 271, 271.25, 271.5, 271.75, 272, 272.25, 272.5, 272.75, 273,
  273.25, 273.5, 273.75, 274, 274.25, 274.5, 274.75, 275, 275.25, 275.5, 275.75, 276, 276.25, 276.5, 276.75, 277,
  277.25, 277.5, 277.75, 278, 278.25, 278.5, 278.75, 279, 279.25, 279.5, 279.75, 280, 280.25, 280.5, 280.75, 281,
  281.25, 281.5, 281.75, 282, 282.25, 282.5, 282.75, 283, 283.25, 283.5, 283.75, 284, 284.25, 284.5, 284.75, 285,
  285.25, 285.5, 285.75, 286, 286.25, 286.5, 286.75, 287, 287.25, 287.5, 287.75, 288, 288.25, 288.5, 288.75, 289,
  289.25, 289.5, 289.75, 290, 290.25, 290.5, 290.75, 291, 291.25, 291.5, 291.75, 292, 292.25, 292.5, 292.75, 293,
  293.25, 293.5, 293.75, 294, 294.25, 294.5, 294.75, 295, 295.25, 295.5, 295.75, 296, 296.25, 296.5, 296.75, 297,
  297.25, 297.5, 297.75, 298, 298.25, 298.5, 298.75, 299, 299.25, 299.5, 299.75, 300, 300.25, 300.5, 300.75, 301,
  301.25, 301.5, 301.75, 302, 302.25, 302.5, 302.75, 303, 303.25, 303.5, 303.75, 304, 304.25, 304.5, 304.75, 305,
  305.25, 305.5, 305.75, 306, 306.25, 306.5, 306.75, 307, 307.25, 307.5, 307.75, 308, 308.25, 308.5, 308.75, 309,
  309.25, 309.5, 309.75, 310, 310.25, 310.5, 310.75, 311, 311.25, 311.5, 311.75, 312, 312.25, 312.5, 312.75, 313,
  313.25, 313.5, 313.75, 314, 314.25, 314.5, 314.75, 315, 315.25, 315.5, 315.75, 316, 316.25, 316.5, 316.75, 317,
  317.25, 317.5, 317.75, 318, 318.25, 318.5, 318.75, 319, 319.25, 319.5, 319.75, 320, 320.25, 320.5, 320.75, 321,
  321.25, 321.5, 321.75, 322, 322.25, 322.5, 322.75, 323, 323.25, 323.5, 323.75, 324, 324.25, 324.5, 324.75, 325,
  325.25, 325.5, 325.75, 326, 326.25, 326.5, 326.75, 327, 327.25, 327.5, 327.75, 328, 328.25, 328.5, 328.75, 329,
  329.25, 329.5, 329.75, 330, 330.25, 330.5, 330.75, 331, 331.25, 331.5, 331.75, 332, 332.25, 332.5, 332.75, 333,
  333.25, 333.5, 333.75, 334, 334.25, 334.5, 334.75, 335, 335.25, 335.5, 335.75, 336, 336.25, 336.5, 336.75, 337,
  337.25, 337.5, 337.75, 338, 338.25, 338.5, 338.75, 339, 339.25, 339.5, 339.75, 340, 340.25, 340.5, 340.75, 341,
  341.25, 341.5, 341.75, 342, 342.25, 342.5, 342.75, 343, 343.25, 343.5, 343.75, 344, 344.25, 344.5, 344.75, 345,
  345.25, 345.5, 345.75, 346, 346.25, 346.5, 346.75, 347, 347.25, 347.5, 347.75, 348, 348.25, 348.5, 348.75, 349,
  349.25, 349.5, 349.75, 350, 350.25, 350.5, 350.75, 351, 351.25, 351.5, 351.75, 352, 352.25, 352.5, 352.75, 353,
  353.25, 353.5, 353.75, 354, 354.25, 354.5, 354.75, 355, 355.25, 355.5, 355.75, 356, 356.25, 356.5, 356.75, 357,
  357.25, 357.5, 357.75, 358, 358.25, 358.5, 358.75, 359, 359.25, 359.5, 359.75, 360, 360.25, 360.5, 360.75, 361,
  361.25, 361.5, 361.75, 362, 362.25, 362.5, 362.75, 363, 363.25, 363.5, 363.75, 364, 364.25, 364.5, 364.75, 365,
  365.25, 365.5, 365.75, 366, 366.25, 366.5, 366.75, 367, 367.25, 367.5, 367.75, 368, 368.25, 368.5, 368.75, 369,
  369.25, 369.5, 369.75, 370, 370.25, 370.5, 370.75, 371, 371.25, 371.5, 371.75, 372, 372.25, 372.5, 372.75, 373,
  373.25, 373.5, 373.75, 374, 374.25, 374.5, 374.75, 375, 375.25, 375.5, 375.75, 376, 376.25, 376.5, 376.75, 377,
  377.25, 377.5, 377.75, 378, 378.25, 378.5, 378.75, 379, 379.25, 379.5, 379.75, 380, 380.25, 380.5, 380.75, 381,
  381.25, 381.5, 381.75, 382, 382.25, 382.5, 382.75, 383, 383.25, 383.5, 383.75, 384, 384.25, 384.5, 384.75, 385,
  385.25, 385.5, 385.75, 386, 386.25, 386.5, 386.75, 387, 387.25, 387.5, 387.75, 388, 388.25, 388.5, 388.75, 389,
  389.25, 389.5, 389.75, 390, 390.25, 390.5, 390.75, 391, 391.25, 391.5, 391.75, 392, 392.25, 392.5, 392.75, 393,
  393.25, 393.5, 393.75, 394, 394.25, 394.5, 394.75, 395, 395.25, 395.5, 395.75, 396, 396.25, 396.5, 396.75, 397,
  397.25, 397.5, 397.75, 398, 398.25, 398.5, 398.75, 399, 399.25, 399.5, 399.75, 400, 400.25, 400.5, 400.75, 401,
  401.25, 401.5, 401.75, 402, 402.25, 402.5, 402.75, 403, 403.25, 403.5, 403.75, 404, 404.25, 404.5, 404.75, 405,
  405.25, 405.5, 405.75, 406, 406.25, 406.5, 406.75, 407, 407.25, 407.5, 407.75, 408, 408.25, 408.5, 408.75, 409,
  409.25, 409.5, 409.75, 410, 410.25, 410.5, 410.75, 411, 411.25, 411.5, 411.75, 412, 412.25, 412.5, 412.75, 413,
  413.25, 413.5, 413.75, 414, 414.25, 414.5, 414.75, 415, 415.25, 415.5, 415.75, 416, 416.25, 416.5, 416.75, 417,
  417.25, 417.5, 417.75, 418, 418.25, 418.5, 418.75, 419, 419.25, 419.5, 419.75, 420, 420.25, 420.5, 420.75, 421,
  421.25, 421.5, 421.75, 422, 422.25, 422.5, 422.75, 423, 423.25, 423.5, 423.75, 424, 424.25, 424.5, 424.75, 425,
  425.25, 425.5, 425.75, 426, 426.25, 426.5, 426.75, 427, 427.25, 427.5, 427.75, 428, 428.25, 428.5, 428.75, 429,
  429.25, 429.5, 429.75, 430, 430.25, 430.5, 430.75, 431, 431.25, 431.5, 431.75, 432, 432.25, 432.5, 432.75, 433,
  433.25, 433.5, 433.75, 434, 434.25, 434.5, 434.75, 435, 435.25, 435.5, 435.75, 436, 436.25, 436.5, 436.75, 437,
  437.25, 437.5, 437.75, 438, 438.25, 438.5, 438.75, 439, 439.25, 439.5, 439.75, 440, 440.25, 440.5, 440.75, 441,
  441.25, 441.5, 441.75, 442, 442.25, 442.5, 442.75, 443, 443.25, 443.5, 443.75, 444, 444.25, 444.5, 444.75, 445,
  445.25, 445.5, 445.75, 446, 446.25, 446.5, 446.75, 447, 447.25, 447.5, 447.75, 448, 448.25, 448.5, 448.75, 449,
  449.25, 449.5, 449.75, 450, 450.25, 450.5, 450.75, 451, 451.25, 451.5, 451.75, 452, 452.25, 452.5, 452.75, 453,
  453.25, 453.5, 453.75, 454, 454.25, 454.5, 454.75, 455, 455.25, 455.5, 455.75, 456, 456.25, 456.5, 456.75, 457,
  457.25, 457.5, 457.75, 458, 458.25, 458.5, 458.75, 459, 459.25, 459.5, 459.75, 460, 460.25, 460.5, 460.75, 461,
  461.25, 461.5, 461.75, 462, 462.25, 462.5, 462.75, 463, 463.25, 463.5, 463.75, 464, 464.25, 464.5, 464.75, 465,
  465.25, 465.5, 465.75, 466, 466.25, 466.5, 466.75, 467, 467.25, 467.5, 467.75, 468, 468.25, 468.5, 468.75, 469,
  469.25, 469.5, 469.75, 470, 470.25, 470.5, 470.75, 471, 471.25, 471.5, 471.75, 472, 472.25, 472.5, 472.75, 473,
  473.25, 473.5, 473.75, 474, 474.25, 474.5, 474.75, 475, 475.25, 475.5, 475.75, 476, 476.25, 476.5, 476.75, 477,
  477.25, 477.5, 477.75, 478, 478.25, 478.5, 478.75, 479, 479.25, 479.5, 479.75, 480, 480.25, 480.5, 480.75, 481,
  481.25, 481.5, 481.75, 482, 482.25, 482.5, 482.75, 483, 483.25, 483.5, 483.75, 484, 484.25, 484.5, 484.75, 485,
  485.25, 485.5, 485.75, 486, 486.25, 486.5, 486.75, 487, 487.25, 487.5, 487.75, 488, 488.25, 488.5, 488.75, 489,
  489.25, 489.5, 489.75, 490, 490.25, 490.5, 490.75, 491, 491.25, 491.5, 491.75, 492, 492.25, 492.5, 492.75, 493,
  493.25, 493.5, 493.75, 494, 494.25, 494.5, 494.75, 495, 495.25, 495.5, 495.75, 496, 496.25, 496.5, 496.75, 497,
  497.25, 497.5, 497.75, 498, 498.25, 498.5, 498.75, 499, 499.25, 499.5, 499.75, 500,
];
