import React, { Component } from "react";
import HalfPolarGraph from "../../components/common/ant-novak/ChartJS/HalfPolarGraph";
// import HalfPolarGraph from "../UI/ChartJS/HalfPolarGraph";
//import { LogSpace } from "../../helpers/helpers";
import { directivity } from "./PistonDirectivity.helper";

class PistonDirectivityGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   radius (of the piston) [cm]
    //   frequency [Hz]
    //   dB ... dynamics of the graph
    // -------------------------
    const { radius, frequency, dB } = this.props;
    // angles
    const N = 360;

    // calculate directivity
    const graphData = directivity(radius, frequency, N, -dB);

    const data = {
      title: "Circular piston directivity pattern",
      data: [graphData],
      axis: { min: -dB, max: 0 },
    };

    return <HalfPolarGraph data={data} />;
  }
}
export default PistonDirectivityGraph;
