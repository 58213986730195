import { ChartConfiguration } from "chart.js";

const signalsDataLabels = Array.from({ length: 400 }, (_, i) => (-3 + i * 0.015).toFixed(2));

const fourierDataLabels = Array.from({ length: 500 }, (_, i) => (-30 + i * 0.12).toFixed(2));

const sharedOptions: ChartConfiguration = {
  type: "line",
  options: {
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
  },
};

export const signalChartOptions: ChartConfiguration = {
  ...sharedOptions,
  data: {
    labels: signalsDataLabels,
    datasets: [
      {
        borderColor: "#131A4F",
        fill: false,
        pointRadius: 0,
        lineTension: 0.4,
        showLine: true,
      },
    ],
  },
  options: {
    ...sharedOptions.options,
    legend: {
      display: false,
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Time Signal",
            fontStyle: "bold",
            fontColor: "#131A4F",
            fontFamily: "'Mulish', 'Arial', sans-serif",
          },
        },
      ],
    },
  },
};

export const fourierChartOptions: ChartConfiguration = {
  ...sharedOptions,
  data: {
    labels: fourierDataLabels,
    datasets: [
      {
        label: "real(FT(s))",
        borderColor: "#FF6040",
        fill: false,
        pointRadius: 0,
        lineTension: 0.4,
        showLine: true,
      },
      {
        label: "imag(FT(s))",
        borderColor: "#131A4F",
        fill: false,
        pointRadius: 0,
        lineTension: 0.4,
        showLine: true,
      },
      {
        label: "|FT|",
        borderColor: "#898CA7",
        fill: false,
        pointRadius: 0,
        lineTension: 0.4,
        showLine: true,
      },
    ],
  },
  options: {
    ...sharedOptions.options,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Fourier Transform",
            fontStyle: "bold",
            fontColor: "#131A4F",
            fontFamily: "'Mulish', 'Arial', sans-serif",
          },
        },
      ],
    },
  },
};
