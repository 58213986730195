import React, { Component, ReactFragment, ReactNode } from "react";
import { Badge, Slider } from "@jelly/ui";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { chartAOptions } from "./Lesson0305b.chart_a.config";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LessonChartA from "./Lesson0305b.chart_a";
import npyjs from "npyjs";
import config from "appConfig";

class Lesson0305b extends Component {
  //Numpy in js
  npy = new npyjs();
  xLabels = Array.from({ length: 300 }, (_, i) => -2 + (i * 4) / 299);

  state = {
    fs: 352,
    fs_stable: 352,
  };
  audio = new Audio();

  /**
   * Adjust sliders config to fit Python
   */
  private sliders = {
    fs: {
      name: "F<sub>s</sub>",
      min: 352,
      max: 1317,
      step: 5,
    },
  };

  updateParams = (key: string, value: number): void => {
    this.setState({ [key]: value });
  };
  updateParamsAfter = (key: string, value: number): void => {
    this.setState({ [key]: value });
    this.loadAndPlayAudio(value);
  };

  loadAndPlayAudio = (index: number): void => {
    const audioFile = `${config.ASSETS_URL}/python-data/0305b/pure_aliasing_${index}.mp3`;
    if (this.audio) {
      try {
        this.audio.pause();
      } catch (error) {
        //no-op
      }
    }
    this.audio = new Audio(audioFile);
    this.audio.play();
  };

  renderParamSlider = (key: "fs"): ReactFragment => {
    const property = this.state[key];
    const config = this.sliders[key];
    return (
      <div style={{ display: "flex", flex: "1 1 48%", alignItems: "center", gap: "0em", paddingRight: "1em" }}>
        <div style={{ flex: "0", minWidth: "6em" }}>
          <Badge theme="secondary" size="small">
            {`${config.name} = ${property.toFixed(2)}`}
          </Badge>
        </div>
        <div style={{ flex: "1" }}>
          <Slider
            min={config.min}
            max={config.max}
            step={config.step}
            theme="primary"
            value={property}
            onChange={(value) => {
              this.updateParams(key, value);
            }}
            onAfterChange={(value) => {
              this.updateParamsAfter(`${key}_stable`, value);
            }}
          />
        </div>
      </div>
    );
  };

  render(): ReactNode {
    const { fs } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%", flex: "1" }}>
        <div
          style={{
            flex: "1 0 80%",
            display: "flex",
            justifyContent: "space-evenly",
            gap: "1em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <LessonChartA fs={fs} options={chartAOptions} />
        </div>
        <div style={{ flex: "0 1", width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>{this.renderParamSlider("fs")}</div>
        </div>
      </div>
    );
  }
}
//
export default Lesson0305b;
