import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { MatlabData } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { MatlabData } from "../../helpers/helpers";
import { parametersEstimate } from "./AdvancedLoudspeaker.helper";

class AdvancedLoudspeakerGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    const { Bl, Mms, page } = this.props;

    // load frequencies, V, U, I
    const dataMatlab = MatlabData(require("./loudspeaker_data.json"));

    // calculate results
    const { Re, Le, Rms, Kms } = parametersEstimate(dataMatlab, Bl, Mms);

    const dataElectric = {
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Re],
          label: ["Re"],
          yLabel: "apparent resistance (Ohm)",
          type: "linear",
        },
        {
          data: [Le],
          label: ["Le"],
          yLabel: "apparent inductance (mH)",
          type: "linear",
        },
      ],
    };

    const dataMechanic = {
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Rms],
          label: ["Rms"],
          yLabel: "mechanical losses resistance (Ns/m)",
          type: "linear",
        },
        {
          data: [Kms],
          label: ["Kms"],
          yLabel: "stiffness Kms (N/m)",
          type: "linear",
        },
      ],
    };

    return (
      <div>
        <div className="one-graph" style={{ display: Number(page) === 0 ? "block" : "none" }}>
          <LineGraph data={dataElectric} graph_size_ratio={0.6} />
        </div>
        <div className="one-graph" style={{ display: Number(page) === 1 ? "block" : "none" }}>
          <LineGraph data={dataMechanic} graph_size_ratio={0.6} />
        </div>
      </div>
    );
  }
}
export default AdvancedLoudspeakerGraph;
