import { monoEncoder, binDecoder, sceneRotator } from "ambisonics";

class Player {
  audioContext = null;

  setSource(audioSource) {
    this.audioSource = audioSource;
  }

  loadAudio() {
    if (this.audioContext != null) {
      return;
    }
    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
    this.isPlaying = false;
    this.audioBuffer = [];
    this.ambisonicsOrder = 4;
    this.ambisonicsEncoder = new monoEncoder(this.audioContext, this.ambisonicsOrder);
    this.ambisonicsSceneRotator = new sceneRotator(this.audioContext, this.ambisonicsOrder);
    this.binauralDecoder = new binDecoder(this.audioContext, this.ambisonicsOrder);
    this.ambisonicsEncoder.out.connect(this.ambisonicsSceneRotator.in);
    this.ambisonicsSceneRotator.out.connect(this.binauralDecoder.in);
    this.binauralDecoder.out.connect(this.audioContext.destination);

    this.mediaElementSource = this.audioContext.createMediaElementSource(this.audioSource);
    this.mediaElementSource.connect(this.ambisonicsEncoder.in);
  }

  rotateScene(yaw, pitch, roll) {
    this.loadAudio();
    this.ambisonicsSceneRotator.yaw = yaw;
    this.ambisonicsSceneRotator.pitch = pitch;
    this.ambisonicsSceneRotator.roll = roll;
    this.ambisonicsSceneRotator.updateRotMtx();
  }

  play() {
    this.loadAudio();
    this.audioSource.play();
    this.isPlaying = true;
  }

  stop() {
    this.loadAudio();
    this.audioSource.pause();
    this.audioSource.currentTime = 0;
    this.isPlaying = false;
  }
}

export default Player;
