import { Chart, ChartOptions } from "chart.js";
import npyjs from "npyjs";
import config from "appConfig";
import "chartjs-plugin-annotation";
import { cloneDeep } from "lodash-es";
import { Spinner } from "@jelly/ui";
import React, { useEffect, useRef, useState } from "react";
import { COLORS } from "../../helpers/colors";
import { reshape } from "./Helpers";

interface LessonChartBProps {
  t: number;
  n: number;
  options: ChartOptions;
  annotations?: Record<string, unknown>;
}

Chart.defaults.global.defaultFontFamily = "'Mulish', 'Arial', sans-serif";
Chart.defaults.global.defaultFontColor = "#131A4F";

const renderLoader = (): React.ReactElement => {
  return (
    <div>
      <Spinner />
    </div>
  );
};

const renderError = (): React.ReactElement => {
  return <div>Could not fetch graph data.</div>;
};

const LessonChartB: React.FC<LessonChartBProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>();
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMain, setLoadingMain] = useState<boolean>(true);
  const [mainData, setMainData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const npy = new npyjs();

  const loadMainData = async (t: number, n: number): Promise<void> => {
    const filePrefix = `N${n}_T${t.toFixed(1)}`;
    const url = `${config.ASSETS_URL}/python-data/0305c/blue_curve_${filePrefix}.npy`;

    try {
      const npData = await npy.load(url);
      const data = reshape(npData.data, [-1, 2]);

      const labels = data.map((val) => val[0].toFixed(2));
      const yPoints = data.map((val) => val[1]);

      setLabels(labels);
      setMainData(yPoints);
      setLoadingMain(false);
    } catch (error) {
      setLoadingMain(false);
      setError(true);
    }
  };

  useEffect(() => {
    loadMainData(props.t, props.n);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingMain) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingMain]);

  /**
   * Create graph once data is loaded.
   */
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    chartRef.current = new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "s1",
            borderColor: COLORS[0],
            fill: false,
            pointRadius: 0,
            data: mainData,
            barThickness: 4,
          },
        ],
      },
      options: cloneDeep({ ...props.options }),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  /**
   * Update graph on yData change
   */
  useEffect(() => {
    if (chartRef.current && chartRef.current?.data.datasets && mainData.length > 0) {
      chartRef.current.data.labels = labels;
      chartRef.current.data.datasets[0].data = mainData;
      chartRef.current.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainData]);

  /**
   * Fetch new data on Tau change
   */
  useEffect(() => {
    loadMainData(props.t, props.n);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.t, props.n]);

  /**
   * Update graph on annotations change
   */
  useEffect(() => {
    if (chartRef.current && props.annotations) {
      // ESLint cannot scan the options from the plugins.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chartRef.current.options.annotation.annotations = [props.annotations];
      chartRef.current.update();
    }
  }, [props.annotations]);

  if (loading) return renderLoader();
  if (error) return renderError();

  return (
    <div style={{ position: "relative", height: "30vh", width: "80vw" }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default LessonChartB;
