/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import { round } from "../../helpers/ant-novak/math.helper";
// import SliderEdit from "../UI/SliderEdit";
import VentedBoxSystemGraph from "./VentedBoxSystem.graph";
import VentedBoxSystemSVG from "./VentedBoxSystem.svg";
import { getLength, getVolume } from "./VentedBoxSystem.helper";
import { sliders } from "./VentedBoxSystem.helper";
import { Badge } from "@jelly/ui";
// import { round } from "../../helpers/math.helper";

// -------------------------
// Vented-box loudspeaker system simulation
// - Loudspeaker with given TS parameters has a closed-box which can change
//        Vmult ... Volume = Vmult * Vas
//        Rmult ... Rms_tot = Rmult * Rms
// -------------------------

class VentedBoxSystem extends Component {
  // class state
  state = {
    Vmult: 1, // multiplication factor of volume
    fmult: 1, // multiplication factor of mechanical resistance
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  renderBlock() {
    const { Vmult, fmult } = this.state;

    const ventLen = `vent length = ${round(getLength(Vmult, fmult) * 100, 2)} cm`;
    const boxVol = ` box volume = ${round(getVolume(Vmult) * 100, 2)} l`;
    return (
      <div>
        <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
          <h5>VENTED-BOX SYSTEM</h5>
          <div style={{ display: "flex", flexDirection: "row", flex: "1", alignItems: "center" }}>
            <div style={{ width: "25%" }}>
              <div>
                <VentedBoxSystemSVG Vmult={Vmult} fmult={fmult} />{" "}
              </div>
              <div>
                <Badge theme="secondary" size="small">
                  {ventLen}
                </Badge>
                <Badge theme="secondary" size="small">
                  {boxVol}
                </Badge>
              </div>
            </div>
            <div style={{ width: "75%" }}>
              <VentedBoxSystemGraph Vmult={Vmult} fmult={fmult} />
            </div>
          </div>
          <div>
            {sliders.map((slider, index) => {
              return (
                <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default VentedBoxSystem;
