export const roundToPrecision = (value: number, precision: number): number => {
  const factor = Math.pow(10, precision);
  return Math.round(value * factor) / factor;
};

export const parametersToKey = (M: number, K: number, tau: number, xi: number): string => {
  return `${M.toFixed(1)},${K.toFixed(0)},${tau.toFixed(1)},${xi.toFixed(1)}`;
};

export const calculateParameters = (params: number[], data: number[]): Record<string, number[]> => {
  const paramResultMap: Record<string, number[]> = {};

  for (let i = 0; i < params.length / 4; i++) {
    const param = [
      roundToPrecision(params[4 * i], 1),
      roundToPrecision(params[4 * i + 1], 0),
      roundToPrecision(params[4 * i + 2], 1),
      roundToPrecision(params[4 * i + 3], 1),
    ];
    const key = parametersToKey(param[0], param[1], param[3], param[2]);
    paramResultMap[key] = data.slice(i * 200, (i + 1) * 200);
  }

  return paramResultMap;
};
