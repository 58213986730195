/* eslint-disable */
// @ts-nocheck

import React from "react";
import { REFLECTION_TYPES } from "../../components/common/ant-novak/waveguide/components/settings/WaveGuide.reflection";
import {
  displacementFnc,
  pressureFnc,
  velocityFnc,
  intensityFnc,
  intensityMeanFnc,
  intensityMinMaxFnc,
} from "../../components/common/ant-novak/waveguide/helpers/WaveGuide.mainEquationsSimple";
import { SIGNAL_CHOICE } from "../../components/common/ant-novak/waveguide/helpers/WaveGuide.signals";
import WaveGuide from "../../components/common/ant-novak/waveguide/WaveGuide";
import { COLORS } from "../../helpers/ant-novak/helpers";

// import React from "react";
// import WaveGuide from "./WaveGuide.jsx";
// import { SIGNAL_CHOICE } from "./helpers/WaveGuide.signals.js";
// import { COLORS } from "../../helpers/helpers.js";
// import { REFLECTION_TYPES } from "./components/settings/WaveGuide.reflection.jsx";
// import {
//   pressureFnc,
//   velocityFnc,
//   displacementFnc,
//   intensityFnc,
//   intensityMeanFnc,
//   intensityMinMaxFnc,
// } from "./helpers/WaveGuide.mainEquationsSimple.js";

const props = {
  colormap: { name: "bone", unit: "", symmetric: false },
  microphone: { show: true, x: 0.5 },
  signals: [
    {
      title: "Harmonic",
      type: SIGNAL_CHOICE.HARMONIC,
      freq: { value: 550, can_be_modified: true, minmax: [100, 2000] },
      A: {
        abs: 0.0,
        arg: 0.0,
        edit: false,
        arg_edit: false,
        inv_edit: false,
        fnc: function (signal, physics) {
          this.abs = signal.Rf.abs() * Math.exp(-signal.alpha[0]);
          this.arg = signal.Rf.arg();
        },
      },
      B: {
        abs: 1.0,
        arg: 0.0,
        edit: false,
        arg_edit: false,
        inv_edit: false,
        fnc: function (signal, physics) {
          this.abs = Math.exp(-signal.alpha[0]);
        },
      },
      graph_limits: { pmax: 2, vmax: 0.006, imax: 0.012, auto: false },
      standing_waves: false,
      drawMinMax: intensityMinMaxFnc,
    },
  ],
  physics: {
    pressureFnc: pressureFnc,
    velocityFnc: velocityFnc,
    displacementFnc: displacementFnc,
    drawFnc: intensityMeanFnc,
    vectorFnc: intensityFnc,
    radius: { values: [1.0 / 8, 1.0 / 8], drag: false, edit: true },
    rho: [1.2, 1.2],
    c0: [343, 343],
    medium: { edit: false, title: "medium: air" },
    loss_factor: 3e-5,
    reflection: {
      edit: true,
      x: 0.0,
      types: [REFLECTION_TYPES.NEUMANN, REFLECTION_TYPES.DIRICHLET, REFLECTION_TYPES.NO, REFLECTION_TYPES.RADIATION],
      type: REFLECTION_TYPES.NEUMANN.value,
    },
    impedance: true,
  },
  pressure_colors: { can_be_modified: true, show: true, text: "show mean intensity colors" },
  velocity_arrows: {
    can_be_modified: true,
    show: false,
    text: "show intensity arrows",
    size_ratio: 6000,
    color: COLORS[1],
  },
  graphs: { space: true, impedance: true },
};

export function WaveGuideLesson09() {
  return (
    <div className={"app"}>
      <div className={"my-div"}>
        <WaveGuide {...props} />
      </div>
    </div>
  );
}
