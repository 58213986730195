import React from "react";
import SliderEdit from "../../../SliderEdit";
// import SliderEdit from "../../../UI/SliderEdit";

export default function WaveGuideFrequency({ properties, value, minmax, updateAmplitudes }) {
  const slider = {
    name: "Frequency",
    unit: "Hz",
    min: minmax[0],
    max: minmax[1],
    step: 0.01,
    log: false,
    edit: false,
  };

  const updateFrequency = (name, value) => {
    properties.signal.freq.value = Number(value);
    properties.signal.setAlpha(properties.physics.radius.values);
    properties.signal.setWavenumer(properties.physics.c0);
    properties.flag_updateReflection = !properties.flag_updateReflection;
    updateAmplitudes(properties);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <SliderEdit onChange={updateFrequency} params={slider} val={value} />
    </div>
  );
}
