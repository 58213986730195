import React, { Component } from "react";
import { round } from "../../../../../../helpers/ant-novak/math.helper";
import { standingWaveRate } from "../../helpers/WaveGuide.helper.js";
// import { round } from "../../../../helpers/math.helper";

class WaveGideAnimationInformation extends Component {
  render() {
    let t = this.props.t;
    let { animation, physics, signal, dt } = this.props.properties;
    let current_time = Math.round(10 * (t / animation.animation_rate) * 1000) / 10;
    const show_standing_wave_rate = signal.standing_waves && signal.A.abs * signal.B.abs > 0;
    const show_reflection_coefficients = physics.reflection?.show_coefficients;

    return (
      <>
        current time: {Number.isInteger(current_time) ? current_time + ".0" : current_time}
        {" ms"}
        {!show_reflection_coefficients && (
          <>
            ,animation slowed down {animation.animation_rate}x, displacement multplied by {signal.displacement_zoom}
            {physics.medium?.title !== undefined && <>,{physics.medium.title}</>}
          </>
        )}
        {show_standing_wave_rate && <>,standing wave ratio : {round(standingWaveRate(signal))}</>}
        {show_reflection_coefficients && (
          <>
            ,reflection coefficient R = {round(signal.left_right === "left" ? signal.Rf.re : signal.Rg.re, 2)},
            transmission coefficient T = {round(signal.left_right === "left" ? signal.Tf.re : signal.Tg.re, 2)}
          </>
        )}
      </>
    );
  }
}

export default WaveGideAnimationInformation;
