/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import MechanicalSystemGraph from "./MechanicalSystem.graph";
import { Fc, Q } from "./MechanicalSystem.helper";
import { Badge, SwitchOption } from "@jelly/ui";

//const MU = String.fromCharCode(956); // symbol for micro (mu)

// -------------------------
// Mechanical Mass Spring System:
// - Shows mechanical impedance or admittannce (amplitude and phase) of a mechanical system.
//   User can select between admittance and impedance,
//   and values of Rm ... mechanical resistance
//                 Mm ... moving mass
//                 Cm ... mechanical compliance
// -------------------------

class MechanicalSystem extends Component {
  // class state
  state = {
    Rm: 0.6, // mechanical resistance [Ns/m]
    Mm: 10.0, // moving mass [g]
    Cm: 1, // mechanical compliance [mm/N]
    AdmImp: "admittance", // "admittance" or "impedance"
    LinLog: "logarithmic", // "linear" or "logarithmic"
  };

  // slider parameters
  sliders = [
    {
      name: "Rm",
      title: "R<sub>m</sub>",
      unit: "N∙s/m",
      min: 0,
      max: 5.0,
      step: 0.1,
    },
    {
      name: "Mm",
      title: "M<sub>m</sub>",
      unit: "g",
      min: 0,
      max: 20.0,
      step: 0.1,
    },
    {
      name: "Cm",
      title: "C<sub>m</sub>",
      unit: "mm/N",
      min: 0,
      max: 5.0,
      step: 0.1,
    },
  ];

  // handle for radio
  handleChangeRadio = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeAdm = (value) => {
    if (value == 0) {
      this.setState({ AdmImp: "admittance" });
    } else {
      this.setState({ AdmImp: "impedance" });
    }
  };
  handleChangeLin = (value) => {
    if (value == 0) {
      this.setState({ LinLog: "linear" });
    } else {
      this.setState({ LinLog: "logarithmic" });
    }
  };
  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  renderBlock() {
    const { Rm, Mm, Cm, AdmImp, LinLog } = this.state;

    return (
      <div>
        {/* --------------------------------------------------*/}
        {/* text values (resonant frequency (fc) and quality factor (Q) ) */}
        {/* --------------------------------------------------*/}
        <p>
          <Badge theme="secondary" size="small">
            {`f<sub>s</sub>=${Fc(Mm * 1e-3, Cm * 1e-3) // pass values from [g] to [kg]
              .toFixed(2) //                     and from [mm/N] to [m/N]
              .toString()}
             (Hz)`}
          </Badge>
          <Badge theme="secondary" size="small">
            {`Q=${Q(Rm, Mm * 1e-3, Cm * 1e-3, AdmImp)
              .toFixed(2)
              .toString()}`}
          </Badge>
        </p>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        {/* */}
        {/* --------------------------------------------------*/}
        {/* radio buttons to choose between linear and logarithmic Yaxis of chart.js*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph">
          {/* --------------------------------------------------*/}
          {/* graph (chartjs)*/}
          {/* --------------------------------------------------*/}
          <MechanicalSystemGraph
            AdmImp={AdmImp} // selector between "serial" and "parallel"
            LinLog={LinLog} // selector between "linear" and "logarithmic"
            Rm={Rm} // resistance
            Mm={Mm * 1e-3} // mass, pass value from [g] to [kg]
            Cm={Cm * 1e-3} // compliance, pass value from [mm/N] to [m/N]
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          {/* --------------------------------------------------*/}
          {/* radio buttons to choose between serial and parallel */}
          {/* --------------------------------------------------*/}

          <SwitchOption
            items={[{ name: "admittance" }, { name: "impedance" }]}
            activeItem={AdmImp == "admittance" ? 0 : 1}
            onClick={this.handleChangeAdm}
          />
          <SwitchOption
            items={[{ name: "linear" }, { name: "logarithmic" }]}
            activeItem={LinLog == "linear" ? 0 : 1}
            onClick={this.handleChangeLin}
          />
        </div>
        {/* --------------------------------------------------*/}
        {/* sliders */}
        {/* --------------------------------------------------*/}
        <div style={{ marginTop: 20 }} className="sliders-fullscreen">
          {this.sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default MechanicalSystem;
