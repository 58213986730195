import Chart from "chart.js";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./assets/styles/index.scss";

import App from "./components/App";

// Set default options for Chart.js
Chart.defaults.global.defaultFontFamily = "Mulish";
Chart.defaults.global.responsive = true;

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
