import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { sensitivity } from "./Geophone.helper";

class GeophoneGraph extends Component {
  render() {
    // List of inputs ("props") :
    // Rl ... load resistance [Ohm]
    // Re ... el. resistance [Ohm]
    // Le ... el. inductance [H]
    // Bl ... intrinsic sensitivity  [Tm]
    // Rm ... mech. resistance  [Ns/m]
    // Mm ... mass [kg]
    // Cm ... compliance [m/N]
    // LinLog ... "linear" or "logarithmic"
    const { Rl, Re, Le, Bl, Rm, Mm, Cm, LinLog } = this.props;

    // frequency axis
    const f_ax = LogSpace(0, 3, 500);

    // abs and phase
    const { absVal, phase } = sensitivity(Rl, Re, Le, Bl, Rm, Mm, Cm, f_ax, LinLog);
    const data = {
      title: "Geophone Sensitivity",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [absVal],
          yLabel: "Sensitivity (dB re. 1 V.s/m)",
          type: "linear",
        },
        {
          data: [phase],
          yLabel: "Phase (deg)",
          type: "linear",
          min: -180,
          max: 180,
        },
      ],
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={0.35} />;
  }
}
export default GeophoneGraph;
