import LessonData from "./Lesson0302.data";
import React, { Component, ReactFragment, ReactNode } from "react";
import { Slider, Badge, Button } from "@jelly/ui";
import { HeatmapWithAxis } from "../../components/common/heatmap/HeatmapWithAxis";
import Lesson0302Chart from "./Lesson0302.chart";
import { chartAnnotations, chartLabels, chartOptions } from "./ChartConfig";
import * as d3Scale from "d3-scale";

class Lesson0302b extends Component {
  private lessonData = new LessonData();
  private timer: NodeJS.Timer | null = null;
  private timeMax = 2;
  state = {
    x_s: this.lessonData.x_s,
    y_s: this.lessonData.y_s,
    x_r: this.lessonData.x_r,
    y_r: this.lessonData.y_r,
    n: this.lessonData.n,
    t_points: this.lessonData.t,
    t_index: 0,
    displayData: 0,
    isPlaying: false,
    annotations: chartAnnotations,
  };
  private sliders = {
    x_s: {
      name: "X<sub>s</sub>",
      min: -0.5,
      max: 0.5,
      step: 0.05,
    },
    y_s: {
      name: "Y<sub>s</sub>",
      min: 0,
      max: 1,
      step: 0.05,
    },
    x_r: {
      name: "X<sub>r</sub>",
      min: -0.5,
      max: 0.5,
      step: 0.05,
    },
    y_r: {
      name: "Y<sub>r</sub>",
      min: 0,
      max: 1,
      step: 0.05,
    },
  };

  updateParams = (key: string, value: number): void => {
    this.setState({ [key]: value });
  };

  tickTime = (): void => {
    let t_index = this.state.t_index;
    const t_points = this.state.t_points;

    t_index += 1;
    if (t_index > t_points.length) {
      //clearInterval(this.timer);
      t_index = 0;
    }
    this.setState({ t_index, annotations: { ...chartAnnotations, value: t_index } });
  };

  play = (): void => {
    this.setState({ time: 0, isPlaying: true });
    this.timer = setInterval(this.tickTime, 75);
  };

  pause = (): void => {
    if (this.timer) {
      clearInterval(this.timer);
      this.setState({ isPlaying: false });
      this.timer = null;
    }
  };

  reset = (): void => {
    this.pause();
    this.setState({ time: 0 });
  };

  handlePlayClick = (): void => {
    if (this.timer == null) {
      this.play();
    } else {
      this.pause();
    }
  };

  generateGraphData = (x_s: number, y_s: number, x_r: number, y_r: number): number[] => {
    //const points = this.lessonData.get_r_i
    const r_i_points = this.lessonData.get_r(x_s, y_s, x_r, y_r);

    const points = this.state.t_points.map(() => {
      return 0;
    });
    const step = this.lessonData.t_max_plot / points.length;
    const r_i_x = Math.round(r_i_points.r_i.x / step);
    const r_s_x = Math.round(r_i_points.r_s.x / step);
    points[r_i_x] = r_i_points.r_i.y;
    points[r_s_x] = r_i_points.r_s.y;
    return points;
  };

  getMaxR = (x_s: number, y_s: number, x_r: number, y_r: number): number => {
    //const points = this.lessonData.get_r_i
    const r_i_points = this.lessonData.get_r(x_s, y_s, x_r, y_r);

    return Math.max(r_i_points.r_i.y, r_i_points.r_s.y);
  };

  renderParamSlider = (key: "x_s" | "y_s" | "x_r" | "y_r"): ReactFragment => {
    const property = this.state[key];
    const config = this.sliders[key];
    return (
      <div style={{ display: "flex", flex: "1 1 48%", alignItems: "center", gap: "0em", paddingRight: "1em" }}>
        <div style={{ flex: "0", minWidth: "6em" }}>
          <Badge theme="secondary" size="small">
            {`${config.name} = ${property.toFixed(2)}`}
          </Badge>
        </div>
        <div style={{ flex: "1" }}>
          <Slider
            min={config.min}
            max={config.max}
            step={config.step}
            theme="primary"
            value={property}
            onChange={(value) => {
              this.updateParams(key, value);
            }}
          />
        </div>
      </div>
    );
  };

  render(): ReactNode {
    const { x_s, y_s, x_r, y_r, t_index, n, isPlaying, annotations, t_points } = this.state;
    const heatMap = this.lessonData.draw(x_s, y_s, x_r, y_r, t_points[t_index]);
    const graphMap = this.generateGraphData(x_s, y_s, x_r, y_r);
    const xScale = d3Scale.scaleLinear().domain([-0.5, 0.5]).range([0, n]);
    const yScale = d3Scale.scaleLinear().domain([0, 1]).range([0, n]);

    const heatmapProps = {
      width: n,
      height: n,
      data: heatMap,
      minValue: 0,
      maxValue: 4,
      //maxValue: this.getMaxR(x_s, y_s, x_r, y_r),
      switchXY: true,
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", flex: "1 1" }}>
        <div
          style={{ flex: "1 1 400px", display: "flex", justifyContent: "center", gap: "1em", alignItems: "baseline" }}
        >
          <div style={{ flex: "1 0 30%", display: "flex", justifyContent: "end" }}>
            <HeatmapWithAxis heatmapProps={heatmapProps} xScale={xScale} yScale={yScale} fullWidth={false}>
              <svg width={heatmapProps.width} height={heatmapProps.height}>
                <g color="#ff0000">
                  <circle cx={x_s * n + 0.5 * n} cy={y_s * n} r="5" fill="red" />
                </g>
                <g color="#0000ff">
                  <circle cx={x_r * n + 0.5 * n} cy={y_r * n} r="3" fill="green" />
                </g>
              </svg>
            </HeatmapWithAxis>
          </div>
          <div style={{ flex: "1 1 70%" }}>
            <Lesson0302Chart
              data={{
                labels: chartLabels,

                datasets: [
                  {
                    label: "Spring-Mass System",
                    backgroundColor: "red",
                    fill: false,
                    pointRadius: 0,
                    data: graphMap,
                    barThickness: 4,
                  },
                ],
              }}
              options={chartOptions}
              annotations={annotations}
            />
          </div>
        </div>
        <div style={{ flex: "0 1", width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            {this.renderParamSlider("x_s")}
            {this.renderParamSlider("x_r")}
            {this.renderParamSlider("y_s")}
            {this.renderParamSlider("y_r")}
          </div>
        </div>
        <div style={{ flex: "1 0", alignSelf: "flex-end" }}>
          <Button
            size="large"
            shape="round"
            icon={"cil-replay"}
            onClick={() => {
              this.reset();
            }}
          >
            Reset
          </Button>
          <Button
            size="large"
            shape="round"
            icon={!isPlaying ? "cil-media-play" : "cil-media-pause"}
            onClick={() => {
              this.handlePlayClick();
            }}
          >
            {!isPlaying ? "Play" : "Pause"}
          </Button>
        </div>
      </div>
    );
  }
}
//
export default Lesson0302b;
