import React, { Component } from "react";
import { COLORS, scale, scaleWithLimits, adjust_font_size } from "../../../../../../helpers/ant-novak/helpers";
// import { COLORS, scale, scaleWithLimits, adjust_font_size } from "../../../../helpers/helpers";
import { REFLECTION_TYPES } from "../settings/WaveGuide.reflection.jsx";

const Ly = 0.125;

// ------ CANVAS MAIN PROPERTIES ------------------------------------------------
class Drawing {
  constructor(obj) {
    this.context = obj.context;
    this.PADDING_TOP = 15; // in pixels
    this.PADDING_BOTTOM = 0; // in pixels
    this.PIXEL_TOLERANCE_LINE_DRAG = 5; // in pixels

    this.size = {
      width: obj.context.canvas.width,
      height: obj.context.canvas.height,
    };

    this.size_graph_pixels = {
      x_min: 0,
      x_max: obj.context.canvas.width,
      y_min: this.PADDING_TOP,
      y_max: obj.context.canvas.height - this.PADDING_TOP - this.PADDING_BOTTOM,
    };

    this.size_graph_meters = { x_min: -0.05, x_max: 1.2, y_min: -0.15, y_max: 0.15 };
  }

  setContext = (context) => {
    this.context = context;
  };

  // map functions between [meters] and [pixels]
  meters_to_pixels_x = (value) => {
    return scale(
      value,
      this.size_graph_meters.x_min,
      this.size_graph_meters.x_max,
      this.size_graph_pixels.x_min,
      this.size_graph_pixels.x_max
    );
  };
  meters_to_pixels_y = (value) => {
    return scale(
      value,
      this.size_graph_meters.y_min,
      this.size_graph_meters.y_max,
      this.size_graph_pixels.y_max,
      this.size_graph_pixels.y_min
    );
  };

  // map functions between [pixels] and [meters]
  pixels_to_meters_x = (value) => {
    return scale(
      value,
      this.size_graph_pixels.x_min,
      this.size_graph_pixels.x_max,
      this.size_graph_meters.x_min,
      this.size_graph_meters.x_max
    );
  };

  // map functions between [meters] and [pixels]
  pixels_to_meters_y = (value) => {
    return scale(
      value,
      this.size_graph_pixels.y_max,
      this.size_graph_pixels.y_min,
      this.size_graph_meters.y_min,
      this.size_graph_meters.y_max
    );
  };

  // update canvas size
  update_drawing_size = () => {
    this.size.width = this.context.canvas.width;
    this.size.height = this.context.canvas.height;

    this.size_graph_pixels.x_max = this.context.canvas.width;
    this.size_graph_pixels.y_max = this.context.canvas.height - this.PADDING_TOP - this.PADDING_BOTTOM;
  };

  // check if canvas size has changed (window has resized)
  drawing_size_has_changed = () => {
    if (this.size.width !== this.context.canvas.width || this.size.height !== this.context.canvas.height) {
      // size has changed
      return true;
    }
    return false;
  };
}

// ------ COLORMAP ------------------------------------------------
export class Colormap {
  constructor(obj) {
    Object.assign(this, obj.parent.properties.animation.colormap);
    this.parent = obj.parent; // parent object (WaveGuide)

    let colormaps = require("colormap");
    this.colormap = colormaps({
      colormap: this.name, // "RdBu", "picnic"
      nshades: this.nshades,
      format: "rgbaString",
      alpha: 1,
    });

    this.color_gradient = NaN; // will be assigned using update_color_gradient method
  }

  // color gradient for ColorBar
  updateColorGradient = () => {
    let drawing = this.parent.drawing;
    this.color_gradient = drawing.context.createLinearGradient(0, 0, 0, drawing.size_graph_pixels.y_max);
    for (let i = 0; i < this.nshades; i++) {
      this.color_gradient.addColorStop(
        ((i / this.nshades) * this.colormap.length) / (this.nshades - 1),
        this.colormap[this.nshades - i - 1]
      );
    }
  };

  getColor = (val, min_val, max_val) => {
    return this.colormap[Math.floor(scaleWithLimits(val, min_val, max_val, 0, this.nshades - 1, 0, this.nshades - 1))];
  };
}

// ------ GROUP OF POINTS ------------------------------------------------
class AirParticles {
  // each group is made of 5 particles with the same x coordinate and random y coordinate
  constructor(obj) {
    this.x = obj.x;
    this.y = [...Array(obj.particles_per_group).keys()].map(() => Ly * (2 * Math.random() - 1));
    this.dx = 0; // displacement (will be updated for animation)
    this.v = 0; // velocity (will be updated for animation)
    this.color = "rgba(0, 0, 0, 1)"; // (will be updated for animation)
    this.parent = obj.parent; // parent object (WaveGuide)
  }

  calculateDisplacement = (t) => {
    let { physics, signal } = this.parent.properties;
    let dx = physics.displacementFnc(signal, this.x, 1.0, t, physics, 0);

    // change each point dx according to displacement_zoom
    this.dx = dx * signal.displacement_zoom;
  };

  calculateArrowValue = (t) => {
    let { physics, signal } = this.parent.properties;
    this.v = physics.vectorFnc(signal, this.x, 1.0, t, physics, 0);
  };

  calculateColorValue = (t) => {
    let { physics, signal, animation } = this.parent.properties;

    let colormap = this.parent.colormap;

    if (animation.pressure_colors.show && this.isInRegion(animation.color_region)) {
      let value = physics.drawFnc(signal, this.x + this.dx, 1.0, t, physics, 0);
      let { value_max, value_min } = this.parent.properties.signal.drawMinMax();
      this.color = colormap.getColor(value, value_min, value_max);
    } else {
      this.color = "rgba(190, 190, 190, 1)";
      //this.color = colormap.getColor(0, 1);
    }
  };

  isInRegion = (region) => {
    return region.xmin <= this.x && this.x <= region.xmax;
  };

  drawPoint = (y) => {
    let canvas = this.parent.drawing;

    canvas.context.beginPath();
    canvas.context.setLineDash([]);
    canvas.context.arc(
      canvas.meters_to_pixels_x(this.x + this.dx),
      canvas.meters_to_pixels_y(y),
      2, // radius of points [pixels]
      0, // starting angle [rad]
      Math.PI * 2 // ending angle, [rad]
    );
    let clr = this.color;
    canvas.context.fillStyle = clr === undefined ? "rgba(0,0,0,1)" : clr;
    canvas.context.fill();
  };

  drawArrow = (y) => {
    let { size_ratio, color = COLORS[0] } = this.parent.properties.animation.velocity_arrows;
    let canvas = this.parent.drawing;
    canvas.context.beginPath();
    canvas.context.setLineDash([]);
    this.drawArrowFnc(
      canvas.context,
      canvas.meters_to_pixels_x(this.x + this.dx),
      canvas.meters_to_pixels_y(y),
      this.v * size_ratio,
      0
    );
    //let clr = this.color;
    //ctx.strokeStyle = clr === undefined ? "rgba(0,0,0,1)" : clr;
    canvas.context.strokeStyle = color;
    canvas.context.stroke();
  };

  drawArrowFnc = (context, x, y, dx, dy) => {
    var headlen = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)) / 3; // length of head in pixels
    var tox = x + dx;
    var toy = y + dy;
    var angle = Math.atan2(dy, dx);
    context.moveTo(x, y);
    context.lineTo(x + dx, y + dy);
    context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
    context.moveTo(tox, toy);
    context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
  };

  draw = () => {
    let { animation } = this.parent.properties;

    let isParticleInsideWaveguide = this.parent.box.isParticleInsideWaveguide;
    let densities = this.parent.properties.animation.points_density;

    let density = this.x < this.parent.x_interface ? densities[0] : densities[1];
    this.y.slice(0, density).map((y) => {
      if (isParticleInsideWaveguide({ x: this.x, y: y })) this.drawPoint(y);
      return null;
    });
    if (this.parent.properties.animation.velocity_arrows.show) {
      if (this.isInRegion(animation.arrow_region)) {
        if (isParticleInsideWaveguide({ x: this.x, y: this.y[0] })) this.drawArrow(this.y[0]);
        if (isParticleInsideWaveguide({ x: this.x, y: this.y[1] })) this.drawArrow(this.y[1]);
      }
    }
  };
}

// ------ MICROPHONE ------------------------------------------------
class Microphone {
  constructor(obj) {
    this.circle = null; // Path2D
    this.x = obj.x;
    this.y = obj.y;
    this.parent = obj.parent; // parent object (WaveGuide)
    this.r = NaN; // circle radius for microphone [pixels]
    //this.updateRadius();
  }

  draw = () => {
    let canvas = this.parent.drawing;

    this.circle = new Path2D();
    this.circle.arc(
      canvas.meters_to_pixels_x(this.x),
      canvas.meters_to_pixels_y(this.y),
      this.r,
      0,
      Math.PI * 2,
      false
    );
    canvas.context.fillStyle = "rgb(0,200,0)";
    canvas.context.fill(this.circle);
    canvas.context.lineWidth = 1;
    canvas.context.strokeStyle = "rgb(0,0,0)";
    canvas.context.stroke(this.circle);
  };

  mouseOver = (mouse_coordinates) => {
    return this.parent.drawing.context.isPointInPath(this.circle, mouse_coordinates.x, mouse_coordinates.y);
  };

  updateRadius = () => {
    this.r = Math.floor(this.parent.drawing.size.width / 100);
  };
}

// ------ COLORBAR ------------------------------------------------
class Colorbar {
  constructor(obj) {
    this.width = obj.width;
    this.colormap = obj.colormap;
    this.ticks_padding = obj.ticks_padding; // pixels
    this.parent = obj.parent; // parent object (WaveGuide)
  }

  draw = () => {
    let { value_max, value_min } = this.parent.properties.signal.drawMinMax();
    let canvas = this.parent.drawing;
    let context = canvas.context;

    if (this.parent.properties.animation.pressure_colors.show) {
      context.fillStyle = this.colormap.color_gradient;
      context.beginPath();
      context.setLineDash([]);
      context.rect(
        canvas.meters_to_pixels_x(1.08),
        canvas.size_graph_pixels.y_min,
        this.width,
        canvas.size_graph_pixels.y_max - canvas.size_graph_pixels.y_min
      );
      context.strokeStyle = "black";
      context.stroke();
      context.fill();

      // tick labels in vertical colormap axis
      context.font = adjust_font_size(this.parent.drawing.size.width, 12) + "px Helvetica";
      context.fillStyle = "rgb(0,0,0)";
      context.textAlign = "left";
      context.textBaseline = "middle";
      context.fillText(
        value_max + " " + this.parent.colormap.unit,
        canvas.meters_to_pixels_x(1.08) + this.width + this.ticks_padding,
        canvas.size_graph_pixels.y_min
      );
      if (this.parent.colormap.symmetric) {
        context.fillText(
          "0",
          canvas.meters_to_pixels_x(1.08) + this.width + this.ticks_padding,
          canvas.meters_to_pixels_y(0.0)
        );
      }
      context.fillText(
        value_min + " " + this.parent.colormap.unit,
        canvas.meters_to_pixels_x(1.08) + this.width + this.ticks_padding,
        canvas.size_graph_pixels.y_max
      );
    }
  };
}

class Box {
  constructor(obj) {
    this.parent = obj.parent; // parent object (WaveGuide)
  }

  isParticleInsideWaveguide = (particle) => {
    let radius = this.parent.properties.physics.radius.values;
    return (
      (particle.x < this.parent.x_interface && Math.abs(particle.y) < radius[0]) ||
      (particle.x >= this.parent.x_interface && Math.abs(particle.y) < radius[1])
    );
  };

  mouseOver = (mouse_coordinates, boundary_index) => {
    let { meters_to_pixels_y, pixels_to_meters_x, PIXEL_TOLERANCE_LINE_DRAG } = this.parent.drawing;
    let radius = this.parent.properties.physics.radius.values[boundary_index];

    let x = pixels_to_meters_x(mouse_coordinates.x);
    let dy1 = Math.abs(meters_to_pixels_y(radius) - mouse_coordinates.y);
    let dy2 = Math.abs(meters_to_pixels_y(-radius) - mouse_coordinates.y);
    let x_condition =
      boundary_index === 0 ? x > 0 && x < this.parent.x_interface : x > this.parent.x_interface && x < 1.0;
    let y_condition = Math.min(dy1, dy2) < PIXEL_TOLERANCE_LINE_DRAG;
    return x_condition && y_condition;
  };

  drawWhiteBox = (canvas, x1, y1, x2, y2) => {
    canvas.context.fillStyle = "white";
    canvas.context.fillRect(
      canvas.meters_to_pixels_x(x1),
      canvas.meters_to_pixels_y(y1),
      canvas.meters_to_pixels_x(x2) - canvas.meters_to_pixels_x(x1),
      canvas.meters_to_pixels_y(y2) - canvas.meters_to_pixels_y(y1)
    );
  };

  // -----------------------------------------------------
  // draw outside box representing the wave guide
  draw = () => {
    let { physics } = this.parent.properties;
    let x_min = 0.003;

    let radius = physics.radius.values;

    let canvas = this.parent.drawing;
    let context = canvas.context;

    context.lineWidth = 2;
    context.strokeStyle = "rgba(0,0,0,1)";

    this.drawWhiteBox(canvas, -x_min, radius[0], this.parent.x_interface, 1.0);
    this.drawWhiteBox(canvas, -x_min, -radius[0], this.parent.x_interface, -1.0);
    this.drawWhiteBox(canvas, this.parent.x_interface, radius[1], 1 + x_min, 1.0);
    this.drawWhiteBox(canvas, this.parent.x_interface, -radius[1], 1 + x_min, -1.0);

    context.beginPath();
    context.setLineDash([]);

    // upper lines
    context.moveTo(canvas.meters_to_pixels_x(-x_min), canvas.meters_to_pixels_y(radius[0]) - 1);
    context.lineTo(canvas.meters_to_pixels_x(this.parent.x_interface), canvas.meters_to_pixels_y(radius[0]) - 1);
    context.lineTo(canvas.meters_to_pixels_x(this.parent.x_interface), canvas.meters_to_pixels_y(radius[1]) - 1);
    context.lineTo(canvas.meters_to_pixels_x(1 + x_min), canvas.meters_to_pixels_y(radius[1]) - 1);

    // bottom lines
    context.moveTo(canvas.meters_to_pixels_x(-x_min), canvas.meters_to_pixels_y(-radius[0]) + 1);
    context.lineTo(canvas.meters_to_pixels_x(this.parent.x_interface), canvas.meters_to_pixels_y(-radius[0]) + 1);
    context.lineTo(canvas.meters_to_pixels_x(this.parent.x_interface), canvas.meters_to_pixels_y(-radius[1]) + 1);
    context.lineTo(canvas.meters_to_pixels_x(1 + x_min), canvas.meters_to_pixels_y(-radius[1]) + 1);

    let closed_left =
      (physics.closed || physics.reflection?.type === REFLECTION_TYPES.NEUMANN.value) && physics.reflection?.x === 0.0;
    if (closed_left) {
      context.moveTo(canvas.meters_to_pixels_x(-x_min), canvas.meters_to_pixels_y(radius[0]) - 1);
      context.lineTo(canvas.meters_to_pixels_x(-x_min), canvas.meters_to_pixels_y(-radius[1]) + 1);
    }

    let closed_right = physics.reflection?.type === REFLECTION_TYPES.NEUMANN.value && physics.reflection?.x === 1.0;
    if (closed_right) {
      context.moveTo(canvas.meters_to_pixels_x(1 + x_min), canvas.meters_to_pixels_y(radius[0]) - 1);
      context.lineTo(canvas.meters_to_pixels_x(1 + x_min), canvas.meters_to_pixels_y(-radius[1]) + 1);
    }

    context.stroke();

    let dashed_right =
      physics.open_right || (physics.reflection?.type === REFLECTION_TYPES.NO.value && physics.reflection?.x === 1.0);
    // dashed open right
    if (dashed_right) {
      context.beginPath();
      context.setLineDash([5, 5]);
      context.moveTo(canvas.meters_to_pixels_x(1), canvas.meters_to_pixels_y(radius[1]) - 1);
      context.lineTo(canvas.meters_to_pixels_x(1 + 0.05), canvas.meters_to_pixels_y(radius[1]) - 1);
      context.moveTo(canvas.meters_to_pixels_x(1), canvas.meters_to_pixels_y(-radius[1]) + 1);
      context.lineTo(canvas.meters_to_pixels_x(1 + 0.05), canvas.meters_to_pixels_y(-radius[1]) + 1);
      context.stroke();
    }

    let dashed_left =
      physics.open_left || (physics.reflection?.type === REFLECTION_TYPES.NO.value && physics.reflection?.x === 0.0);
    // dashed open left
    if (dashed_left) {
      context.beginPath();
      context.setLineDash([5, 5]);
      context.moveTo(canvas.meters_to_pixels_x(0), canvas.meters_to_pixels_y(radius[0]) - 1);
      context.lineTo(canvas.meters_to_pixels_x(-0.05), canvas.meters_to_pixels_y(radius[0]) - 1);
      context.moveTo(canvas.meters_to_pixels_x(0), canvas.meters_to_pixels_y(-radius[0]) + 1);
      context.lineTo(canvas.meters_to_pixels_x(-0.05), canvas.meters_to_pixels_y(-radius[0]) + 1);
      context.stroke();
    }

    // drag the boundaries
    context.font = adjust_font_size(this.parent.drawing.size.width, 12) + "px Helvetica";
    context.fillStyle = "rgb(0,0,0)";
    context.textAlign = "center";

    if (this.parent.properties.physics.radius.can_be_modified) {
      context.fillText(
        "drag the boundary (r = " + Math.round(radius[0] * 1000) / 1000 + " m)",
        canvas.meters_to_pixels_x(0.25),
        canvas.meters_to_pixels_y(radius[0]) - 5
      );
      context.fillText(
        "drag the boundary (r = " + Math.round(radius[1] * 1000) / 1000 + " m)",
        canvas.meters_to_pixels_x(0.75),
        canvas.meters_to_pixels_y(radius[1]) - 5
      );
    }

    // tick labels on x-axis
    context.fillText("x = 0 m", canvas.meters_to_pixels_x(0), canvas.meters_to_pixels_y(-Ly) + 15);
    context.fillText("x = 1 m", canvas.meters_to_pixels_x(1.0), canvas.meters_to_pixels_y(-Ly) + 15);
  };
}

class HighlightBox {
  constructor(obj) {
    this.parent = obj.parent; // parent object (WaveGuide)
    this.region = obj.region;
  }

  draw = () => {
    let canvas = this.parent.drawing;
    let context = canvas.context;

    context.lineWidth = 2;
    context.strokeStyle = COLORS[1];

    context.beginPath();
    context.setLineDash([]);
    // upper lines
    context.moveTo(canvas.meters_to_pixels_x(this.region.xmin), canvas.meters_to_pixels_y(Ly) + 1);
    context.lineTo(canvas.meters_to_pixels_x(this.region.xmax), canvas.meters_to_pixels_y(Ly) + 1);
    context.lineTo(canvas.meters_to_pixels_x(this.region.xmax), canvas.meters_to_pixels_y(-Ly) - 1);
    context.lineTo(canvas.meters_to_pixels_x(this.region.xmin), canvas.meters_to_pixels_y(-Ly) - 1);
    context.lineTo(canvas.meters_to_pixels_x(this.region.xmin), canvas.meters_to_pixels_y(Ly) + 1);

    context.stroke();
  };
}

// Waveguide animation class that includes
// - box around
// - points that moves acording to source and phys. equations
export class MainCanvas {
  constructor(properties) {
    this.properties = properties;
    this.x_interface = properties.physics.reflection?.x ?? 0.5;

    // objects
    this.drawing = null; // canvas context is added in this.setContext() called from componentDidMount
    this.points = this.createNewPoints(); // canvas points representing gas particles inside the waveguide
    if (properties.microphone.show) {
      this.microphone = new Microphone({ x: properties.microphone.x, y: 0, parent: this });
    }
    this.colormap = new Colormap({ parent: this });
    this.colorbar = new Colorbar({
      width: 10,
      colormap: this.colormap,
      ticks_padding: 5,
      parent: this,
    });
    this.box = new Box({ parent: this });

    this.arrow_box = new HighlightBox({
      parent: this,
      region: this.properties.animation.arrow_region,
    });

    // time
    this.t = 0;
  }

  // -----------------------------------------------------
  // create an array of new points representing gas inside the waveguide
  createNewPoints = () => {
    let { animation } = this.properties;

    return [...Array(animation.num_of_particles.groups).keys()].map((i) => {
      return new AirParticles({
        x: i / animation.num_of_particles.groups, // x-coordinate of particles
        particles_per_group: animation.num_of_particles.particles_per_group,
        colormap: animation.colormap,
        parent: this,
      });
    });
  };

  setContext = (context) => {
    this.drawing = new Drawing({ context: context });
  };

  addMouseEvents = () => {
    let canvas = this.drawing.context.canvas;

    // ------ mouse up
    canvas.onmouseup = (e) => {
      canvas.onmousemove = null;
    };

    // ------ mouse down
    canvas.onmousedown = (e) => {
      // get the mouse click coordinates x, y [pixels]
      const rect = canvas.getBoundingClientRect();
      let mouse_coordinates = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };

      if (this.properties.physics.radius.can_be_modified) {
        this.mouseOverBoundary(mouse_coordinates, 0);
        this.mouseOverBoundary(mouse_coordinates, 1);
      }
      if (this.properties.microphone.show) {
        this.mouseOverMicrophone(mouse_coordinates);
      }
    };
  };

  mouseOverBoundary = (mouse_coordinates, boundary_index) => {
    let drawing = this.drawing;
    let canvas = drawing.context.canvas;

    // if click on boundary
    if (this.box.mouseOver(mouse_coordinates, boundary_index)) {
      // attach a function to mouse event
      const rect = canvas.getBoundingClientRect();
      canvas.onmousemove = (e) => {
        // new mouse coordinates inside canvas [in pixels]
        let y = e.clientY - rect.top;

        // new mouse coordinates inside canvas [in meters]
        let y_meters = Math.abs(drawing.pixels_to_meters_y(y));

        // limit x-coordinates to [0, 1.0]
        this.properties.physics.radius.values[boundary_index] = Math.max(0.01, Math.min(y_meters, Ly));

        this.properties.flag_recalculate = true;
      };
    }
  };

  mouseOverMicrophone = (mouse_coordinates) => {
    let drawing = this.drawing;
    let canvas = drawing.context.canvas;

    // if click on microphone
    const rect = canvas.getBoundingClientRect();
    if (this.microphone.mouseOver(mouse_coordinates)) {
      // attach a function to mouse event
      canvas.onmousemove = (e) => {
        // new mouse coordinates inside canvas [in pixels]
        let x = e.clientX - rect.left;

        // new mouse coordinates inside canvas [in meters]
        let x_meters = drawing.pixels_to_meters_x(x);

        // limit x-coordinates to [0, 1.0]
        this.microphone.x = Math.max(0, Math.min(x_meters, 1.0));
        //this.microphone.y = Math.max(0, Math.min(y_meters, 1));

        // update microphone position in properties
        this.properties.microphone.x = this.microphone.x;
      };
    }
  };

  // -----------------------------------------------------
  // calculate new state
  recalculate = (t) => {
    // slow down the time by animation rate
    t /= this.properties.animation.animation_rate;

    // update for each point
    this.points.forEach((point) => {
      point.calculateDisplacement(t);
      point.calculateArrowValue(t);
      point.calculateColorValue(t);
    });

    this.t = t;
  };

  //   drawColorBackground = () => {
  //     let canvas = this.drawing;
  //     //canvas.context.fillStyle = COLORS[1];
  //     canvas.context.fillStyle = "#F0F0F0";
  //     canvas.context.fillRect(
  //       canvas.meters_to_pixels_x(-0.05),
  //       canvas.meters_to_pixels_y(-1),
  //       canvas.meters_to_pixels_x(x_interface) - canvas.meters_to_pixels_x(-0.05),
  //       canvas.meters_to_pixels_y(1) - canvas.meters_to_pixels_y(-1)
  //     );
  //   };

  draw = () => {
    // update size of canvas and all canvas dimensions
    if (this.drawing.drawing_size_has_changed()) {
      this.drawing.update_drawing_size();
      this.colormap.updateColorGradient();
      if (this.properties.microphone.show) {
        this.microphone.updateRadius();
      }
    }

    // draw all the content
    //this.drawColorBackground();

    // draw each point
    for (let i = 0; i < this.points.length; i++) {
      this.points[i].draw();
    }

    // draw microphone
    if (this.properties.microphone.show) {
      this.microphone.draw();
    }

    // draw box
    this.box.draw();

    // draw colorbar
    this.colorbar.draw();

    // draw highlight box
    if (this.properties.animation.arrow_region.box) {
      this.arrow_box.draw();
    }
  };
}

class WaveGuideCanvas extends Component {
  constructor(props) {
    super();
    this.wave_guide_canvas = new MainCanvas(props.properties);
  }

  componentDidMount() {
    // prepare canvas and mouse events
    this.wave_guide_canvas.setContext(this.refs.canvas.getContext("2d"));
    if (this.props.properties.microphone.show) {
      this.wave_guide_canvas.microphone.updateRadius();
    }
    this.wave_guide_canvas.addMouseEvents();
  }

  componentDidUpdate() {
    if (this.props.properties.microphone.show) {
      this.wave_guide_canvas.microphone.x = this.props.properties.microphone.x;
    }
    this.wave_guide_canvas.recalculate(this.props.t);
    this.updateCanvas();
  }

  updateCanvas() {
    // update canvas width (reactive)
    this.refs.canvas.style.width = "100%";
    this.refs.canvas.width = this.refs.canvas.offsetWidth;
    this.refs.canvas.height = this.props.properties.animation.canvas_ratio * this.refs.canvas.offsetWidth;
    this.wave_guide_canvas.draw();
  }

  render() {
    return (
      <div style={{ marginLeft: "5.5%" }}>
        <canvas ref="canvas" className="canvas" />
        {/*{this.props.properties.microphone.show ? (
          <p style={{ fontSize: "80%" }}> drag the microphone (green circle) along x-axis</p>
        ) : (
          ""
        )}*/}
      </div>
    );
  }
}

export default WaveGuideCanvas;
