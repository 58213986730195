import Lesson0312Data from "./Lesson0312.data";
import React, { Component, ReactFragment, ReactNode } from "react";
import { Slider, Badge } from "@jelly/ui";
import { HeatmapWithAxis } from "../../components/common/heatmap/HeatmapWithAxis";
import { ColorLegend } from "../../components/common/heatmap/ColorLegend";
import { interpolateHslLong } from "d3-interpolate";
import * as d3Scale from "d3-scale";

//const colorFunction = interpolateHslLong("#00007e", "#890000");
const colorFunction = interpolateHslLong("blue", "red");

class Lesson0312 extends Component {
  private sliders = {
    horizontalHeightBarrier: {
      name: "Horizontal height barrier",
      min: 0,
      max: 5,
      step: 0.1,
    },
    verticalHeightBarrier: {
      name: "Vertical height barrier",
      min: 0,
      max: 5,
      step: 0.1,
    },
  };
  private lessonData = new Lesson0312Data();
  private res = 0.5;
  private map_length_meter = 50;
  private f = 1000;
  private h_r = 1.0;
  private h_s = 0.5;

  private x_distance_cars = 100;
  private x_speed_cars_kmph = 130;
  private x_line_source_position = 50 / 2;
  //private x_height_barrier = 0;

  private y_distance_cars = 200;
  private y_speed_cars_kmph = 100;
  private y_line_source_position = 40 / 2;
  //private y_height_barrier = 0;

  state = {
    verticalHeightBarrier: 0,
    horizontalHeightBarrier: 0,
  };

  updateParams = (key: string, value: number): void => {
    this.setState({ [key]: value });
  };

  renderParamSlider = (key: "verticalHeightBarrier" | "horizontalHeightBarrier"): ReactFragment => {
    const property = this.state[key];
    const config = this.sliders[key];
    return (
      <div style={{ display: "flex", flex: "1 1 48%", alignItems: "center", gap: "0em", paddingRight: "1em" }}>
        <div style={{ flex: "0", minWidth: "12em" }}>
          <Badge theme="secondary" size="small">
            {`${config.name} = ${property.toFixed(2)}`}
          </Badge>
        </div>
        <div style={{ flex: "1" }}>
          <Slider
            min={config.min}
            max={config.max}
            step={config.step}
            theme="primary"
            value={property}
            onChange={(value) => {
              this.updateParams(key, value);
            }}
          />
        </div>
      </div>
    );
  };

  render(): ReactNode {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { x_coords, y_coords, mat, x_car_sound_power, y_car_sound_power } =
      this.lessonData.calculate_sound_pressure_level(
        this.res,
        this.map_length_meter,
        this.x_distance_cars,
        this.x_speed_cars_kmph,
        this.x_line_source_position,
        this.y_distance_cars,
        this.y_speed_cars_kmph,
        this.y_line_source_position,
        this.state.horizontalHeightBarrier,
        this.state.verticalHeightBarrier,
        this.f,
        this.h_r,
        this.h_s
      );

    const mat_db = mat;
    for (let i = 0; i < x_coords.length; i++) {
      for (let j = 0; j < y_coords.length; j++) {
        mat_db[i][j] = this.lessonData.convert_energy_to_db(mat[i][j]);
      }
    }
    const grid = this.lessonData.reshapeArray(mat_db, x_coords.length);
    const heatmapProps = {
      width: x_coords.length,
      height: y_coords.length,
      data: grid,
      maxValue: 40,
      minValue: 10,
      referenceMaxValue: 40,
      colorFunction,
      switchXY: true,
    };
    const xScale = d3Scale.scaleLinear().domain([0, 50]).range([0, x_coords.length]);
    const yScale = d3Scale.scaleLinear().domain([0, 50]).range([0, y_coords.length]);
    return (
      <div style={{ display: "flex", flexDirection: "column", flex: "1", height: "100%" }}>
        <div style={{ flex: "1 0", display: "flex", justifyContent: "center", gap: "1em", alignItems: "stretch" }}>
          <div style={{ flex: 1 }}>
            <HeatmapWithAxis heatmapProps={heatmapProps} xScale={xScale} yScale={yScale} fullWidth={false} />
          </div>
          <div style={{ flex: 1 }}>
            <ColorLegend
              width={40}
              height={300}
              minValue={heatmapProps.minValue}
              maxValue={heatmapProps.maxValue}
              colorFunction={heatmapProps.colorFunction}
              upsideDown={true}
            />
          </div>
        </div>
        <div style={{ flex: "1 1", width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            {this.renderParamSlider("verticalHeightBarrier")}
            {this.renderParamSlider("horizontalHeightBarrier")}
          </div>
        </div>
      </div>
    );
  }
}
export default Lesson0312;
