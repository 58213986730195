import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { sensitivity } from "./ClosedBoxSystem.helper";

class ClosedBoxSystemGraph extends Component {
  render() {
    const { Vmult, Rmult } = this.props;

    // frequency axis
    const f_ax = LogSpace(1, Math.log10(1e3), 100);

    const { Z, P, Z_baf, P_baf } = sensitivity(Vmult, Rmult, f_ax);

    // graph data
    const dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Z, Z_baf],
          label: ["closed-box", "baffled"],
          yLabel: "Input impedance (abs)",
          type: "linear",
        },
      ],
      animation: 0,
    };

    const dataSensitivity = {
      title: "SPL @2.83V @1m",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [P, P_baf],
          yLabel: "Sound Pressure Level (dB SPL)",
          label: ["closed-box", "baffled"],
          type: "linear",
          min: 60,
          max: 100,
        },
      ],
    };

    return (
      <>
        <LineGraph data={dataImpedance} graph_size_ratio={0.4} />
        <LineGraph data={dataSensitivity} graph_size_ratio={0.4} />
      </>
    );
  }
}
export default ClosedBoxSystemGraph;
