import React, { useState, useEffect } from "react";
import SliderEdit from "../../../SliderEdit";
// import SliderEdit from "../../../UI/SliderEdit";
import { Dropdown } from "@jelly/ui";
const SLIDERS = [
  {
    title: "rho",
    name: "rho",
    unit: "kg/m^3",
    min: 1,
    max: 10000,
    step: 0.1,
    log: true,
    edit: false,
  },
  {
    title: "c0",
    name: "c0",
    unit: "m/s",
    min: 100,
    max: 5000,
    step: 1,
    edit: false,
  },
];

const TITLES = ["Left medium", "Right medium"];

export default function WaveGuideMedium(props) {
  let { properties, idx } = props;

  const [medium_selected, setMedium] = useState(0);
  const [rho, setRho] = useState(1);
  const [c0, setc0] = useState(1);

  const handleChangeSlider = (name, value) => {
    if (name === "rho") setRho(value);
    if (name === "c0") setc0(value);
  };

  useEffect(() => {
    properties.physics.rho[idx] = rho;
    properties.physics.c0[idx] = c0;
    properties.flag_recalculate = true;
  }, [rho, c0, idx]);

  useEffect(() => {
    //   air
    if (medium_selected === 0) {
      setRho(1.2);
      setc0(340);
    }

    // water
    if (medium_selected === 1) {
      setRho(998);
      setc0(1500);
    }
  }, [medium_selected]);

  SLIDERS[0].value = rho;
  SLIDERS[1].value = c0;

  return (
    <div style={{ fontSize: "70%", flex: "1" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "1em", justifyContent: "center" }}>
        <div style={{ fontWeight: "700" }}>{TITLES[idx]}</div>
        <div>
          <Dropdown
            options={[
              { children: "Air", value: 0 },
              { children: "Water", value: 1 },
              { children: "User defined", value: 2 },
            ]}
            listSize="small"
            value={medium_selected}
            onChange={(value) => setMedium(Number(value))}
          />
        </div>
      </div>

      {/* if "user defined" medium is selected show sliders to define it */}

      <div style={{ display: "inline" }}>
        {SLIDERS.map((slider) => {
          return (
            <SliderEdit
              key={slider.name}
              onChange={handleChangeSlider}
              params={slider}
              val={slider.value}
              disabled={medium_selected !== 2}
            />
          );
        })}
      </div>
    </div>
  );
}
