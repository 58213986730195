/* eslint-disable */
// @ts-nocheck

import { round } from "mathjs";

// SetGraphTicks: function to keep only 1, 2, and 5 multiples on ticks of a graph (chart.js)
export function SetYaxisTicks(value, lin_log, scale) {
  let val = round(value, 12);
  if (lin_log === "logarithmic") {
    if (
      !(
        Number.isInteger(Math.log10(value / 1)) ||
        Number.isInteger(Math.log10(value / 2)) ||
        Number.isInteger(Math.log10(value / 5))
      )
    ) {
      // any number that is not multiple of 1, 2, 5
      val = "";
    }
  }
  if (scale === "log") {
    if (Number.isInteger(value)) {
      val = "1e" + value;
    } else {
      val = "";
    }
  }
  return val;
}

// SetPolarTicks: function to keep only multiples of ten ticks of a polar graph (chart.js)
export function SetPolarTicks(properties) {
  let { value, step, full } = properties;
  if (value > 180) {
    value -= 360;
  }
  let val = value;
  let val_limit = full === true ? true : value > 90 || value < -90;
  if (!Number.isInteger(value / step) || val_limit) {
    // any number that is not multiple of step
    val = "";
  } else {
  }
  return val;
}

export function formatNumber(x) {
  if (x === 0) {
    return 0;
  }
  const base10 = Math.log10(Math.abs(x));
  if (base10 > 2) {
    return Math.round(x);
  }
  const decimalPlaces = 2 - Math.floor(base10);
  return Number(Math.round(x + "e" + decimalPlaces) + "e-" + decimalPlaces);
}
