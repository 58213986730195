/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import FourierGraph from "./Fourier.graph";
import FourierAudio from "./Fourier.audio";
import "./Fourier.scss";

// -------------------------
// Several Sine Waves mixed together (Fourier Analysis):
// Signal = Sum of oscilators tuned to frequency f and their integer mutliples
//          Each oscilator has its own
//   - amplitude A (slider variable),
//   - phase init_phase (slider variable).
//   -
// -------------------------

const myAudio = {
  freq: 1000,
  osc: [
    { A: 1.0, init_phase: 0, last_phase: 0 },
    { A: 1.0, init_phase: 0, last_phase: 0 },
    { A: 1.0, init_phase: 0, last_phase: 0 },
  ],
};

class Fourier extends Component {
  state = {
    osc: myAudio.osc,
    freq: myAudio.freq,
  };

  // sliders template
  slider_amplitude = {
    unit: "",
    min: 0,
    max: 1.0,
    step: 0.01,
  };
  slider_phase = {
    unit: "rad",
    min: 0,
    max: 6.28,
    step: 0.01,
  };
  slider_frequency = {
    name: "freq",
    title: "Frequency",
    unit: "Hz",
    min: 100,
    max: 5000,
    step: 0.001,
    log: true,
  };

  // handle for slider_frequency
  frequencyChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: Number(Math.round(value)) });
    }
  };

  // handle for sliders (Amplitude and Phase)
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      const idx = Number(name[1]);
      const amplitude_or_phase = name[0];

      const new_osc = [...this.state.osc];
      if (amplitude_or_phase === "A") {
        // amplitude change
        new_osc[idx] = { ...new_osc[idx], A: Number(value) };
      } else {
        // phase change
        new_osc[idx] = { ...new_osc[idx], init_phase: Number(value) };
      }
      this.setState({ osc: new_osc });
    }
  };

  renderBlock() {
    const { osc, freq } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        {/* ----------------------Graph----------------------------*/}
        <div style={{ flex: "1" }}>
          <FourierGraph osc={osc} freq={freq} />
        </div>
        <div className="parameters">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div className="parameters">
            <div className="parameters-primary">
              {/* frequeny slider */}

              <div className="slider-block sliders-fullscreen">
                <SliderEdit onChange={this.frequencyChange} params={this.slider_frequency} val={this.state.freq} />
              </div>
              <div>
                <FourierAudio osc={osc} freq={freq} />
              </div>
            </div>
            <div className="parameters-secondary">
              {this.state.osc.map((osc, index) => {
                /* sliders (amplitude and phase) for each oscillator */
                return (
                  <div key={index} className={["slider-block"]}>
                    <div className="label">
                      harmonic {index + 1} (f = {(index + 1) * this.state.freq} Hz)
                    </div>
                    {/* amplitude slider */}
                    <SliderEdit
                      onChange={this.handleChange}
                      params={{
                        ...this.slider_amplitude,
                        name: "A" + String(index),
                        title: "Amplitude",
                      }}
                      val={osc.A}
                    />
                    {/* phase slider */}
                    <SliderEdit
                      onChange={this.handleChange}
                      params={{
                        ...this.slider_phase,
                        name: "P" + String(index),
                        title: "Phase",
                      }}
                      val={osc.init_phase}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}

export default Fourier;
