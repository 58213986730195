import { useEffect, useState } from "react";

export const useObserveSizeOf = (domNode: HTMLElement | undefined): [number, number] => {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    if (!domNode) {
      return;
    }
    /* eslint-disable compat/compat */
    const obs = new ResizeObserver(() => setSize([domNode.offsetWidth, domNode.offsetHeight]));
    obs.observe(domNode);

    return () => obs.disconnect();
  }, [domNode]);

  return size;
};
