import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { impedance_admittance } from "./MechanicalSystem.helper";

class MechanicalSystemGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Rm [Ns/m] ... mechanical resistance
    //   Mm [kg] ... moving mass
    //   C [m/N] ... compliance
    //   LinLog ... "linear" or "logarithmic"
    //   AdmImp ... "admittance" or "impedance"
    const { Rm, Mm, Cm, LinLog, AdmImp } = this.props;

    // frequency axis
    const f_ax = LogSpace(1, Math.log10(20e3), 500);

    // impedance/admittance, abs and phase
    const { absVal, phase } = impedance_admittance(Rm, Mm, Cm, AdmImp, f_ax);
    const data = {
      title: AdmImp === "admittance" ? "Mechanical admittance V/F  (m/Ns)" : "Mechanical impedance F/V  (Ns/m)",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [absVal],
          yLabel: "Absolute value",
          type: LinLog,
        },
        {
          data: [phase],
          yLabel: "Phase (deg)",
          type: "linear",
          min: -100,
          max: 100,
        },
      ],
      tooltip: true,
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={0.5} />;
  }
}
export default MechanicalSystemGraph;
