/* eslint-disable */
// @ts-nocheck
import { Button, SwitchOption } from "@jelly/ui";
import React, { Component, ReactNode } from "react";
import AmbisonicsPanner from "./Ambisonics/AmbisonicsPanner";
import AmbisonicEncoder from "./Ambisonics/AmbisonicsEncoder";
import AmbisonicsLoudspeakerDecoder from "./Ambisonics/AmbisonicsLoudspeakerDecoder";

class Lesson0324 extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      isPlaying: false,
    };
  }

  handleTabChange = (value) => {
    this.setState({ tab: value, isPlaying: false });
  };
  handlePlayChange = (value) => {
    this.setState({ isPlaying: !this.state.isPlaying });
  };
  handleRemotePlayChange = (value) => {
    this.setState({ isPlaying: value });
  };

  render(): ReactNode {
    const { tab, isPlaying } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "90%", alignItems: "center" }}>
        <div style={{ flex: "1 0 90%", display: "flex", justifyContent: "center" }}>
          <div>
            {tab == 0 && <AmbisonicEncoder isPlaying={isPlaying} playChange={this.handleRemotePlayChange} />}
            {tab == 1 && (
              <AmbisonicsLoudspeakerDecoder isPlaying={isPlaying} playChange={this.handleRemotePlayChange} />
            )}
            {tab == 2 && <AmbisonicsPanner isPlaying={isPlaying} playChange={this.handleRemotePlayChange} />}
          </div>
        </div>
        <div style={{ flex: "1 1 10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <SwitchOption
            activeItem={tab}
            items={[{ name: "Encoding" }, { name: "Loudspeaker decoding" }, { name: "Binaural decoding" }]}
            onClick={this.handleTabChange}
            size="small"
          />
          <Button onClick={this.handlePlayChange} icon={isPlaying ? "cil-media-pause" : "cil-media-play"}>
            {isPlaying ? "Pause" : "Play"}
          </Button>
        </div>
      </div>
    );
  }
}
export default Lesson0324;
