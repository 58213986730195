/* eslint-disable */
// @ts-nocheck

import React, { useState } from "react";
import "./SliderEdit.css";
import { round } from "../../../helpers/ant-novak/math.helper";
import { Slider, Badge } from "@jelly/ui";

// slider with editable field
// input properties:
// ... onChange = function to be called on change of slider or edit field
// ... params = { title      ... text placed in front of the slider
//               name       ... of the variable to be modified
//               unit       ... text placed after the shown number (abbrev. for physical units)
//               min        ... minumum value of the slider (Math.log10(min) if log=true)
//               max        ... maximum value of the slider (Math.log10(max) if log=true)
//               step       ... step (rounding)
//               log        ... boolean (is the silider in log scale)
//               conversion ... conversion coefficients of units (e.g. 2.54 for inch to cm)
//             }
// ... val = initial value
// ... group = name of the object where "val" is placed (necessary for the onChange callback)
//             if undefined, "val" is inside "state" object of react component

function setDefaultParams(params) {
  params.title = params.title ?? params.name;
  params.log = params.log ?? false;
  params.conversion_units = params.conversion_units ?? "";
  params.minimum = params.log ? Math.log10(params.min) : params.min;
  params.maximum = params.log ? Math.log10(params.max) : params.max;
  return params;
}

function conversion(parameters, value) {
  let return_value = "";
  if (parameters.conversion !== undefined) {
    return_value = " (" + round(parameters.conversion * value) + " (" + parameters.conversion_units + "))";
  }
  return return_value;
}

const SliderEdit = (props) => {
  const { onChange, params, val, group } = props;

  const [parameters] = useState(() => {
    // when initialized set default parameters
    return setDefaultParams(params);
  });

  let labelUnit = " ";
  if (parameters.unit != "") {
    labelUnit = ` ${parameters.unit} `;
  }
  let badge_title = `${parameters.title} = ${round(val).toFixed(2) + labelUnit + conversion(parameters, val)}`;

  return (
    <div style={{ display: "flex" }} className="waveguide-amplitude-main">
      <div className="waveguide-amplitude-block">
        <Badge theme="secondary" size="standard">
          {badge_title}
        </Badge>
      </div>
      <div className="waveguide-amplitude-block-long">
        <Slider
          theme="primary"
          onChange={(value) => {
            const new_val = parameters.log === true ? Math.pow(10, value) : value;
            onChange(parameters.name, new_val, group);
          }}
          min={parameters.minimum}
          max={parameters.maximum}
          value={parameters.log ? Math.log10(val) : val}
          step={parameters.step}
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default SliderEdit;
