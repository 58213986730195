import React, { Component } from "react";

class ImageViewer extends Component {
  // Preload images at the earliest possible lifecycle event
  componentDidMount() {
    this.props.images.forEach((imageGroup) => {
      imageGroup.forEach((src) => {
        const img = document.createElement("img");
        img.src = src; // Assigning the img src immediately requests the image
      });
    });
  }

  render() {
    return (
      <div>
        <img
          src={this.props.images[this.props.activeImageGroup][this.props.activeImageIndex]}
          width={this.props.width}
          alt={this.props.alt}
        />
      </div>
    );
  }
}

export default ImageViewer;
