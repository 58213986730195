import { Slider, Spinner } from "@jelly/ui";
import config from "appConfig";
import "chartjs-plugin-annotation";
import React, { useEffect, useState } from "react";
import useNumpy from "../../hooks/useNumpy";
import { chartAnnotations, chartLabels, chartOptions } from "./ChartConfig";
import { calculateParameters, parametersToKey, roundToPrecision } from "./helpers";

import styles from "./Lesson0302.module.scss";
import image from "../../assets/lesson/0302/animation.png";
import Lesson0302Chart from "./Lesson0302Chart";

const renderLoader = (): React.ReactElement => {
  return (
    <div className={styles.loader}>
      <Spinner />
    </div>
  );
};

const Lesson0302a: React.FC = () => {
  const [massParam, setMassParam] = useState<number>(0.3);
  const [kParam, setKParam] = useState<number>(20);
  const [tauParam, setTauParam] = useState<number>(0.2);
  const [xiParam, setXiParam] = useState<number>(1);
  const [calcParameters, setCalcParameters] = useState<Record<string, number[]>>({});
  const [chartData, setChartData] = useState<number[]>([]);
  const [annotations, setAnnotations] = useState(chartAnnotations);

  const numpyData = useNumpy(`${config.ASSETS_URL}/python-data/0302/dataJS.npy`);
  const numpyParams = useNumpy(`${config.ASSETS_URL}/python-data/0302/params.npy`);
  const numpyIsReady = numpyData && numpyParams;

  const handleChartDataUpdate = (): void => {
    const massValue = roundToPrecision(massParam, 1);
    const kValue = roundToPrecision(kParam, 0);
    const tauValue = roundToPrecision(tauParam, 1);
    const xiValue = roundToPrecision(xiParam, 1);

    const key = parametersToKey(massValue, kValue, tauValue, xiValue);
    const result = calcParameters[key];

    if (!result) {
      // No data to be updated.
      return;
    }

    setChartData(result);
  };

  useEffect(() => {
    if (numpyIsReady) {
      // Don't re-run this function on controls change!
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setCalcParameters(calculateParameters(numpyParams.data, numpyData.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numpyIsReady]);

  useEffect(() => {
    setAnnotations({ ...annotations, value: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    handleChartDataUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcParameters, massParam, kParam, tauParam, xiParam, annotations]);

  if (!numpyIsReady) {
    // Wait for Numpy fetch and conversion.
    return renderLoader();
  }

  return (
    <div className={styles.lesson0302}>
      <div className={styles.chartContainer}>
        <Lesson0302Chart
          data={{
            labels: chartLabels,
            datasets: [
              {
                label: "Spring-Mass System",
                borderColor: "#FF6040",
                fill: false,
                pointRadius: 0,
                data: chartData,
              },
            ],
          }}
          options={chartOptions}
          annotations={annotations}
        />
      </div>
      <div className={styles.interactions}>
        <div className={styles.controlsContainer}>
          <div className={styles.sliderControl}>
            <label>M = {massParam} kg</label>
            <Slider theme={"primary"} min={0.1} max={0.5} step={0.05} value={massParam} onChange={setMassParam} />
          </div>
          <div className={styles.sliderControl}>
            <label>K = {kParam} [N/m]</label>
            <Slider theme={"primary"} min={10} max={40} step={1} value={kParam} onChange={setKParam} />
          </div>
          <div className={styles.sliderControl}>
            <label>ξ = {tauParam} [ɸ]</label>
            <Slider theme={"primary"} min={0} max={1.2} step={0.05} value={tauParam} onChange={setTauParam} />
          </div>
          <div className={styles.sliderControl}>
            <label>τ = {xiParam} [s]</label>
            <Slider theme={"primary"} min={0} max={3} step={0.05} value={xiParam} onChange={setXiParam} />
          </div>
        </div>
        <div className={styles.animationContainer}>
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Lesson0302a;
