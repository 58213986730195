import React, { Component } from "react";
import { round } from "../../../../../../helpers/ant-novak/math.helper";
import WaveGuideCanvas from "./WaveGuide.canvas.jsx";
import arrow from "../../../../../../assets/images/icons/arrows.svg";
import { Switch } from "@jelly/ui";
// import { round } from "../../../../helpers/math.helper";

class WaveGideAnimation extends Component {
  handleCheckVelocityArrows = () => {
    this.props.properties.animation.velocity_arrows.show = !this.props.properties.animation.velocity_arrows.show;
  };

  handleCheckPressureColors = () => {
    this.props.properties.animation.pressure_colors.show = !this.props.properties.animation.pressure_colors.show;
  };

  render() {
    let { animation, text } = this.props.properties;
    let t = this.props.t;

    const show_colors_text = animation.pressure_colors.text || "show pressure colors";
    const show_arrows_text = animation.velocity_arrows.text || "show velocity arrows";

    return (
      <div class="one-graph">
        {/* velocity arrows and pressure colors checkbox */}
        <div className="graph-properties">
          {/* velocity arrows checkbox */}
          {animation.velocity_arrows.can_be_modified && (
            <div className="graph-property">
              <Switch
                onClick={this.handleCheckVelocityArrows}
                defaultChecked={animation.velocity_arrows.show}
                label={show_arrows_text}
              />
            </div>
          )}

          {/* pressure colors checkbox */}
          {animation.pressure_colors.can_be_modified && (
            <div className="graph-property">
              <Switch
                onClick={this.handleCheckPressureColors}
                defaultChecked={animation.pressure_colors.show}
                label={show_colors_text}
              />
            </div>
          )}
        </div>

        {/* informative text fileds and equations above the canvas animation */}
        <div style={{ margin: "auto" }}>
          {text.length > 0 && (
            <div style={{ position: "relative", minHeight: "28px", fontSize: "80%" }}>
              {text.map((text, idx) => (
                <>
                  <div
                    key={idx}
                    style={{
                      position: "absolute",
                      left: text.left,
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "0px 2px 4px 0px rgba(184, 186, 202, 0.25)",
                      border: "1px solid rgba(236, 237, 245, 1)",
                      borderRadius: "5px",
                      padding: "0.5em",
                    }}
                  >
                    {/* variable (latex eq. image) */}
                    <img src={text.variable.img} height="28px" alt="LaTeX equation" />

                    {/* value */}
                    {round(text.fnc(this.props.properties, t / animation.animation_rate), 2)}

                    {/* units (latex image) */}
                    <img src={text.units.img} height="25px" alt="units" />
                    <div
                      style={{
                        position: "absolute",
                        left: idx == 0 ? "100px" : "50px",
                        bottom: "-11px",
                        height: "15px",
                        width: "15px",
                        backgroundImage: arrow,
                        transform: "rotate(180deg)",
                      }}
                    >
                      <img src={arrow} height="15" width="15" />
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

          {/* CANVAS */}
          <WaveGuideCanvas t={t} properties={this.props.properties} />
        </div>
      </div>
    );
  }
}

export default WaveGideAnimation;
