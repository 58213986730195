import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import PolarGraph from "../../components/common/ant-novak/ChartJS/PolarGraph";
import { COLORS, LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import PolarGraph from "../UI/ChartJS/PolarGraph";
// import { LogSpace } from "../../helpers/helpers";
import { sensitivity, polarResponse } from "./MicrophoneCardio.helper";
import { SwitchOption } from "@jelly/ui";
// import { COLORS } from "../../helpers/helpers";

class MicrophoneCardioGraph extends Component {
  state = {
    displayedGraph: 0,
  };

  handleDisplayChange = (value) => {
    this.setState({ displayedGraph: value });
  };

  render() {
    // List of inputs ("props") :
    // Vab ... back volume [m^3]
    const { Vab } = this.props;
    const { displayedGraph } = this.state;
    // frequency axis
    const f_ax = LogSpace(1, Math.log10(20000), 500);
    const db_line = [
      { x: 10, y: -3 },
      { x: 20e3, y: -3 },
    ];

    // abs and phase
    const { absVal, phase } = sensitivity(Vab, f_ax);
    const data = {
      //title: "Microphone Sensitivity",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [absVal, db_line],
          yLabel: "Relative sensitivity (dB)",
          type: "linear",
          min: -25,
          max: 10,
          borderColor: [COLORS[0], COLORS[0]],
          lineWidth: [3, 1],
          borderDash: [undefined, [10, 5]],
        },
        {
          data: [phase],
          yLabel: "Phase (deg)",
          type: "linear",
          min: -180,
          max: 180,
          borderColor: [COLORS[1]],
        },
      ],
      animation: 0,
    };

    // polar graph
    const { graphData } = polarResponse(Vab);
    const dataPolar = {
      //title: "Microphone directivity",
      data: [graphData],
      axis: { min: 0, max: 1.2 },
    };

    return (
      <>
        <div style={{ width: "50%", margin: "auto" }}>
          <SwitchOption
            items={[{ name: "Microphone sensitivity" }, { name: "Mircophone directivity" }]}
            activeItem={displayedGraph}
            onClick={this.handleDisplayChange}
          />
        </div>
        <div>
          {displayedGraph == 0 && <LineGraph data={data} graph_size_ratio={0.6} />}
          {displayedGraph == 1 && <PolarGraph data={dataPolar} />}
        </div>
      </>
    );
  }
}
export default MicrophoneCardioGraph;
