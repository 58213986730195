/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import MicrophoneArrayGraph from "./MicrophoneArray.graph";
import MicrophoneArrayAudio from "./MicrophoneArray.audio";
import { Dropdown } from "@jelly/ui";

// -------------------------
// MicrophoneArray
// - Directivity of a microphone array
// -------------------------
const n_of_transducers_values = [5, 10, 30];
const distance_values = [1, 2, 3];

const sources_angle = (30 / 180) * Math.PI;

// slider parameters
const sliders = [
  {
    title: "Theta",
    name: "theta0",
    unit: "deg",
    min: -90,
    max: 90,
    step: 1,
    edit: false,
  },
  {
    title: "Frequency",
    name: "frequency",
    unit: "Hz",
    min: 20,
    max: 10e3,
    step: 10,
    edit: false,
  },
];

class MicrophoneArray extends Component {
  // class state
  state = {
    n_of_transducers: n_of_transducers_values[0],
    distance: distance_values[0],
    frequency: 500.0, // frequency [Hz]
    dB: 40, // dynamics
    theta0: 0,
  };

  // handle change
  handleChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: parseFloat(value) });
    }
  };

  renderBlock() {
    const { frequency, dB, distance, n_of_transducers, theta0 } = this.state;
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <h5 style={{ marginBottom: "0" }}>MICROPHONE LINE ARRAY</h5>
        <div style={{ flexShrink: "1" }}>
          <MicrophoneArrayGraph
            sources_angle={sources_angle}
            frequency={frequency} //   frequency [Hz]
            dB={dB} //   dB ... dynamics of the graph
            distance={distance / 100}
            n_of_transducers={n_of_transducers}
            theta0={(theta0 / 180) * Math.PI}
          />
        </div>

        <div style={{ flex: "1", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div style={{ flexGrow: "1" }}>
            <small>Number of microphones</small>
          </div>
          <div>
            <Dropdown
              options={n_of_transducers_values.map((val) => ({ children: val, value: val }))}
              value={n_of_transducers}
              listSize="small"
              onChange={(value) => this.handleChange("n_of_transducers", Number(value))}
            />
          </div>
          <div>
            <small>Distance between microphones</small>
          </div>
          <div>
            <Dropdown
              options={distance_values.map((val) => ({ children: val + " (cm)", value: val }))}
              value={distance}
              listSize="small"
              onChange={(value) => this.handleChange("distance", Number(value))}
            />
          </div>
          <MicrophoneArrayAudio
            sources_angle={sources_angle}
            n_of_transducers={n_of_transducers}
            distance={distance / 100}
            theta0={(theta0 / 180) * Math.PI}
          />
        </div>
        <div>
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default MicrophoneArray;
