import React, { useState, useEffect } from "react";
import SliderEdit from "../../../SliderEdit";
// import SliderEdit from "../../../UI/SliderEdit";
import { SIGNAL_CHOICE } from "../../helpers/WaveGuide.signals.js";
import { SwitchOption } from "@jelly/ui";
import close_icon from "../../../../../../assets/images/icons/close.svg";
import open_icon from "../../../../../../assets/images/icons/open.svg";
import no_boundary_icon from "../../../../../../assets/images/icons/no_boundary.svg";
import custom_r_icon from "../../../../../../assets/images/icons/custom_r.svg";
import radiation_icon from "../../../../../../assets/images/icons/radiation.svg";

import "../../WaveGuide.css";

export const REFLECTION_TYPES = {
  NEUMANN: { value: 0, title: "Close", icon: close_icon },
  DIRICHLET: { value: 1, title: "Open", icon: open_icon },
  NO: { value: 2, title: "No boundary", icon: no_boundary_icon },
  COMPLEX: { value: 3, title: "Custom R", icon: custom_r_icon },
  RADIATION: { value: 4, title: "Radiation", icon: radiation_icon },
};

const SLIDERS = [
  {
    title: "|R|",
    name: "R",
    unit: "",
    min: 0,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    title: "phi",
    name: "phi",
    unit: "°",
    min: 0,
    max: 360,
    step: 1,
    edit: false,
  },
];

export default function WaveGuideReflection({ properties, signal, physics, update_flag }) {
  const [reflection_type, setType] = useState(physics.reflection.type);
  const [R, setR] = useState(1);
  const [phi, setPhi] = useState(0);

  const handleChangeSlider = (name, value) => {
    if (name === "R") setR(value);
    if (name === "phi") setPhi(value);
  };

  const updateFnc = (value) => {
    properties.signal.setReflection(value, properties.physics);
    properties.physics.reflection.type = value.type;
    properties.flag_recalculate = true;
  };

  useEffect(() => {
    updateFnc({
      abs: Number(R),
      arg: (Number(phi) * Math.PI) / 180,
      type: reflection_type,
    });
  }, [R, phi]);

  useEffect(() => {
    // if R was set to complex valued |R|*exp(phi)
    // and signal is changed to non-harmonic, complex valued R has no sense.
    // Change it to R = 1.
    if (signal.type !== SIGNAL_CHOICE.HARMONIC && reflection_type === REFLECTION_TYPES.COMPLEX) {
      setType(0);
    }
  }, [signal]);

  useEffect(() => {
    if (reflection_type === REFLECTION_TYPES.NEUMANN.value) {
      setR(1);
      setPhi(0);
    }
    if (reflection_type === REFLECTION_TYPES.DIRICHLET.value) {
      setR(1);
      setPhi(180);
    }
    if (reflection_type === REFLECTION_TYPES.NO.value) {
      setR(0);
      setPhi(0);
    }
    if (reflection_type === REFLECTION_TYPES.RADIATION.value) {
      let k = signal.k[0];
      let H = physics.radius.values[0];
      setR(Math.exp(-Math.pow(k * H, 2) / 2));
      setPhi(-1.4 * k * H * (180 / Math.PI));
    }
  }, [reflection_type, update_flag]);

  SLIDERS[0].value = R;
  SLIDERS[1].value = phi;

  return (
    <div className="reflection-types">
      <SwitchOption
        items={physics.reflection.types.map((type, index) => {
          return { name: `<img src="${type.icon}" class="button-icon"/> ${type.title}` };
        })}
        onClick={(value) => setType(Number(value))}
      />
      {physics.reflection.types.length > 3 && physics.reflection.types[3].value == 3 && (
        <div className="slider-block">
          {SLIDERS.map((slider) => {
            return (
              <SliderEdit
                key={slider.name}
                onChange={handleChangeSlider}
                params={slider}
                val={slider.value}
                disabled={reflection_type !== 3}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
