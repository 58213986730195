// Typed version of
// https://github.com/aplbrain/npyjs/blob/master/index.js

type NumpyHeader = {
  descr: string;
  fortran_order: boolean;
  shape: MultidimensionalArray;
};

interface DType {
  name: string;
  size: number;
  arrayConstructor:
    | Uint8ArrayConstructor
    | Uint16ArrayConstructor
    | Int8ArrayConstructor
    | Int16ArrayConstructor
    | Int32ArrayConstructor
    | BigUint64ArrayConstructor
    | BigInt64ArrayConstructor
    | Float32ArrayConstructor
    | Float64ArrayConstructor;
}

type MultidimensionalArray<T = number> = Array<MultidimensionalArray | T>;

export interface Numpy {
  dtype: string;
  data:
    | Uint8Array
    | Uint16Array
    | Int8Array
    | Int16Array
    | Int32Array
    | BigUint64Array
    | BigInt64Array
    | Float32Array
    | Float64Array;
  shape: MultidimensionalArray & undefined;
  fortranOrder: boolean;
}

export default class NumpyParser {
  private readonly dtypes: Record<string, DType> = {
    "<u1": {
      name: "uint8",
      size: 8,
      arrayConstructor: Uint8Array,
    },
    "|u1": {
      name: "uint8",
      size: 8,
      arrayConstructor: Uint8Array,
    },
    "<u2": {
      name: "uint16",
      size: 16,
      arrayConstructor: Uint16Array,
    },
    "|i1": {
      name: "int8",
      size: 8,
      arrayConstructor: Int8Array,
    },
    "<i2": {
      name: "int16",
      size: 16,
      arrayConstructor: Int16Array,
    },
    "<u4": {
      name: "uint32",
      size: 32,
      arrayConstructor: Int32Array,
    },
    "<i4": {
      name: "int32",
      size: 32,
      arrayConstructor: Int32Array,
    },
    "<u8": {
      name: "uint64",
      size: 64,
      arrayConstructor: BigUint64Array,
    },
    "<i8": {
      name: "int64",
      size: 64,
      arrayConstructor: BigInt64Array,
    },
    "<f4": {
      name: "float32",
      size: 32,
      arrayConstructor: Float32Array,
    },
    "<f8": {
      name: "float64",
      size: 64,
      arrayConstructor: Float64Array,
    },
  };

  parse(buffer: ArrayBuffer): Numpy {
    const headerLength = new DataView(buffer.slice(8, 10)).getUint8(0);
    const offsetBytes = 10 + headerLength;

    const hcontents = new TextDecoder("utf-8").decode(new Uint8Array(buffer.slice(10, 10 + headerLength)));
    const header: NumpyHeader = JSON.parse(
      hcontents
        .toLowerCase() // True -> true
        // eslint-disable-next-line
        .replace(/'/g, '"')
        .replace("(", "[")
        .replace(/,*\),*/g, "]")
    );

    const shape = header.shape as Numpy["shape"];
    const dtype = this.dtypes[header.descr];
    const nums = new dtype["arrayConstructor"](buffer, offsetBytes);

    return {
      dtype: dtype.name,
      data: nums,
      shape,
      fortranOrder: header.fortran_order,
    };
  }

  async load(fileUrl: string, callback?: (result: Numpy) => void): Promise<Numpy> {
    const response = await fetch(fileUrl, { cache: "force-cache" });
    const arrayBuffer = await response.arrayBuffer();
    const result = this.parse(arrayBuffer);
    if (callback) {
      callback(result);
    }

    return result;
  }
}
