// prepare data:
// - inputSignal ... sine wave (one period) [in chartjs format]
const N = 1000;

export function prepareData(osc, freq) {
  let x = multiSinusOnePeriod(osc, freq, N);
  let inputSignal = Array(N);
  for (let i = 0; i < N; i++) {
    inputSignal[i] = { x: (i / N) * 2, y: x[i] };
  }
  return inputSignal;
}

// sine wave signal (one period)
export function multiSinusOnePeriod(osc, freq, N) {
  return [...Array(N).keys()].map((i) =>
    osc.reduce((acc, obj, idx) => {
      let frequency = (idx + 1) * freq;
      return acc + obj.A * Math.sin((((2 * Math.PI * frequency) / N) * i) / (N / 2) + obj.init_phase);
    }, 0)
  );
}
