import React, { Component } from "react";
import BarGraph from "../../components/common/ant-novak/ChartJS/BarGraph";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import BarGraph from "../UI/ChartJS/BarGraph";
import { prepareData } from "./Distortion.helper";

class DistortionGraph extends Component {
  state = {};
  render() {
    // List of inputs ("props") :
    //   A  ... signal amplitude
    //   NLfnc ... nonlinear function to apply
    const { A, NLfnc } = this.props;

    const N_fft = 16;

    const { outputSignal, fftBuffer, THD } = prepareData(A, NLfnc, N_fft);

    const fft_axis = [...Array(N_fft).keys()];

    const signalData = {
      title: "Microphone signal",
      xAxes: {
        xLabel: "Time (ms)",
        type: "linear",
      },
      yAxes: [
        {
          data: [outputSignal],
          yLabel: "Acoustic Pressure (Pa)",
          min: -1.5,
          max: 1.5,
          type: "linear",
        },
      ],
      animation: 0,
    };

    const fftData = {
      title: "THD = " + parseFloat(THD).toFixed(1) + "%",
      xAxes: {
        xLabel: "Frequency (kHz)",
        data: fft_axis,
        scale: 0.5,
      },
      yAxes: {
        data: fftBuffer,
        yLabel: "Sound Pressure Level (dB SPL)",
        min: 20,
        max: 100,
      },
      animation: 0,
    };

    return (
      <>
        <LineGraph data={signalData} graph_size_ratio={0.4} />
        <BarGraph data={fftData} graph_size_ratio={0.45} />
      </>
    );
  }
}

export default DistortionGraph;
