/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import AnalogFiltersGraph from "./AnalogFilters.graph";
import { Switch } from "@jelly/ui";

// -------------------------
// Analog Filters for Loudspeakers:
//                 fc ... cut-off frequency
//                 Rl ... load resistor (tweeter)
// -------------------------

class AnalogFilters extends Component {
  // class state
  state = {
    fc: 2000.0, // cut-off frequency [Hz]
    Rl: 20.0, // load resistor [Ohm]
    invert_tweeter_polarity: true,
  };

  // slider parameters
  sliders = [
    {
      name: "fc",
      title: "f<sub>c</sub>",
      unit: "Hz",
      min: 500,
      max: 3000,
      step: 1,
    },
    {
      name: "Rl",
      title: "R<sub>l</sub>",
      unit: "Ω",
      min: 0,
      max: 50,
      step: 0.1,
    },
  ];

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for checkbox
  invertTweeterPolarity = (event) => {
    this.setState((prevState) => ({
      invert_tweeter_polarity: !prevState.invert_tweeter_polarity,
    }));
  };

  renderBlock() {
    const { Rl, fc, invert_tweeter_polarity } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ flex: "1" }}>
          <AnalogFiltersGraph fc={fc} Rl={Rl} polarity={invert_tweeter_polarity} />
        </div>
        <div>
          <Switch
            onClick={this.invertTweeterPolarity}
            default={invert_tweeter_polarity}
            size="small"
            label="Invert tweeter polarity"
          />
        </div>
        <div>
          {this.sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default AnalogFilters;
