// import { round } from "../../../helpers/math.helper";
import { dotMultiply, complex, add } from "mathjs";
import { round } from "../../../../../helpers/ant-novak/math.helper";

const PI = Math.PI;
const TWO_PI = 2 * PI;

export const SIGNAL_CHOICE = {
  HARMONIC: 0,
  SQUARE: 1,
  BURST: 2,
  PULSE: 3,
};

export function signalSelect(signalParams, physicsParams) {
  switch (signalParams.type) {
    case SIGNAL_CHOICE.HARMONIC:
      return new Harmonic(signalParams, physicsParams);
    case SIGNAL_CHOICE.SQUARE:
      return new Square(signalParams, physicsParams);
    case SIGNAL_CHOICE.BURST:
      return new Burst(signalParams, physicsParams);
    case SIGNAL_CHOICE.PULSE:
      return new Pulse(signalParams, physicsParams);
    default:
      break;
  }
}

class Signal {
  constructor(signalParams, physicsParams) {
    Object.assign(this, signalParams);
    this.left_right = "left";
    this.Rf = complex(0);
    this.Rg = complex(0);
    this.Tf = complex(1.0);
    this.Tg = complex(1.0);

    this.loss_factor = physicsParams.loss_factor ?? 0;

    this.graph_limits = signalParams.graph_limits ?? { pmax: 1, vmax: 1, auto: true };

    this.setAlpha(physicsParams.radius.values);
    this.setWavenumer(physicsParams.c0);
    this.setLambda(physicsParams.c0);
    this.setReflectionPhysics(physicsParams);
  }

  setVelocityMaxima(c0, rho) {
    this.graph_limits.vmax = this.graph_limits.pmax / Math.min(...dotMultiply(c0, rho));
  }

  //   pressure_amplitude_harmonic() {
  //     let pmin = -Math.max(1 + Math.abs(this.Tg), 1 + Math.abs(this.Rg) + Math.abs(this.Tf));
  //     let pmax = Math.max(
  //       1 + Math.abs(this.Rf) + Math.abs(this.Tg),
  //       1 + Math.abs(this.Rg) + Math.abs(this.Tf)
  //     );
  //     return Math.max(Math.abs(pmax), Math.abs(pmin));
  //   }

  setReflectionPhysics(physicsParams) {
    let { c0, rho, radius } = physicsParams;
    let x_interface = physicsParams.reflection?.x ?? 0.5;

    let Zc1 = (rho[0] * c0[0]) / (PI * Math.pow(radius.values[0], 2));
    let Zc2 = (rho[1] * c0[1]) / (PI * Math.pow(radius.values[1], 2));

    if (physicsParams.reflection?.flip) {
      this.Rg = complex((Zc2 - Zc1) / (Zc2 + Zc1));
      this.Rf = complex((Zc1 - Zc2) / (Zc2 + Zc1));
    } else {
      this.Rf = complex((Zc2 - Zc1) / (Zc2 + Zc1));
      this.Rg = complex((Zc1 - Zc2) / (Zc2 + Zc1));
    }

    this.Tf = add(this.Rf, complex(1.0));
    this.Tg = add(this.Rg, complex(1.0));

    const L = 1.0;
    this.wave_delay_f = (1 - c0[1] / c0[0]) * x_interface;
    this.wave_delay_g = (1 - c0[0] / c0[1]) * (L - x_interface);
  }

  setReflection(value, physicsParams) {
    if (physicsParams.reflection?.flip) {
      value.arg = value.arg + Math.PI;
    }
    this.Rf = complex(value);
    this.Tf = add(this.Rf, complex(1.0));
    this.Rg = this.Rf.neg();
    this.Tg = add(this.Rg, complex(1.0));
  }

  setAlpha(radius) {
    this.alpha = [
      (this.loss_factor * Math.sqrt(this.freq.value)) / radius[0],
      (this.loss_factor * Math.sqrt(this.freq.value)) / radius[1],
    ];
  }

  setWavenumer(c0) {
    this.k = [(TWO_PI * this.freq.value) / c0[0] + this.alpha[0], (TWO_PI * this.freq.value) / c0[1] + this.alpha[1]];
  }

  setLambda(c0) {
    this.lambda = [c0[0] / this.freq.value, c0[1] / this.freq.value];
  }
}

class Harmonic extends Signal {
  constructor(signalParams, physicsParams) {
    super(signalParams, physicsParams);
    this.normalize = 1; // normalize the output
    this.displacement_zoom = 5000;
  }

  f(s, phi, idx) {
    return this.A.abs * Math.cos(this.k[idx] * s + phi + this.A.arg);
  }

  g(s, phi, idx) {
    return this.B.abs * Math.cos(this.k[idx] * s + phi + this.B.arg);
  }

  F(s, phi, idx) {
    return (this.A.abs / this.k[idx]) * Math.sin(this.k[idx] * s + phi + this.A.arg);
  }

  G(s, phi, idx) {
    return (this.B.abs / this.k[idx]) * Math.sin(this.k[idx] * s + phi + this.B.arg);
  }

  setPressureMaxima() {
    //this.graph_limits.pmax = scientificRound(this.pressure_amplitude_harmonic());
    return Math.abs(this.A) + Math.abs(this.B);
  }
}

class Square extends Signal {
  constructor(signalParams, physicsParams) {
    super(signalParams, physicsParams);
    this.normalize = 1; // normalize the output
    this.displacement_zoom = 5000;
  }

  f(s, phi, idx) {
    let ks = this.k[idx] * s + this.A.arg + phi;
    return 0.999 * this.A.abs * Math.sign(Math.sin(ks));
  }

  g(s, phi, idx) {
    let ks = this.k[idx] * s + this.B.arg + phi;
    return -0.999 * this.B.abs * Math.sign(Math.sin(ks));
  }

  F(s, phi, idx) {
    let ks = this.k[idx] * s + this.A.arg + phi;
    return this.A.abs * (ks / TWO_PI - Math.round(ks / TWO_PI)) * Math.sign(Math.sin(ks));
  }

  G(s, phi, idx) {
    let ks = this.k[idx] * s + this.B.arg + phi;
    return -this.B.abs * (ks / TWO_PI - Math.round(ks / TWO_PI)) * Math.sign(Math.sin(ks));
  }

  p__max(A, B, k) {
    return Math.abs(this.A.abs) + Math.abs(this.B.abs);
  }

  p__amp(A, B, x, L, idx) {
    return 2.0;
    //pressure_amplitude_harmonic(A, B, this.k[idx], x, L);
  }
}

class Burst extends Signal {
  constructor(signalParams, physicsParams) {
    super(signalParams, physicsParams);
    this.normalize = 0.29e-1; // normalize the output
    this.displacement_zoom = 5e4;
    this.lam = 0.15;
    this.k_burst = (2 * TWO_PI) / (1.0 / 2);
  }

  sp(y) {
    return (1 / this.lam) * y * (1 - y); // function where y is close to a hyperbolic tangent
  }

  spp(y) {
    return Math.pow(1 / this.lam, 2) * y * (1 - y) * (1 - 2 * y); // function where y is close to a hyperbolic tangent
  }

  f(s, phi, idx) {
    const ks = (this.k[0] / this.k[0]) * s;
    const sp_arg = 1 / (1 + Math.exp(-ks / this.lam));
    return (
      this.normalize *
      this.A.abs *
      Math.cos(this.A.arg + phi) *
      (-this.k_burst * this.sp(sp_arg) * Math.sin(this.k_burst * ks) + this.spp(sp_arg) * Math.cos(this.k_burst * ks))
    );
  }

  g(s, phi, idx) {
    const ks = (this.k[idx] / this.k[0]) * s;
    const sp_arg = 1 / (1 + Math.exp(-ks / this.lam));
    return (
      this.normalize *
      this.B.abs *
      Math.cos(this.B.arg + phi) *
      (-this.k_burst * this.sp(sp_arg * Math.sin(this.k_burst * ks)) + this.spp(sp_arg * Math.cos(this.k_burst * ks)))
    );
  }

  F(s, phi, idx) {
    const ks = (this.k[idx] / this.k[0]) * s;
    const sp_arg = 1 / (1 + Math.exp(-ks / this.lam));
    return this.A.abs * Math.cos(this.A.arg + phi) * (this.sp(sp_arg) * Math.cos(this.k_burst * ks)) * this.normalize;
  }

  G(s, phi, idx) {
    const ks = (this.k[idx] / this.k[0]) * s;
    const sp_arg = 1 / (1 + Math.exp(-ks / this.lam));
    return this.B.abs * Math.cos(this.B.arg + phi) * (this.sp(sp_arg) * Math.cos(this.k_burst * ks)) * this.normalize;
  }

  setPressureMaxima() {
    let { A, B, Rf, Tg, Rg, Tf, normalize } = this;
    const ymax = 1 / 2 - Math.sqrt(3) / 6;
    this.graph_limits.pmax = round(
      normalize *
        ((this.k_burst / this.lam / 4 + Math.pow(1 / this.lam, 2) * ymax * (1 - ymax) * (1 - 2 * ymax)) *
          Math.max(A + A * Rf.abs + B * Tg.abs, B + B * Rg.abs + A * Tf.abs)),
      1,
      Math.ceil,
      0.1
    );
  }
}

class Pulse extends Signal {
  constructor(signalParams, physicsParams) {
    super(signalParams, physicsParams);
    this.normalize = 3e-4; // normalize the output
    this.displacement_zoom = 5e4;
    this.lam = 0.005;
  }
  sp(y, idx) {
    return (1 / this.lam) * y * (1 - y); // function where y is close to a hyperbolic tangent
  }

  spp(y, idx) {
    return Math.pow(1 / this.lam, 2) * y * (1 - y) * (1 - 2 * y); // function where y is close to a hyperbolic tangent
  }

  f(s, phi, idx) {
    return (
      this.A.abs *
      Math.cos(this.A.arg + phi) *
      this.spp(1 / (1 + Math.exp(((-this.k[idx] / this.k[0]) * s) / this.lam)), idx) *
      this.normalize
    );
  }

  g(s, phi, idx) {
    return (
      this.B.abs *
      Math.cos(this.B.arg + phi) *
      this.spp(1 / (1 + Math.exp(((-this.k[idx] / this.k[0]) * s) / this.lam)), idx) *
      this.normalize
    );
  }

  F(s, phi, idx) {
    return (
      this.A.abs *
      Math.cos(this.A.arg + phi) *
      this.sp(1 / (1 + Math.exp(((-this.k[idx] / this.k[0]) * s) / this.lam)), idx) *
      this.normalize
    );
  }

  G(s, phi, idx) {
    return (
      this.B.abs *
      Math.cos(this.B.arg + phi) *
      this.sp(1 / (1 + Math.exp(((-this.k[idx] / this.k[0]) * s) / this.lam)), idx) *
      this.normalize
    );
  }

  setPressureMaxima() {
    let { A, B, Rf, Tg, Rg, Tf, normalize } = this;
    const ymax = 1 / 2 - Math.sqrt(3) / 6;
    this.graph_limits.pmax = round(
      normalize *
        Math.pow(1 / this.lam, 2) *
        ymax *
        (1 - ymax) *
        (1 - 2 * ymax) *
        Math.max(A + A * Rf.abs + B * Tg.abs, B + B * Rg.abs + A * Tf.abs),
      1,
      Math.ceil,
      0.1
    );
  }
}
