import React, { Component, ReactFragment, ReactNode } from "react";
import { Badge, Slider } from "@jelly/ui";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { chartAOptions } from "./Lesson0303a.chart_a.config";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { chartBOptions } from "./Lesson0303a.chart_b.config";
import LessonChartA from "./Lesson0303a.chart_a";
import LessonChartB from "./Lesson0303a.chart_b";
import npyjs from "npyjs";

class Lesson0303a extends Component {
  //Numpy in js
  npy = new npyjs();
  xLabels = Array.from({ length: 300 }, (_, i) => -2 + (i * 4) / 299);

  state = {
    t: 40,
    t_stable: 40,
  };

  /**
   * Adjust sliders config to fit Python
   */
  private sliders = {
    t: {
      name: "T",
      min: 0,
      max: 99,
      step: 1,
    },
  };

  updateParams = (key: string, value: number): void => {
    this.setState({ [key]: value });
  };
  updateParamsAfter = (key: string, value: number): void => {
    this.setState({ [key]: value });
  };

  renderParamSlider = (key: "t"): ReactFragment => {
    const property = this.state[key];
    const config = this.sliders[key];
    return (
      <div style={{ display: "flex", flex: "1 1 48%", alignItems: "center", gap: "0em", paddingRight: "1em" }}>
        <div style={{ flex: "0", minWidth: "6em" }}>
          <Badge theme="secondary" size="small">
            {`${config.name} = ${(property * 0.025 - 1).toFixed(2)}`}
          </Badge>
        </div>
        <div style={{ flex: "1" }}>
          <Slider
            min={config.min}
            max={config.max}
            step={config.step}
            theme="primary"
            value={property}
            onChange={(value) => {
              this.updateParams(key, value);
            }}
            onAfterChange={(value) => {
              this.updateParamsAfter(`${key}_stable`, value);
            }}
          />
        </div>
      </div>
    );
  };

  render(): ReactNode {
    const { t_stable } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "100%", flex: "1" }}>
        <div
          style={{
            flex: "1 0 80%",
            display: "flex",
            justifyContent: "space-evenly",
            gap: "1em",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <LessonChartA t={t_stable} options={chartAOptions} />

          <LessonChartB t={t_stable} options={chartBOptions} />
        </div>
        <div style={{ flex: "0 1", width: "100%" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>{this.renderParamSlider("t")}</div>
        </div>
      </div>
    );
  }
}
//
export default Lesson0303a;
