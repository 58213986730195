// import { LogSpace } from "../../helpers/helpers";

import { LogSpace } from "../../helpers/ant-novak/helpers";

export const ranges = {
  TELEPHONE: 0,
  HIGH_FI: 1,
  SUB: 2,
  WOOFER: 3,
  MIDRANGE: 4,
  TWEETER: 5,
};

// space graph options
export const range_options = [
  { title: "Old telephone", value: ranges.TELEPHONE, f1: 300, f2: 3400 },
  { title: "Hifi", value: ranges.HIGH_FI, f1: 20, f2: 20000 },
  { title: "Sub Woofer", value: ranges.SUB, f1: 10, f2: 60 },
  { title: "Woofer", value: ranges.WOOFER, f1: 40, f2: 500 },
  { title: "Midrange", value: ranges.MIDRANGE, f1: 500, f2: 2000 },
  { title: "Tweeter", value: ranges.TWEETER, f1: 2000, f2: 20000 },
];

// calculate resonance frequency Fc
export function FRF(f1, f2) {
  const Q1 = 0.707;
  const Q2 = 0.707;

  // frequency axis
  const f_axis = LogSpace(1, Math.log10(30e3), 100);

  // prepare arrays for Z_in and phase
  const FRF_abs = [];
  //let FRF_phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    FRF_abs[i] = { x: f_axis[i], y: 0 };
    //FRF_phase[i] = { x: f_axis[i], y: 0 };
  }

  // omega1, omega2
  const omega1 = 2 * Math.PI * f1;
  const omega2 = 2 * Math.PI * f2;

  const A = omega1 / Q1 + omega2 / Q2;
  const B = Math.pow(omega1, 2) + Math.pow(omega2, 2) + ((omega1 / Q1) * omega2) / Q2;
  const C = (omega1 / Q1) * Math.pow(omega2, 2) + (omega2 / Q2) * Math.pow(omega1, 2);
  const D = Math.pow(omega1, 2) * Math.pow(omega2, 2);

  // HP - LP FRF sections
  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    const w = 2 * Math.PI * f_axis[i];
    const w2 = Math.pow(w, 2);
    const w3 = Math.pow(w, 3);
    const w4 = Math.pow(w, 4);

    const DEN = Math.pow(w4 - w2 * B + D, 2) + Math.pow(w3 * A - w * C, 2);
    const NUM_RE = -Math.pow(omega2, 2) * w2 * (w4 - w2 * B + D);
    const NUM_IM = Math.pow(omega2, 2) * w2 * (w3 * A - w * C);

    const HPLP1 = Math.pow(NUM_RE / DEN, 2) + Math.pow(NUM_IM / DEN, 2);

    FRF_abs[i].y = 10 * Math.log10(Math.abs(HPLP1));
    //FRF_phase[i].y = -(180 / Math.PI) * Math.atan2(NUM_IM, NUM_RE);
  }

  //return { FRF_abs, FRF_phase };
  return { FRF_abs };
}
