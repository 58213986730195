import React, { Component } from "react";
import { COLORS } from "../../../../../../helpers/ant-novak/helpers";
import LineGraphDrag from "../../../ChartJS/LineGraphDrag";
// import LineGraphDrag from "../../../UI/ChartJS/LineGraphDrag";
import { prepareDataImpedanceFreq } from "../../helpers/WaveGuide.helper.js";
// import { COLORS } from "../../../../helpers/helpers";

class WaveGuideGraphImpedanceFreq extends Component {
  updateDraggedFrequencyFromImpedanceFreq = (e, datasetIndex, index, value) => {
    this.updateFrequency(null, value.x);
  };

  updateFrequency = (name, value) => {
    let { properties, updateAmplitudes } = this.props;
    properties.signal.freq.value = Number(value);
    properties.signal.setAlpha(properties.physics.radius.values);
    properties.signal.setWavenumer(properties.physics.c0);
    properties.flag_updateReflection = !properties.flag_updateReflection;
    updateAmplitudes(properties);
  };

  render() {
    let { signal, physics } = this.props.properties;

    let { Z_abs, freqPoint, freqLine } = prepareDataImpedanceFreq(signal, physics);

    let signalData = {
      //title: "Acoustic Impedance Z(f)",
      dragX: true,
      dragY: false,
      onDragUpdate: true,
      xAxes: {
        xLabel: "f (Hz)",
        type: "linear",
      },
      yAxes: [
        {
          data: [freqPoint, freqLine, Z_abs],
          yLabel: "|Z| (kg/m^2/s)",
          min: 0.01,
          max: 100,
          type: "logarithmic",
          dragData: [true, false, false],
          defaultDragY: [1.0, 0, 0],
          borderColor: [COLORS[1], COLORS[1], COLORS[0]],
          backgroundColor: [COLORS[1], COLORS[1], COLORS[0]],
          lineWidth: [0, 1, 3],
          pointRadius: [5, 0, 0],
        },
      ],
      animation: 0,
    };

    return (
      <LineGraphDrag data={signalData} func={this.updateDraggedFrequencyFromImpedanceFreq} graph_size_ratio={0.25} />
    );
  }
}

export default WaveGuideGraphImpedanceFreq;
