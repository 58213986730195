/* eslint-disable */
// @ts-nocheck

import { Button, Slider } from "@jelly/ui";
import React, { Component } from "react";

import CircularSlider from "@fseehawer/react-circular-slider";
import { debounce } from "lodash-es";
import LineChart from "../../components/common/head/Chart/LineChart";
import Player from "../../components/common/head/Player/BufferAudioPlayer";
import { modulatedSineFrame, roughnessGraphData, fluctuationStrengthGraphData } from "./Modulation.helper";
import { ReactComponent as LoudspeakerIcon } from "../../assets/playButton.svg";

import styles from "./Modulation.module.scss";
import classnames from "classnames";

class Modulation extends Component {
  constructor() {
    super();
    this.player = new Player();

    this.numberOfSamples = this.player.sampleRate * this.player.duration;
    this.audioFrame = new Float32Array(this.numberOfSamples);
    this.timeAxis = this.createTimeAxis(this.player.sampleRate);
    this.frequencyAxis = this.createFrequencyAxis();
    this.roughnessGraphData = roughnessGraphData();
    this.fluctuationStrengthGraphData = fluctuationStrengthGraphData();

    this.state = {
      amplitude: 0.5,
      frequency: 1000,
      modulationFrequency: 4,
      modulationIndex: 1,
      buttonState: "cil-media-play",
      timeGraphData: [],
    };
  }

  componentWillUnmount() {
    if (this.player.isPlaying) {
      this.player.stop();
      this.setState({ buttonState: "cil-media-play" });
    }
  }

  onClick = () => {
    this.playOrStopSound();
  };

  onSliderChange = debounce((value) => {
    this.setState({
      modulationFrequency: value,
    });

    if (this.player.isPlaying) {
      this.createAudioFrame();
      this.setState({
        timeGraphData: this.generateTimeGraphData(),
      });
      this.player.stop();
      this.player.play(this.audioFrame);
    }
  }, 50);

  render() {
    const { timeGraphData, modulationFrequency } = this.state;

    const timeSignalData = {
      legend: {
        display: false,
      },
      datasets: [
        {
          label: "Amplitude Modulated Sine",
          data: timeGraphData,
          fill: false,
          borderColor: "#131a4f",
          showLine: true,
        },
      ],
      xAxes: {
        min: 0,
        max: 1,
        xLabel: "Time (s)",
        type: "linear",
        maxTicksLimit: 11,
        stepSize: 0.1,
      },
      yAxes: {
        min: -1,
        max: 1,
        yLabel: "Amplitude",
        type: "linear",
      },
    };

    const perceptionData = {
      legend: {
        display: false,
      },
      datasets: [
        {
          label: "Fluctuation Strength",
          data: this.fluctuationStrengthGraphData,
          fill: false,
          borderColor: "#131a4f",
          showLine: true,
        },
        {
          label: "Roughness",
          data: this.roughnessGraphData,
          fill: false,
          borderColor: "#ff6040",
          showLine: true,
        },
      ],
      title: "Fluctuation Strength / Roughness",
      xAxes: {
        min: 0.25,
        max: 500,
        xLabel: "Modulation Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: {
        min: 0,
        max: 1,
        yLabel: "Rel. FS / R (%)",
        type: "linear",
      },
    };

    const maxFluctuation = this.fluctuationStrengthGraphData.reduce((acc, val) => {
      if (acc === null) {
        return val;
      }
      return acc.y < val.y ? val : acc;
    }, null);

    const maxRoughness = this.roughnessGraphData.reduce((acc, val) => {
      if (acc === null) {
        return val;
      }
      return acc.y < val.y ? val : acc;
    }, null);

    const perceptionAnnotations = [
      {
        type: "line",
        display: true,
        scaleID: "x-axis-1",
        value: maxFluctuation.x,
        borderColor: "rgba(0, 0, 0, 0.5)",
        borderWidth: 2,
        borderDash: [2, 4, 8, 4],
      },
      {
        type: "line",
        display: true,
        scaleID: "x-axis-1",
        value: maxRoughness.x,
        borderColor: "rgba(0, 0, 0, 0.5)",
        borderWidth: 2,
        borderDash: [2, 4, 8, 4],
      },
      {
        type: "line",
        display: true,
        scaleID: "x-axis-1",
        value: modulationFrequency,
        borderColor: "rgba(0, 0, 128, 0.5)",
        borderWidth: 4,
      },
    ];

    return (
      <div className={styles.lesson0208}>
        <div className={classnames(styles.chart, styles.chartAmplitude)}>
          <LineChart data={timeSignalData} responsive={true} />
        </div>
        <div className={classnames(styles.chart, styles.chartModulation)}>
          <LineChart data={perceptionData} annotations={perceptionAnnotations} responsive="true" />
        </div>
        <div className={styles.controls}>
          <div className={styles.badge}>
            <label>Modulation frequency = {modulationFrequency} Hz</label>
          </div>
          <div className={styles.slider}>
            <Slider theme={"primary"} min={1} max={200} onChange={this.onSliderChange} />
          </div>
          <div className={styles.playButton}>
            <Button shape={"round"} size={"large"} icon={this.state.buttonState} onClick={this.onClick}></Button>
          </div>
        </div>
      </div>
    );

    // return (
    //   <div className="wrap">
    //     <button className="playButton" onClick={this.onClick}>
    //       <LoudspeakerIcon />
    //       {this.state.buttonState}
    //     </button>
    //     <div className="center">
    //       <CircularSlider
    //         min="1"
    //         max="200"
    //         direction="1"
    //         label="Modulation Frequency (Hz)"
    //         labelColor="#131a4f"
    //         knobColor="#131a4f"
    //         knobSize={30}
    //         knobPosition="top"
    //         progressSize={0}
    //         trackColor="#d0dae9"
    //         trackSize={2}
    //         dataIndex={3}
    //         onChange={this.onSliderChange}
    //       />
    //
    //       <LineChart data={timeSignalData} />
    //     </div>
    //     <div>
    //       <LineChart data={perceptionData} annotations={perceptionAnnotations} />
    //     </div>
    //   </div>
    // );
  }

  createTimeAxis(fs) {
    const N = fs;
    const time = new Array(N);
    for (let n = 0; n < N; n++) {
      time[n] = (1 / fs) * n;
    }
    return time;
  }

  createFrequencyAxis() {
    const N = 2000;
    const frequency = new Float32Array(N);
    for (let n = 0; n < N; n++) {
      frequency[n] = 0.25 * (n + 1);
    }
    return frequency;
  }

  createAudioFrame() {
    this.audioFrame = modulatedSineFrame(
      this.audioFrame,
      this.state.modulationIndex,
      this.state.modulationFrequency,
      this.state.amplitude,
      this.state.frequency,
      this.player.sampleRate,
      this.numberOfSamples
    );
  }

  generateTimeGraphData() {
    // Downsample Audio Frame
    const fMax = 4000;
    const timeGraphData = new Array(fMax);
    const downsamplingRate = this.player.sampleRate / fMax;
    let k = 0;
    for (let n = 0; n < fMax; n++) {
      k = Math.round(n * downsamplingRate);

      timeGraphData[n] = { x: this.timeAxis[k], y: this.audioFrame[k] };
    }
    return timeGraphData;
  }

  playOrStopSound() {
    if (this.player.isPlaying) {
      this.player.stop();
      this.setState({ buttonState: "cil-media-play" });
    } else {
      this.createAudioFrame();
      this.player.play(this.audioFrame);

      this.setState({
        buttonState: "cil-media-pause",
        timeGraphData: this.generateTimeGraphData(),
      });
    }
  }
}

export default Modulation;
