import React, { useState, useEffect } from "react";

import "./WaveGuide.amplitude.css";
import { Switch, Slider, Badge } from "@jelly/ui";

export default function WaveGuideAmplitude(props) {
  const { properties, name, title } = props;
  const value = properties.signal[name];

  const [abs, setAbs] = useState(value.abs ?? 1.0);
  const [arg, setPhase] = useState(value.arg ?? 0.0);
  const [invert_arg, setInvertPhase] = useState(false);

  useEffect(() => {
    const arg_add = invert_arg ? 180 : 0;
    value.abs = Number(abs);
    value.arg = (((Number(arg) + arg_add) % 360) * Math.PI) / 180;
    properties.flag_recalculate = true;
  }, [abs, arg, invert_arg]);

  return (
    <>
      <div style={{ display: "flex" }} className="waveguide-amplitude-main">
        <div className="waveguide-amplitude-block">
          <Badge theme="secondary" size="small">
            {`${title} = ${abs.toFixed(2)}`}
          </Badge>
        </div>
        <div className="waveguide-amplitude-block-long">
          <Slider
            theme="primary"
            onChange={(value) => {
              setAbs(value);
            }}
            min={0}
            max={1}
            value={abs}
            step={0.01}
          />
        </div>

        {value.arg_edit && (
          <>
            <div className="waveguide-amplitude-break"></div>
            <div className="waveguide-amplitude-block">
              <Badge theme="secondary" size="small">
                {`Phase = ${arg.toFixed(2)}°`}
              </Badge>
            </div>
            <div className="waveguide-amplitude-block-long">
              <Slider
                onChange={(value) => {
                  setPhase(value);
                }}
                min={0}
                max={360}
                value={arg}
                step={1}
              />
            </div>
          </>
        )}

        {value.inv_edit && (
          <div className="waveguide-amplitude-block invert-switch">
            <Switch onClick={(e) => setInvertPhase((v) => !v)} default={invert_arg} size="small" label="Invert phase" />
          </div>
        )}
      </div>
    </>
  );
}
