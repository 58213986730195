import { complex, add, multiply, divide, pi } from "mathjs";

// slider for each parameter
export const sliders = [
  {
    name: "Re",
    title: "R<sub>e</sub>",
    unit: "Ω",
    min: 0,
    max: 15,
    step: 0.1,
    edit: false,
  },
  {
    name: "Le",
    title: "L<sub>e</sub>",
    unit: "mH",
    min: 0,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    name: "coupling_factor",
    title: "Coupling factor",
    unit: "",
    min: 0,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    name: "Q",
    title: "R<sub>s</sub> / L<sub>s</sub>",
    unit: "Ohm/mH",
    min: 0.1,
    max: 1000,
    log: true,
    step: 0.1,
    edit: false,
  },
];

export const select_Ls_values = {
  CONSTANT: 0,
  FREQ_DEP: 1,
};

// space graph options
export const select_Ls_options = [
  { title: "Constant L<sub>s</sub>", value: select_Ls_values.CONSTANT },
  { title: "Frequency dependent L<sub>s</sub>", value: select_Ls_values.FREQ_DEP },
];

// calculate impedance of a RLC circuit
export function eddyCurrenstFnc(Re, Le, coupling_factor, Q, f_axis, mode) {
  // prepare arrays for absVal and phase
  const R = [];
  const L = [];
  for (let i = 0; i < f_axis.length; i++) {
    R[i] = { x: f_axis[i], y: 0 };
    L[i] = { x: f_axis[i], y: 0 };
  }

  let Q_selected = Q;
  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    const f = f_axis[i];
    const omega = 2 * pi * f;

    if (mode === select_Ls_values.FREQ_DEP) {
      Q_selected = Math.sqrt(Q * omega);
    }

    const jw = complex(0, omega);
    const kw2 = Math.pow(coupling_factor * omega, 2);
    const numerator = add(Q_selected, jw);

    const denumerator = multiply(kw2, Le);
    const Ze = add(complex(Re, omega * Le), divide(denumerator, numerator));

    // attribute the abs value to the chart.js y-axis
    R[i].y = Ze.re;
    // attribute the phase to the chart.js y-axis
    L[i].y = (1000 * Ze.im) / omega;
  }

  return { R, L };
}
