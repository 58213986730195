/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import MicrophoneEldynGraph from "./MicrophoneEldyn.graph";
import { sliders } from "./MicrophoneEldyn.helper";

class MicrophoneEldyn extends Component {
  // class state
  state = {
    Rt: 10, // mechanical resistance [Ns/m]
    Mt: 0.3, // moving mass [g]
    Ct: 0.5, // mechanical compliance [mm/N]
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  renderBlock() {
    let { Rt, Mt, Ct } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ flex: "1" }}>
          <h5>ELECTRODYNAMIC MICROPHONE</h5>
          <MicrophoneEldynGraph
            Rt={Rt} // mech. resistance
            Mt={Mt * 1e-3} // mass, pass value from [g] to [kg]
            Ct={Ct * 1e-3} // compliance, pass value from [mm/N] to [m/N]
          />
        </div>
        <div className="slider-block">
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default MicrophoneEldyn;
