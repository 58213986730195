/* eslint-disable */
// @ts-nocheck

import { AzimuthAngles, ITD, ILD } from "./LocalizationCueData";

export function getItdGraphData(): Array<{ x: number; y: number }> {
  var N = AzimuthAngles.length;
  let itdGraphData = new Array(N);
  for (let n = 0; n < N; n++) {
    itdGraphData[n] = {
      x: AzimuthAngles[n],
      y: ITD[n],
    };
  }
  return itdGraphData;
}

export function getIldGraphData(): Array<{ x: number; y: number }> {
  var N = AzimuthAngles.length;
  let ildGraphData = new Array(N);
  for (let n = 0; n < N; n++) {
    ildGraphData[n] = {
      x: AzimuthAngles[n],
      y: ILD[n],
    };
  }
  return ildGraphData;
}
