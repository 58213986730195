import React, { useState, useEffect } from "react";
import { SwitchOption } from "@jelly/ui";
export default function WaveGuideLeftRight(props) {
  const { properties } = props;

  const [lr, setLR] = useState("L");

  useEffect(() => {
    if (lr === "L") {
      properties.signal.left_right = "left";
      properties.signal.A.abs = 1;
      properties.signal.A.arg = 0;
      properties.signal.B.abs = 0;
      properties.signal.B.arg = 0;
    } else if (lr === "R") {
      properties.signal.left_right = "right";
      properties.signal.A.abs = 0;
      properties.signal.A.arg = 0;
      properties.signal.B.abs = 1;
      properties.signal.B.arg = 0;
    }
  }, [lr]);

  return (
    <SwitchOption
      items={[{ name: "Left source" }, { name: "Right source" }]}
      onClick={(value) => {
        if (value == 0) {
          setLR("L");
        }
        if (value == 1) {
          setLR("R");
        }
      }}
    />
  );
}
