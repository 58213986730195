import React, { useState } from "react";
import { signalSelect } from "../../helpers/WaveGuide.signals.js";
import { SwitchOption } from "@jelly/ui";
export default function WaveGuideSignals({ properties }) {
  let { signals, physics } = properties;
  const [signal_selected, setSignal] = useState(0);

  // handle for selection of signals
  const handleChangeSignal = (value) => {
    // if reflection keep R coefficient
    const R = properties.signal.Rf;

    const new_signal_selected = Number(value);
    properties.signal = signalSelect(signals[new_signal_selected], physics);

    setSignal(new_signal_selected);

    // if reflection, then set R
    if (physics.reflection) {
      properties.signal.setReflection(R, physics);
    }

    properties.flag_recalculate = true;
  };

  return (
    <>
      {
        /* --- SELECT signal --------------------------------*/
        properties.signals.length > 1 && (
          <SwitchOption
            items={signals.map((option) => {
              return { name: `<img class="button-icon" src="${option.icon}"/> ${option.title}` };
            })}
            onClick={handleChangeSignal}
          />
        )
      }
    </>
  );
}
