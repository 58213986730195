import { complex, abs, arg, divide, pi } from "mathjs";

// slider for each parameter
export const sliders = [
  {
    name: "Mt",
    title: "M<sub>t</sub>",
    unit: "g",
    min: 0.1,
    max: 1,
    step: 0.01,
    edit: false,
  },
  {
    name: "Rt",
    title: "R<sub>t</sub>",
    unit: "Ns/m",
    min: 1,
    max: 20,
    step: 0.1,
    edit: false,
  },
  {
    name: "Ct",
    title: "C<sub>t</sub>",
    unit: "mm/N",
    min: 0.1,
    max: 1,
    step: 0.01,
    edit: false,
  },
];

// calculate impedance of a RLC circuit
export function sensitivity(Rt, Mt, Ct, f_axis) {
  // prepare arrays for absVal and phase
  let absVal = [];
  let phase = [];
  for (let i = 0; i < f_axis.length; i++) {
    absVal[i] = { x: f_axis[i], y: 0 };
    phase[i] = { x: f_axis[i], y: 0 };
  }

  for (let i = 0; i < f_axis.length; i++) {
    // frequency axis
    let f = f_axis[i];
    let omega = 2 * pi * f;

    let Zt = complex(Rt, omega * Mt - 1 / (Ct * omega));

    let Mr = divide(Rt, Zt);

    // attribute the abs value to the chart.js y-axis
    absVal[i].y = 20 * Math.log10(abs(Mr));
    // attribute the phase to the chart.js y-axis
    phase[i].y = (180 / pi) * arg(Mr);
  }

  return { absVal, phase };
}

// calculate impedance of a RLC circuit
export function frequencyLimits(Rt, Mt, Ct) {
  let fl = 1 / (2 * pi * Rt * Ct);
  let fh = Rt / (2 * pi * Mt);

  let fl_line = [
    { x: fl, y: 5 },
    { x: fl, y: -30 },
  ];
  let fh_line = [
    { x: fh, y: 5 },
    { x: fh, y: -30 },
  ];
  let db_line = [
    { x: 10, y: -3 },
    { x: 20e3, y: -3 },
  ];

  if (fl < 10) fl_line = undefined;
  if (fh > 20e3) fh_line = undefined;

  return { fl_line, fh_line, db_line };
}
