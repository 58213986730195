import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { eddyCurrenstFnc } from "./EddyCurrents.helper";

class EddyCurrentsGraph extends Component {
  render() {
    // List of inputs ("props") :
    // Re ... el. resistance [Ohm]
    // Le ... el. inductance [H]
    // coupling_factor ... coupling factor  [-]
    // Q ... Rs/Ls [ohm/H]
    const { Re, Le, coupling_factor, Q, mode } = this.props;

    // frequency axis
    const f_axis = LogSpace(Math.log10(20), Math.log10(20e3), 500);

    // abs and phase
    const { R, L } = eddyCurrenstFnc(Re, Le, coupling_factor, Q, f_axis, mode);
    const data = {
      title: "EddyCurrents",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
        min: 20,
        max: 20e3,
      },
      yAxes: [
        {
          data: [R],
          yLabel: "Apparent Resistance (Ohm)",
          type: "linear",
          min: 0,
          max: 30,
        },
        {
          data: [L],
          yLabel: "Apparent Inductance (mH)",
          type: "linear",
          min: 0,
          max: 1,
        },
      ],
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={0.6} />;
  }
}
export default EddyCurrentsGraph;
