import React, { Component } from "react";
import { Button } from "@jelly/ui";

// Component used to play audio
// props:   freq ... frequency [Hz]
//          osc  ... array of oscillators, each oscillator has: { A, init_phase, last_phase}

const BUFFER_SIZE = 4096;

class FourierAudio extends Component {
  state = {
    is_playing: false, // BOOL (audio is/(is not) playing)
  };

  audioContext = null;
  componentDidUpdate() {
    // extract props from parrent
    let { osc, freq } = this.props;

    // on component mount create audioContext (Web Audio API)
    // scriptNode -> output (audioContext.destination)
    if (this.audioContext === null && this.state.is_playing === true) {
      // audio context
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      this.audioContext = new AudioContext();

      // script node
      this.scriptNode = this.audioContext.createScriptProcessor(BUFFER_SIZE, 0, 2);
    }

    if (this.audioContext !== null) {
      // on change any of the properties check if audio is on (checkbox)
      if (this.state.is_playing === true) {
        // connect the scriptNode to the destination (it will start playing)
        this.scriptNode.onaudioprocess = function (audioProcessingEvent) {
          const outputBuffer = audioProcessingEvent.outputBuffer;
          const N_channels = outputBuffer.numberOfChannels;
          const fs = outputBuffer.sampleRate;
          // go through each output channel
          for (let channel = 0; channel < N_channels; channel++) {
            let outputData = outputBuffer.getChannelData(channel);

            // sample-by-sample processing
            for (let sample = 0; sample < outputBuffer.length; sample++) {
              let out_sample = 0;
              // accumulate each oscillator
              for (let i = 0; i < osc.length; i++) {
                let frequency = (i + 1) * freq;
                out_sample += osc[i].A * Math.sin((2 * Math.PI * frequency * sample) / fs + osc[i].last_phase);
              }
              outputData[sample] = out_sample / osc.length; // normalize to avoid saturation
            }
          }
          // calculate the last phase of each oscillator to ensure smooth audio
          for (let i = 0; i < osc.length; i++) {
            let frequency = (i + 1) * freq;
            osc[i].last_phase += (2 * Math.PI * frequency * outputBuffer.length) / fs;
          }
        };

        this.scriptNode.connect(this.audioContext.destination);
      } else {
        // disconnect the oscilator from the gain (it will stop playing)
        this.scriptNode.disconnect();
      }
    }
  }

  // when unmounting close the audio context
  componentWillUnmount() {
    if (this.audioContext !== null) {
      this.audioContext.close();
    }
  }

  // handle for checkbox audio playing
  handleChangePlay = () => {
    this.setState((prevState) => ({
      is_playing: !prevState.is_playing,
    }));
  };

  render() {
    return (
      <Button
        onClick={this.handleChangePlay}
        icon={this.state.is_playing ? "cil-media-pause" : "cil-media-play"}
        defaultChecked={this.state.is_playing}
        shape="round"
        size="large"
      ></Button>
    );
  }
}

export default FourierAudio;
