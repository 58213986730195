/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import MicrophoneDirectivityGraph from "./MicrophoneDirectivity.graph";
import MicrophoneDirectivityAudio from "./MicrophoneDirectivity.audio";
import { polarValue } from "./MicrophoneDirectivity.helper";
import { Badge, SwitchOption } from "@jelly/ui";

class MicrophoneDirectivity extends Component {
  // class state
  state = {
    beta: 0.5,
    order: 1,
    sourceAngle: 0,
  };

  // slider parameters
  sliders = [
    {
      title: "Pattern coefficient ß",
      name: "beta",
      unit: "",
      min: 0,
      max: 1,
      step: 0.01,
      edit: false,
    },
  ];

  range_options = [
    { title: "order 1", value: 1 },
    { title: "order 2", value: 2 },
  ];

  dragFnc = (angle) => {
    this.setState({ sourceAngle: angle });
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  // handle for selection of ranges
  handleChangeRange = (value) => {
    this.setState({
      order: Number(value) + 1,
    });
  };

  renderBlock() {
    let { beta, order, sourceAngle } = this.state;
    let gain = polarValue(sourceAngle, beta, order);
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ flexShrink: "1" }}>
          <h5>MICROPHONE DIRECTIVITY PATTERN</h5>
          <p>
            <small>
              The orange point represents the playing music source.
              <br />
              You can move with the source around the microphone directivity function while playing sound.
            </small>
          </p>
          <MicrophoneDirectivityGraph beta={beta} order={order} sourceAngle={sourceAngle} dragFnc={this.dragFnc} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "auto", width: "100%" }}>
          <SwitchOption
            items={this.range_options.map((option) => {
              return { name: option.title };
            })}
            onClick={this.handleChangeRange}
          />
          <div style={{ flex: "1", width: "100%" }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
              );
            })}
          </div>

          <MicrophoneDirectivityAudio gain={gain} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}

export default MicrophoneDirectivity;
