import { complex, multiply, divide, add, subtract, abs } from "mathjs";

function getFrequencyIndex(fc, f_axis) {
  let f_axis_shifted = f_axis.map((f) => Math.abs(f - fc));
  return f_axis_shifted.indexOf(Math.min(...f_axis_shifted));
}

// calculate impedance of a RLC circuit
export function calculateFilters(fc, Rl, polarity, dataMatlab) {
  let frequency_idx = getFrequencyIndex(fc, dataMatlab.f);

  // Linkwitz-Ryley filters
  let Zwoofer_at_resonance = dataMatlab.Zwoofer[frequency_idx];
  let Ztweeter_at_resonance = dataMatlab.Ztweeter[frequency_idx];
  let L1 = Zwoofer_at_resonance / Math.PI / fc - 1e-3; // -1e-3 is here to compensate the driver inductance
  let L2 = Ztweeter_at_resonance / Math.PI / fc;
  let C1 = 1 / Math.PI / Zwoofer_at_resonance / 4 / fc;
  let C2 = 1 / Math.PI / Ztweeter_at_resonance / 4 / fc;

  // prepare arrays for graphs
  let sumOfSpeakers = [];
  let wooferFiltered = [];
  let tweeterFiltered = [];

  // add or subtracy (tweeter polarity)
  let fncAddOrSubstract = add;
  if (polarity) fncAddOrSubstract = subtract;

  for (let i = 0; i < dataMatlab.f.length; i++) {
    // frequency axis
    let f = dataMatlab.f[i];
    let omega = 2 * Math.PI * f;

    let Zw = complex({
      abs: Number(dataMatlab.Zwoofer[i]),
      arg: (Number(dataMatlab.ZwooferPhase[i]) * Math.PI) / 180,
    });
    let Zt = complex({
      abs: Number(dataMatlab.Ztweeter[i]),
      arg: (Number(dataMatlab.Ztweeter[i]) * Math.PI) / 180,
    });

    let jwC1inv = complex(0, -1 / (omega * C1));
    let jwC2inv = complex(0, -1 / (omega * C2));
    let jwL1 = complex(0, omega * L1);
    let jwL2 = complex(0, omega * L2);

    let Zp = divide(multiply(Zw, jwC1inv), add(Zw, jwC1inv));
    // Zp=Zw/1j/C1./om./(Zw+1./1j/C1./om);
    let Uw = divide(Zp, add(jwL1, Zp));
    // Uw=Zp./(1j*om*L1+Zp);

    Zp = divide(multiply(Zt, jwL2), add(Zt, jwL2));
    // Zp=Zt*1j*L2.*om./(Zt+1j*L2.*om);
    let Ut = multiply(divide(Zp, add(Zp, jwC2inv)), divide(Zt, add(Rl, Zt)));
    // Ut=Zp./(1/1j./om/C2+Zp).*Zt./(Rl+Zt);

    let Pw = complex({
      abs: Number(dataMatlab.woofer[i]),
      arg: (Number(dataMatlab.wooferPhase[i]) * Math.PI) / 180,
    });
    let Pt = complex({
      abs: Number(dataMatlab.tweeter[i]),
      arg: (Number(dataMatlab.tweeter[i]) * Math.PI) / 180,
    });

    let Woofer = multiply(Pw, Uw);
    let Tweeter = multiply(Pt, Ut);

    // attribute the impedance to the chart.js
    wooferFiltered[i] = { x: f, y: 20 * Math.log10((abs(Woofer) / 2e-5) * 2.83) };
    tweeterFiltered[i] = { x: f, y: 20 * Math.log10((abs(Tweeter) / 2e-5) * 2.83) };
    sumOfSpeakers[i] = {
      x: f,
      y: 20 * Math.log10((abs(fncAddOrSubstract(Woofer, Tweeter)) / 2e-5) * 2.83),
    };
  }

  return { wooferFiltered, tweeterFiltered, sumOfSpeakers };
}
