/**
 * When you have 2 sets of x,y points with different number of X elements,
 * use this to stretch your smaller (sourceData) array over a longer one.
 *
 * A new array will be returned, with Y values at indexes closest to ones in xLabels.
 * Missing indexes are filled with nulls.
 *
 * @param sourceData  - array containing arrays with X and Y at 0,1 indexes
 * @param xLabels  - array with X values
 * @returns
 */
export function stretchPointsForXY(sourceData: number[][], xLabels: number[]): Array<number | null> {
  let sourceDataIndex = 0;
  const extendedData: Array<number | null> = [];
  for (let i = 0; i < xLabels.length; i++) {
    const sourceValue = sourceData[sourceDataIndex];
    if (sourceDataIndex >= sourceData.length) {
      break;
    }
    if (xLabels[i] <= sourceValue[0] && sourceValue[0] < xLabels[i + 1]) {
      sourceDataIndex += 1;
      extendedData[i] = sourceValue[1];
    } else {
      extendedData[i] = null;
    }
  }
  return extendedData;
}
