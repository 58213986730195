import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
// import { LogSpace } from "../../helpers/helpers";
import { sensitivity } from "./MicrophoneElstat.helper";

class MicrophoneElstatGraph extends Component {
  render() {
    // List of inputs ("props") :
    //        D  ... Diameter of the diaphragm [m]
    //        d0 ... Distance between electrodes at rest [m]
    //        nu ... stretching force per unit length [N.m-1]
    //        U0 ... Bias voltage [V] (std 200 V)
    //        Ra ... Acoustic resistance of holes in the back electrode [kg.s-1.m-2]
    const { D, d0, nu, U0, Ra } = this.props;

    // frequency axis
    const f_ax = LogSpace(0, 7, 800);

    // abs and phase
    const absVal = sensitivity(D, d0, nu, U0, Ra, f_ax);
    const data = {
      title: "Microphone Sensitivity",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [absVal],
          min: -100,
          yLabel: "Sensitivity  (dB)",
          type: "linear",
        },
      ],
      animation: 0,
    };

    return <LineGraph data={data} graph_size_ratio={1} />;
  }
}
export default MicrophoneElstatGraph;
