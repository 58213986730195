class Numpy {
  static linspace(startValue: number, stopValue: number, cardinality: number): number[] {
    const arr: number[] = [];
    const step = (stopValue - startValue) / (cardinality - 1);
    for (let i = 0; i < cardinality; i++) {
      arr.push(startValue + step * i);
    }
    return arr;
  }

  static meshgrid(x: number[], y: number[]): [number[][], number[][]] {
    const X: number[][] = [];
    const Y: number[][] = [];

    for (let j = 0; j < y.length; j++) {
      X[j] = [];
      Y[j] = [];
      for (let i = 0; i < x.length; i++) {
        X[j][i] = x[i];
        Y[j][i] = y[j];
      }
    }

    return [X, Y];
  }
  static npArraySubtractScalar(arr: number[][], scalar: number): number[][] {
    return arr.map((row) => row.map((val) => val - scalar));
  }

  static npScalarSubtractArray(scalar: number, arr: number[][]): number[][] {
    return arr.map((row) => row.map((val) => scalar - val));
  }

  static npArrayAddScalar(arr: number[][], scalar: number): number[][] {
    return arr.map((row) => row.map((val) => val + scalar));
  }

  static npArrayAdd(arr1: number[][], arr2: number[][]): number[][] {
    return arr1.map((row, i) => row.map((val, j) => val + arr2[i][j]));
  }

  static ArrayDivideArray(arr1: number[][], arr2: number[][]): number[][] {
    return arr1.map((row, i) => row.map((val, j) => val / arr2[i][j]));
  }

  static npArrayPow2(arr: number[][]): number[][] {
    return arr.map((row) => row.map((val) => Math.pow(val, 2)));
  }
  static npArraySqrt(arr: number[][]): number[][] {
    return arr.map((row) => row.map((val) => Math.sqrt(val)));
  }
  static npArrayExp(arr: number[][]): number[][] {
    return arr.map((row) => row.map((val) => Math.exp(val)));
  }

  static ArrayDivide(arr: number[][], scalar: number): number[][] {
    return arr.map((row) => row.map((val) => val / scalar));
  }
  static ArrayMultiply(arr: number[][], scalar: number): number[][] {
    return arr.map((row) => row.map((val) => val * scalar));
  }
}

export default Numpy;
