// import { scaleWithLimits } from "../../../helpers/helpers";

import { scaleWithLimits } from "../../../../../helpers/ant-novak/helpers";

export function distanceFromPoint(a, b) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function distanceFromArea(a, b) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function setDensityPoints(rho) {
  // density points
  return [
    Math.round(scaleWithLimits(rho[0], 1, 1000, 5, 20, 5, 20)),
    Math.round(scaleWithLimits(rho[1], 1, 1000, 5, 20, 5, 20)),
  ];
}

export function prepareDataTime(x, time, physics, signal) {
  const L = 1.0; // 1 m long waveguide

  const N = 100; // number of points on the time graph
  const fs = 100e3; // sample rate

  // prepare signals as arrays of N points
  let pressureSignal = Array(N);
  let velocitySignal = Array(N);

  // special case: v0 = vL = 0
  if ((signal.A.abs === 0) & (signal.B.abs === 0)) {
    for (let i = 0; i < N; i++) {
      let t = i / fs; // sampling period
      pressureSignal[i] = {
        x: 1000 * t,
        y: 0,
      };
      velocitySignal[i] = {
        x: 1000 * t,
        y: 0,
      };
    }
  } else {
    for (let i = 0; i < N; i++) {
      let t = i / fs; // sampling period

      pressureSignal[i] = {
        x: 1000 * t,
        y: physics.pressureFnc(signal, x, L, -t + time, physics, 0),
      };
      velocitySignal[i] = {
        x: 1000 * t,
        y: 1000 * physics.velocityFnc(signal, x, L, -t + time, physics, 0),
      };
    }
  }
  return {
    pressureSignal: pressureSignal,
    velocitySignal: velocitySignal,
  };
}

export function prepareDataStanding(physics, signal) {
  const L = 1.0; // 1 m long wveguide
  const N = 500;

  let { A, B, k, Rf } = signal;
  let draw = signal.standing_waves && (signal.A.abs * signal.B.abs !== 0 || Rf.abs() > 0);

  // if reflection at the end calculate standing wave from B as being Rf
  if (physics.reflection?.edit) {
    B = { abs: Rf.abs(), arg: Rf.arg() - k[0] * L };
  }
  let ABpows = Math.pow(A.abs, 2) + Math.pow(B.abs, 2);
  let ABmul = 2 * A.abs * B.abs;
  let ABphi = A.arg + B.arg;
  let rhoC = 1 / (physics.rho[0] * physics.c0[0]);
  let pressureStandingA = Array(N);
  let pressureStandingB = Array(N);
  let velocityStandingA = Array(N);
  let velocityStandingB = Array(N);

  for (let i = 0; i < N; i++) {
    let x = (i / N) * L; // sampling period
    let penv = Math.sqrt(ABpows + ABmul * Math.cos(2 * k[0] * (x - L / 2) + ABphi));
    let venv = rhoC * Math.sqrt(ABpows - ABmul * Math.cos(2 * k[0] * (x - L / 2) + ABphi));

    pressureStandingA[i] = {
      x: x,
      y: draw ? penv : NaN,
    };
    pressureStandingB[i] = {
      x: x,
      y: draw ? -penv : NaN,
    };
    velocityStandingA[i] = {
      x: x,
      y: draw ? 1000 * venv : NaN,
    };
    velocityStandingB[i] = {
      x: x,
      y: draw ? -1000 * venv : NaN,
    };
  }
  return {
    pressureStandingA,
    pressureStandingB,
    velocityStandingA,
    velocityStandingB,
  };
}

export function standingWaveRate(signal) {
  let A = signal.A.abs;
  let B = signal.B.abs;
  let pmax_env = Math.sqrt(A * A + B * B + 2 * A * B);
  let pmin_env = Math.sqrt(A * A + B * B - 2 * A * B);
  return pmax_env / pmin_env;
}

export function prepareDataSpace(time, physics, signal) {
  const L = 1.0; // 1 m long wveguide
  const N = 500;

  let pressureSignal = Array(N);
  let velocitySignal = Array(N);
  for (let i = 0; i < N; i++) {
    let x = (i / N) * L; // sampling period

    pressureSignal[i] = {
      x: x,
      y: physics.pressureFnc(signal, x, L, time, physics, 0),
    };
    velocitySignal[i] = {
      x: x,
      y: 1000 * physics.velocityFnc(signal, x, L, time, physics, 0),
    };
  }
  return {
    pressureSignal,
    velocitySignal,
  };
}

export function prepareDataImpedance(signal) {
  const L = 1.0; // 1 m long wveguide
  const N = 500;

  let Z_abs = Array(N);
  let Z_phase = Array(N);
  let Z_abs_0 = Array(N);
  let Z_phase_0 = Array(N);

  let A = signal.A;
  let B = signal.B;
  let alpha = signal.alpha[0];
  let k = signal.k[0];

  for (let i = 0; i < N; i++) {
    let x = (i / N) * L; // sampling period

    let impedanceData = calcImpedance(A, B, alpha, x, k);
    let impedanceData_0 = calcImpedance(A, B, alpha, x, k);

    Z_abs[i] = {
      x: x,
      y: Math.log10(impedanceData.p_module / impedanceData.v_module),
    };
    Z_phase[i] = {
      x: x,
      y: wrap_to_pi_over_2(impedanceData.p_phase - impedanceData.v_phase),
    };
    Z_abs_0[i] = {
      x: x,
      y: Math.log10(impedanceData_0.p_module / impedanceData_0.v_module),
    };
    Z_phase_0[i] = {
      x: x,
      y: wrap_to_pi_over_2(impedanceData_0.p_phase - impedanceData_0.v_phase),
    };
  }

  return {
    Z_abs,
    Z_phase,
    Z_abs_0,
    Z_phase_0,
  };
}

function calcImpedance(A, B, alpha, x, k) {
  let p_module = Math.sqrt(
    Math.pow(
      A.abs * Math.exp(-alpha * x) * Math.cos(k * x - A.arg) + B.abs * Math.exp(alpha * x) * Math.cos(k * x + B.arg),
      2
    ) +
      Math.pow(
        A.abs * Math.exp(-alpha * x) * Math.sin(k * x - A.arg) - B.abs * Math.exp(alpha * x) * Math.sin(k * x + B.arg),
        2
      )
  );

  let v_module = Math.sqrt(
    Math.pow(
      A.abs * Math.exp(-alpha * x) * Math.cos(k * x - A.arg) - B.abs * Math.exp(alpha * x) * Math.cos(k * x + B.arg),
      2
    ) +
      Math.pow(
        A.abs * Math.exp(-alpha * x) * Math.sin(k * x - A.arg) + B.abs * Math.exp(alpha * x) * Math.sin(k * x + B.arg),
        2
      )
  );

  let p_phase = Math.atan2(
    A.abs * Math.exp(-alpha * x) * Math.sin(k * x - A.arg) - B.abs * Math.exp(alpha * x) * Math.sin(k * x + B.arg),
    A.abs * Math.exp(-alpha * x) * Math.cos(k * x - A.arg) + B.abs * Math.exp(alpha * x) * Math.cos(k * x + B.arg)
  );

  let v_phase = Math.atan2(
    A.abs * Math.exp(-alpha * x) * Math.sin(k * x - A.arg) + B.abs * Math.exp(alpha * x) * Math.sin(k * x + B.arg),
    A.abs * Math.exp(-alpha * x) * Math.cos(k * x - A.arg) - B.abs * Math.exp(alpha * x) * Math.cos(k * x + B.arg)
  );

  return { p_module, v_module, p_phase, v_phase };
}

export function prepareDataImpedanceFreq(signal, physics) {
  const L = 1.0; // 1 m long wveguide
  const N = 1000;

  let Z_abs = Array(N);

  let Rg = signal.Rg.abs();
  let phi_Rg = signal.Rg.arg();

  let k1 = (2 * Math.PI) / physics.c0[0];
  let k2 = physics.loss_factor / physics.radius.values[0];

  let f_max = 1000;
  for (let i = 0; i < N; i++) {
    let f = (i / N) * f_max; // sampling period
    let k = k1 * f + k2 * Math.sqrt(f);
    let alpha = (signal.loss_factor * Math.sqrt(f)) / physics.radius.values[0];

    let p_module = Math.sqrt(
      Math.pow(Rg * Math.exp(-2 * alpha * L) * Math.cos(k * L - phi_Rg) + Math.cos(k * L), 2) +
        Math.pow(Rg * Math.exp(-2 * alpha * L) * Math.sin(k * L - phi_Rg) - Math.sin(k * L), 2)
    );

    let v_module = Math.sqrt(
      Math.pow(Rg * Math.exp(-2 * alpha * L) * Math.cos(k * L - phi_Rg) - Math.cos(k * L), 2) +
        Math.pow(Rg * Math.exp(-2 * alpha * L) * Math.sin(k * L - phi_Rg) + Math.sin(k * L), 2)
    );

    Z_abs[i] = {
      x: f,
      y: p_module / v_module,
    };
  }

  let freqLine = [
    { x: signal.freq.value, y: 0.01 },
    { x: signal.freq.value, y: 100 },
  ];
  let freqPoint = [{ x: signal.freq.value, y: 1 }];

  return { Z_abs, freqPoint, freqLine };
}

function wrap_to_pi_over_2(angle) {
  angle = mod(angle, 2 * Math.PI); // 0 ... 2*PI
  angle = angle > Math.PI ? angle - 2 * Math.PI : angle; // -PI:PI
  let tmp = mod(angle + Math.PI / 2, Math.PI);
  return tmp - Math.PI / 2 + Math.PI * (angle > 0 && tmp === 0);
}

function mod(n, m) {
  return ((n % m) + m) % m;
}

export function getWaveguideIndex(x, x_interface) {
  return x < x_interface ? 0 : 1;
}

export function reflectionCoefficientRadiation(signal, physics) {
  let k = signal.k[0];
  let H = physics.radius.values[0];

  return { abs: Math.exp(-Math.pow(k * H, 2) / 2), arg: -1.4 * k * H };
}
