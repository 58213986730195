import { Slider, Spinner } from "@jelly/ui";
import config from "appConfig";
import { ChartConfiguration } from "chart.js";
import React, { useEffect, useMemo, useState } from "react";
import ChartBlock from "../../components/common/asknow/ChartBlock/ChartBlock";
import { fourierChartOptions, signalChartOptions } from "./chartConfig";
import useNumpy from "../../hooks/useNumpy";

import styles from "./Lesson0304.module.scss";

const renderChart = (data: ChartConfiguration): React.ReactElement => {
  return (
    <div className={styles.chart}>
      <ChartBlock
        style={{
          height: "220px",
        }}
        canvasStyle={{ width: "700px", height: "220px" }}
        data={data}
      />
    </div>
  );
};

const renderLoader = (): React.ReactElement => {
  return (
    <div className={styles.loader}>
      <Spinner />
    </div>
  );
};

const limitConvert = (value: number): number => {
  return Math.round(value * 10) / 10;
};

const Lesson0304: React.FC = () => {
  const [amplitude, setAmplitude] = useState<number>(1);
  const [lowerLimit, setLowerLimit] = useState<number>(-1);
  const [upperLimit, setUpperLimit] = useState<number>(1);
  const numpyData = useNumpy(`${config.ASSETS_URL}/0304/dataJS.npy`);

  const [signalsData, setSignalsData] = useState<ChartConfiguration>(signalChartOptions);
  const [fourierData, setFourierData] = useState<ChartConfiguration>(fourierChartOptions);

  const signalChartData = useMemo(() => {
    return {
      ...signalChartOptions,
      ...signalsData,
    };
  }, [signalsData]);
  const fourierChartData = useMemo(() => {
    return {
      ...fourierChartOptions,
      ...fourierData,
    };
  }, [fourierData]);

  useEffect(() => {
    if (numpyData) {
      handleChartDataUpdate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numpyData, upperLimit, lowerLimit, amplitude]);

  if (!numpyData) {
    // Wait for Numpy download and conversion.
    return renderLoader();
  }

  const getData = (index: number, start: number, end: number): number[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Array.from(numpyData.data.slice(index * 1402 + start, index * 1402 + end));
  };

  const handleChartDataUpdate = (): void => {
    const lowerLimitUpdate = limitConvert(lowerLimit);
    const upperLimitUpdate = limitConvert(upperLimit);
    let indexLowerLimit = Math.round((lowerLimitUpdate + 3) * 10);
    let indexUpperLimit = Math.round((upperLimitUpdate + 3) * 10);

    if (indexLowerLimit > indexUpperLimit) {
      [indexLowerLimit, indexUpperLimit] = [indexUpperLimit, indexLowerLimit];
    }

    const index = indexLowerLimit * 61 + indexUpperLimit;
    const signalData = getData(index, 2, 402); // 400 elements
    const realData = getData(index, 402, 902); // 500 elements
    const imagData = getData(index, 902, 1402); // 500 elements
    const magnitudeData = realData.map((val, i) => Math.sqrt(val ** 2 + imagData[i] ** 2));

    setSignalsData({
      ...signalsData,
      data: {
        ...signalsData.data,
        datasets: [
          {
            ...(signalChartOptions.data?.datasets ? signalChartOptions.data?.datasets[0] : {}),
            data: signalData.map((val) => val * amplitude),
          },
        ],
      },
      options: {
        ...signalsData.options,
        scales: {
          ...signalsData.options?.scales,
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Time Signal",
                fontStyle: "bold",
                fontColor: "#131A4F",
                fontFamily: "'Mulish', 'Arial', sans-serif",
              },
              ticks: {
                min: 0,
                max: 1.05 * amplitude,
              },
            },
          ],
        },
      },
    });

    const allValues = [...realData, ...imagData, ...magnitudeData];
    setFourierData({
      ...fourierData,
      data: {
        ...fourierData.data,
        datasets: [
          {
            ...(fourierChartOptions.data?.datasets ? fourierChartOptions.data?.datasets[0] : {}),
            data: realData.map((val) => val * amplitude),
          },
          {
            ...(fourierChartOptions.data?.datasets ? fourierChartOptions.data?.datasets[1] : {}),
            data: imagData.map((val) => val * amplitude),
          },
          {
            ...(fourierChartOptions.data?.datasets ? fourierChartOptions.data?.datasets[2] : {}),
            data: magnitudeData.map((val) => val * amplitude),
          },
        ],
      },
      options: {
        ...fourierData.options,
        scales: {
          ...fourierData.options?.scales,
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Fourier Transform",
                fontStyle: "bold",
                fontColor: "#131A4F",
                fontFamily: "'Mulish', 'Arial', sans-serif",
              },
              ticks: {
                min: Math.min(...allValues) * amplitude - 0.1,
                max: Math.max(...allValues) * amplitude + 0.1,
              },
            },
          ],
        },
      },
    });
  };

  return (
    <div className={styles.lesson0304}>
      <div className={styles.chartsContainer}>
        {renderChart(signalChartData)}
        {renderChart(fourierChartData)}
      </div>
      <div className={styles.controlsContainer}>
        <div className={styles.sliderControl}>
          <label>Amplitude = {amplitude.toFixed(1)}</label>
          <Slider theme={"primary"} min={0} max={5} step={0.1} value={amplitude} onChange={setAmplitude} />
        </div>
        <div className={styles.sliderControl}>
          <label>a = {limitConvert(lowerLimit).toFixed(1)}</label>
          <Slider theme={"primary"} min={-3} max={3} step={0.1} value={lowerLimit} onChange={setLowerLimit} />
        </div>
        <div className={styles.sliderControl}>
          <label>b = {limitConvert(upperLimit).toFixed(1)}</label>
          <Slider theme={"primary"} min={-3} max={3} step={0.1} value={upperLimit} onChange={setUpperLimit} />
        </div>
      </div>
    </div>
  );
};

export default Lesson0304;
