import React, { Component } from "react";
/* eslint-disable */
// @ts-nocheck
class TrackViewer extends Component {
  constructor() {
    super();
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    this.draw();
  }

  render() {
    var gainVal = "-";
    if (this.props.encoderGains[this.props.chNr] != undefined) {
      gainVal = (this.props.encoderGains[this.props.chNr][0] || 0).toFixed(2);
    }
    return (
      <div>
        <small>
          Channel: {this.props.chNr} Gain: {gainVal}
        </small>
        <canvas ref={this.canvasRef} />
      </div>
    );
  }

  draw() {
    const canvas = this.canvasRef;

    if (canvas.current !== null) {
      var ctx = canvas.current.getContext("2d");
      canvas.current.width = 180;
      canvas.current.height = 60 / 2;
      ctx.fillStyle = "rgb(255, 255, 255)";
      ctx.fillRect(0, 0, canvas.current.width, canvas.current.height);

      ctx.lineWidth = 2;
      ctx.strokeStyle = "rgb(255, 0, 0)";
      ctx.beginPath();

      var sliceWidth = canvas.current.width / this.props.bufferSize;
      var x = 0;

      for (var i = 0; i < this.props.bufferSize; i++) {
        var val = 0;
        if (this.props.audioBuffer[this.props.chNr][i]) {
          val = this.props.audioBuffer[this.props.chNr][i];
        }
        var y = val * (canvas.current.height / 2) + canvas.current.height / 2;

        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      ctx.lineTo(canvas.current.width, canvas.current.height / 2);
      ctx.stroke();
    }
    if (this.props.encoderGains[this.props.chNr] != undefined) {
      this.props.getCurrentAudioFrame(this.props.chNr);
    }
    requestAnimationFrame(this.draw.bind(this));
  }
}

export default TrackViewer;
