import React, { Component } from "react";
import HideDiv from "../HideDiv";
import WaveGuideGraphSpace from "./components/graphs/WaveGuide.graphSpace.jsx";
import WaveGuideGraphTime from "./components/graphs/WaveGuide.graphTime.jsx";
import WaveGuideGraphImpedance from "./components/graphs/WaveGuide.graphImpedance.jsx";
import WaveGuideGraphImpedanceFreq from "./components/graphs/WaveGuide.graphImpedanceFreq.jsx";
import WaveGuideSignals from "./components/signals/WaveGuide.signals.jsx";
import WaveGuideAmplitude from "./components/settings/WaveGuide.amplitude.jsx";
import WaveGuideFCW from "./components/settings/WaveGuide.fcw.jsx";
import WaveGuideOpenClosed from "./components/settings/WaveGuide.openclosed.jsx";
import WaveGuideReflection from "./components/settings/WaveGuide.reflection.jsx";
import WaveGuideLeftRight from "./components/settings/WaveGuide.leftright.jsx";
import WaveGuideMedium from "./components/settings/WaveGuide.medium.jsx";
import WaveGuideShowWaves from "./components/settings/WaveGuide.showWaves.jsx";
import WaveGuideRadius from "./components/settings/WaveGuide.radius.jsx";
import WaveGuideFrequency from "./components/settings/WaveGuide.frequency.jsx";
import WaveGuideAnimation from "./components/animation/WaveGuide.animation.jsx";
import WaveGuideAnimationInformation from "./components/animation/WaveGuide.animation.information.jsx";

import { setDensityPoints } from "./helpers/WaveGuide.helper.js";
import { signalSelect } from "./helpers/WaveGuide.signals.js";

import { Button, SwitchOption } from "@jelly/ui";
// import HideDiv from "../UI/HideDiv.jsx";
import "./WaveGuide.css";

const dt_default = 0.1;

class WaveGuide extends Component {
  constructor(props) {
    super();
    this.state = {
      // the only parameter that make component update is time
      // other parameters are state independent
      t: 0, // time
      show_graph_space_selected: !(props.graphs.impedance === true),
      show_graph_time_selected: false,
      show_graph_impedance_selected: props.graphs.impedance,
      show_graph_impedance_freq_selected: props.graphs.impedance_freq,
    };
    this.properties = {
      dt: dt_default, // time increment
      physics: props.physics,
      signals: props.signals,
      signal: signalSelect(props.signals[0], props.physics),
      animation: {
        animation_rate: props.animation_rate || 3000,
        num_of_particles: { groups: 250, particles_per_group: 20 }, // number of particles
        colormap: { symmetric: true, nshades: 255, ...props.colormap },
        velocity_arrows: props.velocity_arrows ?? {
          can_be_modified: true,
          show: false,
          size_ratio: 5000,
        },
        pressure_colors: props.pressure_colors ?? { can_be_modified: true, show: true },
        //ASPECT RATIO
        canvas_ratio: 0.2, // ratio of canvas height/width
        color_region: props.color_region ?? { xmin: 0, xmax: 1, box: false },
        arrow_region: props.arrow_region ?? { xmin: 0, xmax: 1, box: false },
      },
      microphone: props.microphone ?? { show: false, x: 0.5 },
      graphs: props.graphs,
      flag_recalculate: true,
      flag_update_reflection: false,
      text: props.text ?? [],
    };
    this.modifiers = {
      signal_selected: 0,
      //   space_graph_show: true,
      //   fkc_show: true,
    };

    this.updateCoefficients();
  }

  componentDidMount() {
    // update animation every dt [ms]
    this.interval = setInterval(() => {
      let { t } = this.state;
      this.setState({ t: t + this.properties.dt }); // increment time by dt [s]

      // if properties need to be recalculated
      if (this.properties.flag_recalculate) {
        this.updateProperties();
        this.properties.flag_recalculate = false;
      }

      // animation restart for non-harmonic signals
      let time_repeat = this.properties.signal.time_repeat;
      if (time_repeat > 0) {
        // restart automatically animation
        if (t > (time_repeat * this.properties.animation.animation_rate) / 1000) {
          this.setState({ t: 0.0 });
        }
      }
    }, Math.round(1000 * dt_default)); // update every dt [ms]
  }

  // clean after finishing
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateCoefficients() {
    let { signal, physics, animation } = this.properties;
    animation.points_density = setDensityPoints(physics.rho); // 5 to 20
    signal.setWavenumer(physics.c0);
    signal.setLambda(physics.c0);
    if (!physics.reflection?.edit && !physics.openclosed) {
      // update coefficients of reflection from physics
      signal.setReflectionPhysics(physics);
    }
    this.updateAmplitudes(this.properties);
  }

  /* Amplitudes A and B can be constant or variable.
     If it is a variable it need to be updated when needed
     as a function on other parameters.  */
  updateAmplitudes(properties) {
    let { signal, physics } = properties;
    // update amplitudes of signals if dependent on parameters
    if (signal.A.fnc !== undefined) {
      signal.A.fnc(signal, physics);
    }
    if (signal.B.fnc !== undefined) {
      signal.B.fnc(signal, physics);
    }
  }

  updateProperties() {
    this.updateCoefficients();

    // update p_max and v_max (maximum values of pressure and velocity)
    if (this.properties.signal.graph_limits.auto) {
      this.properties.signal.setPressureMaxima();
      this.properties.signal.setVelocityMaxima(this.properties.physics.c0, this.properties.physics.rho);
    }
  }

  // handles for Restart and Pause
  handleRestart = (event) => {
    this.properties.dt = dt_default;
    this.setState({ t: 0.0 });
  };
  handlePause = (event) => {
    this.properties.dt = this.properties.dt === 0.0 ? dt_default : 0.0;
  };

  render() {
    let {
      t,
      show_graph_space_selected,
      show_graph_time_selected,
      show_graph_impedance_selected,
      show_graph_impedance_freq_selected,
    } = this.state;
    let { animation, physics, signal, signals, graphs, microphone, flag_update_reflection } = this.properties;

    const show_amplitudes_sliders = signal.A.edit || signal.B.edit || signals.length > 1;

    const show_frequency_sliders = signal.freq.can_be_modified_with_c0;
    const show_frequency = signal.freq.can_be_modified;
    const show_reflection_edit = physics.reflection?.edit;
    const show_slider_A = signal.A.edit;
    const show_slider_B = signal.B.edit;
    const show_left_right = signal.edit_left_right;
    const show_medium_choice = physics.medium?.edit;
    const show_radius_sliders = physics.radius?.edit;
    const show_waves_choice = physics.showWaves?.edit;

    const show_graph_space = graphs.space;
    const show_graph_time = microphone.show;
    const show_graph_impedance = graphs.impedance;
    const show_graph_impedance_freq = graphs.impedance_freq;
    const show_open_closed = physics.openclosed;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        {/* -------------------------------------------------*/
        /* --- REFLECTION COEFFICIENT -----------------------*/
        /* --------------------------------------------------*/}

        {show_medium_choice && <WaveGuideShowWaves physics={physics} />}

        {/* --------------------------------------------------*/}
        {/* --- ANIMATION ------------------------------------*/}
        {/* --------------------------------------------------*/}

        <WaveGuideAnimation t={t} properties={this.properties} />

        {/* --------------------------------------------------*/}
        {/* --- IMPEDANCE CHART ------------------------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_impedance && show_graph_impedance_selected && (
          <div className="one-graph graph">
            <WaveGuideGraphImpedance physics={physics} signal={signal} />
          </div>
        )}

        {/* --------------------------------------------------*/}
        {/* --- IMPEDANCE FREQUENCY CHART --------------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_impedance_freq && show_graph_impedance_freq_selected && (
          <div className="one-graph graph">
            <WaveGuideGraphImpedanceFreq updateAmplitudes={this.updateAmplitudes} properties={this.properties} />
          </div>
        )}

        {/* --------------------------------------------------*/}
        {/* --- SPACE CHART (Aco.pres & vel) -----------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_space && show_graph_space_selected && (
          <div className="one-graph graph">
            <WaveGuideGraphSpace
              properties={this.properties}
              x={this.properties.microphone.x}
              t={t / animation.animation_rate}
              physics={physics}
              signal={signal}
            />
          </div>
        )}

        {/* --------------------------------------------------*/}
        {/* ----------    TIME CHART (Mic pos)   -------------*/}
        {/* --------------------------------------------------*/}
        {show_graph_time && show_graph_time_selected && (
          <>
            <div className="one-graph graph">
              <WaveGuideGraphTime
                x={this.properties.microphone.x}
                t={t / animation.animation_rate}
                physics={physics}
                signal={signal}
                showTitle={false}
              />
            </div>
          </>
        )}

        {/* --------------------------------------------------*/
        /* --- MEDIUM PROPERTIES -----------------------------*/
        /* --------------------------------------------------*/}
        {show_medium_choice && (
          <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
            <WaveGuideMedium idx={0} properties={this.properties} />
            <WaveGuideMedium idx={1} properties={this.properties} />
          </div>
        )}

        <div className="hint default">
          <i className="cil-comment-bubble-exclamation"></i>
          <WaveGuideAnimationInformation t={t} properties={this.properties} />
        </div>

        {/* -------------------------------------------------*/
        /* --- SLIDERS (frequency, wavelength, speed) -------*/
        /* --------------------------------------------------*/}
        {show_frequency_sliders && (
          <WaveGuideFCW c0={physics.c0[0]} freq={signal.freq.value} properties={this.properties} />
        )}

        {/* --------------------------------------------------*/
        /* --- FREQUENCY -------------------------------------*/
        /* --------------------------------------------------*/}
        {show_frequency && (
          <div style={{ width: "80%", margin: "auto", paddingBottom: "1em" }}>
            {/* Radius */}
            {show_radius_sliders && (
              <WaveGuideRadius
                properties={this.properties}
                value={physics.radius.values[0]}
                updateAmplitudes={this.updateAmplitudes}
              />
            )}

            <WaveGuideFrequency
              properties={this.properties}
              value={signal.freq.value}
              minmax={signal.freq.minmax}
              updateAmplitudes={this.updateAmplitudes}
            />
          </div>
        )}

        {/* -------------------------------------------------*/
        /* --- SLIDERS/DROPDOWN          (radius,phi) -------*/
        /* --------------------------------------------------*/
        /*& this.properties.physics.reflection.types.length > 3)*/}

        {show_reflection_edit && (
          <WaveGuideReflection
            properties={this.properties}
            signal={signal}
            physics={physics}
            update_flag={flag_update_reflection}
          />
        )}
        {/* --------------------------------------------------*/
        /* --- SIGNAL SLIDERS (amplitude phase) -------------*/
        /* --------------------------------------------------*/}

        <div className="slider-block">
          {show_amplitudes_sliders && (
            <div className="slider-row">
              {
                /* --- SLIDER Amplitude A ------------------------*/
                show_slider_A && (
                  <div className="slider-col">
                    <WaveGuideAmplitude properties={this.properties} name="A" title="A" />
                  </div>
                )
              }
              {
                /* --- SLIDER Amplitude B ------------------------*/
                show_slider_B && (
                  <div className="slider-col">
                    <WaveGuideAmplitude properties={this.properties} name="B" title="B" />
                  </div>
                )
              }
            </div>
          )}
        </div>

        {
          /* --- source from LEFT or RIGHT------------------*/
          show_left_right && <WaveGuideLeftRight properties={this.properties} />
        }
        {/* Signal select */}
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {this.properties.signals.length > 1 && (
            <div style={{ flex: "1" }}>
              <WaveGuideSignals properties={this.properties} />
            </div>
          )}
          {/* Resonators */ show_open_closed && <WaveGuideOpenClosed properties={this.properties} />}
          {/* Graph switcher for lessons 3-6*/}
          {show_graph_space && show_graph_time && !show_graph_impedance && (
            <div>
              <SwitchOption
                items={[{ name: "p/v vs. space" }, { name: "p/v vs. time at mic. position" }]}
                onClick={(value) => {
                  if (value == 0) {
                    this.setState({
                      show_graph_space_selected: true,
                      show_graph_time_selected: false,
                    });
                  } else {
                    this.setState({
                      show_graph_space_selected: false,
                      show_graph_time_selected: true,
                    });
                  }
                }}
              />
            </div>
          )}
          {/* Graph switcher for lessons 7*/}
          {show_graph_space && show_graph_time && show_graph_impedance && (
            <div>
              <SwitchOption
                items={[{ name: "Z/phase vs. space" }, { name: "p/v vs. space" }, { name: "p/v vs. time" }]}
                onClick={(value) => {
                  if (value == 0) {
                    this.setState({
                      show_graph_impedance_selected: true,
                      show_graph_space_selected: false,
                      show_graph_time_selected: false,
                    });
                  }
                  if (value == 1) {
                    this.setState({
                      show_graph_impedance_selected: false,
                      show_graph_space_selected: true,
                      show_graph_time_selected: false,
                    });
                  } else if (value == 2) {
                    this.setState({
                      show_graph_impedance_selected: false,
                      show_graph_space_selected: false,
                      show_graph_time_selected: true,
                    });
                  }
                }}
              />
            </div>
          )}
          {/* Graph switcher for lessons 8*/}
          {show_graph_time && show_graph_impedance_freq && (
            <div>
              <SwitchOption
                items={[{ name: "Acc. impedance" }, { name: "p/v vs. time" }]}
                onClick={(value) => {
                  if (value == 0) {
                    this.setState({
                      show_graph_impedance_freq_selected: true,
                      show_graph_time_selected: false,
                    });
                  }
                  if (value == 1) {
                    this.setState({
                      show_graph_impedance_freq_selected: false,
                      show_graph_time_selected: true,
                    });
                  }
                }}
              />
            </div>
          )}

          <div>
            <Button
              size="large"
              shape="round"
              onClick={this.handlePause}
              icon={this.properties.dt != 0.0 ? "cil-media-pause" : "cil-media-play"}
            />
          </div>
          <div>
            <Button size="large" shape="round" onClick={this.handleRestart} icon="cil-replay" />
          </div>
        </div>
      </div>
    );
  }
}

export default WaveGuide;
