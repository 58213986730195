import React, { Component } from "react";
import HalfPolarGraph from "../../components/common/ant-novak/ChartJS/HalfPolarGraph";
import { COLORS } from "../../helpers/ant-novak/helpers";
// import HalfPolarGraph from "../UI/ChartJS/HalfPolarGraph";
//import { LogSpace } from "../../helpers/helpers";
import { directivity, microphonePoints } from "./MicrophoneArray.helper";
// import { COLORS } from "../../helpers/helpers";

class MicrophoneArrayGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   frequency [Hz]
    //   dB ... dynamics of the graph
    // -------------------------
    const { frequency, dB, distance, n_of_transducers, theta0, sources_angle } = this.props;

    // calculate directivity
    const graphData = directivity(theta0, distance, frequency, n_of_transducers, -dB);
    const microphonesData = microphonePoints(n_of_transducers, distance, dB);
    const sourcesPoints = [
      { angle: sources_angle, dB: 10 },
      { angle: -sources_angle, dB: 10 },
    ];

    const data = {
      title: "",
      data: [graphData, microphonesData, [sourcesPoints[0]], [sourcesPoints[1]]],
      axis: { min: -dB, max: 0 },
      pointRadius: [0, 3, 10, 10],
      borderWidth: [3, 0, 0, 0],
      color: [COLORS[0], COLORS[1], COLORS[3], COLORS[4]],
    };

    return <HalfPolarGraph data={data} />;
  }
}
export default MicrophoneArrayGraph;
