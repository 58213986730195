import React, { Component } from "react";
import Chart from "chart.js";
import { COLORS } from "../../../../helpers/ant-novak/helpers";
import { SetYaxisTicks } from "./graph.helpers";

class LineGraphDrag extends Component {
  myChart = {};
  chartRef = React.createRef();

  componentDidMount() {
    this.myChart = new Chart(
      this.chartRef.current.getContext("2d"), // context
      this.createChartContext() // what to render
    );
  }

  componentDidUpdate() {
    // update chart data for each line in the graph
    let k = 0;
    for (let i = 0; i < this.props.data.yAxes.length; i++) {
      // update data
      for (let j = 0; j < this.props.data.yAxes[i].data.length; j++) {
        this.myChart.data.datasets[k].data = this.props.data.yAxes[i].data[j];
        k += 1;
      }
      // update type of the yAxis (linear or logarithmic)
      this.myChart.options.scales.yAxes[i].type = this.props.data.yAxes[i].type;
      // update type of the yAxis (linear or logarithmic)
      this.myChart.options.scales.yAxes[i].ticks.min = this.props.data.yAxes[i].min;
      this.myChart.options.scales.yAxes[i].ticks.max = this.props.data.yAxes[i].max;
    }
    this.myChart.options.title.text = this.props.data.title === undefined ? "" : this.props.data.title;
    this.myChart.update();
  }

  render() {
    return (
      <div>
        <canvas id="myChart" ref={this.chartRef} width="100%" height={this.props.graph_size_ratio * 100 + "%"} />
      </div>
    );
  }

  // -------------------------------------
  // createDataset, createYAxes, createChartContext
  // ... functions to prepare chart data and options
  // -------------------------------------
  createDataset = (idxAxis, idxData, idxColor) => {
    const yAxes = this.props.data.yAxes[idxAxis];

    return {
      data: yAxes.data[idxData],
      label: yAxes.label?.[idxData] ?? "",
      fill: false,
      pointRadius: yAxes.pointRadius?.[idxData] ?? 0,
      borderColor: yAxes.borderColor?.[idxData] ?? COLORS[idxColor],
      backgroundColor: yAxes.backgroundColor?.[idxData] ?? COLORS[idxColor],
      borderDash: yAxes.borderDash?.[idxData] ?? undefined,
      borderWidth: yAxes.lineWidth?.[idxData] ?? 3,
      lineTension: yAxes.lineTension ?? 0,
      cubicInterpolationMode: yAxes.interpolation ?? "linear",
      yAxisID: idxAxis,
      dragData: yAxes.dragData ?? true,
      defaultDragY: yAxes?.defaultDragY?.[idxData] ?? 0,
    };
  };

  createYAxes = (idxAxis) => {
    const yAxes = this.props.data.yAxes[idxAxis];
    return {
      scaleLabel:
        yAxes.yLabel === undefined
          ? { display: false }
          : {
              display: true,
              fontColor: COLORS[idxAxis],
              labelString: yAxes.yLabel,
            },
      type: yAxes.type,
      ticks: {
        min: yAxes.min,
        max: yAxes.max,
        fontColor: COLORS[idxAxis],
        callback: (value, index, values) => {
          return SetYaxisTicks(value, yAxes.type, yAxes.scale);
        },
      },
      position: idxAxis === 0 ? "left" : "right",
      id: idxAxis,
      gridLines: { drawOnChartArea: idxAxis === 0 }, // grid lines only for first dataset
    };
  };

  createChartContext = () => {
    const {
      xAxes,
      yAxes,
      dragX = false,
      dragY = true,
      onDragUpdate = false,
      title,
      animation = 100,
      point_radius = 0,
    } = this.props.data;
    const dragFnc = this.props.func;

    // prepare datasets
    const datasets = [];
    let k = 0;
    for (let i = 0; i < yAxes.length; i++) {
      for (let j = 0; j < yAxes[i].data.length; j++) {
        datasets[k] = this.createDataset(i, j, k);
        k += 1;
      }
    }

    // prepare yAxes
    const yAxesData = [];
    for (let i = 0; i < yAxes.length; i++) {
      yAxesData[i] = this.createYAxes(i);
    }

    return {
      type: "line",
      data: {
        datasets: datasets,
      },
      options: {
        tooltips: { enabled: false },
        hover: { mode: null },

        dragData: true,
        dragX: dragX,

        onDragStart: function (e, element) {
          // where e = event
        },
        onDrag: function (e, datasetIndex, index, value) {
          // change cursor style to grabbing during drag action
          value.y = dragY ? value.y : datasets[datasetIndex].defaultDragY;
          e.target.style.cursor = "grabbing";
          if (onDragUpdate) dragFnc(e, datasetIndex, index, value);
          // where e = event
        },
        onDragEnd: dragFnc,

        title: title === undefined ? { display: false } : { display: true, text: title },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: xAxes.xLabel,
              },
              type: xAxes.type,
              ticks: {
                callback: (value, index, values) => {
                  return SetYaxisTicks(value, xAxes.type);
                },
              },
            },
          ],
          yAxes: yAxesData,
        },
        legend: {
          display: yAxes[0].label !== undefined,
        },
        animation: {
          duration: animation, // general animation time
        },
        elements: {
          point: {
            radius: point_radius,
          },
        },
      },
    };
  };
}

export default LineGraphDrag;
