export const SPEAKER_POSITIONS = [
  [180.0, -31.7161, 0.5878],
  [180.0, 31.7161, 0.5878],
  [-121.7161, 0, 0.5878],
  [121.7161, 0, 0.5878],
  [-90.0, -58.2839, 0.5878],
  [-90.0, 58.2839, 0.5878],
  [90.0, -58.2839, 0.5878],
  [90.0, 58.2839, 0.5878],
  [-58.2839, 0, 0.5878],
  [58.2839, 0, 0.5878],
  [0, -31.7161, 0.5878],
  [0, 31.7161, 0.5878],
];
