/* eslint-disable no-console */

class Lesson0312Data {
  private xx = [
    29.9, 35.0375, 39.4319, 43.5048, 47.4705, 51.0075, 53.5798, 57.8671, 61.4041, 64.0836, 67.5134, 72.015, 76.4094,
    82.1972, 87.3419, 92.0579, 97.0954, 101.4898, 106.5273, 111.672, 116.1736, 120.4609, 125.0697, 130.2497,
  ];

  convert_distance_cars_to_cars_per_hour(distance_cars: number, speed_cars_kmph: number): number {
    if (distance_cars < 0) {
      throw new Error("distance_cars cannot be smaller than 0.");
    }
    if (speed_cars_kmph < 0) {
      throw new Error("speed_cars_kmph cannot be smaller than 0.");
    }
    return speed_cars_kmph / (distance_cars / 1000);
  }

  calculate_sound_line_power(car_sound_power: number, cars_per_hour: number, car_speed: number): number {
    if (car_sound_power < 0) {
      throw new Error("car_sound_power cannot be smaller than 0.");
    }
    if (cars_per_hour < 0) {
      throw new Error("cars_per_hour cannot be smaller than 0.");
    }
    if (car_speed < 0) {
      throw new Error("car_speed cannot be smaller than 0.");
    }
    return (car_sound_power * cars_per_hour) / (car_speed * 1000);
  }

  convert_db_to_energy(sound_power_db: number): number {
    if (sound_power_db < 0) {
      throw new Error("sound_power_db cannot be smaller than 0.");
    }
    if (sound_power_db == 0) {
      return 0;
    }
    return Math.pow(10, sound_power_db / 10) * 1e-12;
  }

  convert_energy_to_db(sound_power: number): number {
    if (sound_power === 0) {
      return 0;
    } else {
      return 10 * Math.log10(sound_power / 1e-12);
    }
  }

  calculate_deltaR(a: number, b: number, c: number, wavelength: number): number {
    if (a < 0) {
      throw new Error("a cannot be smaller than 0.");
    }
    if (b < 0) {
      throw new Error("b cannot be smaller than 0.");
    }
    if (c < 0) {
      throw new Error("c cannot be smaller than 0.");
    }
    if (wavelength < 0) {
      throw new Error("wavelength cannot be smaller than 0.");
    }

    const d = a + b - c;
    return 10 * Math.pow(Math.log10(2 * Math.PI), 2) * (d / wavelength);
  }

  is_number(value: number, name: string): void {
    if (typeof value !== "number") {
      throw new Error(name + " have to be a real number.");
    }
  }

  is_number_and_smaller_than(value: number, name: string, limit: number): void {
    if (typeof value !== "number") {
      throw new Error(name + " " + typeof value + " have to be a real number.");
    }
    if (value < limit) {
      throw new Error(name + " cannot be smaller than " + limit + ".");
    }
  }

  generate_coordinates(x_length_m: number, y_length_m: number, resolution: number): [number[], number[]] {
    const x_size = Math.floor(x_length_m / resolution) + 1;
    const y_size = Math.floor(y_length_m / resolution) + 1;
    const x = new Array(x_size).fill(0).map((_, i) => i * resolution);
    const y = new Array(y_size).fill(0).map((_, i) => i * resolution);
    return [x, y];
  }

  calculate_line_source_pressure(
    x: number[],
    index_line_source: number,
    line_source_power: number,
    mat: number[][],
    is_x_dim: boolean,
    noise_barriers: number[][],
    f: number,
    h_r = 1.7,
    h_s = 1
  ): number[][] {
    const pos_line_source = x[index_line_source];
    let sound_pressure = 0;
    for (const r of [
      [...Array(x.length - index_line_source - 1).keys()].map((i) => i + index_line_source + 1),
      [...Array(index_line_source).keys()].map((i) => index_line_source - 1 - i),
    ]) {
      let is_barrier = false;
      let barrier_position = 0;
      let barrier_height = 0;

      for (const i of r) {
        if (!is_x_dim) {
          if (noise_barriers[index_line_source][i] > 0 && noise_barriers[index_line_source + 1][i] > 0) {
            is_barrier = true;
            barrier_position = x[i];
            barrier_height = noise_barriers[index_line_source][i];
          }
        }
        if (is_x_dim) {
          if (noise_barriers[i][index_line_source] > 0 && noise_barriers[i][index_line_source + 1] > 0) {
            is_barrier = true;
            barrier_position = x[i];
            barrier_height = noise_barriers[i][index_line_source];
          }
        }

        if (is_barrier) {
          const h_b = barrier_height;
          const d_s = pos_line_source - barrier_position;
          const d_r = barrier_position - x[i];
          const a = Math.sqrt((h_b - h_s) ** 2 + d_s ** 2);
          const b = Math.sqrt((h_b - h_r) ** 2 + d_r ** 2);
          const c = Math.sqrt((h_r - h_s) ** 2 + (d_r + d_s) ** 2);
          const d = a + b - c;

          if (d === 0) {
            sound_pressure = Math.min(
              Math.min(line_source_power / (2 * Math.PI * c), line_source_power),
              sound_pressure
            );
          } else {
            const delta_r = this.calculate_deltaR(a, b, c, 343 / f);
            sound_pressure = line_source_power / Math.pow(10, delta_r / 10);
          }

          if (is_x_dim) {
            for (let j = 0; j < mat[i].length; j++) {
              mat[i][j] += sound_pressure;
            }
          } else {
            for (let j = 0; j < mat.length; j++) {
              mat[j][i] += sound_pressure;
            }
          }
        } else {
          const d = Math.abs(pos_line_source - x[i]);
          const r = Math.sqrt((h_r - h_s) ** 2 + d ** 2);
          let sound_pressure;

          if (d === 0) {
            sound_pressure = line_source_power;
          } else {
            sound_pressure = Math.min(line_source_power / (2 * Math.PI * r), line_source_power);
          }

          if (is_x_dim) {
            for (let j = 0; j < mat[i].length; j++) {
              mat[i][j] += sound_pressure;
            }
          } else {
            for (let j = 0; j < mat.length; j++) {
              mat[j][i] += sound_pressure;
            }
          }
        }
      }

      if (is_x_dim) {
        for (let j = 0; j < mat[index_line_source].length; j++) {
          mat[index_line_source][j] += line_source_power;
        }
      } else {
        for (let j = 0; j < mat.length; j++) {
          mat[j][index_line_source] += line_source_power;
        }
      }
    }

    return mat;
  }

  calculate_generate_map(
    x: number[],
    y: number[],
    x_line_source: number[],
    y_line_source: number[],
    noise_barriers: number[][],
    f: number,
    h_r = 1.7,
    h_s = 1
  ): number[][] {
    let mat = new Array(x.length).fill(null).map(() => new Array(y.length).fill(0));

    for (let i = 0; i < x_line_source.length; i++) {
      const val = x_line_source[i];
      if (val > 0) {
        mat = this.calculate_line_source_pressure(x, i, val, mat, true, noise_barriers, f, h_r, h_s);
      }
    }

    for (let i = 0; i < y_line_source.length; i++) {
      const val = y_line_source[i];
      if (val > 0) {
        mat = this.calculate_line_source_pressure(y, i, val, mat, false, noise_barriers, f, h_r, h_s);
      }
    }

    return mat;
  }

  plot_sound_pressure_level(x: number[], y: number[], mat: number[][]): void {
    const mat_db = mat.map((row) => row.map((val) => this.convert_energy_to_db(val)));

    const grid = mat_db;
    // Assuming you have a suitable way to display the grid as an image
    // eslint-disable-next-line no-console
    console.log(grid); // Just for illustration, replace this with actual visualization code
  }

  get_sound_power_car_from_speed(speed: number): number {
    const yy = [
      44.4271, 45.6468, 46.5955, 47.4538, 48.1314, 48.8542, 49.2608, 49.9836, 50.5708, 50.8419, 51.2936, 51.9713,
      52.423, 52.9651, 53.6427, 53.8234, 54.5914, 54.7269, 55.4045, 55.7659, 56.0821, 56.4435, 56.6694, 57.0308,
    ];

    for (let i = 0; i < this.xx.length; i++) {
      const diff = speed - this.xx[i];
      if (diff < 0) {
        const index_left = i - 1;
        const index_right = i;
        const x_diff_tot = this.xx[index_right] - this.xx[index_left];
        const x_diff = speed - this.xx[index_left];
        const y_diff_tot = yy[index_right] - yy[index_left];
        const y_diff = (x_diff / x_diff_tot) * y_diff_tot;
        return y_diff + yy[index_left];
      }
    }
    return 0; // Handle this case as needed
  }

  calculate_sound_pressure_level(
    res = 0.1,
    map_length_meter = 10,
    x_distance_cars = 100,
    x_speed_cars_kmph = 130,
    x_line_source_position = 5,
    y_distance_cars = 200,
    y_speed_cars_kmph = 100,
    y_line_source_position = 4,
    x_noise_barrier_height = 3,
    y_noise_barrier_height = 3,
    f = 1000,
    h_r = 1.7,
    h_s = 1
  ): { x_coords: number[]; y_coords: number[]; mat: number[][]; x_car_sound_power: number; y_car_sound_power: number } {
    const x_car_sound_power = this.convert_db_to_energy(this.get_sound_power_car_from_speed(x_speed_cars_kmph));
    const x_cars_per_hour = this.convert_distance_cars_to_cars_per_hour(x_distance_cars, x_speed_cars_kmph);
    const x_sound_power_line_source = this.calculate_sound_line_power(
      x_car_sound_power,
      x_cars_per_hour,
      x_speed_cars_kmph
    );

    const y_car_sound_power = this.convert_db_to_energy(this.get_sound_power_car_from_speed(y_speed_cars_kmph));
    const y_cars_per_hour = this.convert_distance_cars_to_cars_per_hour(y_distance_cars, y_speed_cars_kmph);
    const y_sound_power_line_source = this.calculate_sound_line_power(
      y_car_sound_power,
      y_cars_per_hour,
      y_speed_cars_kmph
    );

    const [x_coords, y_coords] = this.generate_coordinates(map_length_meter, map_length_meter, res);

    const x_line_source: number[] = new Array(x_coords.length).fill(0);
    x_line_source[Math.floor(x_line_source_position / res)] = x_sound_power_line_source;

    const y_line_source: number[] = new Array(y_coords.length).fill(0);
    y_line_source[Math.floor(y_line_source_position / res)] = y_sound_power_line_source;

    const noise_barriers: number[][] = new Array(x_coords.length)
      .fill(null)
      .map(() => new Array(y_coords.length).fill(0));

    for (let ix = 0; ix < x_coords.length; ix++) {
      noise_barriers[Math.floor((x_line_source_position + 2 * res) / res)][ix] = x_noise_barrier_height;
    }

    for (let iy = 0; iy < y_coords.length; iy++) {
      noise_barriers[iy][Math.floor((y_line_source_position + 2 * res) / res)] = y_noise_barrier_height;
    }

    const mat = this.calculate_generate_map(
      x_coords,
      y_coords,
      x_line_source,
      y_line_source,
      noise_barriers,
      f,
      h_r,
      h_s
    );

    mat[0][0] = mat[1][1];
    for (let i = 0; i < mat.length; i++) {
      mat[0][i] = mat[1][i];
    }
    for (let i = 0; i < mat[0].length; i++) {
      mat[i][0] = mat[i][1];
    }

    return { x_coords, y_coords, mat, x_car_sound_power, y_car_sound_power };
  }

  reshapeArray(array: number[][], rows: number): number[][] {
    const flatArray = array.flat();

    const reshapedArray: number[][] = [];

    while (flatArray.length) reshapedArray.push(flatArray.splice(0, rows));

    return reshapedArray;
  }
}

export default Lesson0312Data;
