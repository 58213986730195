import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import ImageViewer from "../../components/common/ant-novak/ImageViewer";
import { pad } from "../../helpers/ant-novak/helpers";
// import LineGraph from "../UI/ChartJS/LineGraph";
import { prepareData, getImageIndex, speakers_options } from "./NonlinearLoudspeaker.helper";
// import ImageViewer from "../UI/ImageViewer";
// import { pad } from "../../helpers/helpers";

const x_max = 5; // maximal displacement [mm]
const N = 26; // number of image files

class NonlinearLoudspeakerGraph extends Component {
  constructor(props) {
    super(props);
    this.images = Array.from(Array(speakers_options.length), () => new Array(N));
    speakers_options.map((speaker) => {
      for (let i = 0; i < N; i++) {
        this.images[speaker.value][i] = "/assets/img_loudspeaker/" + speaker.file_name_start + pad(i, 2) + ".jpg";
      }
      return null;
    });
  }

  render() {
    // List of inputs ("props") :
    //   u   ... voltage
    const { x, selected_speaker } = this.props;

    const { Bl_curve, Bl_point } = prepareData(x, x_max, selected_speaker);
    const plotData = {
      title: "Bl(x)",
      xAxes: {
        xLabel: "position (mm)",
        type: "linear",
      },
      yAxes: [
        {
          yLabel: "Bl (T.m)",
          data: [Bl_curve, Bl_point],
          min: 0,
          max: 10,
          type: "linear",
          pointRadius: [0, 6],
        },
      ],
    };

    const activeImageIndex = getImageIndex(x - selected_speaker.x_dc, x_max, N);
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "45%" }}>
          <ImageViewer
            images={this.images}
            activeImageGroup={selected_speaker.value}
            activeImageIndex={activeImageIndex}
            width="100%"
            alt="loudspeaker"
          />
        </div>
        <div style={{ width: "100%" }}>
          <LineGraph data={plotData} graph_size_ratio={0.6} />
        </div>
      </div>
    );
  }
}

export default NonlinearLoudspeakerGraph;
