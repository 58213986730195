import React, { useState, useEffect } from "react";
import { Dropdown } from "@jelly/ui";

export default function WaveGuideShowWaves({ physics }) {
  const [showWaves_selected, setShowWaves] = useState(0);

  useEffect(() => {
    if (showWaves_selected === 0) {
      physics.showWaves = { incident: 1, reflected: 1 };
    } else if (showWaves_selected === 1) {
      physics.showWaves = { incident: 1, reflected: 0 };
    } else if (showWaves_selected === 2) {
      physics.showWaves = { incident: 0, reflected: 1 };
    }
  }, [showWaves_selected]);

  return (
    <>
      <Dropdown
        options={[
          { children: "All waves", value: 0 },
          { children: "Without reflected wave", value: 1 },
          { children: "Without incident wave", value: 2 },
        ]}
        listSize="small"
        value={showWaves_selected}
        onChange={(value) => setShowWaves(Number(value))}
      />
    </>
  );
}
