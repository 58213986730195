import { pi, divide, multiply, subtract } from "mathjs";

// calculate resonance frequency Fc
export function parametersEstimate(dataMatlab, Bl, Mms) {
  let Re = [];
  let Le = [];
  let Rms = [];
  let Kms = [];

  let V, I, U, f, omega;
  let Ze, Zms;
  for (let i = 0; i < dataMatlab.frequencies.length; i++) {
    U = dataMatlab.U[i];
    I = dataMatlab.I[i];
    V = dataMatlab.V[i];
    f = dataMatlab.frequencies[i];

    // electric impedance Ze
    Ze = subtract(divide(U, I), multiply(Bl, divide(V, I)));

    // mechanical impedance Zms
    Zms = multiply(Bl, divide(I, V));

    // apparent resistance (Re)
    Re[i] = {
      x: f,
      y: Ze.re,
    };

    // apparent inductance (Le)
    Le[i] = {
      x: f,
      y: (Ze.im / (2 * pi * f)) * 1000,
    };

    if (f <= 300) {
      // mechanica losses Rms
      Rms[i] = {
        x: f,
        y: Zms.re,
      };

      // omega
      omega = 2 * pi * f;

      // stiffness Kms
      Kms[i] = {
        x: f,
        y: omega * (omega * Mms - Zms.im),
      };
    }
  }

  return { Re, Le, Rms, Kms };
}
