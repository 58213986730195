import React, { Component } from "react";
import Chart from "chart.js";
import "chartjs-plugin-annotation";

class LineChart extends Component {
  constructor() {
    super();
    this.chart = {};
    this.chartRef = React.createRef();
  }

  componentDidMount() {
    this.chart = new Chart(this.chartRef.current.getContext("2d"), this.createChartContext());

    this.chart.update();
  }

  componentDidUpdate(previousProps) {
    if (previousProps !== this.props) {
      this.chart.data.datasets = this.props.data.datasets;
      this.chart.options.annotation.annotations = this.props.annotations || [];

      this.chart.update();
    }
  }

  render() {
    return (
      <div style={{ width: this.props.width ?? "auto", height: this.props.height ?? "auto" }}>
        <canvas id="chart" ref={this.chartRef} />
      </div>
    );
  }

  createChartContext() {
    console.log(this.props);
    return {
      type: "scatter",
      data: {
        datasets: this.props.data.datasets,
      },
      options: {
        responsive: this.props.responsive ?? false,
        legend: this.props.data.legend || {},
        animation: {
          duration: 0, // general animation time
        },
        hover: {
          animationDuration: 0, // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0, // animation duration after a resize
        elements: {
          point: {
            radius: 0,
          },
          line: {
            tension: 0.1, // 0 disables bezier curves
            borderWidth: 1,
            fill: false,
            stepped: false,
            borderDash: [],
          },
        },
        scales: {
          xAxes: [
            {
              min: this.props.data.xAxes.min,
              max: this.props.data.xAxes.max,
              type: this.props.data.xAxes.type,
              ticks: {
                min: this.props.data.xAxes.min,
                max: this.props.data.xAxes.max,
                maxTicksLimit: this.props.data.xAxes.maxTicksLimit || 5,
                fontFamily: "'Mulish', 'Arial', sans-serif",
                stepSize: this.props.data.xAxes.stepSize || undefined,
              },
              scaleLabel: {
                display: true,
                labelString: this.props.data.xAxes.xLabel,
                fontColor: "#131A4F",
                fontStyle: "bold",
                fontFamily: "'Mulish', 'Arial', sans-serif",
                fontSize: 10,
              },
            },
          ],

          yAxes: [
            {
              min: this.props.data.yAxes.min,
              max: this.props.data.yAxes.max,
              type: this.props.data.yAxes.type,
              ticks: {
                min: this.props.data.yAxes.min,
                max: this.props.data.yAxes.max,
                beginAtZero: true,
                maxTicksLimit: 5,
                fontFamily: "'Mulish', 'Arial', sans-serif",
              },
              scaleLabel: {
                display: true,
                labelString: this.props.data.yAxes.yLabel,
                fontColor: "#131A4F",
                fontStyle: "bold",
                fontFamily: "'Mulish', 'Arial', sans-serif",
                fontSize: 10,
              },
            },
          ],
        },
        annotation: {
          annotations: this.props.annotations || [],
        },
      },
    };
  }
}

export default LineChart;
