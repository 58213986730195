/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { prepareData } from "./PressureBasics.helper";

class PressureBasicsGraph extends Component {
  state = {};
  render() {
    // List of inputs ("props") :
    //   dataArray ... array with audio data
    let { dataArray_L, dataArray_R, PlotData } = this.props;

    //    console.log(PlotIndex);

    //let { input_signal, output_signal } = signal(A, NLfnc);
    let inputSignal = prepareData(PlotData.channel === 0 ? dataArray_L : dataArray_R, PlotData);

    let signalData = {
      xAxes: {
        xLabel: "Time (ms)",
        type: "linear",
        min: 0,
        max: 20,
      },
      yAxes: [
        {
          data: [inputSignal],
          min: PlotData.zoom_limits[PlotData.zoom_index].min,
          max: PlotData.zoom_limits[PlotData.zoom_index].max,
          type: "linear",
          yLabel: PlotData.name + " " + PlotData.ylabel,
        },
      ],
      animation: 0,
    };

    return <LineGraph data={signalData} graph_size_ratio={0.5} />;
  }
}

export default PressureBasicsGraph;
