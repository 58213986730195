/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import ProgressBar from "../../components/common/ant-novak/ProgressBar";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
import { COLORS } from "../../helpers/ant-novak/helpers";
import AddedMassGraph from "./AddedMass.graph";
import "./AddedMass.css";
import { Switch, Button } from "@jelly/ui";

const title_text =
  "Select a type of measurement and click on 'Start Measurement'. You need to do at least two measurements (one without added mass, another one with an added mass) to be able to get the Thiele-Small parameters.";
const TextButton = ["Start Measurement", "", "Do another measurement"];

class AddedMass extends Component {
  // class state
  state = {
    page: 0, // step
    added_Mms: 10, // mass [g]
    add_mass: false,
    progress: 0,
  };

  // slider parameters
  sliders = [
    {
      title: "Added mass",
      name: "added_Mms",
      unit: "g",
      min: 5,
      max: 20.0,
      step: 1,
    },
  ];

  // handle for radio
  handleChangeRadio = () => {
    this.setState({ ["add_mass"]: !this.state.add_mass });
  };

  // handle for sliders
  handleChange = (name, value) => {
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  // handle for button cliks
  buttonClick = (event) => {
    const new_page = (Number(this.state.page) + 1) % 3;
    this.setState({ page: new_page });

    if (new_page === 1) {
      // show the progress bar
      this.setState({ progress: 0 });

      this.interval = setInterval(
        function () {
          if (this.state.progress >= 100) {
            // hide the progress bar
            this.setState({ page: 2 });
            clearInterval(this.interval);
          }
          this.setState({ progress: this.state.progress + 4 });
        }.bind(this),
        100
      );
    }
  };

  render() {
    const { added_Mms, page, add_mass, progress } = this.state;

    const display_slider_mass = page === 0 && add_mass === true;

    return (
      <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
        <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", width: "100%", minHeight: "80vh" }}>
          <div style={{ flex: "1" }}>
            {page == 1 && <ProgressBar bgcolor={COLORS[0]} completed={progress} />}
            {page == 2 && <AddedMassGraph added_Mms={added_Mms} add_mass={add_mass} />}
          </div>
          <div style={{ display: "flex", alignSelf: "center" }}>
            {page === 0 && <Button onClick={this.buttonClick}>{TextButton[page]}</Button>}
            {page == 2 && (
              <Button icon="cil-replay" onClick={this.buttonClick}>
                {TextButton[page]}
              </Button>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div>
              {page == 0 && <Switch onClick={this.handleChangeRadio} defaultChecked={add_mass} label="Added mass" />}
            </div>
            <div style={{ flex: "1", paddingRight: "10px" }}>
              {display_slider_mass && (
                <SliderEdit
                  onChange={this.handleChange}
                  params={this.sliders[0]}
                  val={this.state[this.sliders[0].name]}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="hint default">
            <i className="cil-comment-bubble-exclamation"></i>
            <p>{title_text}</p>
          </div>
        </div>
      </div>
    );
  }
}
export default AddedMass;
