/* eslint-disable */
// @ts-nocheck
import { Slider, Badge, Button } from "@jelly/ui";
import React, { Component } from "react";
import { monoEncoder } from "ambisonics";
import CircularSlider from "@fseehawer/react-circular-slider";
import * as jshlib from "spherical-harmonic-transform";
import { ReactComponent as LoudspeakerIcon } from "../assets/speaker.svg";
import TrackViewer from "../Chart/TrackViewer";
import styles from "../Lesson0324.module.scss";
import HeadSVG from "../assets/head.svg";
import Figure2SVG from "../assets/figure-2.svg";

class AmbisonicsEncoder extends Component {
  ambisonicsOrder = 2;
  nChannels = (this.ambisonicsOrder + 1) * (this.ambisonicsOrder + 1);
  isPlaying = false;
  constructor() {
    super();
    this.audioPath = "/assets/viola.wav";
    this.state = {
      currentAudioBuffer: new Array(this.nChannels).fill(0),
      encoderGains: [],
      isLoaded: true,
      loopAudio: false,
      buttonState: "Play",
      azimuth: 0,
      elevation: 0,
    };
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.isPlaying != this.props.isPlaying) {
      if (this.props.isPlaying) {
        this.play();
      } else {
        this.stop();
      }
    }
  }
  componentWillUnmount(): void {
    if (this.isPlaying) {
      this.stop();
    }
  }

  initAudio() {
    if (this.audioContext != null) {
      return;
    }

    this.audioContext = new (window.AudioContext || window.webkitAudioContext)();

    this.ambisonicsEncoder = new monoEncoder(this.audioContext, this.ambisonicsOrder);
    this.channelSplitter = this.audioContext.createChannelSplitter(this.nChannels);
    this.ambisonicsEncoder.out.connect(this.channelSplitter);
    this.analyser = new Array(this.nChannels);
    // Create and connect analysers
    for (var i = 0; i < this.nChannels; i++) {
      this.analyser[i] = this.audioContext.createAnalyser();
      this.analyser[i].channelCountMode = "explicit";
      this.analyser[i].channelCount = 1;
      this.channelSplitter.connect(this.analyser[i], i);
    }
    var currentAudioBuffer = new Array(this.nChannels);
    this.analyser.map((analyser, chNr) => (currentAudioBuffer[chNr] = new Float32Array(analyser.fftSize)));
    this.channelSplitter.connect(this.audioContext.destination, 1);
    this.setState({
      currentAudioBuffer: currentAudioBuffer,
      encoderGains: new Array(this.nChannels).fill(0),
      azimuth: 0,
      elevation: 0,
    });

    this.audioSource = document.getElementById("audio");
    this.audioSource.onended = this.onAudioEnded.bind(this);
    this.mediaElementSource = this.audioContext.createMediaElementSource(this.audioSource);
    this.mediaElementSource.connect(this.ambisonicsEncoder.in);
    this.ambisonicsEncoder.azim = this.state.azimuth;
    this.ambisonicsEncoder.elev = this.state.elevation;
    this.ambisonicsEncoder.updateGains();
    this.updateEncoderGains();
  }

  componentWillUnmount() {
    if (this.isPlaying) {
      this.stop();
    }
  }

  render() {
    return (
      <>
        <audio id="audio" loop={this.state.loopAudio} src={this.audioPath}></audio>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1em" }}>
          <div style={{ display: "flex", flexDirection: "row", flex: "1 0 80%", gap: "1em" }}>
            <div style={{ flex: "1 1 40%" }}>
              <div
                style={{
                  flex: "1 0 80%",
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularSlider
                  min={-180}
                  max={180}
                  direction={-1}
                  label="Azimuth"
                  labelColor="#fff"
                  appendToValue="°"
                  knobColor="#131a4f"
                  knobSize={30}
                  knobPosition="bottom"
                  progressSize={0}
                  trackColor="#d0dae9"
                  trackSize={2}
                  hideLabelValue={true}
                  dataIndex={180}
                  onChange={(value) => {
                    this.onSliderChange("azimuth", value);
                  }}
                >
                  <LoudspeakerIcon height="30px" width="30px" x="1" y="1" />
                </CircularSlider>
                <img
                  src={HeadSVG}
                  style={{
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "30%",
                    margin: "auto",
                    width: "100px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: "0 1 20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Azimuth</span>
                <div className={styles.label}>{this.state.azimuth}°</div>
              </div>
            </div>
            <div style={{ flex: "1 0", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ flex: "1 0 80%" }}>
                <Slider
                  min={-90}
                  max={90}
                  step={1}
                  defaultValue={0}
                  vertical
                  theme="primary"
                  onChange={(value) => {
                    this.setState({
                      elevation: value,
                    });
                    this.onSliderChange("elevation", value);
                  }}
                />
              </div>

              <div
                style={{
                  flex: "0 1 20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Elevation</span>

                <div className={styles.label}>{this.state.elevation}°</div>
              </div>
            </div>
            <div style={{ flex: "1 1" }}>
              <img src={Figure2SVG} alt="Figure" />
            </div>
          </div>
          <div style={{ flex: "1 0 20%", display: "flex", flexWrap: "wrap", gap: "5%", maxWidth: "600px" }}>
            {this.state.currentAudioBuffer.map((object, chNr) => (
              <div key={chNr} style={{ flex: "1 1 30%", width: "28%", height: "52px" }}>
                <TrackViewer
                  key={chNr}
                  chNr={chNr}
                  bufferSize={this.analyser != undefined ? this.analyser[chNr].fftSize : 2048}
                  encoderGains={this.state.encoderGains}
                  audioBuffer={this.state.currentAudioBuffer}
                  getCurrentAudioFrame={this.getCurrentAudioFrame.bind(this)}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }

  getCurrentAudioFrame(chNr) {
    var currentAudioBuffer = this.state.currentAudioBuffer;
    this.analyser[chNr].getFloatTimeDomainData(currentAudioBuffer[chNr]);
    this.setState({ currentAudioBuffer });
  }

  updateEncoderGains() {
    var encoderGains = jshlib.computeRealSH(this.ambisonicsOrder, [
      [(this.state.azimuth * Math.PI) / 180, (this.state.elevation * Math.PI) / 180],
    ]);
    this.setState({ encoderGains });
  }

  play() {
    this.initAudio();
    this.audioSource.play();
    this.isPlaying = true;
    this.setState({ buttonState: "Play" });
    this.props.playChange(true);
  }

  stop() {
    this.audioSource.pause();
    this.audioSource.currentTime = 0;
    this.isPlaying = false;
    this.setState({ buttonState: "Stop" });
    this.props.playChange(false);
  }

  playOrStopSound() {
    if (this.isPlaying) {
      this.stop();
    } else {
      this.play();
    }
  }

  onSliderChange(type, value) {
    this.initAudio();
    switch (type) {
      case "azimuth":
        this.setState({ azimuth: value });
        this.ambisonicsEncoder.azim = value;
        break;
      case "elevation":
        this.setState({ elevation: value });
        this.ambisonicsEncoder.elev = value;
        break;
      default:
        this.ambisonicsEncoder.azim = 0;
        this.ambisonicsEncoder.elev = 0;
    }
    this.ambisonicsEncoder.updateGains();
    this.updateEncoderGains();
  }

  onAudioEnded() {
    this.playOrStopSound();
  }

  onClick() {
    this.playOrStopSound();
  }
}

export default AmbisonicsEncoder;
