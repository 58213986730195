import React from "react";
import useBoundingClientRect from "@rooks/use-boundingclientrect";
import classnames from "classnames";
import defaultStyle from "./SoundSourceLocalization.module.scss";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LineChart from "../../components/common/head/Chart/LineChart";
import { getIldGraphData, getItdGraphData } from "./helpers";
import * as Tone from "tone";

const AUDIO_ASSET_URL = "/assets/viola.wav";

function SoundSourceLocalization(): JSX.Element {
  const rootRef = React.useRef<HTMLDivElement | null>(null);
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const rootRect = useBoundingClientRect(rootRef);
  const [isDragging, setIsDragging] = React.useState(false);
  const [angle, setAngle] = React.useState(270);
  const [audioUrl, setAudioUrl] = React.useState<string | null>(null);

  const [angle360, setAngle360] = React.useState<number>(0);

  React.useEffect(() => {
    if (audioUrl == null) return;

    const panner = new Tone.Panner3D().set({
      panningModel: "HRTF",
      positionX: 1,
    });

    const player = new Tone.Player({
      url: audioUrl,
      loop: true,
      autostart: true,
    });

    player.connect(panner);
    panner.toMaster();

    return () => {
      player.stop();
      player.disconnect();
      panner.disconnect();
    };
  }, [audioUrl]);

  // update audio listener based on angle
  React.useEffect(() => {
    const radians = angle * (Math.PI / 180);
    const forwardX = Math.sin(radians);
    const forwardZ = -Math.cos(radians);

    // Set the listener's orientation
    Tone.Listener.setOrientation(forwardX, 0, forwardZ, 0, 1, 0);
  }, [angle]);

  function handleClickButton(url: string): void {
    if (audioUrl === url) {
      setAudioUrl(null);
    } else {
      setAudioUrl(url);
    }
  }

  // update audio listener based on angle
  React.useEffect(() => {
    let newAngle = (angle > 0 ? angle : 360 + angle) + 90;
    if (newAngle >= 360) newAngle -= 360;
    newAngle = (360 - newAngle) % 360; // Reversing the direction
    setAngle360(newAngle);
  }, [angle]);

  function handleDragStart(ev: React.MouseEvent): void {
    setIsDragging(true);
    ev.preventDefault();
  }

  function handleDragMove(ev: React.MouseEvent): void {
    if (!isDragging) return;
    if (rootRect == null) return;
    const centerX = rootRect.left + rootRect.width / 2;
    const centerY = rootRect.top + rootRect.height / 2;
    const deltaX = ev.pageX - centerX;
    const deltaY = ev.pageY - centerY;

    const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

    wrapperRef?.current?.style.setProperty("--pointer-angle", `${angle}deg`);
    setAngle(angle);
  }

  function handleDragStop(): void {
    setIsDragging(false);
  }

  const itdData = {
    datasets: [
      {
        label: null,
        data: getItdGraphData(),
        fill: false,
        borderColor: "#ff6040",
        showLine: true,
      },
    ],
    legend: {
      display: false,
    },
    xAxes: {
      min: 0,
      max: 360,
      xLabel: "Azimuth Angle (°)",
      type: "linear",
    },
    yAxes: {
      min: 0,
      max: 1,
      yLabel: "|ITD| (s)",
      type: "linear",
    },
  };
  const ildData = {
    datasets: [
      {
        //label: "ILD",
        data: getIldGraphData(),
        fill: false,
        borderColor: "#ff6040",
        showLine: true,
      },
    ],

    legend: {
      display: false,
    },

    xAxes: {
      min: 0,
      max: 360,
      xLabel: "Azimuth Angle (°)",
      type: "linear",
    },
    yAxes: {
      min: 0,
      max: 20,
      yLabel: "|ILD| (dB)",
      type: "linear",
    },
  };

  const azimuthAngleAnnotation = [
    {
      type: "line",
      display: true,
      scaleID: "x-axis-1",
      value: angle360,
      borderColor: "rgba(0, 0, 128, 0.5)",
      borderWidth: 4,
    },
  ];

  return (
    <div className={defaultStyle.root}>
      <div
        className={classnames(defaultStyle.circleContainer, {
          [defaultStyle.dragging]: isDragging,
        })}
        ref={rootRef}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragStop}
      >
        <div className={defaultStyle.circle}>
          <div className={defaultStyle.pointerWrapper} ref={wrapperRef}>
            <div className={defaultStyle.arrows} />
            <div className={defaultStyle.pointer} onMouseDown={handleDragStart} />
          </div>
          <div className={defaultStyle.head} />
        </div>
        <div className={defaultStyle.buttonsWrapper}>
          <button
            className={classnames(defaultStyle.audioButton, {
              [defaultStyle.active]: audioUrl === AUDIO_ASSET_URL,
            })}
            onClick={() => handleClickButton(AUDIO_ASSET_URL)}
          >
            <i className="cis-sound" />
            Play sound
          </button>
        </div>
      </div>
      <div className="charts">
        <div className={defaultStyle.chartWrapper}>
          <LineChart
            data={itdData}
            annotations={azimuthAngleAnnotation}
            width="500px"
            height="250px"
            responsive={true}
          />
          <div className={defaultStyle.chartValue}>ITD = 0.4 ms</div>
        </div>
        <div className={defaultStyle.chartWrapper}>
          <LineChart
            data={ildData}
            annotations={azimuthAngleAnnotation}
            width="500px"
            height="250px"
            responsive={true}
          />
          <div className={defaultStyle.chartValue}>ILD = 13 dB</div>
        </div>
      </div>
    </div>
  );
}

export default SoundSourceLocalization;
