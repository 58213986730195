/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import BandPassGraph from "./BandPass.graph";
import BandPassAudio from "./BandPass.audio";
import { range_options } from "./BandPass.helper";
import { Dropdown } from "@jelly/ui";

class BandPass extends Component {
  // class state
  state = {
    f1: range_options[0].f1, // frequency 1
    f2: range_options[0].f2, // frequency 2
    selected_device: 0,
  };

  // slider parameters
  sliders = [
    {
      name: "f1",
      title: "f<sub>1</sub>",
      unit: "Hz",
      min: 10,
      max: 20001,
      log: true,
      step: 0.01,
      edit: false,
    },
    {
      name: "f2",
      title: "f<sub>2</sub>",
      unit: "Hz",
      min: 10,
      max: 20001,
      log: true,
      step: 0.01,
      edit: false,
    },
  ];

  // handle for selection of ranges
  handleChangeRange = (value) => {
    this.setState({
      f1: range_options.find((obj) => obj.value === Number(value)).f1,
      f2: range_options.find((obj) => obj.value === Number(value)).f2,
      selected_device: value,
    });
  };

  // handle for radio and sliders
  handleChange = (name, value) => {
    value = Math.round(Number(value));

    if (name === "f1") {
      this.setState({ f1: value });
      if (this.state.f2 < value) {
        this.setState({ f2: value });
      }
    }

    if (name === "f2") {
      this.setState({ f2: value });
      if (this.state.f1 > value) {
        this.setState({ f1: value });
      }
    }
  };

  renderBlock() {
    let { f1, f2, selected_device } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {/* --------------------------------------------------*/}
        {/* ---------------  CHART SECTION  ------------------*/}
        {/* --------------------------------------------------*/}
        <div className="one-graph" style={{ width: "100%", flexGrow: "1", flexShrink: "0" }}>
          <BandPassGraph f1={f1} f2={f2} />
        </div>
        <div className="slider-block">
          {/* --------------------------------------------------*/}
          {/* sliders */}
          {/* --------------------------------------------------*/}
          <div style={{ marginTop: 20 }}>
            {this.sliders.map((slider, index) => {
              return (
                <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
              );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "50%", margin: "auto" }}>
            {/* --------------------------------------------------*/}
            {/* predefined values */}
            {/* --------------------------------------------------*/}
            <Dropdown
              options={range_options.map((option) => {
                return { children: option.title, value: option.value };
              })}
              listSize="small"
              value={selected_device}
              onChange={this.handleChangeRange}
            />
            <BandPassAudio f1={f1} f2={f2} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}

export default BandPass;
