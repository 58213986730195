import React, { Component } from "react";
import LineGraph from "../../components/common/ant-novak/ChartJS/LineGraph";
import { LogSpace } from "../../helpers/ant-novak/helpers";
import { loudspeaker } from "./AddedMass.helper";

class AddedMassGraph extends Component {
  render() {
    // List of inputs ("props") :
    //   Bl [Tm] ... force factor
    let { added_Mms, add_mass } = this.props;

    if (add_mass === false) added_Mms = 0;

    // impedance and phase
    const f_ax = LogSpace(1, Math.log10(500), 300);
    const { Z_in } = loudspeaker(added_Mms, f_ax);

    const dataImpedance = {
      title: "Loudspeaker Impedance",
      xAxes: {
        xLabel: "Frequency (Hz)",
        type: "logarithmic",
      },
      yAxes: [
        {
          data: [Z_in],
          yLabel: "Absolute value (ohm)",
          type: "linear",
        },
      ],
      tooltip: true,
      animation: 0,
    };

    return (
      <div>
        <div className="one-graph">
          <LineGraph data={dataImpedance} graph_size_ratio={0.5} />
        </div>
      </div>
    );
  }
}
export default AddedMassGraph;
