/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import EddyCurrentsGraph from "./EddyCurrents.graph";
import { sliders, select_Ls_options, select_Ls_values } from "./EddyCurrents.helper";
import { SwitchOption } from "@jelly/ui";

// -------------------------
// Eddy currents :
// -------------------------

class EddyCurrents extends Component {
  // class state
  state = {
    Re: 8, // resistance [ohm]
    Le: 0.5, // inductance [mH]
    coupling_factor: 0.9, // coupling factor
    Q: 20, // Rs/Ls [ohm/H]
    mode: select_Ls_values.CONSTANT,
  };

  // handle for sliders
  handleChange = (name, value) => {
    this.setState({ [name]: Number(value) });
  };

  handleChangeSelect = (value) => {
    this.setState({ mode: Number(value) });
  };

  renderBlock() {
    const { Re, Le, coupling_factor, Q, mode } = this.state;

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div style={{ width: "50%", margin: "auto" }}>
          <SwitchOption
            items={select_Ls_options.map((option, index) => {
              return { name: option.title };
            })}
            onClick={this.handleChangeSelect}
          />
        </div>
        <div style={{ flex: "1" }}>
          <EddyCurrentsGraph
            Re={Re} // el. resistance
            Le={Le * 1e-3} // el. inductance
            coupling_factor={coupling_factor} // intrinsic sensitivity
            Q={Q * 1e3} // Rs/Ls
            mode={mode}
          />
        </div>
        <div>
          {sliders.map((slider, index) => {
            return (
              <SliderEdit key={index} onChange={this.handleChange} params={slider} val={this.state[slider.name]} />
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}
export default EddyCurrents;
