export const reshape = (array: number[], shape: [number, number]): number[][] => {
  const reshaped: number[][] = [];
  let start = 0;
  let end = shape[1];

  while (end <= array.length) {
    reshaped.push(array.slice(start, end));
    start += shape[1];
    end += shape[1];
  }

  return reshaped;
};
