/* eslint-disable */
// @ts-nocheck

import React, { Component } from "react";
import SliderEdit from "../../components/common/ant-novak/SliderEdit";
// import SliderEdit from "../UI/SliderEdit";
import DistortionGraph from "./Distortion.graph";
import DistortionAudio from "./Distortion.audio";
import { Dropdown } from "@jelly/ui";

// -------------------------
// Distortion (of a loudspeaker):
// - Input sine signal (500 Hz) of amplitude A (slider variable)
//   passes through a loudspeaker (simulated - options to be seected).
//   Two graphs are shown:
//   - input and output signal (one period)
//   - Power Spectrum (20*log10(abs(fft(...)))) of the output signal
// -------------------------

class Distortion extends Component {
  state = {
    A: 0.5, // amplitude of the input signal
    indexOfNLFunction: 0, // index of the selected nonlinear function
  };

  // slider to change the amplitude of the input signal
  sliderA = {
    name: "A",
    unit: "",
    min: 0,
    max: 1.0,
    step: 0.01,
  };

  // list of Nonlinear Functions user can choose from
  NLfunctions = [
    {
      name: "Linear (no distortion)",
      fnc: (x) => {
        return x;
      },
    },
    {
      name: "Week nonlinearities (low distorton)",
      fnc: (x) => {
        const vec = [1, 0.07, -0.04, -0.09, 0.02, 0.05];
        return vec.reduce((acc, v, idx) => acc + v * Math.pow(x, idx + 1), 0);
      },
    },
    {
      name: "Stronger nonlinearities (higher distortion)",
      fnc: (x) => {
        const vec = [1.11, -0.3, 0.5, 0.7, -9.7, -4, 47, 15, -108, -26.5, 131, 22, -81, -7, 20];
        return vec.reduce((acc, v, idx) => acc + v * Math.pow(x, idx + 1), 0);
      },
    },
  ];

  // handle for select (Nnlinear function)
  handleSelectChange = (value) => {
    this.setState({ indexOfNLFunction: value });
  };

  // handle for slider (Amplitude)
  handleSliderChange = (name, value) => {
    // check if user has entered a numeric value
    if (!isNaN(value)) {
      this.setState({ [name]: value });
    }
  };

  renderBlock() {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "calc(100vh - 40px)" }}>
        <div className="one-graph" style={{ width: "100%", flexGrow: "1", flexShrink: "0" }}>
          <DistortionGraph A={this.state.A} NLfnc={this.NLfunctions[this.state.indexOfNLFunction].fnc} />
        </div>
        <div style={{ flexGrow: "0", marginTop: "1em" }}>
          <SliderEdit onChange={this.handleSliderChange} params={this.sliderA} val={this.state.A} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "70%", margin: "auto" }}>
          <div style={{ minWidth: "300px" }}>
            <Dropdown
              options={this.NLfunctions.map((NLfnc, index) => {
                return { children: NLfnc.name, value: index };
              })}
              listSize="small"
              value={this.state.indexOfNLFunction}
              onChange={this.handleSelectChange}
            />
          </div>
          <DistortionAudio A={this.state.A} NLfnc={this.NLfunctions[this.state.indexOfNLFunction].fnc} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"app"}>
        <div className={"my-div"}>{this.renderBlock()}</div>
      </div>
    );
  }
}

export default Distortion;
