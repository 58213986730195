// import { besselJ } from "../../helpers/math.helper";
// import { AirParameters } from "../../helpers/helpers";

// calculate directivity in [dB]
import { AirParameters } from "../../helpers/ant-novak/helpers";
import { besselJ } from "../../helpers/ant-novak/math.helper";

export function directivity(radius, frequency, N, dBLimit) {
  // data will contain array of {angle, dB}
  const data = Array(N + 1);

  // k .. wave length
  const k = (2 * Math.PI * frequency) / AirParameters.c0;

  for (let i = 0; i < N; i++) {
    // theta is the angle (from -PI/2 to PI/2 in N steps)
    const theta = Math.PI * (i / N) - Math.PI / 2;

    // k*a*sin(theta)
    const k_a_sin = k * radius * Math.sin(theta);

    let dB = 20 * Math.log10(Math.abs((2 * besselJ(1, k_a_sin)) / k_a_sin));

    // limiter to avoid values outside graph
    dB = dB < dBLimit ? dBLimit : dB;

    data[i] = {
      angle: theta,
      dB: k_a_sin === 0 ? 0 : dB,
    };
  }

  // set last point to NaN so that the radar graph is not closed (first and last point not connected)
  data[N] = NaN;

  return data;
}
